"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function joinNotEmpty(words, separator = ' ', trim = true) {
  let result = '';
  words.forEach(word => {
    const trimmed = (word || '').trim();
    if (word && (!trim || trimmed)) {
      if (result.length > 0) result += separator;
      result += trim ? word : trimmed;
    }
  });
  return result;
}
exports.default = joinNotEmpty;