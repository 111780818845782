"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseI18Object = void 0;
exports.baseI18Object = {
  sqvr: {
    sqvr: '',
    street: '',
    house: '',
    address: '',
    registry: '',
    polls: '',
    naturalPerson: '',
    legalPerson: '',
    title: ''
  },
  common: {
    yes: '',
    no: '',
    ok: '',
    save: '',
    notSave: '',
    close: '',
    cancel: '',
    retry: '',
    cancelShort: '',
    cancelChanges: '',
    exit: '',
    select: '',
    search: '',
    all: '',
    settings: '',
    home: '',
    registry: '',
    userRights: '',
    committees: '',
    contractors: '',
    generalInfo: '',
    enter: '',
    entered: '',
    reset: '',
    warning: '',
    forgotPassword: '',
    continue: '',
    add: '',
    create: '',
    createNew: '',
    requestError: '',
    required: '',
    return: '',
    share: '',
    m2: '',
    and: '',
    inDevelopment: '',
    authorizationRequired: '',
    unhandledError: '',
    badGatewayError: '',
    contacts: '',
    messages: '',
    description: '',
    witnessInfo: '',
    selectedName: '',
    email: '',
    useEmail: '',
    phone: '',
    sms: '',
    useSms: '',
    delete: '',
    logoutFromAccount: '',
    loginInObject: '',
    changesHistory: '',
    register: '',
    author: '',
    status: '',
    other: '',
    filters: '',
    noName: '',
    generated: '',
    date: '',
    endDate: '',
    copy: '',
    publish: '',
    hide: '',
    detail: '',
    questionNumber(args) {},
    averageMark: '',
    ball: '',
    fromTen: '',
    additionalMenu: '',
    template: '',
    useTemplate: '',
    applyTemplate: '',
    makeCopy: '',
    goToEdit: '',
    noVote: '',
    vote: '',
    inputYourOption: '',
    watchResults: '',
    reVote: '',
    preview: '',
    questions: '',
    edit: '',
    backToEditing: '',
    preliminaryResults: '',
    templateChoosing: '',
    noTemplates: '',
    coverChoosing: '',
    uploadImage: '',
    pollPreview: '',
    pollPublish: '',
    pollDelete: '',
    name: '',
    addQuestion: '',
    choose: '',
    chooseDate: '',
    chooseUserName: '',
    addLink: '',
    uploadLimit: '',
    maxFilesCountExceed: '',
    questionName: '',
    questionType: '',
    option: '',
    deleteOption: '',
    addOption: '',
    userInputOption: '',
    attachFile: '',
    useFilters: '',
    showAllFilters: '',
    error: '',
    send: '',
    secondsShort: '',
    uploadError: '',
    confirm: '',
    inputMessageText: '',
    change: '',
    theme: '',
    notificationTitle: '',
    notifyUsers: '',
    nothingFound: '',
    noMatch: '',
    clearSelection: '',
    complete: '',
    comment: '',
    usersSearch: '',
    loadMore: '',
    pay: '',
    active: '',
    notActive: '',
    more: '',
    polls: '',
    meetings: '',
    loading: '',
    noData: '',
    noDataToFilter: '',
    filtersDeleted(args) {},
    filterDeleted(args) {},
    filterDeletedAll(args) {},
    sqvrSystemUser: '',
    addCover: '',
    sign: '',
    refuse: '',
    commentAndClose: '',
    doComment: '',
    smsVerificationCodeSent: '',
    days(args) {},
    votes(args) {},
    join: '',
    joinToObject: '',
    results: '',
    countShortTitle: '',
    squareTitle: '',
    votePercentsTitle: '',
    total: '',
    notSpecified: '',
    copyLink: '',
    apply: '',
    important: '',
    confirmSending: '',
    yesDelete: '',
    rejectSending: '',
    noUser: '',
    hiddenUser: '',
    hiddenData: '',
    legalUser: '',
    individualUser: '',
    cancelChoise: '',
    whatsapp: '',
    telegram: '',
    telegramBot: '',
    byCall: '',
    byEmail: '',
    byWhatsApp: '',
    byTelegram: '',
    bySMS: '',
    rejectDeleting: '',
    paymentType: '',
    done: '',
    inviteLink: '',
    appealsTooltip: '',
    addComment: '',
    view: '',
    noMoreShow: '',
    rulesForSendingNotifications: '',
    rulesForSendingInvitations: '',
    rulesForSendingInvitationsTelegram: '',
    download: '',
    bold: '',
    italic: '',
    underline: '',
    viewDocument: '',
    menu: '',
    meetingToastText: '',
    meetingToastTitle: '',
    meetingToastButton: '',
    payTariff: '',
    fillFields: '',
    gotItText: '',
    clickToSort: '',
    notingToSort: '',
    selectItemForSort: '',
    or: '',
    your: '',
    selectAll: '',
    success: '',
    from: '',
    today: '',
    captchaError: '',
    buildingSuffix: '',
    buildingSuffix2: '',
    timeAt: '',
    profile: '',
    union: '',
    answer: '',
    reply: '',
    comments: '',
    ruble: '',
    piecesShort: '',
    availableIn: '',
    downloadedIn: '',
    toAddOwnerFillFields: '',
    onlyJoinOwnershipIsAvailableForAddition: '',
    dateCantBeMoreCurrentDate: '',
    dateHasError: '',
    fieldCantBeEmpty: '',
    addPerson: '',
    registered: '',
    notRegistered: '',
    row(args) {},
    was(args) {},
    deleted(args) {},
    deleteed(args) {},
    rowsDeleted: '',
    successfully: '',
    she(args) {},
    concern(args) {},
    not: '',
    alredyRegisteredUers: '',
    forDeleteRegisteredUsers: '',
    columnSettings: '',
    defaultSettings: '',
    edited: '',
    possibleOnly: '',
    goTo: '',
    copied: '',
    noInfoForCopy: '',
    highPriority: '',
    rowHasErrors: '',
    registryUnlocked: '',
    acceptApplication: '',
    fixedAllErrors: '',
    acceptedApplication: '',
    errorWithAcceptedApplication: '',
    fileIsEmpty: '',
    errorInFileFormation: '',
    clearColumns: '',
    clearRows: '',
    attach: '',
    serverErrorWithUploadFile: '',
    admin: '',
    notJoin: '',
    registration: '',
    complaints: '',
    house: '',
    property: '',
    codeSent: '',
    unknown: '',
    find: '',
    housing: '',
    russia: '',
    password: '',
    confirmPassword: '',
    street: '',
    userAvatar: '',
    organization: '',
    addHouse: '',
    user: '',
    realty: '',
    login: '',
    activate: ''
  },
  filesUpload: {
    uploadError: '',
    maxFileSizeExceed: '',
    maxFileSizeExceedCustom(args) {},
    maxFilesCountExceed: '',
    maxFilesCountExceedCustom(args) {},
    preventSelectMoreFiles(args) {},
    preventSelectMoreFilesText1(args) {},
    preventSelectMoreFilesText2(args) {},
    preventSelectNewFiles: '',
    filesUpload: '',
    filesUploadTitle(args) {},
    successUpload(args) {},
    cancelUpload: '',
    filesUploadRestriction: '',
    upload: '',
    availableFileFormats: '',
    image: '',
    audio: '',
    video: '',
    archive: '',
    document: '',
    selectFiles(args) {},
    fileSizeLimit(args) {},
    availableSpace: '',
    selectedFiles: '',
    wrongFormat: '',
    noSpaceOnTheDisk: '',
    messageOfAvailableDiskSpace(args) {},
    successFilesUpload: '',
    errorFileUpload: '',
    estimatedTimeForUpload(args) {},
    switchToTariffs: '',
    reloadFileUpload: '',
    reloadFileUploadS3: '',
    countFilesWithErrors(args) {}
  },
  socialNetworks: {
    facebook: '',
    twitter: '',
    vk: '',
    google: ''
  },
  messages: {
    sendingEmailForAddressError(args) {},
    successPasswordReset(args) {},
    errorPasswordReset(args) {},
    wrongPassword: '',
    emailVerificationError: '',
    emailVerificationSent(args) {},
    socialAuthError: '',
    recaptchaModalTitle: ''
  },
  components: {
    dateAndTime: {
      recommendedDate: '',
      notRecommendedDate: ''
    },
    navigationMenu: {
      search: '',
      contacts: '',
      documents: '',
      usefulAddresses: '',
      desires: '',
      announcements: '',
      digest: '',
      meetings: '',
      polls: '',
      budget: '',
      financialReports: '',
      management: '',
      howItWork: '',
      howToBegin: '',
      forOwners: '',
      bills: '',
      invite: '',
      inviteTooltip: '',
      changeObject: '',
      communication: '',
      messages: '',
      support: '',
      tariffs: '',
      pretensions: '',
      pretension: '',
      petitions: '',
      myProfile: '',
      feedback: '',
      exit: '',
      reservations: '',
      instructions: '',
      forAdmins: '',
      vote: '',
      delivery: '',
      appeals: '',
      financial: '',
      help: '',
      info: '',
      calendar: ''
    },
    header: {
      search: '',
      tips: '',
      writeToSupport: '',
      messages: '',
      integrator: '',
      resident: '',
      contractor: '',
      hoaEmployee: '',
      managementStuff: '',
      moderator: '',
      admin: '',
      changeObject: '',
      areYouOwner: '',
      areYouOwnerInObject: '',
      wantToUserSqvr: '',
      thenFillTheForm: '',
      fillTheForm: ''
    },
    footer: {
      copywrite(args) {},
      policyAndLicenseLinkText: '',
      policyAndLicenseModalTitle: '',
      policyAndLicenseModalButtonClose: '',
      googleAppLink: '',
      appStoreAppLink: '',
      ruStoreAppLink: '',
      commonAppLink: '',
      googleAppLinkText: '',
      appStoreAppLinkText: '',
      ruStoreAppLinkText: '',
      commonAppLinkText: ''
    },
    searchFilters: {
      selectedFiltersNum(args) {},
      clearFilters: '',
      deleteFilter: '',
      resetFiltersAlert: '',
      clear: '',
      showMore: '',
      selectedFilters: '',
      show: '',
      filtersInfoModalTitle: '',
      warning: '',
      saveSelected: '',
      cleanSelected: '',
      onChangeSaveOrClean: '',
      saveOrClearSelect: '',
      filtersInfoModalDescription: '',
      groupsInfoModalTitle(args) {},
      groupsInfoModalHelperText: ''
    },
    editCommentPopper: {
      placeholder: '',
      placeholderWithMinSymbolsCount: '',
      saveEnteredData: '',
      placeholderDelete: '',
      title: ''
    },
    notifications: {
      notifications: '',
      notificationsHint: '',
      markAllAsRead: ''
    },
    textEditor: {
      inputLink: '',
      userNotFound: '',
      mentionToolTooltip: '',
      insertDateToolTooltip: '',
      attachFilesToolTooltip: '',
      attachFilesToolDisabledTooltip: '',
      boldToolTooltip: '',
      italicToolTooltip: '',
      underlineToolTooltip: '',
      sendToolTooltip: '',
      discardToolTooltip: '',
      commentPlaceholder: ''
    },
    appSearch: {
      byRequest: '',
      nothingFound: '',
      contractors: '',
      owners: '',
      budget: '',
      works: '',
      workOn: '',
      complaints: '',
      meetings: '',
      documents: '',
      announcements: '',
      managementAnnouncement: '',
      urgentManagementAnnouncement: '',
      ownAnnouncement: '',
      urgentOwnAnnouncement: '',
      newComplaint: '',
      completedComplaint: '',
      inWorkComplaint: '',
      rejectedComplaint: '',
      employeesInnerGroup: '',
      budgetInnerGroup: '',
      worksInnerGroup: '',
      contractsInnerGroup: '',
      normalMeeting: '',
      virtualMeeting: '',
      semiVirtualMeeting: '',
      ownersMeeting: '',
      managementMeeting: '',
      committeeMeeting: '',
      residentsMeeting: '',
      finishedRejectedMeeting: '',
      rejectedMeeting: '',
      closedMeeting: '',
      draftMeeting: '',
      visibleDraftMeeting: '',
      activeMeeting: '',
      calcResultsMeeting: '',
      registrationMeeting: '',
      votingMeeting: '',
      finishedAcceptedMeeting: '',
      protocolReviewMeeting: '',
      resident: '',
      hoaEmployee: '',
      contractor: '',
      integrator: '',
      moderator: '',
      managementStuff: '',
      admin: '',
      resultSearchText: '',
      emptyResultSearchText1: '',
      emptyResultSearchText2: '',
      unknown: ''
    },
    policyAccept: {
      accept: '',
      rules: '',
      and: '',
      policy: ''
    },
    hoaSelect: {
      title: '',
      selectPlaceholder: '',
      loadingMessage: '',
      nothingFound: ''
    },
    userSelection: {
      ownersSelect: '',
      ownersSearch: '',
      selectAll: '',
      needSelectSomeone: ''
    },
    notificationModals: {
      usersInvite: '',
      usersNotification: '',
      userNameTemplate: '',
      selectedRows(args) {},
      rowsRelated(args) {},
      registeredUsersWarning1(args) {},
      registeredUsersWarning2(args) {},
      registeredUsersWarning3(args) {},
      registeredUsersWarning4: '',
      notRegisteredNotContactUsersSingleWarning(args) {},
      notRegisteredNotContactUsersMultipleWarning(args) {},
      otherWillReceiveInvite: '',
      invitesHaveSuccessfullySent: '',
      deliveryCanTakeSomeTime(args) {},
      notificationDeliveryCanTakeSomeTime(args) {},
      noOneInviteSendingChannelSelected: '',
      inviteSendingChannelWarning(args) {},
      notifySendingChannelWarning(args) {},
      emailOrPhoneChannel: '',
      emailChannel: '',
      phoneChannel: '',
      inviteSentSuccessfully(args) {},
      notificationSentSuccessfully(args) {},
      inviteAllNotRegisteredUsers: '',
      inviteOwnersIntelegram: '',
      inviteOwnersInSqvr: '',
      smsEnableHint: '',
      addUsersHint: '',
      changeUsersHint: '',
      userNameMailHint: '',
      notEditableFieldsHint: '',
      previewHint: '',
      cantSend(args) {},
      greetings: '',
      nameMailSubstitution: '',
      smsInvitePlaceholder(args) {},
      smsInvitePlaceholderHoaName: '',
      smsInvitePlaceholderLink: '',
      smsInvitePlaceholderUserName: '',
      notifications: '',
      inviteToSqvr: '',
      notificationsPreview: '',
      invitePreview: '',
      whom: '',
      highPriority: '',
      recipientsCount(args) {},
      recipientsCountNoRecipients: '',
      smsUsedSymbols(args) {},
      smsUsedSymbolsShort(args) {},
      smsCount(args) {},
      smsTotal(args) {},
      saveDialogTitle: '',
      saveDialogText: '',
      saveDialogYesLabel: '',
      saveDialogNoLabel: '',
      inviteUserSelectionHint: '',
      notifyUserSelectionHint: '',
      inviteMailPreview1: '',
      inviteMailPreview2: '',
      inviteMailPreview3: '',
      inviteMailPreview4: '',
      inviteMailPreview5: '',
      inviteMailPreview6: '',
      inviteMailPreview7: '',
      inviteMailPreview8: '',
      inviteMailPreview9: '',
      inviteMailPreview10: '',
      inviteMailPreview11: '',
      youHaveSelected: '',
      onlyGroups(args) {},
      onlyUsers(args) {},
      notifyNotRegisteredUsersWarning: '',
      registryNotifyNotRegisteredUsersWarning(args) {},
      selectedUser(args) {},
      notRegisteredUser(args) {},
      usersRelated(args) {},
      userWithoutContactData(args) {},
      selectionWithNotRegisteredUserAndWithoutEmail(args) {},
      selectionWithNotRegisteredUsers(args) {},
      selectionWithNoContactUsersSingle: '',
      selectionWithNoContactUsers(args) {},
      needToSelectSendingChannel: '',
      usersNotificationConfirmationTitle: '',
      usersNotificationConfirmationRecipientsTotal(args) {},
      usersNotificationConfirmationCannotBeNotified(args) {},
      chooseUsers: '',
      sendMe: '',
      sendMeInfoText: '',
      choseOrDeleteUsersTooltip: '',
      choseUsersTooltip: '',
      change: '',
      statisticsTooltipUsers: '',
      hasNoLimit: '',
      statisticsTooltipText: '',
      statisticsTooltipText1: '',
      statisticsTooltipText2: '',
      recipients: '',
      cancelChoice: '',
      cancelChoiceAlertText: '',
      clean: '',
      revertChoice: '',
      revertChoiceAlertText: '',
      revert: '',
      close: '',
      cancel: '',
      continue: '',
      notHaveSelectedUsers: '',
      continueDisabledTooltip: '',
      notificationWarningTooltip: '',
      statisticsTitle: '',
      detailStatisticsTitle: '',
      detailStatisticsText0: '',
      detailStatisticsText1: '',
      detailStatisticsText2: '',
      detailStatisticsText3: '',
      detailStatisticsText4: '',
      detailStatisticsText5: '',
      detailStatisticsText6: '',
      items: '',
      allSelected: '',
      canNotify: '',
      notReceive: '',
      needEmailLimit: '',
      needSmsLimit: '',
      receive: '',
      finalResult: '',
      usedLimits: '',
      counts(args) {},
      limitsError: '',
      buyMore: '',
      showMore: '',
      yesBuy: '',
      yseSend: '',
      buyLimitsButton: '',
      hasNoLimits: '',
      afterYouCanReturn: '',
      sendWithoutLimitText: '',
      area: '',
      vote: '',
      include: '',
      hasEmail: '',
      hasPhone: '',
      hasPush: '',
      hasTgBot: '',
      hasApp: '',
      notRegistered: '',
      isRegistered: '',
      disabledNotification: '',
      spamList: '',
      onlySms: '',
      onlyEmail: '',
      bothOrMore: '',
      moreNotification: '',
      forEmail: '',
      forSms: '',
      aboutSmsCountSymbols(args) {},
      aboutSmsCountRecipients(args) {},
      hintTextPush: '',
      hintTextEmail: '',
      hintTextPhone: '',
      hintTextTg: '',
      hintBoth: '',
      sendSuccessMessage(args) {},
      sendSuccessInviteMessage(args) {},
      back: '',
      notIndicated: '',
      availableLimits: '',
      emailLimits(args) {},
      smsLimits(args) {},
      notEnoughEmail(args) {},
      notEnoughSms(args) {},
      recomendedBuyLimits: '',
      recomendedBuyLimitsLong: '',
      onlyNotRigisteredUsers: '',
      useEmail: '',
      useSms: '',
      youCan: '',
      buyLimits: '',
      reduceCount: '',
      continueWithReducingCount: '',
      selectEmail: '',
      selectSms: '',
      reduceText: '',
      countOfMessages(args) {},
      unlimited: '',
      enough: '',
      canReceiveEmail: '',
      canReceiveSms: '',
      cantReceive: '',
      cantSendWarning: '',
      notReceiveInvite: '',
      needLimit: '',
      alreadyRegistered: '',
      disableNotify: '',
      spammers: '',
      notOwners: '',
      noContacts: '',
      noEmail: '',
      noPhone: '',
      noLimitsText1: '',
      noLimitsText2: '',
      limitsForSending: '',
      messages(args) {},
      notUse: '',
      alreadyHasTgBot: '',
      noValidUsersSelected: '',
      noUsersSelected: '',
      isInviteInfoPopperText1: '',
      isInviteInfoPopperText2: '',
      isInviteInfoPopperText5: '',
      filterPopperText1: '',
      filterPopperText2: '',
      filterPopperText3: '',
      filterPopperText4: '',
      filterPopperText5: '',
      telegramInfoPopperText1: '',
      telegramInfoPopperText2: '',
      infoPopperText1: '',
      infoPopperText2: '',
      infoPopperText3: '',
      infoPopperText4: '',
      infoPopperText5: '',
      infoPopperText6: '',
      infoPopperText7: '',
      infoPopperText8: '',
      infoPopperText9: '',
      infoPopperText10: '',
      infoPopperText11: '',
      total: '',
      recipientsSelected: '',
      youHaveNotCompletedSendingNotification: ''
    },
    changesHistory: {
      changesHistory: '',
      hideFilters: '',
      showFilters: '',
      closeHistory: '',
      profileChangedAction: '',
      ownerRemovedAction: '',
      ownerAddedAction: '',
      ownerDataHasBeenCleanedAction: '',
      apartmentWasDeletedAction: '',
      addressChangedAction: '',
      areaOfApartmentChangedAction: '',
      houseWasDeletedAction: '',
      houseWithApartmentWasDeletedAction: '',
      changeAccessLevelAction: '',
      changeContactInfoAction: '',
      thereWasImportOfRegistry: ''
    },
    pepSiginingModal: {
      requestCode: '',
      resendCode: '',
      requestCodeTimoutComment: '',
      inputReceivedCode: '',
      inputPhoneNumber: '',
      savePhone: '',
      requestCodeAgain: '',
      disableTooltipTextShouldWait: '',
      disableTooltipTextNoPhone: ''
    },
    telegramModal: {
      modalTitle: '',
      exit: '',
      connectTelegram: '',
      chooseUsersOne(args) {},
      chooseUsersFew(args) {},
      ofThem: '',
      noRegistreredOne(args) {},
      noRegistreredFew(args) {},
      hasTelegramOne(args) {},
      hasTelegramFew(args) {},
      offNotificationsOne(args) {},
      offNotificationsFew(args) {},
      other: '',
      all: '',
      uniqueLinks: '',
      uploadQR: '',
      or: '',
      copyLink: '',
      copySuccess: '',
      forMessengerAndAd: '',
      goToTariffs: '',
      back: '',
      noLimits(args) {},
      mayGo: '',
      tariffs: '',
      pay: '',
      wasSentOne(args) {},
      wasSentFew(args) {},
      noLimitsSms(args) {},
      noLimitsEmail(args) {},
      nobodyChoose: '',
      chooseOther: '',
      tooltip: '',
      invitationSMSTemplate: ''
    },
    commentFeed: {
      titleThreadFeed: '',
      titleCommentFeed: '',
      placeholder: '',
      closeCommentFeed: '',
      buttonText: '',
      showAnswers: '',
      addComment: '',
      copyComment: '',
      copySuccess: '',
      replyComment: '',
      deleteModalTitle: '',
      deleteModalContent: '',
      infoModalTitle: '',
      edited: '',
      yesLabel: '',
      noLabel: '',
      commentYesLabel: '',
      commentNoLabel: '',
      infoModalContentLKP: '',
      InfoModalContentOLK: ''
    },
    usefulAddress: {
      copyAddress: '',
      editAddress: '',
      deleteAddress: '',
      deleteAddressModalTitle: '',
      deleteAddressModalText: '',
      deleteAddressModalYes: '',
      deleteAddressModalNo: '',
      addressLabel: '',
      contactsLabel: '',
      descriptionLabel: '',
      reviewLabel: '',
      deleteAddressSuccess: '',
      deleteReviewSuccess: '',
      addFavoriteToast: '',
      removeFavoriteToast: '',
      sendReview: '',
      more: '',
      noReview: '',
      beeFirst: '',
      clickToButton: '',
      nothingFound: '',
      noAddresses: '',
      addFirstAddress: '',
      deleteAddressReview: '',
      deleteAddressReviewText: '',
      createReviewSuccess: '',
      editReviewSuccess: '',
      createReviewTitle: '',
      yourReviewTitle: '',
      ratingLabel: '',
      commentLabel: '',
      submitButton: '',
      cancelButton: '',
      tooltipTitle: '',
      copyUrl: '',
      emptyReviews: '',
      notificationModalTitle: '',
      notificationReviewModalDesc: '',
      notificationAddressModalDesc: ''
    },
    messages: {
      dialogsFeedDrawerTitle: '',
      noMessages: '',
      noMessagesAmi: '',
      searchTextLimitError(args) {},
      noSearchResult: '',
      pleaseEnterAnotherQuery: '',
      userWasDeletedText: '',
      youAreBlacklistedText: '',
      userWasBlacklistedTooltipText: '',
      userWasBlacklistedFooterText: '',
      removeFromBlackListModalText: '',
      noContacts: '',
      alertModalTitle: '',
      removeFromBlackListTitle: '',
      alertModalText: '',
      messageWasDeleted: '',
      typing: '',
      writeMessages: '',
      inReplyTo: '',
      removeFromBlackList: '',
      keepInBlackList: '',
      userDeletedTitle: '',
      userDeletedText: '',
      youOffNotify: '',
      amiChatText: '',
      messageCopied: '',
      adminModerators: '',
      messageWasNotEdited: ''
    },
    fastMenu: {
      writeAnnouncement: '',
      creatingComplaint: '',
      book: ''
    },
    table: {
      selectedAllText: '',
      settingsUpColumns: '',
      defaultSettings: '',
      close: ''
    },
    appeals: {
      title: '',
      goToPrevAppealButtonTooltip: '',
      goToNextAppealButtonTooltip: '',
      collapseAppeals: '',
      expandAppeals: ''
    },
    files: {
      downloadAll: '',
      previousFile: '',
      nextFile: ''
    }
  },
  modules: {
    app: {
      payment: {
        order: '',
        orderRegistry: '',
        orderRegistrySqvr(args) {},
        orderRegistryDescription(args) {},
        orderRegistrySecondaryDescription: '',
        download: '',
        downloadStatement: '',
        apply: '',
        applyStatement: '',
        free: '',
        noWarranties: '',
        submitAStatement: '',
        statementRosreestr: '',
        statementRosreestrWithCheckData: '',
        orderTotalCost: '',
        currencyValue(args) {},
        cost: '',
        dataValidity: '',
        days(args) {},
        unitCost(args) {},
        fullRosreestrOrderText(args) {},
        editOrder: '',
        proceedToCheckout: '',
        toPay: '',
        customer: '',
        delivery: '',
        email: '',
        executionPeriod: '',
        proceed: '',
        cardData: '',
        cardDataDescription: '',
        policyDescription: '',
        policy(args) {}
      },
      types: {
        unknown: '',
        apartment(args) {},
        membership(args) {},
        ownership(args) {},
        person(args) {}
      },
      validateMessages: {
        oneOfFieldsIsRequired: '',
        fieldCannotBeEmpty: '',
        mustBeNumber: ''
      },
      messages: {
        serverError: '',
        stepPart1: '',
        stepPart2: '',
        serverConnectionError: '',
        serverRequestError: '',
        serverRequestErrorDescription: '',
        atTheMomentTheServerIsUnderMaintenance: '',
        successfullySaved: '',
        innerErrorApp: '',
        notPossibleToSwitch: '',
        emailOfCurrentUserIsNotSpecified: '',
        selectedAddressWasNotFound: '',
        addressIdIsNotRecognized: ''
      },
      tooltipTitles: {
        hideHints: '',
        openAllHints: '',
        showHints: '',
        needToSelectPeople: ''
      },
      labels: {
        linkToSurvey: '',
        selectOwner: '',
        searchUser: '',
        selectAll: '',
        street: '',
        embankment: '',
        avenue: '',
        boulevard: '',
        lane: '',
        deadEnd: '',
        square: '',
        directions: '',
        tract: '',
        alley: '',
        highway: '',
        row: '',
        line: '',
        user(args) {},
        registryRow(args) {},
        registryCol(args) {},
        registryChoose(args) {},
        registryChooseCols(args) {},
        filtersChoose(args) {},
        filterRow(args) {},
        rowsAfterAction(args) {},
        rowsChoose(args) {},
        rowsRelate(args) {}
      },
      modals: {
        isVerifiedChangesModalText1: '',
        isVerifiedChangesModalText2Part1: '',
        isVerifiedChangesModalText2Part2: '',
        isVerifiedChangesModalText2Part3: '',
        isVerifiedChangesModalText2Part4: '',
        isVerifiedChangesModalText2Part5: '',
        isVerifiedChangesModalText3Part1: '',
        isVerifiedChangesModalText3Part2: '',
        isVerifiedChangesModalText3Part3: '',
        isVerifiedChangesModalText3Part4: '',
        isVerifiedChangesModalText4Part1: '',
        isVerifiedChangesModalText4Part2: '',
        importChangeTownSettingsModalText: '',
        totalArea: '',
        inImportedRegistry: '',
        selectedSpaces: '',
        dataOfAllUnregisteredUsersWillBeDeleted: '',
        changeSettingsOfHouseToRequiredValues: '',
        importChangeVillageSettingsModalText: '',
        ownershipMergeModalText: '',
        ownershipMergeForManyRegisteredUsersModalTextPart1: '',
        ownershipMergeForManyRegisteredUsersModalTextPart2: '',
        ownershipMergeForOneRegisteredUserModalText1: '',
        ownershipMergeForOneRegisteredUserModalText2: '',
        registryBlockedModalText1: '',
        registryBlockedModalText2: '',
        selectPeopleModalTitle: '',
        selectPeopleModalSubTitleV1: '',
        selectPeopleModalSubTitleV2: '',
        selectPeopleModalSubTitleV3: '',
        selectPeopleModalTextPart1: '',
        selectPeopleModalTextPart2: '',
        selectPeopleModalTextPart3: ''
      }
    },
    registry: {
      messageTexts: {
        notFoundRowsToDelete: '',
        unknownStatus(args) {},
        noUsersForMerge: '',
        availableOnlyJoinOwnership: '',
        notAvailableOwnershipNotFoundFreeSpace: '',
        editingAllowedOnlyForJurPersons: '',
        impossibleAddingNotFoundOwner: '',
        impossibleEditingCell: '',
        serviceRowNotEditing: '',
        canNotEditInRoomView: '',
        joiningRequestContactDataChangeWarning: '',
        registryWithJoiningRequestsDeleteWarning: '',
        joiningRequestAccepted(args) {},
        joiningRequestRejected(args) {},
        registryDeleteWarningTitle: '',
        registryDeleteWarningMessageFirst: '',
        registryDeleteWarningMessageSecond: '',
        registryDeleteWarningNotification: '',
        hideThisAlert: '',
        cantEdit(args) {},
        fio: '',
        juristicPerson: '',
        states: '',
        formRegisteredType(args) {},
        rowsCleared: '',
        statusRowsCleared(args) {},
        hasNoRows: ''
      },
      columnTitles: {
        id: '',
        checkbox: '',
        homeAddress: '',
        homeAddressHeader: '',
        street: '',
        unit: '',
        unitFull: '',
        letter: '',
        addressErrors: '',
        apartment: '',
        apartmentFull: '',
        apartmentFullSecond: '',
        apartmentHeader: '',
        totalArea: '',
        totalAreaHeader: '',
        area: '',
        share: '',
        shareHeader: '',
        ownershipPercent: '',
        ownershipPercentHeader: '',
        votePercent: '',
        votePercentHeader: '',
        voteGroup: '',
        entrance: '',
        entranceFull: '',
        floor: '',
        floorFull: '',
        propertyUsageType: '',
        propertyUsageTypeHeader: '',
        propertyUsageTypeFull: '',
        ownershipType: '',
        ownershipTypeFull: '',
        ownershipTypeHeader: '',
        personType: '',
        personTypeFull: '',
        membership: '',
        ownerGroup: '',
        lastName: '',
        lastNameFull: '',
        firstName: '',
        firstNameFull: '',
        middleName: '',
        middleNameFull: '',
        inn: '',
        phones: '',
        emails: '',
        cadastreCode: '',
        ownershipDocumentGroup: '',
        ownershipDocumentName: '',
        ownershipDocumentNameFull: '',
        ownershipDocumentNumber: '',
        ownershipDocumentNumberFull: '',
        ownershipDocumentIssueDate: '',
        ownershipDocumentIssueDateFull: '',
        ownerDocumentNumberFullName: '',
        ownerDocumentGroup: '',
        ownerDocumentType: '',
        ownerDocumentTypeFull: '',
        ownerDocumentNumber: '',
        ownerDocumentNumberFull: '',
        ownerDocumentIssueDate: '',
        ownerDocumentIssueDateFull: '',
        addressGroup: '',
        buildingNumber: '',
        snils: ''
      },
      tooltipTitles: {
        registryColumnSettings: '',
        columnSettingsWithHiddenRows: '',
        discardChanges: '',
        discardChangesDisabled: '',
        joinInvite: '',
        joinInviteDisabled: '',
        joinRequests: '',
        joinRequestsVillage: '',
        joinRequestsDisabled: '',
        registryHistory: '',
        registryHistoryDisabled: '',
        registryFilters: '',
        registrySelectColumn: '',
        registrySortColumn: '',
        changeShipTypeInColumn(args) {},
        registryBlocked: '',
        isNotCleanableColumn: '',
        filtersChip: '',
        disableFilter(args) {},
        allowMeeting: '',
        unknownPersonNotice: '',
        tempRows: '',
        downloadVersion: '',
        noLink: '',
        unknownDate: '',
        nothingToClear: ''
      },
      hintTitles: {
        registryColumnSettings: '',
        cellWithError: '',
        discardChanges: '',
        joinRequests: '',
        registryHistory: '',
        registryFilters: '',
        registryMenu: '',
        unknown: '',
        personTypeNatural: '',
        personTypeLegal: '',
        personTypeGoverment: '',
        membershipTypeNotMember: '',
        membershipTypeMember: '',
        total: '',
        reformaGKH: '',
        valFromSettings: '',
        spaceSettingsDiffReestr: '',
        areaSettingsGtReestr: '',
        roomsSettingsGtReestr: '',
        columnNotSelected: '',
        columnSuccessCleared: '',
        columnStatusUnknown: '',
        notSelectRow: '',
        notSelectRowToDelete: '',
        notSelectRowToNotification: '',
        downloadRows: '',
        clearRows: '',
        clearCol: '',
        unknownU: '',
        unknownUCellLabel: '',
        visitUserCard: '',
        cellHaveErrors: '',
        cellHaveErrorsAndSave: '',
        successUpdate: '',
        successAdded: '',
        successUploaded: '',
        successDeleted: '',
        colDisabled: '',
        notifyRequestErrorMessage: '',
        notifyRequestSuccessMessageNotify: '',
        notifyRequestSuccessMessageInvite: '',
        streetBlock: ''
      },
      rowsControlsTitles: {
        openedApartmentChipTitle(args) {},
        showAllOwnersOfOneApartment: '',
        youAlreadyInApartment: '',
        notSelectedOrMultipleSelected: '',
        isOneUserSelectedForConnect: '',
        isUnknownSelectedForConnect: '',
        isSameApartmentSelectedForConnect: '',
        addOwner: '',
        deleteRows: '',
        sendNotification: '',
        connectApartmentToOwner: '',
        search: ''
      },
      confirmTitles: {
        youHaveUnsavedChangesBeforeLeave: '',
        stringHasErrors: '',
        numberCantBeMore: ''
      },
      errorTooltips: {
        userCanNotBeAdd: '',
        userIsRegisteredCanNotEditPersonType: '',
        userIsRegisteredCanNotEditContacts: '',
        forChangeGoToUserCard(args) {},
        phoneNumber: '',
        email: '',
        userIsRegisteredCanNotEditName: '',
        forChangeNameGoToUserCard: ''
      },
      modals: {
        importTitle: '',
        importText: '',
        template: '',
        importButton: '',
        return: '',
        resetLoad: '',
        notChange: '',
        change: '',
        continue: '',
        success: '',
        warning: '',
        changeSettings: '',
        errorStrings(args) {},
        allUploadStrings(args) {},
        successLoadString(args) {},
        updateOwners(args) {},
        stringsWithErrors(args) {},
        houseSettings: '',
        accessDeniedTitle: '',
        accessDeniedMessage: '',
        toMainPage: '',
        inviteModalTitle: '',
        notificationModalTitle: '',
        successEmailSended(args) {},
        successEmailsSended(args) {},
        inviteSentSuccessfully(args) {},
        successInviteSent(args) {},
        notifySentSuccessfully(args) {},
        successNotifySent(args) {},
        lostWarning: '',
        closeInvite: '',
        closeNotify: '',
        spaces: '',
        inRegistryCell: '',
        inSettingsCell: '',
        rowsClean: '',
        rowsCleanWarning(args) {},
        attention: '',
        house: '',
        houses: '',
        village: '',
        resizeInfo(args) {},
        goToSupport: '',
        items: ''
      },
      filterTitles: {
        indicated: '',
        unknown: '',
        buildingId(args) {},
        buildingStreet(args) {},
        propertyUsageType(args) {},
        personType(args) {},
        ownershipType(args) {},
        ownerDocument(args) {},
        ownershipDocument(args) {},
        phone(args) {},
        email(args) {},
        member(args) {},
        entrance(args) {},
        isRegistered(args) {},
        allowMeeting(args) {},
        lName(args) {},
        fName(args) {},
        mName(args) {},
        cadastreCode(args) {},
        shareArea(args) {},
        filterTitle: ''
      },
      footer: {
        registryError: '',
        notFilled(args) {},
        apartments(args) {},
        buildings(args) {},
        living(args) {},
        nonLiving(args) {},
        sortButtonWarningHint0(args) {},
        sortButtonWarningHint1(args) {},
        loseDataWarning: '',
        notAddress: '',
        items: '',
        inRegistry: '',
        inSettings: '',
        inReforma: '',
        apart: '',
        settingsModalTitle: ''
      },
      statistics: {
        tooltips: {
          rows: {
            filtered: '',
            total: ''
          },
          apartments: {
            filtered: '',
            total: ''
          },
          totalArea: {
            filtered: '',
            total: ''
          },
          owners: {
            filtered: '',
            total: ''
          },
          ownerMeetingVotesPercents: {
            filtered: '',
            total: ''
          },
          communityMeetingVotesPercents: {
            filtered: '',
            total: ''
          }
        }
      },
      header: {
        title: '',
        commonData: '',
        registry: '',
        informationDisclosure: '',
        userRights: ''
      },
      ownershipsMerge: {
        ownershipsMerge: '',
        noNotRegisteredUsersForMerge: '',
        connectingRooms: '',
        yesPass: ''
      },
      joiningRequestsDialog: {
        joiningRequests: '',
        legendTitleNoRequests: '',
        legendTitlePart1: '',
        legendTitlePart2(args) {},
        legendItem1Part1: '',
        legendItem1Part2: '',
        legendItem2: '',
        legendItem3: '',
        legendItem4: '',
        legendItem5: '',
        legendItem6: '',
        acceptRequests: '',
        rejectRequests: '',
        reject: '',
        owners: '',
        apartment: '',
        requestsAcceptedSuccessfully(args) {},
        requestsRejectedSuccessfully(args) {},
        requestsAcceptedWithConflicts(args) {},
        rejectRequestsConfirmationTitle(args) {},
        rejectionCommentPlaceholder: '',
        buttonsDisabledText: ''
      },
      rowsDelete: {
        rowsDelete: '',
        canNotDeleteSelectedRowsCause: '',
        canNotDeleteSelectedRowsMessage1: '',
        canNotDeleteSelectedRowsMessage2: '',
        canNotDeleteSelectedRowsMessage3(args) {},
        canNotDeleteSelectedRowsMessage4(args) {},
        approveRowsDelete(args) {},
        rowsSelected(args) {},
        rows(args) {},
        thisRows(args) {},
        ofThem: '',
        but: '',
        rowsRelatedToRegisteredUsers(args) {},
        rowsRelatedToUnknownUsers(args) {},
        rowsRelatedToJoiningRequest(args) {},
        rowsWouldNotBeDeleted(args) {},
        canNotDeleteSelectedRowsMessage5(args) {},
        canNotDeleteSelectedRowsMessage6(args) {},
        joiningRequestsInSelection(args) {}
      },
      rowsClear: {
        joiningRequestsInSelection(args) {},
        fromThisRoom: '',
        fromTheseRooms(args) {},
        deleteJuristicPerson(args) {},
        deletePerson(args) {},
        sureWantDelete(args) {},
        acceptClearing: '',
        select: '',
        ofThese: '',
        registeredUsers: '',
        rowsClearing: ''
      },
      notifyModal: {
        ownersNotify: '',
        canNotSendNotifyForNotRegisteredUsers: '',
        rows(args) {},
        rowsSelect(args) {},
        rowsRelate(args) {},
        selectedUser(args) {},
        notRegisteredUser(args) {},
        userWithoutContactData(args) {},
        notRegisteredUserAndWithoutEmail(args) {},
        selectionWithoutNotRegisteredUsers(args) {}
      },
      historyFilters: {
        houses(args) {},
        structure(args) {},
        reason(args) {},
        registry(args) {},
        owners(args) {},
        commitee(args) {},
        meetings(args) {},
        unit(args) {},
        rights(args) {}
      },
      subHeader: {
        showErrors: '',
        uploadData: '',
        exelExport: '',
        downloadTemplate: '',
        deleteAll: '',
        cantEdit: '',
        orderRegistry: '',
        invite: '',
        connectTelegram: '',
        registryProblems: ''
      },
      errorBlock: {
        attention: '',
        invalidValue1: '',
        invalidValue2: '',
        registryInfo1: '',
        registryInfo2: '',
        cadastralInfo1: '',
        cadastralInfo2: '',
        cadastralInfo3: '',
        cadastralInfo4: '',
        cadastralInfo5: '',
        cadastralInfo6: '',
        support: '',
        apartmentAmount(args) {},
        cadastralNumberInfo1: '',
        cadastralNumberInfo2: '',
        cadastralNumberInfo3: '',
        cadastralNumberInfo4: '',
        cadastralNumberInfo5: '',
        cadastralNumberInfo6: '',
        technicalSupport: '',
        cadastralNumberInfo7: '',
        areaInfo(args) {},
        maxAreaError(args) {},
        maxAmountError(args) {},
        villageInfo1: '',
        villageInfo2: '',
        villageInfo3: '',
        villageInfo4: '',
        villageInfo5: '',
        villageInfo6: ''
      },
      errorModal: {
        registryProblems: '',
        registryError: '',
        inconsistencyInTheNumberOfRooms: '',
        inconsistencyInTheNumberOfRoomsShort: '',
        notAllFields: '',
        allThisMakesItDifficult: '',
        incorrectCountingOfVotes: '',
        getQuorum: '',
        automaticCountingOfVotes: '',
        sendingReminders: '',
        generationDocuments: '',
        noInfoAbout: '',
        rowInRegistry: '',
        votesOnMeeting: '',
        allProblemsFixed: '',
        registryHasBeenCorrect: '',
        countAppartments: '',
        countBuildings: '',
        inSettings: '',
        inRegistry: '',
        inOpenSource: '',
        m2: ''
      },
      ownershipEditor: {
        incorrectValue: '',
        fractionCantBeMore: '',
        procentCantBeMore: '',
        totalAreaCantBeMore(args) {},
        specifyTotalArea: '',
        totalAreaCantBeLow: '',
        editIncorrectData: '',
        attentionToRedCell: '',
        deleteDow: '',
        forEditHaveColumn: '',
        serverError: '',
        successImport: '',
        cantDeleteRegisteredUser: '',
        cantDeleteInvitedUser: '',
        resultsDeleteregistry: '',
        cantDeleteRegistryRows: '',
        errorFileExport(args) {},
        exportErrorNoFile: '',
        clearingAColumn: '',
        cellsWithDataRegisteredUsers: '',
        newApplicationToJoin: '',
        errorSavingResults: '',
        editRegistrySettings: '',
        cantUloadThisFile: '',
        changeTypeOwner: '',
        afterChangeOwnerType(args) {},
        onChangeOwnerType(args) {},
        requiredField: '',
        notEndedEditing: ''
      }
    },
    polls: {
      titles: {
        delete: '',
        cancel: '',
        votes: '',
        voteWarning: ''
      },
      texts: {
        delete: '',
        cancel: '',
        results(args) {},
        voteWarning: '',
        pollsAuthor: '',
        pollDateCreate: '',
        pollStatus: '',
        daysToEndPoll: '',
        alreadyVoted: '',
        createNewPoll: '',
        selectFromTemplate: '',
        deleteDraft: '',
        copyPoll: '',
        changeCopyDraft: '',
        deleteDraftPoll: '',
        publishPoll: '',
        afterPublishNoEdit: '',
        pollPublish: '',
        pollShareLink: '',
        goToPoll: '',
        noPollsWithParameters: '',
        noPollsCreated: '',
        finishPollEditing: '',
        chooseSeveralOptions: '',
        chooseSeveralOptionsOrInputYour: '',
        markOneToTen: '',
        pleaseVote: '',
        pollResults: '',
        pollAlreadyVoted: '',
        ownerVoting: '',
        pollPost: '',
        cannotChangeAfterPublishing: '',
        noPoll: '',
        changeDecision: '',
        downloadPollResults: '',
        unreachablePollResults: '',
        chooseSuitableTemplate: '',
        search: '',
        pollIsFinishedTooltip: '',
        selectQuestionOption: '',
        integerRangeQuestionOption: '',
        checkboxQuestionOption: '',
        takeOneOfAvailableOnes: '',
        pollsTemplates: '',
        usePreviouslyCreatedPollsPart1: '',
        usePreviouslyCreatedPollsPart2: ''
      },
      buttons: {
        delete: '',
        cancel: '',
        allPolls: '',
        results: '',
        backToVote: '',
        downloadVotersRegistry: ''
      },
      pollResults: {
        votePeople(args) {},
        questionVoteRateByPeoplePercents: '',
        questionVoteBySquareRatePercents: ''
      },
      dialogs: {
        loginToSendVoteResultTitle: '',
        loginToSendVoteResultMessage: '',
        joinToObject: '',
        requiredAuthorizationMessage1: '',
        requiredAuthorizationMessage2: '',
        requiredAuthorizationMessage3: '',
        voteError: '',
        downloadTitle: ''
      },
      historyFilters: {
        PollsCreated: '',
        PollTitleChanged: '',
        PollDescAdded: '',
        PollDescChangedDetail: '',
        PollDescDocAdded: '',
        PollDescDocDeleted: '',
        PollQuestionAdded: '',
        PollQuestionTitleChanged: '',
        PollQuestionItemsAdded: '',
        PollQuestionItemsDeleted: '',
        PollQuestionItemsChanged: '',
        PollQuestionTypeChanged: '',
        PollQuestionDocAdded: '',
        PollQuestionDocDeleted: '',
        PollQuestionDeleted: '',
        PollWasPublished: '',
        PollWasDeleted: '',
        PublishedPollWasCanceled: '',
        PollWasFinished: '',
        PollDateFinishedChanged: '',
        PollCreatedFromTemplate: '',
        PollCreatedFromCopy: '',
        PollVoted: '',
        PollExportResult: '',
        FiltersAndSearch: ''
      },
      common: {
        poll: '',
        polls: '',
        cancelPoll: '',
        pollResults: '',
        addCover: '',
        pollPreview: '',
        publishPoll: '',
        pollIsPublished: '',
        pollPublishError: '',
        deletePoll: '',
        deletePollQuestion: '',
        attachDocuments: '',
        filtersAndSearch: '',
        pollDescription: '',
        pollPost: '',
        notEditPollAfterPublishing: '',
        followToPoll: '',
        pollDraftWillBeDeleted: '',
        usersVoted(args) {},
        votes(args) {},
        votesPerscentsFromAll: '',
        createPoll: '',
        active: '',
        finished: '',
        archived: '',
        draft: '',
        withMyParticipation: '',
        withoutMyParticipation: '',
        pollWillBeFinished: '',
        usersVotedCount: '',
        voteIsTaken: '',
        pollFinished: '',
        pollCancelled: ''
      }
    },
    userCard: {
      counterTitles: {
        maxLength(args) {}
      },
      menuLinks: {
        history: '',
        writeToUser: '',
        saveInPhone: '',
        addToFavorites: '',
        addToBlacklist: '',
        deleteFromFavorites: '',
        deleteFromBlacklist: '',
        delete: '',
        deleteSelf: '',
        copyLink: '',
        photo: '',
        initials: '',
        uploadPhoto: '',
        photograph: '',
        allowMeeting: '',
        downloadContractWithUK: ''
      },
      messages: {
        copySuccess: '',
        lastSendLessThanHour: '',
        lastSend: '',
        contactIsAlreadyInUse(args) {},
        contactTypePhone: '',
        contactTypeEmail: '',
        blackListAdd: '',
        blackListDelete: '',
        favoriteAdd: '',
        favoriteDelete: '',
        addUserInJoinOwnership(args) {},
        allowMeetingSuccess: '',
        allowMeetingError: '',
        ownershipsEditingWarning: '',
        invitingToSqvr: '',
        registrationCodeSentTo(args) {},
        registrationCodeSentTo1: '',
        registrationCodeSendingError: '',
        checkEmail: '',
        checkSMS: '',
        emailVerificationSent(args) {},
        phoneVerificationSent(args) {},
        emailVerificationError: '',
        emailVerifiedSuccessfully(args) {},
        contactEmailVerificationRequired: '',
        contactEmailVerificationSend(args) {},
        notMainEmailVerifiedSuccessfullyPart1(args) {},
        notMainEmailVerifiedSuccessfullyPart2: '',
        notMainPhoneVerifiedSuccessfullyPart1(args) {},
        notMainPhoneVerifiedSuccessfullyPart2: '',
        phoneVerificationError: '',
        phoneVerifiedSuccessfully(args) {},
        recoverPasswordDialogTitle: '',
        recoverPasswordDialogMessage: '',
        socialNetworkUserPasswordWarning(args) {},
        emailVerificationSentSuccessefuly(args) {},
        emailVerificationSendFail: '',
        inMinutes(args) {},
        inSeconds(args) {},
        verificationSmsHasAlreadySent(args) {},
        verificationEmailHasAlreadySent(args) {},
        resendingWillAvailableIn(args) {},
        registrationSmsHasAlreadySent(args) {},
        emailHasSent: '',
        smsHasSent: '',
        toggleMainEmailMessage(args) {},
        toggleMainPhoneMessage(args) {},
        inThisHOA: '',
        inAnotherHOA: '',
        toggleMainContactConflict1(args) {},
        toggleMainContactConflict2: '',
        toggleMainContactConflict3: '',
        toggleMainContactConflict4: '',
        toggleMainContactConflict5: '',
        toggleMainContactConflict6(args) {},
        inviteConflictWarning1(args) {},
        inviteConflictWarning2: '',
        inviteConflictWarning3: '',
        inviteConflictWarning4: '',
        inviteConflictWarning5(args) {},
        inviteUsersConflictWarning1(args) {},
        inviteUsersConflictWarning2: '',
        inviteUsersConflictWarning3: '',
        inviteUsersConflictWarning4: '',
        inviteUsersConflictWarning5: '',
        inviteUsersConflictWarning6(args) {},
        registered: '',
        invite: '',
        toggleMainEmailWarning: '',
        toggleMainContactSmsLimitExceed: '',
        toggleMainContactSmsLimitExceedAM(args) {},
        contactExpertConflict(args) {},
        telegramLinkSuccess(args) {},
        contactRegisteredConflict(args) {},
        notConfirmed: '',
        emailNotConfirmed: '',
        phoneNotConfirmed: '',
        modalText1: '',
        modalText2: '',
        modalText3: '',
        modalText4: '',
        send: '',
        noSend: '',
        confirmationEmail: '',
        emailNotVerified: '',
        phoneNotVerified: '',
        avatarVisible: '',
        initialsVisible: '',
        userHasBeenSuccessfully: '',
        removedFromBlackList: '',
        addedToBlackList: '',
        yourProfile: '',
        open: '',
        closed: '',
        successfullySaved: '',
        membershipSuccessfullyChanged: '',
        userHasBeenSuccessfullyDeleted: '',
        updateStatus: '',
        invalidData: '',
        ownershipHasBeenSuccessfullyAdded: '',
        emailAlreadyInUse: '',
        phoneAlreadyInUse: '',
        phoneAlreadyInUse2: '',
        occurredWhenCreatingUser: '',
        settingsHaveBeenSuccessfullyChanged: '',
        failedToChangeSettings: '',
        occurredErrorWhenRequestingServer: '',
        canNotDeleteMainEmail: '',
        canNotDeleteMainPhone: '',
        avatarSuccessfullyUploaded: '',
        ownershipHasBeenSuccessfullyDeleted: '',
        yourPasswordHasBeenChanged: '',
        dataNotSaved: '',
        settingsHaveBeenSuccessfullySaved: '',
        socialNetworkHasBeenSuccessfullyAdded: '',
        socialNetworkHasBeenSuccessfullyRemoved: '',
        youMustSendFlagToConfirm: '',
        statusOfRequest: ''
      },
      buttonTitles: {
        repeatSend: '',
        sendCode: '',
        sendInvite: '',
        sendVerificationEmail: '',
        sendVerificationSMS: '',
        appeals: '',
        passwordEditing: '',
        password: '',
        allowMeeting: '',
        toggleMain: '',
        attachCopiesOfDocuments: '',
        addOwnership: ''
      },
      ownershipTitles: {
        address: '',
        share: '',
        entrance: '',
        floor: '',
        ownershipType: '',
        documents: '',
        documentsName: '',
        documentsNumber: '',
        documentsDate: '',
        owners: '',
        deleteOwnership: '',
        living(args) {},
        noLiving(args) {},
        house: '',
        land: '',
        townhouse: '',
        room: '',
        unknown: '',
        other: '',
        owner: ''
      },
      headerTitles: {
        ownCard: '',
        ownEditing: '',
        delete: '',
        editing: '',
        editingSpecial: '',
        creating: '',
        creatingContractor: '',
        creatingManager: '',
        creatingHoaEmployee: '',
        noMembership: '',
        changeMembership1: '',
        changeMembership2: ''
      },
      tabTitles: {
        ownContactDetails: '',
        contactDetails: '',
        ownOwnership: '',
        ownership: '',
        settings: '',
        ownActivity: '',
        activity: '',
        password: ''
      },
      labels: {
        password: '',
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
        lastName: '',
        firstName: '',
        middleName: '',
        nickname: '',
        description: '',
        additionalInfo: '',
        position: '',
        juristicPersonName: '',
        juristicPersonLegalForm: '',
        juristicPersonLegalFormFull: '',
        juristicPersonOgrn: '',
        juristicPersonInn: '',
        managementCompanyInn: '',
        managementCompanyInnPlaceholder: '',
        managementCompanyLegalForm: '',
        managementCompanyLegalFormFull: '',
        managementCompanyLegalName: '',
        snils: '',
        phone: '',
        phoneNumber: '',
        email: '',
        dobDate: '',
        fb: '',
        twitter: '',
        vk: '',
        site: '',
        telegram: '',
        whatsapp: '',
        documentType: '',
        documentNumber: '',
        documentIssueDate: '',
        personType: '',
        buildingStreet: '',
        buildingNumber: '',
        ownerType: '',
        propertyUsageType: '',
        apartmentNumber: '',
        totalArea: '',
        ownershipShare: '',
        entrance: '',
        floor: '',
        allowMeeting(args) {},
        toAdmin: '',
        reason: '',
        additionalEmail: '',
        mainEmail: '',
        additionalPhone: '',
        mainPhone: '',
        organization: '',
        ownerDocumentType: '',
        docNumber: '',
        whenDocumentWasIssued: '',
        apartNumber: '',
        apartArea: '',
        entranceNumber: '',
        ownershipDocumentName: '',
        issueDate: '',
        phoneAlreadyUse: '',
        emailAlreadyUse: '',
        ru: '',
        whoAreYou: '',
        resident: '',
        manager: '',
        expert: '',
        natural: '',
        legal: '',
        index: '',
        town: '',
        area: '',
        streetType: '',
        streetName: '',
        houseNumber: '',
        unit: '',
        federateEntity: '',
        organizationName: '',
        post: '',
        director: '',
        additionalInfoShort: '',
        complaintsHaveBeenConsidered: '',
        complaintsHaveBeenSubmitted: '',
        reviewedAndSubmittedReports: '',
        submittedReports: '',
        placedAds: '',
        participatedInMeetings: '',
        createdPolls: '',
        conductedOnWebsite: '',
        rating: '',
        contractorForComplaints: '',
        lastVisit: '',
        sharedOwnership: '',
        jointOwnership: '',
        unknownOwnership: '',
        government: '',
        contactPerson: '',
        contactDetails: '',
        contactPeople: '',
        president: '',
        vicePresident: '',
        secretary: '',
        accountant: '',
        commiteeMember: '',
        changeSettings: '',
        changeRegistrySettings: ''
      },
      validateMessages: {
        passwordMin(args) {},
        passwordConfirm: '',
        additionalMax(args) {},
        exists(args) {},
        alreadyHaveShareOfOwnershipInSelectedRoom: '',
        noFreeAreaInSelectedRoom: '',
        notEnoughFreeAreaInSelectedRoom: '',
        noShareOfOwnershipInSelectedRoom: '',
        valueMustBeNumber: '',
        valueMustBeLessThan100: '',
        valueMustntBeLessThan1: '',
        valueMustntBeLessThan1Variant2: '',
        valueMustBeLessThan1: '',
        unknownTypeOfProperty: '',
        areaOfRoomMustntBeMoreThanAreaOfAllRooms: ''
      },
      tooltipTitles: {
        allowMeeting: '',
        needToVerifyEmail: '',
        needToVerifyPhone: '',
        addedEmailVerificationWarning: '',
        notVerifiedPhone: '',
        notVerifiedEmail: '',
        additionalPhone: '',
        additionalEmail: '',
        canNotChangeMainPhone: '',
        canNotChangeMainEmail: '',
        setMainPhone: '',
        setMainEmail: '',
        needSendRegCodeForUser: '',
        sendRegCode: '',
        mainOwnEmailInfo: '',
        contactPersonVerifiedEmail: '',
        contactPersonDeniedMailing: '',
        mainPhone: '',
        mainEmail: '',
        avatar: '',
        minimize: '',
        close: '',
        history: '',
        addPhoneNumber: '',
        deletePhoneNumber: '',
        deletePhoneNumberImpossible: '',
        addEmail: '',
        deleteEmail: '',
        deleteEmailImpossible: '',
        mainPhoneForRegistration: '',
        mainEmailForRegistration: '',
        addContact: '',
        deleteContact: '',
        president: '',
        boardMember: '',
        commiteeMember: '',
        tsnMember: '',
        notTsnMember: '',
        unknownMember: '',
        companyMember: '',
        contractor: '',
        admin: '',
        moderator: '',
        notActive: '',
        profileIsLocked: '',
        profileIsOpen: '',
        privateProfileIsAMI: '',
        publicProfileIsAMI: '',
        privateProfileIsNotOwner: '',
        publicProfileIsNotOwner: '',
        privateProfile: '',
        publicProfile: '',
        canToggleProfile: '',
        publicAdminProfile: '',
        unlockProfile: '',
        lockProfile: '',
        editMode: '',
        ownershipShow: '',
        ownershipHide: '',
        deleteOwner: '',
        chooseProperty: '',
        chooseOwnershipType: '',
        choosePersonType: '',
        addInFavorite: '',
        userInFavorite: '',
        addInBlacklist: '',
        userInBlacklist: '',
        regCode: '',
        mainPhoneInfo: '',
        otherPhoneInfo: '',
        mainEmailInfo: '',
        otherEmailInfo: '',
        mainPhoneDisabled: '',
        otherPhoneDisabled: '',
        mainEmailDisabled: '',
        otherEmailDisabled: '',
        reports: '',
        rating: '',
        forDisabledMainField: '',
        disabledPersonalNotifications: '',
        disabledAutomaticNotifications: '',
        thinkWeSpammers: '',
        disabledAllNotifications: '',
        userIsInFavorite1: '',
        userIsInFavorite2: '',
        userIsInBlackList1: '',
        userIsInBlackList2: '',
        writeUser: '',
        openProfile: '',
        closeProfile: '',
        voteMembership: '',
        voteOwnership: '',
        votingIsNotAllowed: '',
        unableToSendInvitation: '',
        phoneNumberIsAlreadyInSystem: '',
        menu: '',
        toViewPaymentHistoryGoToPage: '',
        cannotEditTypeOfExistingRoom: '',
        editOnlyInRegistryVariant1Part1: '',
        editOnlyInRegistryVariant1Part2: '',
        editOnlyInRegistryVariant2Part1: '',
        editOnlyInRegistryVariant2Part2: '',
        closeModal: '',
        makePhoto: ''
      },
      modals: {
        registryProhibitionAlertModalTitle: '',
        registryProhibitionAlertModalText: '',
        changePersonTypeModalTitle: '',
        changePersonTypeModalText: '',
        checkUnsavedDataModalText1: '',
        checkUnsavedDataModalText2: '',
        joinSocialModalTitle: '',
        joinSocialModalText: '',
        registryRedirectModalTitle: '',
        registryRedirectModalText: '',
        registryRedirectModalYesLabel: '',
        resetCreatingModalText: '',
        successModalText1: '',
        successModalText2: '',
        successModalText3: '',
        webCamErrorModalText: '',
        warningModalText: '',
        checkSettingsWarningModalText1: '',
        checkSettingsWarningModalText2: '',
        checkContactDetailsModalTitle: '',
        verifiedChangesModalTitlePart1: '',
        verifiedChangesModalTitlePart2Variant1: '',
        verifiedChangesModalTitlePart2Variant2: '',
        userDeleteWarningModalTitleOwn: '',
        userDeleteWarningModalTitleNotOwn: '',
        userDeleteWarningModalText: '',
        deleteSelectedPropertyModalTitle: '',
        itIsNotPossibleToDeletePropertyModalTitle: '',
        itIsNotPossibleToDeletePropertyModalText: ''
      },
      settings: {
        moderatorTolltipTitle: '',
        adminTooltipTitle: '',
        tooltipTitle: '',
        settingTitle: '',
        commonNotificaionLabel: '',
        privateNotificationLabel: '',
        telegramNotificationLabel: '',
        modalTextOff: '',
        noOff: '',
        yesOff: '',
        tgConnect: '',
        multiInfo: '',
        sendLink: '',
        telegramConnected: '',
        linkWasSent: '',
        success(args) {},
        email: '',
        social: '',
        socialText: ''
      },
      popover: {
        deleted: '',
        closed: ''
      },
      hintWhatsapp: {
        popoverTitle: '',
        memoTitle1: '',
        memoTitle2: '',
        memoTitle3: '',
        memoInfoText1: '',
        memoInfoText2: '',
        memoInfoText3: '',
        memoInfoText4: '',
        memoInfoText5: '',
        memoInfoText6: '',
        memoInfoText7: '',
        memoInfoText8: '',
        memoInfoSubtext1: '',
        tariffs: ''
      },
      spam: {
        title: '',
        modalTitle: '',
        modalOkButton: '',
        confirme: '',
        modalTelegramButton: '',
        modalNoButton: '',
        button: '',
        text: '',
        resumedSendingMessagesToYourEmail: '',
        thinkWeSpammers: '',
        availableIn: '',
        downloadedIn: '',
        notification: '',
        offPushOffTelegramNotification: '',
        onPushOffTelegramNotification: '',
        offPushOnTelegramNotification: '',
        thinkWeSpammersNotification(args) {},
        cantExcludeSpam(args) {}
      },
      avatar: {
        debt: ''
      },
      contactDetails: {
        noContacts: '',
        contactInfo: '',
        documents: '',
        documentIsValidPersonality: '',
        ownershipDocument: '',
        ownership: '',
        ownershipDocuments: ''
      },
      hintTitles: {
        activity: '',
        profileTabs: '',
        avatar: '',
        extraMenu: '',
        ownership: '',
        tabsInProfile: '',
        email: ''
      },
      appeals: {
        lastNameOfOwner: '',
        nameOfOwner: '',
        middleNameOfOwner: '',
        juristicName: '',
        juristicLegalForm: '',
        juristicOgrn: '',
        juristicInn: '',
        phone: '',
        email: '',
        personType: '',
        contactPerson: '',
        dateOfBirth: '',
        documentType: '',
        documentNumber: '',
        documentIssueDate: '',
        personalFiles: '',
        addressSelect: '',
        buildingStreet: '',
        apartmentNumber: '',
        buildingNumber: '',
        totalArea: '',
        ownershipShare: '',
        propertyUsageType: '',
        ownerType: '',
        ownershipDocumentName: '',
        ownershipDocumentNumber: '',
        ownershipDocumentIssueDate: '',
        ownershipFiles: ''
      }
    },
    registration: {
      tooltipTitles: {
        selectAddress: '',
        selectHoa: '',
        disabledForInvite: '',
        addressForJoin: ''
      },
      labels: {
        searchType: '',
        addressType: '',
        hoaType: '',
        zip: '',
        region: '',
        city: '',
        settlement: '',
        streetType: '',
        street: '',
        house: '',
        block: '',
        unitType: '',
        info: '',
        address: '',
        withSocialNetworks: '',
        withEmail: '',
        forgotPassword: '',
        newUserRegistration: ''
      },
      placeholders: {
        addressType: '',
        hoaType: ''
      },
      messages: {
        addToNewAccount: '',
        addToNewAccountWithPass: '',
        addToExistAccount: '',
        addVillageToExistsAccount: '',
        addTownToExistsAccount: '',
        accountAlreadyExists: '',
        socialNetworkAccountAlreadyExists: '',
        logInObject: '',
        joiningRequestWasSent(args) {},
        joiningRequestMessage: '',
        joiningRequestDoneMessageOne: '',
        joiningRequestDoneMessageTwo: '',
        joiningRequestDoneTitle: '',
        joiningAnExistingObject: ''
      },
      carousel: {
        slideTitle1: '',
        slideText1: '',
        slideTitle2: '',
        slideSubtitle2: '',
        slideText2Line1: '',
        slideText2Line2: '',
        slideText2Line3: '',
        slideTitle3: '',
        slideText3Line1: '',
        slideText3Line2: ''
      },
      accountCreation: {
        createAccount: '',
        accountExists: '',
        accountExists2: '',
        accountExistsInHOA: '',
        canNotUseThisLogin: '',
        accountsJoining: '',
        alreadyRegistered(args) {},
        here: '',
        inputPassword: '',
        canNotUseExistAccount(args) {},
        accountExistsInSocialAuth(args) {},
        inputAnotherLogin: '',
        youCanUseAnotherLogin: '',
        youCanUseAnotherLoginInThisHOA: '',
        youCanUseAnotherLoginOrUseSocialNetwork: ''
      },
      joiningUser: {
        whoAreYou: '',
        multipleHOAHint: '',
        selectHouse: '',
        yourData: '',
        totalArea: '',
        fractionValidationMessage1: '',
        fractionValidationMessage2: '',
        fractionValidationMessage3: '',
        fractionValidationMessage4: '',
        fractionValidationMessage5: '',
        valueMustBeNumber: '',
        percentValidationMessage1: '',
        valueMustntBeNumber: '',
        areaValidationMessage1: ''
      },
      codeInput: {
        weSentCode(args) {},
        inputCodeFrom(args) {},
        afterCodeInput: '',
        askAdminForCode: '',
        checkContact(args) {},
        inputCode: '',
        inputGettingCode: '',
        noCode: '',
        register: '',
        haveAccount: '',
        inputLoginAndPassword: '',
        continue: '',
        error: '',
        emailConfirmationCodeTitle: '',
        emailConfirmationInputCode: '',
        emailConfirmationEnsure: '',
        emailConfirmationAlreadyConfirmed: ''
      },
      socialNetworks: {
        canNotUseSameNetworks: '',
        useAnotherNetwork: '',
        registrationNotPossible: ''
      },
      dialogs: {
        fillProfileAndSaveTitle: '',
        fillProfileAndSaveMessage: '',
        confirmEmailTitle: '',
        confirmEmailMessage(args) {},
        notVerifiedEmail: '',
        proceedToApplicationTitle: '',
        proceedToApplicationMessage: '',
        proceedToApplicationOK: '',
        proceedToApplicationCancel: ''
      },
      houseForm: {
        street: '',
        streetType: '',
        houseNumber: '',
        unit: '',
        info: '',
        entrancesAmount: '',
        floorsAmount: '',
        area: '',
        amount: '',
        fraction: '',
        share: '',
        address: '',
        type: '',
        form: '',
        count: '',
        property: '',
        identicalHouse: '',
        zipError: '',
        addHouseTooltip: '',
        addHouseDisabledTooltip: ''
      },
      invitationAccept: {
        phone: '',
        email: '',
        password: '',
        passwordConfirm: ''
      },
      accountConflictModal: {
        close: '',
        login: '',
        ok: '',
        emailUsedForResidentInCurrentHoaContent: '',
        phoneUsedForResidentInCurrentHoaContent: '',
        phoneUsedForResidentInCurrentHoaTitle: '',
        emailUsedForResidentInCurrentHoaTitle: '',
        differentUserTypesTitle: '',
        differentUserRolesTitle: '',
        differentUserTypesContent: '',
        differentUserRolesContent: ''
      },
      invitationAcceptModal: {
        title: '',
        buttonReject: '',
        buttonAccept: '',
        text(args) {}
      },
      policyAcceptModal: {
        title: '',
        invitationModalPolicyText: '',
        buttonReject: '',
        buttonAccept: '',
        pdfURL: '',
        googleDocsPdfURL: '',
        checkBoxText: '',
        downloadPdfText: ''
      },
      invitationWhatsapp: {
        title: '',
        titlePassword: '',
        titleDone: '',
        titlePasswordConfirmWarning: '',
        loginFormTitle: '',
        subTitleExist(args) {},
        titleExist: '',
        titleConflict: '',
        whatsappFormTitle: '',
        whatsappFormReject: '',
        whatsappFormContinue: '',
        tooltipPhone: '',
        existPassword: '',
        existWait: '',
        existWaitLogin: '',
        existWarning: '',
        phone: '',
        donePhone: '',
        email: '',
        doneEmail: '',
        password: '',
        passwordValidate: '',
        donePassword: '',
        passwordConfirm: '',
        differentPasswords: '',
        donePasswordConfirm: '',
        warning: ''
      },
      invitationEmail: {
        title: '',
        titlePassword: '',
        titleDone: '',
        titlePasswordConfirmWarning: '',
        loginFormTitle: '',
        subTitleExist(args) {},
        titleExist: '',
        titleConflict: '',
        emailFormTitle: '',
        emailFormReject: '',
        emailFormContinue: '',
        tooltipEmail: '',
        existPassword: '',
        existWait: '',
        existWarning: '',
        phone: '',
        donePhone: '',
        email: '',
        doneEmail: '',
        password: '',
        passwordValidate: '',
        donePassword: '',
        passwordConfirm: '',
        differentPasswords: '',
        donePasswordConfirm: '',
        warning: ''
      },
      components: {
        lastActives: '',
        thisHouseAlreadyRegistered: '',
        noteOnlyOne: '',
        selectHouseToJoin: '',
        registeredNewHouse: '',
        findHouse: '',
        system: '',
        weDoServices: '',
        controlBudjets: '',
        sqvrAllows0: '',
        sqvrAllows1: '',
        sqvrAllows2: '',
        sqvrAllows3: '',
        hasComplaitns: '',
        solveProblems: '',
        backToStep: '',
        deleteDataAboutHouse: '',
        awaitAnswer: '',
        createAnotherHouse: '',
        recomendedDoThisIf: '',
        second: '',
        third: '',
        fourth: '',
        fifth: '',
        sixth: '',
        seventh: '',
        eighth: '',
        ninth: '',
        tenth: '',
        eleventh: '',
        twelfth: '',
        thirteenth: '',
        fourteenth: '',
        addHouse: '',
        user: '',
        realty: '',
        login: '',
        activate: '',
        addressNotFound: '',
        alreadyRegistered: '',
        positionAlreadyRegistered: '',
        continueSearch: '',
        search: '',
        changeSearch: '',
        selectHoa: '',
        advancedSearch: '',
        street: '',
        addNew: '',
        city: '',
        cityDistrict: '',
        federalSignificance: '',
        settlement: '',
        town: '',
        urbanSettlement: '',
        password: '',
        userAlreadyRegistered: '',
        youCanAddNew: '',
        youCanCreateNew: '',
        wrongLogin: '',
        errorLogin: '',
        inSelectedRoom: '',
        format5: '',
        format9: '',
        unknownType: '',
        selectUsageType: '',
        selectOwnerType: '',
        selectAddress: '',
        selectNeededYouAddress: '',
        apartmentNumber: '',
        totalArea: '',
        ownershipShare: '',
        selectFormVillage: '',
        usageTypeOther: '',
        houseNumber: '',
        buildingNumber: '',
        landNumber: '',
        appartment: '',
        moreInfo: '',
        accepted: '',
        policy: '',
        addInfo: '',
        infoAboutUk: '',
        youSelectRole: '',
        appartementBuilding: '',
        settlement1: '',
        selfGoverment: '',
        partnershipOwner: '',
        tsj: '',
        managementCompany: '',
        living: '',
        noLiving: '',
        room: '',
        land: '',
        townhouse: '',
        share: '',
        join: '',
        fraction: '',
        used: '',
        usedInHOA: '',
        notUsed: '',
        continueWith: '',
        withEmail: '',
        withSocial: '',
        incorrectResponse: '',
        createJoinRequestTitle: '',
        createJoinRequestMsg: '',
        socialNotFoundTitle: '',
        socialNotFoundMsg: '',
        emailNotAttachedToSocialTitle: '',
        emailNotAttachedToSocialMsg: '',
        accountAlreadyExistsTitle: '',
        accountAlreadyExistsMsg: '',
        unknown: '',
        unknownSubdomen: '',
        owner: '',
        ownerRole: '',
        manager: '',
        managerRole: '',
        contractor: '',
        contractorRole: '',
        expert: '',
        expertRole: '',
        avatarError: '',
        uploadAvatar: ''
      }
    },
    registryOrder: {
      order: '',
      orderRegistry: '',
      managementOrganizationStatement: '',
      free: '',
      dataValidity: '',
      noWarranties: '',
      statementHint: '',
      downloadStatement: '',
      propertyPassport: '',
      pricePerMeterFrom(args) {},
      propertyPassportDescription: '',
      inExcelFormat: '',
      orderTerm(args) {},
      goToPayment: '',
      changeOrder: '',
      orderTotalCost: '',
      inputAddressHint: '',
      registryExtractDiscountHint: '',
      overallSquare(args) {},
      pricePerMeter(args) {},
      propertyPassportDescriptionFull: '',
      lastOrderDate(args) {},
      rosRegistryExtract: '',
      rosRegistryExtractDescription: '',
      rosRegistryExtractDescriptionFull: '',
      registryFromExcelFormat: '',
      registryExtractOrderDiscountHint: '',
      orderRegistryDescription(args) {},
      addressNotFoundAlertTitle: '',
      addressNotFoundAlertDesriptionPart1: '',
      addressNotFoundAlertDesriptionPart2: '',
      orderContactsModalTitle: '',
      orderContactsModalDescriptionPart1: '',
      orderContactsModalDescriptionPart2: '',
      fullNameLabel: '',
      emailLabel: '',
      phoneLabel: '',
      inputValueRequired: '',
      fullNameRequired: '',
      inputEmailRequired: '',
      inputPhoneRequired: '',
      orderContactsModalSubmit: '',
      customer: '',
      deliveryOnEmail: '',
      executionPeriod: '',
      proceed: '',
      cardData: '',
      cardDataDescription: '',
      cardNumber: '',
      cardNumberRequired: '',
      cardMonth: '',
      cardFieldError: '',
      cardYear: '',
      cardCVV: '',
      goPay: '',
      savedContactsAlertTitle: '',
      savedContactsAlertDescription: '',
      policyDescription: '',
      policyTermsAndConditions: '',
      policyPrivatePolicy: '',
      policyRefund: '',
      goNext: '',
      goBack: '',
      addressSearch: '',
      paymentAttemptFailed(args) {},
      successPaymentTitle: '',
      successPaymentDescription(args) {},
      failedPaymentTitle: '',
      failedPaymentDescription(args) {},
      yourOrder: '',
      price: '',
      successActialization(args) {}
    },
    meeting: {
      entities: {
        agendaSolution: '',
        rationale: '',
        documents: '',
        questionDescription: '',
        agendas: '',
        selectedNumOfNumQuestions(args) {},
        selectedNumOfNumShortWithBr(args) {},
        hasNumQuestionsInside(args) {},
        agendaTemplateModalInfoPartOne: '',
        agendaTemplateModalInfoPartTwo: '',
        agendaTemplateModalResetBtn: '',
        agendaTemplateModalMainHeader: '',
        showAllQuestions: '',
        showSelectedQuestions: '',
        selected: '',
        agendaTemplateModalWarnInfo: '',
        agendaTemplateModalWarnRecommendation: '',
        agendaTemplateSelectedModalConfirm: '',
        agendaTemplateSelectedModalReturn: '',
        agendaTemplateSelectedModalHeader: '',
        agendaTemplateSelectedModalBody: '',
        removeQuestionWarnMessage: '',
        noQuestionsAreSelected: '',
        successMessage(args) {},
        chooseOne: '',
        chooseAll: '',
        deleteOne: '',
        deleteAll: ''
      },
      bulletin: {
        getVotingBulletin: '',
        get: '',
        selectSettingsForPrinting: '',
        singleSidedPrinting: '',
        doubleSidedPrinting: '',
        sortByLastName: '',
        sortByAppartaments: ''
      },
      settingsError: {
        settingsMismatch: '',
        hideDetails: '',
        showDetails: '',
        allThisMakesItDifficult: '',
        reachingQuorum: '',
        automaticVoteCounting: '',
        sendingNotifications: '',
        generatingDocuments: '',
        incorrectVoteCount: ''
      },
      modals: {
        representativeInfo: '',
        addRepresentative: '',
        addRepresentativeComment: '',
        representativeLawDocument: '',
        representativePassportDocument: '',
        procuration: '',
        edit: '',
        reverse: '',
        addFIO: '',
        addEmail: '',
        addDescription: '',
        noInfo: '',
        addDocuments: ''
      },
      adminPanel: {
        presentInPerson: '',
        representativePresent: '',
        editRepresentativeData: '',
        total: '',
        appartments: '',
        votePercents: '',
        withFilters: '',
        registration: '',
        bulletins: '',
        contacts: '',
        notificationUsers: '',
        notifyParticipants: '',
        notifyDisallowedForNotAMI: '',
        notifyNoOneSelected: '',
        nextContactDate: '',
        useFilter: '',
        showDetails: '',
        sumVoted: '',
        sumNotAcceptedVoted: '',
        sumNotVoted: '',
        sortByFio: '',
        sortByApartment: '',
        sortByAddress: '',
        sortByPercent: '',
        sortByRegistration: '',
        sortByBulletins: '',
        notEditable: '',
        visitedButNotEdited: '',
        sortByDate: '',
        lastModifyDate: '',
        voting: '',
        votePercentInMeeting: '',
        adressTooltip: '',
        sortByContacts: '',
        sortByComment: '',
        cantEditRegistrationStatus: '',
        openMenuRegistrationAlertTitle: '',
        openMenuRegistrationAlertText: '',
        openMenuRegistrationAlertAcceptButton: '',
        updateButtonTooltip: '',
        warningDataMayBeStaleRefreshTable: '',
        downloadBulletinsChooseParticipants: '',
        downloadBulletinsSingleParticipant: '',
        downloadBulletinsSingleParticipantSuccess: '',
        downloadBulletinsSelectedParticipants: '',
        archiveSendToEmail: '',
        yet: '',
        collapse: '',
        cantEditBulletin: '',
        votingNotStarted: '',
        thisMeetingDontHaveBulletins: '',
        countBulletins: '',
        countUsers: '',
        countVotePercents: '',
        meetingMembers: ''
      },
      bulletinStatuses: {
        EVoteAccepted: '',
        EVoteAcceptedAsMinor: '',
        EVoteIgnored: '',
        EVoteNothing: '',
        EVoteNothingIgnored: '',
        InProcess: '',
        Accept: '',
        IsAcceptedPresident: '',
        IsAcceptedSecretary: '',
        SendToAccept: '',
        Nothing: ''
      },
      userContactStatuses: {
        Promise: '',
        NoActions: '',
        Inaccessible: '',
        AbsentContacts: '',
        DenyParticipate: '',
        Dismiss: '',
        NextDateContact: ''
      },
      choiceButton: {
        totalLikes: '',
        percentVotesOfMeeting: '',
        totalDislikes: '',
        noComment: '',
        cantVote: ''
      },
      decisionComments: {
        comments: '',
        show: '',
        showMoreComments: '',
        showPrevComments: '',
        addComment: '',
        answer(args) {},
        hide: '',
        notCommentedYet: '',
        commentPlaceholder: '',
        save: '',
        withoutComment: '',
        cancel: '',
        addNewCommentToThread: '',
        changeReaction: '',
        saveSuccess: '',
        from: '',
        edit: '',
        change: '',
        delete: '',
        editComment: '',
        deleteSuccess: '',
        commendedDecision: ''
      },
      unrecognizedDocuments: {
        name: '',
        sortByName: '',
        document: '',
        sortByDocumentName: '',
        uploader: '',
        sortByUploader: '',
        dateUploaded: '',
        sortByUploadedDate: '',
        unrecognizedDocument: '',
        disableTootltipText: '',
        disableTootltipTextToDelete: '',
        infoModalTitle: '',
        content: '',
        tryDeleteDocuments(args) {},
        fileDeleted: '',
        recognizedFilesNotDeleted: '',
        deleteFile: '',
        sendToEmail: '',
        fileAttached: '',
        fileNotAttached: '',
        pageNumber: '',
        bulletinsPage: '',
        documentsOfAuthorizedPerson: '',
        save: '',
        edit: '',
        tryToMoveDocument: '',
        disabledInputText: '',
        dasableInputPageNumber: '',
        canHaveMoreBulletinsPage(args) {},
        disabledButtonText: '',
        needChanges: '',
        documentSaved: '',
        chooseUser: '',
        info: '',
        fileName: '',
        editerUser: '',
        uploaderUser: '',
        choosePageNumber: '',
        userFromMeeting: '',
        documentType: '',
        infoDocumentModalTitle: '',
        contentModal: '',
        important: '',
        contentModal2: '',
        warningModalText1: '',
        warningModalText2(args) {},
        warningModalText3: '',
        warningModalText4: '',
        warningModalText5: '',
        warningModalText6: '',
        warningModalText7: '',
        warningModalText8: '',
        warningModalText9: '',
        warningModalText10: '',
        noMoreUnrecognizedDocuments: '',
        unrecognizedDocuments: '',
        continue: '',
        finaly: '',
        allDocumentsRecognized: '',
        countRecognizedDocuments(args) {},
        countUnrecognizedDocuments(args) {},
        chooseOptionContinue: '',
        chooseOptionFinaly: '',
        unsavedData: '',
        notSave: '',
        return: '',
        warning: '',
        resetBulletinStatusText: '',
        helperText: '',
        canSelectUser: '',
        userSelected: '',
        warningTextForNotCorrectedBulletinStatus1: '',
        warningTextForNotCorrectedBulletinStatus2: '',
        warningTextForNotCorrectedBulletinStatus3: '',
        warningTextForNotCorrectedBulletinStatus4: '',
        warningTextForNotCorrectedBulletinStatus5: '',
        warningTextForNotCorrectedBulletinStatus6: '',
        commentModalTextForNotCorrectedBulletinStatus1: '',
        commentModalTextForNotCorrectedBulletinStatus2: '',
        disabledMeetingStatus: '',
        deleteDocument: '',
        numberOfPage: '',
        dontReplacePage: '',
        replacePage: ''
      },
      votingWithPEP: {
        modalTitle: '',
        titleInfoModal: '',
        votingWithPEPText1: '',
        textWithMobileNumber(args) {},
        enterCodeText: '',
        votingWithPEPHelperText1(args) {},
        votingWithPEPHelperText2(args) {},
        votingWithPEPHelperText3: '',
        successToastTitle: '',
        successToastText1(args) {},
        successToastText2(args) {},
        votingWithPEPInfoText1: '',
        votingWithPEPInfoText2: '',
        votingWithPEPInfoText3: '',
        votingWithPEPInfoText4: '',
        votingWithPEPInfoText5: ''
      },
      bulletinCard: {
        goToEVoting: '',
        goToNormalVoting: '',
        scannedCopy: '',
        agendaIssues: '',
        questionNumber: '',
        userParticipant: '',
        noOneBulletinFiles: '',
        pageIsMissing(args) {},
        voiceHasNotCountedYet: '',
        voiceHasNotCounted: '',
        eVotingIsNotAccepted: '',
        acceptedByEVoting: '',
        acceptedByPaper: '',
        paperBulletinIsApproved: '',
        paperBulletinIsApprovedMyBulletin: '',
        paperBulletinIsNotApproved: '',
        paperBulletinIsNotApprovedMyBulletin: '',
        userIsVotedByEVoteButInThisMeetingAcceptedOnlyPaperBulletins: '',
        userIsVotedByEVoteButEVoteIsIgnored: '',
        userIsVotedByEVoteButEVoteIsIgnoredMyBulletin: '',
        userIsNotVotedByEVoteButEVoteIsIgnored: '',
        userIsNotVotedByEVoteButEVoteIsIgnoredMyBulletin: '',
        userIsNotVotedByEVoteButEVoteIsAllowed: '',
        userIsNotVotedByEVoteButEVoteIsAllowedMyBulletin1: '',
        userIsNotVotedByEVoteButEVoteIsAllowedMyBulletin2: '',
        userIsVotedByEVoteButYouCantChangeEVote: '',
        userIsVotedByEVoteMyBulletin: '',
        noPaperBulletin: '',
        noPaperBulletinMyBulletin1: '',
        noPaperBulletinMyBulletin2: '',
        noPaperBulletinMyBulletin3: '',
        userIsVotedByEVoteButBulletinVoteIsPriority: '',
        userIsVotedByEVoteButBulletinVoteIsPriorityMyBulletin: '',
        helperText1: '',
        helperText2: '',
        toPresident: '',
        toSecretary: '',
        userIsNotVotedByEVote: '',
        userIsNotVotedByEVoteMyBulletin: '',
        userIsVotedByEVote: '',
        yes: '',
        yesForProjectNumber(args) {},
        against: '',
        againstAll: '',
        abstained: '',
        abstainedItem: '',
        spoiled: '',
        notVoted: '',
        maximumCandidates(args) {},
        recomended: '',
        notRecomended: '',
        select: '',
        selectMore: '',
        candidates(args) {},
        cantSelectMore: '',
        selectButton: '',
        previousParticipant: '',
        nextParticipant: '',
        votingEnded: '',
        cantVoteHasNoBulletin: '',
        clickForEdit: '',
        clickToMoreMenuForEdit: '',
        clickHereForEdit: '',
        canEVoteOnlyMobileApp: '',
        canEVoteOnlyParticipant: '',
        eVoteIgnored: '',
        eVoteNothingIgnored: '',
        eVoteAcceptedAsMinor: '',
        canEditEvoteButIgnored: '',
        canEditEvoteButHasPaperBulletin: '',
        saveAndSubmitForApproval: '',
        submitForApproval: '',
        cancelEdit: '',
        confirmThereIsAuthenticBulletin: '',
        chooseSolutionForAllIssues: '',
        confirmThereIsAuthenticBulletinTooltipText: '',
        submit: '',
        edit: '',
        informationAboutTrustedPerson: '',
        copyLink: '',
        linkCopied: '',
        editBulletin: '',
        downloadScanCopie: '',
        cantDownloadScanCopie: '',
        deleteBulletin: '',
        delete: '',
        cancel: '',
        requiredComment: '',
        withoutCommentCantDelete(args) {},
        cancelAutomaticRecognition(args) {},
        clickToDeleteButton(args) {},
        afterDeleteFilesTransfered(args) {},
        deleteVoting: '',
        presidentAndSecretaryHasEmail: '',
        afterDeleteBulletin(args) {},
        protocolPublishing: '',
        canEditProtocolPublishing: '',
        cantDeleteMeetinEnded: '',
        cantEditMeetinEnded: '',
        cantDeleteStatusNothing: '',
        cantEditStatusNothing: '',
        allFilesDeleted: '',
        waitingForApprovalFromPresident: '',
        waitingForApprovalFromSecretary: '',
        waitingForApproval: '',
        bulletinApproved: '',
        sendToTelegram: '',
        sendToTelegramMyBulletin: '',
        sendToEmailMyBulletin(args) {},
        sendToEmailOrTelegram: '',
        sendToEmail(args) {},
        sentToTelegram: '',
        cantDownloadBulletinText1: '',
        cantDownloadBulletinText2: '',
        cantDownloadBulletinText3: '',
        cantDownloadBulletinText4: '',
        cantDownloadBulletinText5: '',
        bulletinWasAccepted: '',
        ifEditBulletin: '',
        userHasEmail: '',
        clickToEditButton: '',
        bulletinWasProcessed: '',
        allBulletinNeedToAccept: '',
        editingModeStart: '',
        youHaveEditingMode: '',
        forStartEditingMode: '',
        stopEditing: '',
        youWantToStopEdit: '',
        allDataHasBeenReturned: '',
        continueWithoutSaving: '',
        exitWithoutSaving: '',
        isNotAllPagesInBulletinDescriptionText1(args) {},
        isNotAllPagesInBulletinDescriptionTextForRole2: '',
        isNotAllPagesInBulletinDescriptionText2: '',
        isNotAllPagesInBulletinDescriptionText3: '',
        isNotAllPagesInBulletinDescriptionText4ForRole: '',
        isNotAllPagesInBulletinDescriptionText4: '',
        makeChangesToVotingResultsForApproval: '',
        normalVoteResult: '',
        eVoteResult: '',
        pageScans: '',
        downloadMyBulletin: '',
        startVoting: '',
        goToApp: '',
        downloadMyBulletinTooltip: '',
        cantDownloadMyBulletinTooltip: '',
        voting: '',
        votingInApp: '',
        canEVoteOnlyMobileAppModal: '',
        canViewInfoOnlyMobilaAppModal: '',
        ifYouDontHaveMobileApp: '',
        youCanDownloadBulletin: '',
        fileWasRemoved: '',
        noPage: '',
        page: '',
        formReset: '',
        whyYouEditBulletin: '',
        mandatoryComment: '',
        changesAdded: '',
        selectFiles: '',
        noUploadedScans: '',
        allScansAreRemoved: '',
        selectFilesForRemoval: '',
        filesRemoved(args) {},
        viewScans: '',
        noAppeals: '',
        showAppeals: '',
        hideAppeals: '',
        addEmail: '',
        confirmEmail: '',
        connectTelegram: '',
        damagedTooltip: '',
        rotatePhoneWarningText: '',
        rotatePhoneWarningTitle: '',
        exitEditMode: '',
        cantMettingAllow: '',
        availableIn: '',
        downloadedIn: ''
      }
    },
    digest: {
      sections: {
        meetings: '',
        polls: '',
        complaints: '',
        announcements: '',
        documents: '',
        usefullAddresses: '',
        reports: '',
        users: '',
        noDigest: '',
        emptyDigest: '',
        emptyDigestVillage: '',
        emptyMeetings: '',
        emptyPolls: '',
        emptyAnnouncements: '',
        emptyDocuments: '',
        emptyUsefullAddresses: '',
        emptyReportsNotVillage: '',
        emptyReportsVillage: '',
        emptyReportsHelp: '',
        emptyReportsHelpVillage: '',
        meetingsActionText: '',
        pollsActionText: '',
        complaintsActionText: '',
        announcementsActionText: '',
        documentsActionText: '',
        usefullAddressesActionText: '',
        reportsActionText: '',
        usersActionText: '',
        documentsFilledMain: '',
        usefulAddressesMain: '',
        usersMainPart1: '',
        usersMainPart2: '',
        usersMainNeutral: '',
        usersItemTotal: '',
        usersItemOwners: '',
        usersItemContractors: '',
        usersItemEmployees: '',
        usersItemEmployeesStructureTypeOther: '',
        usersTurnOffNotifications: '',
        announcementMain(args) {},
        announcementItem: ''
      },
      pollItem: {
        statusActive: '',
        statusFinished: '',
        days(args) {},
        activeHeaderConnection: '',
        activeWillLast: '',
        activeWillFinish: '',
        activeFinishAt: '',
        activeRestMessage: '',
        activeOpenPollBtnText: '',
        finishedHeaderConnection: '',
        finishedInQuestion: '',
        finishedMostPopularAnswer: '',
        finishedGainedPercent: '',
        finishedVotes: '',
        finishedGainedFromParticipants: '',
        finishedOfVotes: '',
        finishedGainedFromAll: '',
        finishedNobodyVoted: ''
      },
      complaints: {
        newComplaintsLastMonth: '',
        newComplaintsLastMonthVillage: '',
        outOfThem: '',
        successfullySolvedComplaints: '',
        totalCountInProcess: '',
        complaint: '',
        tableTitle: '',
        statTableHeaderAmount: '',
        statTableHeaderAverageTime: '',
        statTableTotal: '',
        statTablePublic: '',
        statTablePrivate: '',
        statTableSolved: '',
        statTableDeclined: '',
        daysShorten: '',
        hoursShorten: '',
        minutesShorten: '',
        tableCaption1: '',
        tableCaption2: '',
        newComplaintZero: ''
      },
      meetingItem: {
        publishedDraftHeaderThereIsNew: '',
        publishedDraft: '',
        publishedDraftHeaderOfMeeting: '',
        publishedDraftBodyNewMeetingIsPlanned: '',
        publishedDraftBodyMeetingTitleIs: '',
        publishedDraftBodyHelpText: '',
        publishedDraftOpenMeeting: '',
        active: '',
        activeHeader: '',
        activeBodyLeftBeforeStart: '',
        activeBodyLeftDays(args) {},
        activeBodyStartVoting: '',
        activeBodyNormal: '',
        activeBodyFinishVoting: '',
        activeBodyAgenda: '',
        activeBodyHelpText: '',
        activeBodyLink: '',
        meeting: '',
        voting: '',
        votingBodyMeetingInProcess: '',
        votingBodyUsual: '',
        votingBodySudden: '',
        votingBodySemiVirtual: '',
        votingBodyVirtual: '',
        votingBodyNormal: '',
        votingBodyOwners: '',
        votingBodyResidents: '',
        votingBodyCommittee: '',
        votingBodyCanVoteUntil: '',
        votingBodyYear: '',
        votingResult: '',
        votingBodyOwnersCount(args) {},
        votingBodyResidentsCount(args) {},
        votingBodyCommitteeCount(args) {},
        votingBodyHas(args) {},
        votingBodyOfVotes: '',
        votingBodyNoQuorum: '',
        votingBodyHasQuorum: '',
        votingBodyNoResults: '',
        votingBodyHelpText: '',
        votingBodyLinkText: '',
        calcResults: '',
        calcResultsBodyFinished: '',
        calcResultsBodyDays(args) {},
        calcResultsBodyCalculation: '',
        calcResultsBodyHelpText: '',
        calcResultsLinkText: '',
        closed: '',
        closedBodyHelpText: '',
        closedBodyLinkText: '',
        meetingWasClosedWithComment: '',
        meetingWasClosedByUserWithComment: '',
        finishedAccepted: '',
        finishedBodyFinished: '',
        finishedBodyHasQuorum: '',
        finishedBodyNoQuorum: '',
        finishedBodyHelpText: '',
        finishedBodyLinkText: '',
        finishedRejected: ''
      }
    },
    auth: {
      auth: {
        useMailAndPass: '',
        enterWithoutPass: '',
        noAccount: '',
        registrationCode: '',
        checkMail: '',
        sentCode(args) {},
        resendMail: '',
        instructionMail: '',
        spamNotificationMessage: '',
        passwordRecoverLinkSent(args) {},
        recover: '',
        unregisteredEmail: '',
        unregisteredPhone: '',
        registrations: '',
        newAccout: '',
        mailSent: '',
        sentError: '',
        smsSent: '',
        specifiedEmail(args) {},
        specifiedPhone(args) {},
        uniqueLinkWasSent: '',
        instructionRecoveryPasswordWasSent: '',
        resend: '',
        uniqueLinkEmail: '',
        followMailLink: '',
        followSmsLink: '',
        waitLink: '',
        waitComment(args) {},
        waitRecovery: '',
        willBeAvailableAfter(args) {},
        fogotPassword: '',
        wrongFormat: '',
        fogotPasswordText: '',
        linkForLogin: '',
        linkForLoginTooltip: '',
        recoverPassword: '',
        recoverPasswordTooltip: '',
        linkWasSent: '',
        instructionWasSent: ''
      }
    },
    protocolVersion: {
      protocolVersion: {
        protocolPublished: '',
        protocolUploaded: '',
        protocolWaitingForPublishing: '',
        rejectProtocolPublishing: '',
        removePublishedProtocol: '',
        beginProtocolPublishing: '',
        disabledButton: '',
        protocolCreatingSigningAndPublishing: '',
        haveNotProtocol: '',
        protocolNotPublishingWaitingForPublishing: '',
        meetingWasHeld: '',
        meetingNotSucceed: '',
        initiatedTheReplacementOfProtocol: '',
        youCanMakeChangeToVotingResults: '',
        finalProtocolHasBeenPublished: '',
        completeAllFiveSteps: ''
      },
      stepList: {
        downloadProtocol: '',
        protocolDownloaded: '',
        downloadProtocolTooltip: '',
        markSigners: '',
        protocolSigned: '',
        markSignersTooltip: '',
        uploadSignedProtocol: '',
        protocolUploaded: '',
        uploadSignedProtocolTooltipFirstPart: '',
        uploadSignedProtocolTooltipSecondPart: '',
        uploadSignedProtocolTooltipThirdPart: '',
        uploadSignedProtocolActiveTooltip: '',
        uploadSignedProtocolDoneTooltip: '',
        initiateProtocolPublication: '',
        protocolPublicationInitiated: '',
        initiateProtocolPublicationTooltip: '',
        initiateProtocolPublicationDoneTooltip: '',
        confirmPublication: '',
        protocolPublishedStatus: '',
        confirmPublicationTooltipFirstPart: '',
        confirmPublicationTooltipSecondPart: '',
        confirmPublicationTooltipListFirstItem: '',
        confirmPublicationTooltipListSecondItem: '',
        afterConfirmPublicationTooltip: '',
        at: '',
        uploadedFinishProtocol: '',
        initiatedProtocolPublication: '',
        protocolPublished: ''
      },
      dialogs: {
        delete: '',
        dontDelete: '',
        noChanges: '',
        changes: '',
        started: '',
        yesCancel: '',
        noCancel: '',
        cancel: '',
        whoSignedProtocol: '',
        cancelingPublicationProtocol: '',
        signersSelectComment: '',
        minSignersRule(args) {},
        youCanDeleteAlreadyPublishedProtocol: '',
        itIsNotAdvisable: '',
        startDownload: '',
        thisCanOnlyBeDoneIfThereAreGoodReasons: '',
        theChangeMessageWillBeAutomaticallySentOut: '',
        doYouReallyWantToReplaceTheFinalProtocol: '',
        userUploadedFinalProtocol(args) {},
        finalProtocolHasAlreadyBeenSubmittedForPublication: '',
        afterDeletingYouWillNeedToGoAllStepsAgain: '',
        deleteFinalProtocolOfMeeting: '',
        changeProtocolFileModalText1(args) {},
        changeProtocolFileModalText2: '',
        publishingProtocolStartedModalText1: '',
        publishingProtocolStartedModalText2: '',
        publishingProtocolStartedModalText3: '',
        publishingProtocolStartedModalText4: '',
        publishingProtocolStartedModalText5: '',
        publishingProtocolStartedModalText6: '',
        publishingProtocolStartedModalText7: '',
        cancelingPublicationProtocolModalText1: '',
        cancelingPublicationProtocolModalText2: '',
        cancelingPublicationProtocolModalText3: '',
        cancelingPublicationProtocolModalText4: '',
        cancelingPublicationProtocolModalText5: '',
        cancelingPublicationProtocolModalText6: '',
        informationAboutDownloadedFiles: '',
        attention: '',
        bulletinsInProcess(args) {}
      }
    },
    tariffs: {
      yourTariffShort: '',
      tariffs: '',
      allPlans: '',
      payment: '',
      limits: '',
      meetings(args) {},
      polls(args) {},
      messages(args) {},
      mailings: '',
      smsMailings: '',
      editingBulletinPossibility: '',
      additionalMailings: '',
      mettingTemplatesAccess: '',
      massDocumentsSigning: '',
      perYear: '',
      buyTariff: '',
      buyTariffLimitsPage: '',
      payUpToTariff: '',
      free: '',
      standart: '',
      business: '',
      premium: '',
      selectTariff: '',
      yourTariff: '',
      limitIncrease: '',
      limitIncreasePrice: '',
      limitIncreaseDifferenceComment: '',
      upTo(args) {},
      daysLeft(args) {},
      trialIsActiveLabel: '',
      selectTariffExtended: '',
      selectTariffComment: '',
      selectNewTariff: '',
      paymentLimitIncreaseTitle: '',
      selectNewTariffComment: '',
      payUpToNewTariff: '',
      payUpToNewTariffComment: '',
      a: '',
      b: '',
      unlimitMettings: '',
      meetingsAreUnlimited: '',
      unlimitPolls: '',
      unlimitEmails: '',
      currentPaymentMethod: '',
      customizePaymentMethod: '',
      payedUpTo(args) {},
      tariffAutoRenewal: '',
      willDebitedFromCard(args) {},
      paymentHistory: '',
      noPaymentHistory: '',
      tariffLimits: '',
      includedInTariff: '',
      used: '',
      unlimit: '',
      notAvailable: '',
      available: '',
      emailCount: '',
      smsCount: '',
      meetingsCount: '',
      pollsCount: '',
      bulletinEdit: '',
      templateMeetingsAvailability: '',
      registryActualization: '',
      userItemPercents(args) {},
      limitIsOver(args) {},
      usedMessages: '',
      usedMeetings: '',
      usedPolls: '',
      yourCurrentTariff: '',
      youHaveSelectedTariff: '',
      payUpDiffComment(args) {},
      payUpDiffLimitsComment: '',
      willBeInNewTariff: '',
      haveNow: '',
      willBeAfterBuy: '',
      includedInNewTariff: '',
      limitIncreaseTariffDiffColumnTitle: '',
      willTransferFromOldTariff: '',
      noGoBack: '',
      buyingTariffLimitsComment1: '',
      buyingTariffLimitsComment2: '',
      yourOrder: '',
      tariff: '',
      payUpToTariff2: '',
      tariffValidityPeriod: '',
      limitValidityPeriod: '',
      paymentFailure: '',
      paymentFailureMessage(args) {},
      paymentSuccess: '',
      paymentSuccessEmailMessage(args) {},
      paymentSuccessPhoneMessage(args) {},
      autoRenewalWarning1(args) {},
      autoRenewalWarning2(args) {},
      autoRenewalTurnOffMessage: '',
      autoRenewalTurnOffWarning1(args) {},
      autoRenewalTurnOffWarning2(args) {},
      tariffPayUpComment1: '',
      tariffPayUpComment2: '',
      tariffPayUpComment3: '',
      tariffPayUpComment4(args) {},
      tariffPayUpComment5: '',
      pollsLimitExceed(args) {},
      templatePollsAccessDenied(args) {},
      emailAndSmsLimitsExceed(args) {},
      emailLimitExceed(args) {},
      smsLimitExceed(args) {},
      emailLimitExceedWithoutLink: '',
      smsLimitExceedWithoutLink: '',
      buyTariffTooltip: '',
      payUpTariffTooltip: '',
      notIncludedInTariff: '',
      townSettingsIsWrong: '',
      needCadastralCode: '',
      notAvailableInFreeTariff: '',
      pollAndMeetingTemplate: '',
      includedInPaiTariffs: '',
      unusedLimits: '',
      limitsDiff: '',
      willBeAfterPayUp: '',
      willBeAfterPay: '',
      messageLimitsOverheadMessage(args) {},
      emailMessagesSelected(args) {},
      smsMessagesSelected(args) {},
      emailMessagesAvailable(args) {},
      smsMessagesAvailable(args) {},
      trialTariff: '',
      trialTariffsMessage: '',
      willTryLater: '',
      activateTrialTariff: '',
      from: '',
      customTariffTooltip1: '',
      customTariffTooltip2: '',
      customTariffTooltipCondo: '',
      customTariffTooltipVillage: '',
      customTariffTooltip3: '',
      customTariffTooltip4: '',
      notInclude1: '',
      notInclude2: '',
      notInclude3: '',
      notInclude4: '',
      notInclude5: '',
      includeInTariff: '',
      meetings1: '',
      meetings2: '',
      meetings3: '',
      meetings4: '',
      meetings5: '',
      emailInfo(args) {},
      smsInfo(args) {},
      signing1: '',
      signing2: '',
      petitions: '',
      pretensions: '',
      customName: '',
      recommendationHighlightLabel: '',
      calculatorCustomTariffIncreaseLimitsHintTitle: '',
      calculatorCustomTariffIncreaseLimitsHintBody: '',
      calculatorCustomTariffCustomTariffHint: '',
      calculatorCustomTariffRecommendationHint1: '',
      calculatorCustomTariffRecommendationHint2: '',
      calculatorHintMessage2: '',
      calculatorLimitsNameColumnTitle: '',
      calculatorAvailableLimitsColumnTitle: '',
      calculatorCustomLimitSelectColumnTitle: '',
      calculatorCustomLimitPriceColumnTitle: '',
      calculatorNewLimitsAfterPurchaseColumnTitle: '',
      calculatorAvailableStorageLimitHint: '',
      calculatorAvailableStorageLimitHintShort: '',
      calculatorCustomLimitSelectBlankValue: '',
      calculatorCustomLimitSelectDefaultStorageValue(args) {},
      calculatorCustomLimitSelectMaxStorageValue(args) {},
      calculatorCustomLimitTotalPriceLabel: '',
      calculatorButtonGoBack: '',
      calculatorButtonBuyTariff: '',
      meetingsLimitName: '',
      actializationLimitName: '',
      bulletinsLimitName: '',
      filesLimitName: '',
      smsLimitName: '',
      emailLimitName: '',
      pollsLimitName: '',
      pepLimitName: '',
      meetingsTooltip1: '',
      meetingsTooltip2: '',
      meetingsTooltip3: '',
      meetingsTooltip4: '',
      meetingsTooltip5: '',
      meetingsTooltip6: '',
      meetingsTooltip7: '',
      meetingsTooltip8: '',
      meetingsTooltip9: '',
      meetingsTooltip10: '',
      meetingsTooltip11: '',
      storageTooltip1: '',
      storageTooltip2: '',
      town: '',
      village: '',
      storageTooltip3: '',
      storageTooltip4: '',
      bulletinsTooltip1: '',
      bulletinsTooltip2: '',
      bulletinsTooltip3: '',
      smsTooltip1: '',
      smsTooltip2: '',
      smsTooltip3(args) {},
      smsTooltip4: '',
      smsTooltip5: '',
      emailTooltip1: '',
      emailTooltip2: '',
      emailTooltip3: '',
      emailTooltip4: '',
      actualizeTooltip1: '',
      actualizeTooltip2: '',
      actualizeTooltip3: '',
      actualizeTooltip4: '',
      actualizeTooltip5: '',
      actualizeTooltip6: '',
      actualizeTooltip7: '',
      payUpLimits: '',
      currentTariff: '',
      modalTitle1: '',
      modalTitle2: '',
      cantBuy: '',
      forCondo1: '',
      forCondo2: '',
      tariffsDepend: '',
      need: '',
      settings: '',
      noNumber1: '',
      noNumber2: '',
      noNumber11: '',
      noNumber12: '',
      noNumber3: '',
      noNumber33: '',
      noNumber4(args) {},
      noNumber5: '',
      noNumber6: '',
      noNumber66: '',
      noNumber7: '',
      close: '',
      goToSettings: '',
      structure1: '',
      structure2: '',
      structure3(args) {},
      structure4: '',
      settings1: '',
      settings2: '',
      page: '',
      apartmentsCount1: '',
      apartmentsCount2: '',
      customMeetings: '',
      customPolls: '',
      smsAndEmails: '',
      actualization: '',
      pollsTooltip1: '',
      pollsTooltip2: '',
      pollsTooltip3: '',
      pollsTooltip4: '',
      pollsTooltip5: '',
      limitUnitStorageGb: '',
      limitUnitStorageMb: '',
      limitUnitStorageKb: '',
      limitUnitStorageBytes: '',
      villageModal1: '',
      villageModal2: '',
      disabledTitle: '',
      pepTooltipTitle: '',
      pepTooltipText1: '',
      pepTooltipText2: '',
      pepTooltipText3: '',
      pepTooltipText4: '',
      pepTooltipText6: '',
      pepTooltipPriceText(args) {},
      pepTooltipNoPriceText: '',
      nameOfPEPLimit: '',
      notificationDataSavedToastText: ''
    },
    payment: {
      addPaymentMethod: '',
      paymentMethod: '',
      payment: '',
      changePaymentMethod: '',
      noCardsAdded: '',
      payUp: '',
      price: '',
      cardAddError: '',
      cardRemoveError: '',
      cardSelectError: '',
      cardDelete: '',
      cardDeleteWarning: '',
      cardWithAutoRenewalDeleteWarning: '',
      cardAutorewireLabel: '',
      cvvHintTitle: '',
      cvvHintMessage: '',
      russianCard: '',
      foreignCard: '',
      stripeTokenErrorText: '',
      emailLabel: '',
      emailHint: '',
      emailErrorRequired: '',
      emailError: ''
    },
    pretensions: {
      createSuccess: '',
      editFieldTitle: '',
      editFieldDate: '',
      editFieldRSO: '',
      editFieldDescriptionRSO: '',
      daysLeftTooltip: '',
      requestCountTooltip: '',
      appeal: '',
      days: '',
      filterInitiator: '',
      filterStatus: '',
      filterRSO: '',
      notSelectRowsToDelete: '',
      notSelectRowsToExport: '',
      notSelectRowsToSend: '',
      pretensionCreate: '',
      pretensionCreature: '',
      exportPretension: '',
      sendingNotification: '',
      selectedDelete: '',
      pretensionEmpty: '',
      requestEdit: '',
      newRequest: '',
      whoAsked: '',
      requestNumber: '',
      disabledDateTooltip: '',
      notificationHint: '',
      freeTariff: '',
      requests: {
        title: '',
        addRequest: '',
        createBeforeAdd: '',
        columns: {
          owner: '',
          address: '',
          id: '',
          number: ''
        },
        filter: {
          street: '',
          entrance: '',
          isRegistered: '',
          contactInfo: ''
        },
        modalView: {
          titleEdit: '',
          titleNew: '',
          fields: {
            whoApplied: '',
            number: ''
          }
        },
        registered: '',
        unregistered: '',
        notSpecified: '',
        emailSpecified: '',
        phoneSpecified: '',
        titleDelete: ''
      },
      inProgress: '',
      deadlineWasExceededWithMoney: '',
      fixedInDueTimeWithoutMoney: '',
      success: ''
    },
    petitions: {
      petition: '',
      generated: '',
      published: '',
      petitionCreate: '',
      selectFromTemplate: '',
      daysLeft(args) {},
      lessThanDay: '',
      voted(args) {},
      charactersLeft(args) {},
      numberSignatories: '',
      petitionEmpty: '',
      petitionTemplatesEmpty: '',
      filterCreator: '',
      filterStatus: '',
      indefinitely: '',
      remainingCompleteSignatures: '',
      remainingCompleteSignaturesIndefinitely: '',
      signatureEndDate: '',
      textPetitionMinSymbol: '',
      collectionSignaturesBefore: '',
      statusActive: '',
      statusDraft: '',
      statusVotingClosed: '',
      petitionSigning: '',
      petitionSigningDescription1: '',
      petitionSigningDescription2: '',
      petitionSigningDescription3: '',
      createSuccess: '',
      changesSaved: '',
      deleteSuccess: '',
      closeSuccess: '',
      publishSuccess: '',
      cloneSuccess: '',
      deleteThePetition: '',
      petitionDeleteWarning: '',
      petitionDraftDeleteWarning: '',
      closeThePetition: '',
      petitionСloseWarning: '',
      petitionPublish: '',
      petitionPublishWarning: '',
      getLinksRegistry: '',
      linksRegistryComment: '',
      downloadPrintingDocuments: '',
      printingDocumentsComment: '',
      notifyUsers: '',
      copyLink: '',
      deletePetition: '',
      closePetition: '',
      editPetition: '',
      clonePetition: '',
      sendLinksRegistrySuccess: '',
      sendPrintingDocumentsSuccess: '',
      refuseModalTitle: '',
      refuseModalMessage: '',
      signModalTitle: '',
      signModalMessage: '',
      signingSuccess: '',
      rejectSuccess: '',
      rejectStatus(args) {},
      signStatus(args) {},
      revokeSign: '',
      revokeSignSuccess: '',
      revokeReject: '',
      revokeRejectSuccess: '',
      signRevoking: '',
      rejectRevoking: '',
      revokeComment: '',
      revokeCommentPlaceholder: '',
      refuseCommentPlaceholder: '',
      deleteCommentPlaceholder: '',
      closeCommentPlaceholder: '',
      loginToSign: '',
      loginToSignMessage: '',
      guestWarning: '',
      petitionCreating: '',
      templateApply: '',
      petitionCreateFromTemplateMessage(args) {},
      petitionTemplateApplyMessage: '',
      templateApplySuccess: '',
      cancelTemplateSelect: '',
      petitionTemplate: '',
      commonResults: '',
      rollCallResults: '',
      usersSigned: '',
      usersRejected: '',
      usersAbstained: '',
      usersNotRead: '',
      usersRevoked: '',
      sign: '',
      resultButtonTooltip: '',
      noTitle: '',
      noDesription: '',
      wrongDesription(args) {},
      wrongFinishDate: '',
      signPetition: '',
      petitionLink: '',
      abstainedUsersTooltip: '',
      notReadUsersTooltip: '',
      notRegisteredUserWarning: '',
      signCommentPlaceholder: '',
      loginToSendVoteResultTitle: '',
      loginToSendVoteResultMessage: '',
      voteCommentPlaceholder: '',
      withoutComment: '',
      withComment: '',
      signCommentTitle: '',
      revokeCommentTitle: '',
      refuseCommentTitle: '',
      tariff: '',
      closePetitionHint: '',
      editPetitionHint: '',
      backAndChangePhone: '',
      petitionsResultInfoModalTitle: '',
      petitionsResultInfoModalText1: '',
      petitionsResultInfoModalText2: ''
    },
    callCenter: {
      contacts: '',
      editCommentPlaceholder: '',
      commentPlaceholder: '',
      noContacts: '',
      lastContact: '',
      nextContact: '',
      noContactsOccurred: '',
      noContactsPlanned: '',
      statusChangeSuccessfully: '',
      nextContactHasPlanned: '',
      closeContactModalAlertTitle: '',
      closeContactModalAlertText: '',
      closeContactModalAlertYesLabel: '',
      closeContactModalAlertNoLabel: '',
      chooseNextContact: '',
      linkCopied: ''
    },
    cadastral: {
      cadastralSaveNumber: {
        modalTitle: '',
        modalTitleGroup: '',
        modalYes: '',
        modalNo: '',
        cadastralNumber: '',
        cadastralChangeNotification0: '',
        cadastralChangeNotification1: '',
        tryingToCheck: '',
        tooltip: '',
        addressNotFound: '',
        wrongObjectType: '',
        badRequest: '',
        tryLater: '',
        sameNumbers: '',
        notFound: '',
        noCadastralNumbersForHoa: '',
        noCadastralNumbersForOther: ''
      },
      cadastralInfoApartments: {
        modalTitle: '',
        settingsTitle: '',
        modalOk: '',
        noNumber1: '',
        noNumberForGroup1: '',
        noNumber2(args) {},
        noNumberForGroup2(args) {},
        thereIsNumber: '',
        thereIsNumberForGroup: '',
        statementWasNotReceivedText1: '',
        statementWasNotReceivedTextForGroup1: '',
        statementWasNotReceivedText2: '',
        statementHasBeenReceived: '',
        statementHasBeenReceivedForGroup: '',
        noApartment1: '',
        noApartmentForGroup1: '',
        noApartment2(args) {},
        noApartmentForGroup2(args) {},
        numbersOfApartmentsIsKnown: '',
        numbersOfApartmentsIsKnownForGroup: '',
        wait: '',
        item3: '',
        cadastralDescriptionThird: '',
        cadastralDescriptionThirdLink: '',
        goToSettings: '',
        settings: '',
        enterData: '',
        settingsButton: '',
        unavailable: '',
        noSettings: '',
        after: '',
        cadastralNumberYourHouse: '',
        cadastralNumbersYourHouses: '',
        noNumberCondo: '',
        noApartmentCondo: '',
        noSettingsCondo: '',
        modalSubtitle: '',
        cadastralNumberWithCountApartmentsText: '',
        cadastralNumberWithCountApartmentsForCondoText: ''
      },
      cadastralInfoRegistryActualizationModal: {
        modalTitle: '',
        yesLabel: '',
        okLabel: '',
        noLabel: '',
        tariffLabel: '',
        cadastralNumber(args) {},
        alreadyOrderRegistry: '',
        reminder(args) {},
        buyActualization: '',
        goToTariffs: '',
        attention: '',
        suggestion: '',
        priceCalculating: '',
        priceActualization: '',
        price(args) {},
        registryOrderPrice(args) {},
        calculatingSchemePart1: '',
        calculatingSchemeInfoModalTitle: '',
        calculatingSchemePart2: '',
        calculatingSchemePart3: '',
        calculatingSchemePart4: '',
        supportPart1: '',
        supportPart2: '',
        tooltipOrderTitle: '',
        tooltipOrderTitleMKD: '',
        tooltipOrderTitleGroup: '',
        step: '',
        tooltipPart1: '',
        tooltipCode1: '',
        tooltipApartments(args) {},
        tooltipCalcApartments(args) {},
        tooltipPart2(args) {},
        tooltipPart5(args) {},
        tooltipPart6(args) {},
        tooltipPart7(args) {},
        tooltipPart9: '',
        tooltipPart10(args) {},
        tooltipPart11(args) {},
        tooltipActualizeTitle: '',
        tooltipActualizeCost: '',
        example1: '',
        example2: '',
        example3(args) {},
        example4(args) {},
        actualizationTitle: ''
      },
      cadastralCommonInfo: {
        modalTitle: '',
        yesLabel: '',
        noLabel: '',
        info: '',
        condoGroup: '',
        enterNumber: '',
        planToPlay: '',
        itemA: '',
        itemB: '',
        itemC: '',
        ifSaveNumber: '',
        warning: '',
        cantAddNewBuldings: '',
        dontEnterNumber: '',
        dontPlanToPay: '',
        ifDontKnowNumber: '',
        forSearch: '',
        alreadyOrderedTitle: '',
        alreadyOrdered: '',
        wait(args) {},
        questions: '',
        support: '',
        close: '',
        noNeed: '',
        again: '',
        yesOrder: '',
        alreadyActualize: '',
        waitActualize(args) {}
      },
      cadastralNoCadastralModal: {
        title: '',
        noSettingsTitle: '',
        noLabel: '',
        yesLabel: '',
        text1: '',
        text2: '',
        text3: '',
        text4: '',
        text5: '',
        text6: '',
        text7: '',
        tooltip1: '',
        tooltip2: '',
        tooltip3: '',
        tooltip4: '',
        tooltip5: '',
        condo1: '',
        condo2: '',
        condo3: '',
        condo4: '',
        condo5: ''
      },
      cadastrialInfoRegistryInfoModal: {
        modalTitle: '',
        modalContent: '',
        modalYes: '',
        modalNo: ''
      },
      common: {
        unknownCadastralCode: '',
        goToPayment: '',
        addedCadastralCode: ''
      }
    },
    actualization: {
      actualization: {
        noNumberTitle: '',
        noOrderedTitle: '',
        noLimitTitle: '',
        noMeetingTitle: '',
        noOrderTitle: '',
        actualizationTitle: '',
        alreadyOrderedTitle: '',
        alreadyActualizationTitle: '',
        alreadyCompleteTitle: '',
        uknownCountTitle: '',
        unavailable: '',
        noNumber: '',
        noNumberCondo: '',
        goToSettings: '',
        enterNumber: '',
        enterNumberCondo: '',
        noOrder: '',
        noLimit: '',
        orderRegistry: '',
        or: '',
        buyTariff: '',
        noMeetings: '',
        noLabel: '',
        goToTariffs: '',
        noLimitDescription: '',
        tariffs: '',
        chooseTariffPlan: '',
        oneTimeTariff: '',
        alreadyOrdered: '',
        registry: '',
        actialization: '',
        wait(args) {},
        orderCompletes: '',
        questions: '',
        writeToSupport: '',
        click: '',
        payForTariff: '',
        actualize: '',
        noNumberTooltip1: '',
        noNumberTooltip2: '',
        noNumberTooltip3: '',
        noNumberTooltip4: '',
        unknownCountTooltip: '',
        orderRunning: '',
        orderRunningTooltip1: '',
        orderRunningTooltip2: '',
        yet: '',
        waitOrder(args) {},
        attention1: '',
        after: '',
        noLimit2: '',
        attention2: '',
        available: '',
        lastLimit: '',
        limits: '',
        limitsCount1(args) {},
        limitsCount2(args) {},
        moreLimits1: '',
        moreLimits2: '',
        complete: '',
        noNeedOrderAgain: '',
        noAgain: '',
        noLimit3: '',
        noMeetingsNoNumber: '',
        noMeetings2: ''
      }
    },
    contacts: {
      tooltipForWarningIcon: '',
      menuItem1: '',
      menuItem2ManagementStuff: '',
      menuItem2HOAEmployee: '',
      menuInvite1: '',
      menuInvite2: '',
      successInviteMessage(args) {},
      notifyNoOneSelected: '',
      addUserButtonTooltipText: ''
    },
    addresses: {
      addressModalForm: {
        addressCreateTitle: '',
        addressEditTitle: '',
        cancelAddressCreateTitle: '',
        cancelAddressEditTitle: '',
        cancelAddressCreateText: '',
        cancelAddressEditText: '',
        phoneTypeCreateTitle: '',
        phoneTypeEditTitle: '',
        bottomTextForChipsInput: '',
        disabledTooltipText: '',
        createAddressSuccess: '',
        leaveReview: '',
        editAddressSuccess: '',
        noOptions: '',
        showMore: '',
        errorTitleText: '',
        errorAddressText: '',
        typeMobilePhone: '',
        typeLandlinePhone: '',
        typeWorkPhone: '',
        typeHomePhone: ''
      }
    },
    documents: {
      addDocumentModal: {
        addDocumentTitle: '',
        editDocumentTitle: '',
        chipsInputBtmText(args) {},
        nameInputHelperText(args) {},
        nameInputBottomHelperText: '',
        tagsError(args) {},
        selectPlaceholder: '',
        createCategory: '',
        createCategoryModalTitle: '',
        editCategoryModalTitle: '',
        inputLabelOptionCreate: '',
        inputLabelOptionEdit: '',
        maxLength(args) {},
        documentSuccessfullyAddedText: ''
      },
      documentsTable: {
        name: '',
        category: '',
        date: '',
        favorite: '',
        menuItem1: '',
        menuItem2: '',
        menuItem3: '',
        menuItem4: '',
        menuItem5: '',
        menuItem6: '',
        removeFavorite: '',
        addFavorite: '',
        download: '',
        copy: '',
        edit: '',
        delete: '',
        sort: '',
        deletedTooltip: '',
        editTableColumns: '',
        menuTooltip: '',
        favoriteIconTooltip: ''
      },
      documentsInfo: {
        title: '',
        whoEdited: '',
        size: '',
        deleted: '',
        sizeInKb: '',
        sizeInMb: ''
      },
      documentsControls: {
        disableDownloadTooltipText: '',
        disableDeleteTooltipText: '',
        modalAlertTitle: '',
        gotItText: '',
        successDeletedDocumentsText: '',
        inputSearchPlaceholder: '',
        selectAll: '',
        successDownloadDocsText: '',
        sentToSQVRAppText: '',
        sentToTgBotText: '',
        sentToEmailText: '',
        warningDeleteText: '',
        urlCopy: '',
        addFavorite: '',
        removedFavorite: '',
        emptyText: ''
      }
    },
    documentsSigning: {
      greetings: '',
      owner: '',
      rooms(args) {},
      phone: '',
      getValidationCode: '',
      getValidationCodeAgain: '',
      resendingCodeTime(args) {},
      signContract: '',
      registerText1: '',
      registerText2: '',
      signInToSQVRText1: '',
      signInToSQVRText2: '',
      contractHasAlreadySigned: '',
      viewContract: '',
      pageNotFound: '',
      infoModalText1: '',
      infoModalText2: '',
      infoModalText3: '',
      infoModalText4: '',
      infoModalText5: '',
      infoModalText6: '',
      infoModalText7: '',
      infoModalText8: '',
      checkSms: '',
      wrongCode: ''
    },
    errors: {
      error404Title: '',
      error404Description: '',
      goHome: '',
      mobileAppInfo1: '',
      mobileAppInfo2(args) {},
      mobileAppInfo3: ''
    }
  },
  yup: {
    mixed: {
      default: '',
      required: '',
      oneOf: '',
      notOneOf: ''
    },
    string: {
      length(args) {},
      min(args) {},
      max(args) {},
      matches(args) {},
      email: '',
      url: '',
      trim: '',
      lowercase: '',
      uppercase: ''
    },
    number: {
      min(args) {},
      max(args) {},
      lessThan(args) {},
      moreThan(args) {},
      positive: '',
      negative: '',
      integer: ''
    },
    date: {
      min(args) {},
      max(args) {}
    },
    object: {
      noUnknown: ''
    },
    array: {
      min(args) {},
      max(args) {}
    }
  },
  validateMessages: {
    checkCompare: '',
    checkCompareWithIndex: '',
    checkNotCompareWithIndex: '',
    phone: '',
    email: '',
    minValue: '',
    maxValue: '',
    lessOrEqualValue: '',
    checkLetters: '',
    onlyRussianLetters: '',
    onlyLatinLetters: '',
    userNameLetters: '',
    onlyNumbers: '',
    undefinedRule: '',
    empty: '',
    undefinedFunction: '',
    bik: '',
    inn: '',
    innFull: '',
    snils: '',
    ogrn: '',
    cardNumber: '',
    cvv: '',
    month: '',
    year: '',
    date: '',
    emailExistInHoa: '',
    phoneExistInHoa: '',
    dataIsExists(args) {},
    minPasswordLength(args) {},
    duplicatedEmail: '',
    duplicatedPhone: '',
    passwordConfirmError: '',
    phoneUsed: '',
    phoneFormatHint: '',
    required: '',
    invalidCardNumber: '',
    invalidCardHolder: '',
    cardHasExpired: '',
    wrongCode: '',
    wrongChars: '',
    nickname: '',
    minLength(args) {},
    maxLength(args) {},
    remainingNumCharacters(args) {},
    minTextLength(args) {},
    maxTextLength: '',
    extraCharacters(args) {},
    enterValidMobilePhone: '',
    enterValidPhone: ''
  },
  formLabels: {
    email: '',
    emailOrPhone: '',
    password: '',
    passwordConfirm: '',
    firstName: '',
    lastName: '',
    middleName: '',
    juristicPersonName: '',
    juristicPersonLegalForm: '',
    juristicPersonOgrn: '',
    juristicPersonInn: '',
    phone: '',
    phoneShort: '',
    zip: '',
    region: '',
    city: '',
    settlement: '',
    streetType: '',
    house: '',
    block: '',
    unitType: '',
    info: '',
    address: '',
    form: '',
    name: '',
    propertyUsageType: '',
    street: '',
    flat: '',
    land: '',
    apartment: '',
    apartmentShort: '',
    apartmentFull: '',
    verificationCode: '',
    dateAndTime: '',
    cardNumber: '',
    month: '',
    year: '',
    cvv: '',
    expiryDate: '',
    code: '',
    cardHolder: '',
    autoRenewal: '',
    date: '',
    fio: '',
    sum: '',
    entrance: '',
    homeAddress: '',
    building: '',
    buildingShort: '',
    startEnteringName: '',
    passport: '',
    passportIssued: '',
    webSite: '',
    tags: '',
    additionalInfo: '',
    category: '',
    role: ''
  },
  user: {
    Integrator: '',
    Resident: '',
    Contractor: '',
    HOAEmployee: '',
    ManagementStuff: ''
  },
  supportModal: {
    modalTitle: '',
    email: '',
    message: '',
    messageSentTitle: '',
    messageSentText: ''
  }
};