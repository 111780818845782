import { appConfig } from '@sqvr/utils';
import { createApolloClient } from '@sqvr/protocol';
import { fetchMeDataAndInitializeLocale } from './fetchUserAndInitializeLocale';

async function initializeApp() {
  // Загрузим файл конфига с основными настройками приложения
  await appConfig.prepare();

  // Создадим клиент Apollo
  createApolloClient(appConfig.graphqlAPI);

  const meData = await fetchMeDataAndInitializeLocale();

  // Загрущим модуль core с основной логикой приложения
  const { getCerebralApp } = await import(/* webpackChunkName: "core" */ '@sqvr/core');

  const { startAppAppForBackbone } = await import(
    /* webpackChunkName: "app-startup-pipeline" */ './appStartupPipeline'
  );

  // Подготовим всю экосистему для реакта со сторами
  await startAppAppForBackbone();

  // Загрузим необходимые компоненты для бекбона
  const { componentsForBackbone } = await import(/* webpackChunkName: "react-components" */ './componentForBackbone');

  window.reactAppAPI.setComponents(componentsForBackbone);

  const cerebralApp = getCerebralApp();

  // Запустим главную секвенуцию инициализации
  await cerebralApp.getSequence('initialize')({ meData });

  window.reactAppAPI.initializeComponentContainer();
  window.reactAppAPI.renderPortals();
}

// eslint-disable-next-line no-console
initializeApp().catch(err => console.error('ERR', err));
