"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  hideHints: 'Hide hints',
  openAllHints: 'Open all hints',
  showHints: 'Show hints',
  needToSelectPeople: 'You need to select someone'
};