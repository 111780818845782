"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Добавьте свой e-mail',
  titlePassword: 'Придумайте пароль',
  titleDone: 'Вы все сделали верно',
  titlePasswordConfirmWarning: 'Пароли не совпадают',
  loginFormTitle: 'Этот телефон уже зарегистрирован. Введите пароль от данного телефона.',
  subTitleExist: 'Но этот аккаунт зарегистрирован в другом объекте, если Вы хотите присоединится и к {{hoaName}} то сделайте следующее:',
  titleExist: 'Оказывается у Вас уже есть аккаунт',
  titleConflict: 'Используйте другой телефон',
  emailFormTitle: 'Приглашение',
  emailFormReject: 'Прервать. НЕ ПРИСОЕДИНЯТЬСЯ',
  emailFormContinue: 'Присоединиться ',
  tooltipEmail: 'Вы не можете сейчас изменить e-mail. Если это не Ваш e-mail, нажмите на "ПРЕРВАТЬ. НЕ ПРИСОЕДИНЯТЬСЯ" в нижней части этого окна. Вы сможете изменить e-mail, после завершения регистрации.',
  existPassword: 'Введите Ваш пароль',
  existWait: 'После введения пароля вы будете зарегистрированы',
  existWarning: 'Администратор Объекта получит от Вас заявку на присоединение и, после ее одобрения, Вы получите сообщения с подтверждением номера телефона и адреса электронной почты.',
  phone: '<strong>Ведите номер телефона</strong>' + '. Вы сможете использовать двухфакторную авторизацию и подтверждать свое голосование простой электронной подписью ПЭП.',
  donePhone: 'Номер телефона сохранен.',
  email: '<strong>E-mail</strong>' + ' уже сохранен. Вы сможете получать сообщения обо всех событиях в доме или посёлке и уменьшите затраты на отправку смс.',
  doneEmail: 'Адрес электронной почты сохранен',
  password: '<strong>Придумайте пароль.</strong>',
  passwordValidate: 'Придумайте пароль.',
  donePassword: 'Пароль создан.',
  passwordConfirm: '<strong>Повторите пароль</strong>' + ', чтобы избежать ошибки.',
  differentPasswords: 'Пароли не совпадают',
  donePasswordConfirm: 'Пароль введен повторно.',
  warning: 'Администратор Объекта получит от Вас заявку на присоединение и, после ее одобрения, Вы получите sms с подтверждением номера телефона.'
};