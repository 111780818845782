"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  tooltips: {
    rows: {
      filtered: 'Displayed rows including filters',
      total: 'Total lines in the register'
    },
    apartments: {
      filtered: 'Displayed rooms including filters',
      total: 'Total rooms according to house settings'
    },
    totalArea: {
      filtered: 'Sum of areas of the displayed rooms taking into account filters',
      total: 'Sum of all areas according to house settings'
    },
    owners: {
      filtered: 'Displayed owners taking into account filters',
      total: 'Total owners in the register'
    },
    ownerMeetingVotesPercents: {
      filtered: 'Sum of votes of the displayed owners taking into account filters',
      total: 'Sum of votes of all owners in % of the sum of votes according to the house settings'
    },
    communityMeetingVotesPercents: {
      filtered: 'Sum of votes of the displayed owners-members of the Partnership taking into account filters',
      total: 'Sum of votes of all members of the Partnership in % of all votes of the building owners'
    }
  }
};