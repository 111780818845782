"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  poll: 'Опрос',
  polls: 'Опросы',
  cancelPoll: 'Отменить опрос',
  pollResults: 'Результаты голосования',
  addCover: 'Добавить обложку для опроса',
  pollPreview: 'Предпросмотр опроса',
  publishPoll: 'Опубликовать опрос',
  pollIsPublished: 'Опрос опубликован!',
  pollPublishError: 'Ошибка публикации опроса!',
  deletePoll: 'удалить опрос',
  deletePollQuestion: 'Удалить опрос?',
  attachDocuments: 'Прикрепить к опросу документы',
  filtersAndSearch: 'Фильтры и поиск по опросам',
  pollDescription: 'Описание опроса',
  pollPost: 'Публикация опроса',
  notEditPollAfterPublishing: 'После публикации опроса вы не сможете вносить в него изменения.',
  followToPoll: 'Перейти к опросу',
  pollDraftWillBeDeleted: 'Черновик опроса будет удален.',
  usersVoted_0: 'проголосовал',
  usersVoted_1: 'проголосовало',
  usersVoted_2: 'проголосовало',
  votes_0: '{{count}} голос',
  votes_1: '{{count}} голоса',
  votes_2: '{{count}} голосов',
  votesPerscentsFromAll: 'от голосов всех собственников',
  createPoll: 'Создать опрос',
  active: 'Идёт голосование',
  finished: 'Завершён',
  archived: 'Отменен',
  draft: 'Черновик',
  withMyParticipation: 'С моим участием',
  withoutMyParticipation: 'Опросы, где я не голосовал',
  pollWillBeFinished: 'Опрос завершится',
  usersVotedCount: 'Проголосовавших:',
  voteIsTaken: 'Голос учтён',
  pollFinished: 'Опрос завершен',
  pollCancelled: 'Опрос отменен'
};