"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  addPaymentMethod: 'Добавить способ оплаты',
  paymentMethod: 'Способ оплаты',
  payment: 'Оплата',
  changePaymentMethod: 'Изменить способ оплаты',
  noCardsAdded: 'Не добавлено ни одной карты',
  payUp: 'Доплатить',
  price: 'Стоимость',
  cardAddError: 'Ошибка при добавлении карты.',
  cardRemoveError: 'Ошибка при удалении карты.',
  cardSelectError: 'Ошибка при выборе карты.',
  cardDelete: 'Удаление карты',
  cardDeleteWarning: 'Вы действительно хотите удалить данную карту?',
  cardWithAutoRenewalDeleteWarning: 'Вы действительно хотите удалить данную карту? В данный момент она используется для автоматического продления тарифа. После удаления автопродление будет отключено.',
  cardAutorewireLabel: 'Сохранить карту для будущих оплат',
  cvvHintTitle: 'Расположение номера CVV',
  cvvHintMessage: 'На картах Visa и Mastercard\n3‑значный номер CVV\nуказывается сзади,\nсправа от подписи.',
  russianCard: 'Российская карта',
  foreignCard: 'Зарубежная карта',
  stripeTokenErrorText: 'Не удалось получить токен карты от платежной системы. Оплата не произведена. Пожалуйста, попробуйте еще раз',
  emailLabel: 'Введите e-mail',
  emailHint: 'На этот e-mail мы отправим чек об оплате',
  emailErrorRequired: 'E-mail обязателен для отправки чека',
  emailError: 'Некорректный e-mail'
};