"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  totalLikes: 'всего "лайков"',
  percentVotesOfMeeting: 'процент голосов на собрании',
  totalDislikes: 'всего "дизлайков"',
  noComment: 'Без комментариев',
  cantVote: 'Вы не можете голосовать на собрании'
};