import _get from 'lodash/get';
import { MSG_ERROR_500, MSG_ERROR_502 } from '_app/constants';

const ERROR_MESSAGE = {
  500: MSG_ERROR_500(),
  502: MSG_ERROR_502(),
};

export const getErrorFromGql = error => {
  const errorMessage =
    _get(error, 'graphQLErrors.0.message') ||
    _get(error, 'networkError.result.errors.0.message') ||
    _get(error, 'message') ||
    'Error: undefined';
  const newError = { ...error };
  const networkStatus = _get(error, 'networkError.statusCode', 500);

  newError.message = ERROR_MESSAGE[networkStatus] || errorMessage;
  return {
    error: JSON.stringify(newError),
    originalError: error,
    errorMessage: ERROR_MESSAGE[networkStatus] || errorMessage,
    networkStatus,
  };
};

export default getErrorFromGql;
