"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  search: 'What do you want to find?',
  tips: 'Hints',
  writeToSupport: 'Write to technical support',
  messages: 'Messages',
  integrator: 'Expert',
  resident: 'Owner',
  contractor: 'Contractor',
  hoaEmployee: 'HOA employee',
  managementStuff: 'Management employee',
  moderator: 'Moderator',
  admin: 'Administrator',
  changeObject: 'Change object',
  areYouOwner: 'Are you the owner of the premises?',
  areYouOwnerInObject: 'You are the owner of the premises in the facility',
  wantToUserSqvr: 'Do you want to use SQVR?',
  thenFillTheForm: 'Then fill out the survey and join your neighbors.',
  fillTheForm: 'Fill out the form'
};