"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  email: 'E-mail',
  emailOrPhone: 'E-mail или телефон',
  password: 'Пароль',
  passwordConfirm: 'Подтвердите пароль',
  firstName: 'Имя',
  lastName: 'Фамилия',
  middleName: 'Отчество',
  juristicPersonName: 'Название',
  juristicPersonLegalForm: 'Организационно-правовая форма',
  juristicPersonOgrn: 'ОГРН',
  juristicPersonInn: 'ИНН',
  phone: 'Номер телефона',
  phoneShort: 'Телефон',
  zip: 'Индекс',
  region: 'Субъект Федерации',
  city: 'Город/населенный пункт',
  settlement: 'Район',
  streetType: 'Тип улицы',
  house: 'Номер дома',
  block: 'Корпус',
  unitType: 'Тип',
  info: 'Доп. информация',
  address: 'Адрес',
  form: 'Форма',
  name: 'Название',
  propertyUsageType: 'Тип недвижимости',
  street: 'Улица',
  flat: 'Номер квартиры',
  land: 'Номер участка',
  apartment: 'Номер помещения',
  apartmentShort: 'Пом.',
  apartmentFull: 'Помещения',
  verificationCode: 'Код подтверждения',
  dateAndTime: 'Дата и время',
  cardNumber: 'Номер карты',
  month: 'Месяц',
  year: 'Год',
  cvv: 'CVV',
  expiryDate: 'Срок',
  code: 'Код',
  cardHolder: 'Имя на карте',
  autoRenewal: 'Автоматическое продление',
  date: 'Дата',
  fio: 'ФИО',
  sum: 'Сумма (Руб)',
  entrance: 'Номер подъезда',
  homeAddress: 'Адрес дома',
  building: 'Строение',
  buildingShort: 'Стр.',
  startEnteringName: 'Начните вводить имя',
  passport: 'Серия и номер паспорта',
  passportIssued: 'Кем и когда выдан',
  webSite: 'Сайт',
  tags: 'Теги',
  additionalInfo: 'Дополнительная информация',
  category: 'Категория',
  role: 'Роль'
};