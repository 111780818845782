"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  useMailAndPass: 'Or use E-mail and password',
  enterWithoutPass: 'Login without password',
  noAccount: 'No account?',
  registrationCode: 'Enter the registration code',
  checkMail: 'Check your e-mail',
  sentCode: 'We have sent you a six-digit code to: {{email}}. Enter the code you received or follow the link in the message.',
  resendMail: "I didn't get an email. Send it again",
  instructionMail: 'We will send you an email/phone with instructions on how to recover your password',
  spamNotificationMessage: 'The email may go into spam.',
  passwordRecoverLinkSent_email: 'A unique password reset link has been sent to the e-mail address you provided: {{login}} an e-mail with a unique password reset link has been sent.',
  passwordRecoverLinkSent_phone: 'An sms with a unique password reset link was sent to the phone number you specified: {{login}}.',
  recover: 'Restore',
  unregisteredEmail: 'E-mail is not registered in SQVR, please check if your e-mail is correct or follow the link for',
  unregisteredPhone: 'Phone is not registered in SQVR, please check the correct phone number entered or follow the link for',
  registrations: 'registrations',
  newAccout: ' a new account.',
  mailSent: 'The letter has been sent',
  sentError: "It's been more than 15 minutes since the send",
  smsSent: 'SMS sent',
  specifiedEmail: 'To the e-mail address you provided {{login}}',
  specifiedPhone: 'To the phone number you provided {{login}}',
  uniqueLinkWasSent: 'a unique login link has been sent.',
  instructionRecoveryPasswordWasSent: 'was sent instructions on how to change your password.',
  resend: 'Resend',
  uniqueLinkEmail: 'We will send a letter/sms to your e-mail/phone with a unique login link. The letter may be spammed.',
  followMailLink: 'You can enter the code manually or follow the link from the email',
  followSmsLink: 'You can enter the code manually or follow the link from the sms',
  waitLink: 'Wait to receive and follow the link. Check for spam.',
  waitComment: 'If you do not receive the message in {{timeout}} seconds, click the button below.',
  waitRecovery: 'Wait until you receive the message. Check for spam.',
  willBeAvailableAfter_0: 'Will be available in {{count}} second',
  willBeAvailableAfter_1: 'Will be available in {{count}} seconds',
  willBeAvailableAfter_2: 'Will be available in {{count}} seconds',
  fogotPassword: 'Forgot password?',
  wrongFormat: 'Incorrect data format',
  fogotPasswordText: 'Enter your e-mail or phone number and select a convenient login option',
  linkForLogin: 'login link',
  linkForLoginTooltip: 'We will send you a unique one-time login link. You do not need to change your password',
  recoverPassword: 'reset password',
  recoverPasswordTooltip: 'We will send you instructions on how to change your password',
  linkWasSent: 'Link sent',
  instructionWasSent: 'Instructions sent'
};