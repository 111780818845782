"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  search: 'Поиск по скверу',
  contacts: 'Контакты',
  documents: 'Документы',
  usefulAddresses: 'Полезные адреса',
  desires: 'Обращения',
  announcements: 'Объявления',
  digest: 'Дайджесты',
  meetings: 'Собрания',
  polls: 'Опросы',
  budget: 'Бюджет',
  financialReports: 'Отчёты',
  management: 'Управление',
  howItWork: 'Как работает Сквер',
  howToBegin: 'С чего начать',
  forOwners: 'Для собственников',
  bills: 'Счета',
  invite: 'Пригласить в Сквер',
  inviteTooltip: 'Поделитесь ссылкой на профиль вашего дома в Сквере с помощью социальных сетей или мессенджеров',
  changeObject: 'Сменить объект',
  communication: 'Связь',
  messages: 'Сообщения',
  support: 'Служба поддержки',
  tariffs: 'Тарифы',
  pretensions: 'Претензии',
  pretension: 'Претензия',
  petitions: 'Петиции',
  myProfile: 'Мой профиль',
  feedback: 'Техподдержка',
  exit: 'Выйти',
  reservations: 'Мои бронирования',
  instructions: 'Инструкции охране',
  forAdmins: 'Для администраторов',
  vote: 'Голосуем',
  delivery: 'Мои доставки',
  appeals: 'Обращения',
  financial: 'Финансы',
  help: 'Помощь',
  info: 'Инфо',
  calendar: 'Календарь событий'
};