"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const apollo_link_context_1 = require("apollo-link-context");
const utils_1 = require("@sqvr/utils");
const localTimeLink = (0, apollo_link_context_1.setContext)((operation, prevContext) => Object.assign(Object.assign({}, prevContext), {
  headers: Object.assign(Object.assign({}, prevContext && prevContext.headers || {}), {
    'X-Local-Time': (0, utils_1.formatDate)(new Date(), {
      customFormat: 'yyyy-MM-dd HH:mm:ss'
    })
  })
}));
exports.default = localTimeLink;