"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  delete: 'Удалить опрос?',
  cancel: 'Опрос будет перемещен в архив',
  results: 'Теперь Вы можете ознакомиться с результатами опроса. У Вас есть возможность изменить Ваше голосование до {{date}}.',
  voteWarning: 'Выберите ответы на все вопросы и тогда ваш голос будет учтен.',
  pollsAuthor: 'Автор опроса',
  pollDateCreate: 'Дата создания опроса',
  pollStatus: 'Статус опроса',
  daysToEndPoll: 'Осталось дней до завершения опроса',
  alreadyVoted: 'Уже проголосовали по опросу',
  createNewPoll: 'Создать новый опрос',
  selectFromTemplate: 'Выбрать из шаблона',
  deleteDraft: 'Черновик опроса будет удален.',
  copyPoll: 'Скопировать текущий опрос?',
  changeCopyDraft: 'У вас есть черновик другого опроса. Если вы сделаете копию с текущего опроса, то Ваш черновик будет заменён.',
  deleteDraftPoll: 'Создание нового опроса приведет к удалению имеющегося черновика опроса',
  publishPoll: 'Публикация опроса',
  afterPublishNoEdit: 'После публикации опроса вы не сможете вносить в него изменения.',
  pollPublish: 'Опрос опубликован',
  pollShareLink: 'Теперь вы можете поделиться опросом с помощью ссылки.',
  goToPoll: 'перейти к опросу',
  noPollsWithParameters: 'Не найдено ни одного опроса, подходящего под выбранные параметры',
  noPollsCreated: 'Еще не создано ни одного опроса',
  finishPollEditing: 'Закончите редактирование опроса в черновике',
  chooseSeveralOptions: 'Выберите один или несколько вариантов',
  chooseSeveralOptionsOrInputYour: 'Выберите один или несколько вариантов, или впишите свой',
  markOneToTen: 'Оцените от 1 до 10, где 1 - самая худшая, а 10 - высшая оценка',
  pleaseVote: 'Проголосуйте пожалуйста в опросе.',
  pollResults: 'Результаты опроса',
  pollAlreadyVoted: 'Вы уже проголосовали в этом опросе',
  ownerVoting: 'Только собственники могут принимать участие в голосовании',
  pollPost: 'Опубликовать опрос?',
  cannotChangeAfterPublishing: 'После публикации опроса вы не сможете вносить изменения.',
  noPoll: 'Такого опроса не существует',
  changeDecision: 'Вы уже проголосовали. Если Вы хотите изменить свои решения, то выберите',
  downloadPollResults: 'Скачать результаты опроса',
  unreachablePollResults: 'Результаты опроса недоступны - нет учтенных голосов',
  chooseSuitableTemplate: 'Выберите подходящий шаблон для вашего опроса',
  search: 'Поиск по опросам',
  pollIsFinishedTooltip: 'Опрос завершен. Голосовать нельзя.',
  selectQuestionOption: 'Один из вариантов',
  integerRangeQuestionOption: 'Оценить от 1 до 10',
  checkboxQuestionOption: 'Множественный выбор',
  takeOneOfAvailableOnes: 'Возьмите один из имеющихся',
  pollsTemplates: 'шаблонов опроса',
  usePreviouslyCreatedPollsPart1: 'Использовать для создания нового опроса ранее созданные опросы? Найдите нужный опрос, нажмите на',
  usePreviouslyCreatedPollsPart2: 'и сделайте копию.'
};