"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  ownContactDetails: 'My details',
  contactDetails: 'Contact details',
  ownOwnership: 'My property',
  ownership: 'Property',
  settings: 'Settings',
  ownActivity: 'My activity',
  activity: 'Activity',
  password: 'Password change'
};