"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  contacts: 'Contacts',
  editCommentPlaceholder: 'Specify a comment on the change, minimum 2 characters',
  commentPlaceholder: 'Comment on your last contact to keep other organizers informed',
  noContacts: 'No contact details required',
  lastContact: 'Last contact',
  nextContact: 'Next contact',
  noContactsOccurred: 'There was no contact',
  noContactsPlanned: 'No contact is planned',
  statusChangeSuccessfully: 'Status has been successfully changed!',
  nextContactHasPlanned: 'The next contact has been scheduled!',
  closeContactModalAlertTitle: 'Closing',
  closeContactModalAlertText: 'When you close, you will lose the data you entered. Do you want to exit?',
  closeContactModalAlertYesLabel: 'Exit',
  closeContactModalAlertNoLabel: 'Cancel',
  linkCopied: 'Link copied to clipboard!',
  chooseNextContact: 'Choose date and time\nfor the next contact'
};