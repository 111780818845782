"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  popoverTitle: 'Памятка',
  memoTitle1: 'Зачем мы используем отправку приглашения в WhatsApp?',
  memoTitle2: 'Поэтому для того, чтобы не нарушать 152-й ФЗ “О персональных данных” лучше использовать личные сообщения в WhatsApp.',
  memoTitle3: 'Как это работает?',
  memoInfoText1: '1. Массовая рассылка приглашений в сервис Сквер может быть использована только и исключительно в рамках подготовки и проведения общего собрания собственников или собрания членов Товарищества. Потому что об этом прямо говорит статья 45 Жилищного кодекса РФ',
  memoInfoText2: '1. Очень часто пользователям нужно несколько раз напомнить о регистрации прежде чем они зарегистрируются. Чтобы не расходовать платные лимиты sms отправляйте сначала приглашения по WhatsApp. После того как сам пользователь отреагирует на такое приглашение, мы вышлем ему код регистрации по смс.',
  memoInfoText3: '2. Если Вы уверены, что пользователь готов сразу зарегистрироваться, Вы можете сразу отправить ему код регистрации по sms или по e-mail. При этом будет израсходован имеющийся оплаченный ранее лимит. Если у Вас нет оплаченных лимитов, то для рассылки сообщений нужно предварительно оплатить тариф. Перейти в ',
  memoInfoText4: '3. Мы не отправляем приглашение по WhatsApp, если номер в WhatsApp уже зарегистрирован в Сквере, и если номер телефона и номер WhatsApp не совпадают.',
  memoInfoText5: '4. Мы не отправляем приглашение по WhatsApp, если в профиле пользователя нет Фамилии и Имени.',
  memoInfoText6: '5. После клика на кнопку “Отправить приглашение” на Вашем телефоне или компьютере откроется интерфейс мессенджера WhatsApp, в котором будет типовое приглашение со ссылкой для данного пользователя.',
  memoInfoText7: '6. Отправьте это сообщение. После того как получатель приглашения кликнет на ссылку он окажется в интерфейсе Сквера, и должен будет придумать свой пароль, и подтвердить, что он согласен зарегистрироваться в Сквере.',
  memoInfoText8: '7. После этого на мобильный номер пользователя будет выслано sms со ссылкой, завершающей подтверждение номера телефона.',
  memoInfoSubtext1: '”п. 3.1. ...Согласие собственников помещений в многоквартирном доме на передачу персональных данных, содержащихся в реестре собственников помещений в многоквартирном доме, при предоставлении этого реестра в порядке, установленном настоящей частью, в целях созыва и организации проведения общего собрания собственников помещений в многоквартирном доме не требуется. (часть 3.1 введена Федеральным законом от 31.12.2017 N 485-ФЗ)”',
  tariffs: 'Тарифы'
};