"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  copySuccess: 'The link to the profile has been copied!',
  lastSendLessThanHour: 'The invitation was sent less than an hour ago',
  lastSend: 'The invitation was sent %hour% ago',
  contactIsAlreadyInUse: 'This {{contactType}} is already used in the Sqvr service',
  contactTypePhone: 'phone',
  contactTypeEmail: 'e-mail',
  blackListAdd: 'User has been successfully added to the blacklist',
  blackListDelete: 'User successfully removed from the blacklist',
  favoriteAdd: 'User has been successfully added to favorites',
  favoriteDelete: 'User has been successfully deleted from favorites',
  addUserInJoinOwnership: 'Premises No. {{apartmentNumber}} is jointly owned, the new owner will be added to the',
  allowMeetingSuccess: "Successfully changed user's ability to participate in voting",
  allowMeetingError: 'There was an error when changing the voting status',
  ownershipsEditingWarning: 'You can only edit ownership documents. To change the ownership data, contact the facility administrator.',
  invitingToSqvr: 'Welcome to the Square',
  registrationCodeSentTo: 'Registration code successfully sent to user on {{contactValue}}',
  registrationCodeSendingError: 'Error when sending registration code',
  checkEmail: 'Check your email',
  checkSMS: 'Check the sms',
  emailVerificationSent: 'We have sent you an email to {{email}}. Please follow the link in the message to confirm your e-mail address.',
  phoneVerificationSent: 'We have sent you an sms to {{phone}}. Please follow the link in the message to confirm your phone number.',
  emailVerificationError: 'Error during e-mail confirmation',
  emailVerifiedSuccessfully: 'E-mail "{{email}}" has been successfully confirmed',
  contactEmailVerificationRequired: 'Not yet confirmed. The contact person is not receiving notifications.',
  contactEmailVerificationSend_0: 'The confirmation has been sent. The next confirmation can be sent after {{count}} minute.',
  contactEmailVerificationSend_1: 'The confirmation has been sent. The next confirmation can be sent after {{count}} minutes.',
  contactEmailVerificationSend_2: 'The confirmation has been sent. The next confirmation can be sent after {{count}} minutes.',
  notMainEmailVerifiedSuccessfullyPart1: 'You have confirmed your email: {{email}}, if you want to receive notifications on it make it "Primary" by clicking on',
  notMainEmailVerifiedSuccessfullyPart2: 'near the contact',
  notMainPhoneVerifiedSuccessfullyPart1: 'You have confirmed your phone: {{phone}}, if you want to receive notifications on it make it "Primary" by clicking on',
  notMainPhoneVerifiedSuccessfullyPart2: 'near the contact',
  phoneVerificationError: 'Error when confirming a phone number',
  phoneVerifiedSuccessfully: 'Phone number "{{phone}}" has been successfully confirmed',
  recoverPasswordDialogTitle: 'Retrieve password',
  recoverPasswordDialogMessage: "<p> If you can't remember your password and want to change your old one, you can start password recovery. After that:<br /> <br /> 1. We will change your password and send it to your email.<br /> 2. Copy the new password from the messages.<br /> 3. Use this password to log in to your Object.<br /> 4. Open your profile, select the “Change password” tab and paste the copied password in place of the old password.<br /> 5. Think about and remember your new password.<br /> <br /> The current session will be terminated. Continue? </p><p> If you can’t remember your password and want to change your old one, you can start password recovery. After that:<br /> <br /> 1. We will change your password and send it to your email.<br /> 2. Copy the new password from the messages.<br /> 3. Use this password to log in to your Object.<br /> 4. Open your profile, select the “Change password” tab and paste the copied password in place of the old password.<br /> 5. Think about and remember your new password.<br /> <br /> The current session will be terminated. Continue?</p>",
  socialNetworkUserPasswordWarning: 'Authorization via social network {{socialNetwork}} is used in the account. Change the password in the social network account.',
  emailVerificationSentSuccessefuly: 'An email requesting confirmation of an e-mail address was successfully sent to the contact person at e-mail: {{email}}',
  emailVerificationSendFail: 'Error when sending an email!',
  inMinutes_0: '{{count}} minute',
  inMinutes_1: '{{count}} minutes',
  inMinutes_2: '{{count}} minutes',
  inSeconds_0: '{{count}} second',
  inSeconds_1: '{{count}} seconds',
  inSeconds_2: '{{count}} seconds',
  verificationSmsHasAlreadySent: 'Sms sent. Repeat in {{timeLeft}}',
  verificationEmailHasAlreadySent: 'The letter has been sent. Repeat in {{timeLeft}}',
  resendingWillAvailableIn: 'Resend will be available after {{minutes}}',
  registrationSmsHasAlreadySent: 'Sms with invitation has been sent. Re-sending will be available after {{minutes}}',
  emailHasSent: 'E-mail successfully sent!',
  smsHasSent: 'Sms successfully sent!',
  toggleMainEmailMessage: 'A confirmation e-mail was sent to the specified e-mail address {{email}}',
  toggleMainPhoneMessage: 'A confirmation sms was sent to the specified phone number {{phone}}',
  inThisHOA: 'in the same facility',
  inAnotherHOA: 'in another facility',
  toggleMainContactConflict1: 'You specified {{contactType}}, which is already in use',
  toggleMainContactConflict2: 'another user',
  toggleMainContactConflict3: 'Please note that if',
  toggleMainContactConflict4: 'confirms the use of the contact, your profile will be deleted from the Square service, and the',
  toggleMainContactConflict5: 'will own all of your premises.',
  toggleMainContactConflict6: 'Are you sure you want to send a confirmation to this {{contactType}}?',
  inviteConflictWarning1: 'You specified {{contactType}}, which is already in use',
  inviteConflictWarning2: 'another user',
  inviteConflictWarning3: 'Please note that if',
  inviteConflictWarning4: 'will accept the invitation, then the user information will be replaced with information from another object.',
  inviteConflictWarning5: 'Are you sure you want to send an invitation to this {{contactType}}?',
  inviteUsersConflictWarning1: 'You specified {{contactType}}, which is already in use',
  inviteUsersConflictWarning2: 'another user',
  inviteUsersConflictWarning3: 'That said, there are inconsistencies in the margins:',
  inviteUsersConflictWarning4: 'Please note that if',
  inviteUsersConflictWarning5: "will accept your invitation, he'll take over as owner",
  inviteUsersConflictWarning6: 'Are you sure you want to send an invitation to this {{contactType}}?',
  registered: 'Registered',
  invite: 'Invitation',
  toggleMainEmailWarning: '<p style=\\"margin-bottom: 16px\\">If you change your primary e-mail address, all previously sent registration codes will become invalid!</p><p  style=\\"margin-bottom: 16px\\">You will be able to resend them to the new primary e-mail address.</p>',
  toggleMainContactSmsLimitExceed: 'There is no SMS texting limit in your Facility. In order to confirm a new phone number you can contact any Moderator or Administrator to have them pay for a tariff that includes SMS texting.',
  toggleMainContactSmsLimitExceedAM: 'Or you can pay your own <a href=\\"{{link}}\\">rate</a> for texting in your Facility',
  contactExpertConflict: 'This {{contactType}} is already used in the Square service for the special role "expert". You cannot use this {{contactType}}. Enter a new {{contactType}}',
  telegramLinkSuccess: 'Connection link successfully sent to user {{user}}',
  contactRegisteredConflict: 'This number {{contactType}} is already registered to another user on the Square service. Use another {{contactType}}',
  notConfirmed: 'Not confirmed yet. You cannot use it as a means of authorization and receive messages on it.',
  emailNotConfirmed: 'E-mail has not been confirmed yet. After confirmation it will be possible to send messages to it. The user will be able to use this e-mail when authorizing.',
  phoneNotConfirmed: 'The phone has not been confirmed yet. After confirmation, you will be able to send messages to it. The user will be able to use this phone number for authorization.',
  modalText1: "1. We'll check to see if it's being used in the Square service.",
  modalText2: '2. In case this e-mail is not yet used in the Square service, we will send an e-mail with a unique link, after clicking on which the e-mail of the contact person will be confirmed.',
  modalText3: '3. If this e-mail is already in use, the e-mail will contain instructions on how to change it.',
  modalText4: '4. Until the contact person confirms the new e-mail address, they will not receive messages from Square.',
  send: 'Yes. Send',
  noSend: 'Do not send. Back',
  confirmationEmail: "Confirmation of contact person's e-mail address",
  emailNotVerified: 'E-mail has not yet been confirmed.',
  phoneNotVerified: 'The phone has not yet been confirmed.',
  userHasBeenSuccessfullyDeleted: 'User has been successfully deleted',
  membershipSuccessfullyChanged: 'Membership in the partnership has been successfully changed',
  ownershipHasBeenSuccessfullyAdded: 'Ownership has been successfully added',
  avatarSuccessfullyUploaded: 'Avatar has been successfully uploaded',
  occurredErrorWhenRequestingServer: 'An error occurred when requesting the server',
  phoneAlreadyInUse: 'Such a phone number already exists',
  socialNetworkHasBeenSuccessfullyRemoved: 'Social network has been successfully removed from your account',
  youMustSendFlagToConfirm: 'You must send a flag of consent with adding',
  invalidData: 'Not all fields contain valid data',
  closed: 'closed',
  addedToBlackList: 'added to the blacklist',
  open: 'open',
  avatarVisible: 'Avatar visibility is enabled',
  occurredWhenCreatingUser: 'An error occurred when creating a user',
  settingsHaveBeenSuccessfullyChanged: 'Settings have been successfully changed!',
  successfullySaved: 'Successfully saved',
  yourProfile: 'Your profile',
  initialsVisible: 'Initials visibility is enabled',
  phoneAlreadyInUse2: 'You are trying to add a phone number that is already in use by another user! Data will not be saved.',
  dataNotSaved: 'Data not saved!',
  failedToChangeSettings: 'Failed to change settings',
  updateStatus: 'Status of data update: ',
  socialNetworkHasBeenSuccessfullyAdded: 'Social network has been successfully added to your account',
  registrationCodeSentTo1: 'Registration code successfully sent to user on ',
  canNotDeleteMainPhone: 'Cannot delete main phone number',
  ownershipHasBeenSuccessfullyDeleted: 'Premises has been successfully deleted',
  emailAlreadyInUse: 'You are trying to add an email that is already in use by another user! Data will not be saved.',
  settingsHaveBeenSuccessfullySaved: 'Your settings have been successfully saved!',
  removedFromBlackList: 'removed from the blacklist',
  yourPasswordHasBeenChanged: 'Your password has been changed!',
  userHasBeenSuccessfully: 'User has been successfully',
  canNotDeleteMainEmail: 'Cannot delete main email',
  statusOfRequest: 'Status of request'
};