"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  votePeople_0: 'проголосовавший',
  votePeople_1: 'проголосовавших',
  votePeople_2: 'проголосовавших',
  questionVoteRateByPeoplePercents: 'от голосов собственников, принявших участие в голосовании',
  questionVoteBySquareRatePercents: 'от голосов всех собственников'
};