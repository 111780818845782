"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  petition: 'Петиции',
  generated: 'Создана',
  published: 'Опубликована',
  petitionCreate: 'Создать новую петицию',
  selectFromTemplate: 'Выбрать из шаблона',
  daysLeft_0: 'остался {{count}} день',
  daysLeft_1: 'осталось {{count}} дня',
  daysLeft_2: 'осталось {{count}} дней',
  lessThanDay: 'осталось меньше дня',
  voted_0: '{{count}} голос',
  voted_1: '{{count}} голоса',
  voted_2: '{{count}} голосов',
  charactersLeft: 'осталось {{count}} символов',
  numberSignatories: 'Число голосов',
  petitionEmpty: 'Петиции не найдены',
  petitionTemplatesEmpty: 'Шаблоны петиций не найдены',
  filterCreator: 'Автор петиции',
  filterStatus: 'Статус',
  indefinitely: 'Бессрочно',
  remainingCompleteSignatures: 'По истечению этого срока подписание петиции станет невозможным',
  remainingCompleteSignaturesIndefinitely: 'Петиция будет завершена вручную администратором',
  signatureEndDate: 'Дата окончания подписания петиции',
  textPetitionMinSymbol: 'Текст петиции, не менее 5 символов.',
  collectionSignaturesBefore: 'Сбор подписей до',
  statusActive: 'Идёт голосование',
  statusDraft: 'Черновик',
  statusVotingClosed: 'Закрыта',
  petitionSigning: 'Подписание петиции',
  petitionSigningDescription1: 'Подписание петиции осуществляется с помощью простой электронной подписи (ПЭП). Для этого Вам нужно ',
  petitionSigningDescription2: 'выбрать телефон или добавить',
  petitionSigningDescription3: ' новый номер телефона. На указанный номер будет отправлено СМС с кодом. ',
  createSuccess: 'Новая петиция успешно создана',
  changesSaved: 'Изменения сохранены',
  deleteSuccess: 'Петиция успешно удалена',
  closeSuccess: 'Петиция успешно закрыта',
  publishSuccess: 'Петиция успешно опубликована',
  cloneSuccess: 'Петиция успешно скопирована',
  deleteThePetition: 'Удалить петицию?',
  petitionDeleteWarning: 'Петиция со всеми результатами голосования будет удалена',
  petitionDraftDeleteWarning: 'Черновик петиции будет безвозвратно удален',
  closeThePetition: 'Закрыть петицию?',
  petitionСloseWarning: 'Петиция будет закрыта, голосование собственников будет остановлено',
  petitionPublish: 'Публикация петиции',
  petitionPublishWarning: 'После публикации петиции она будет доступна всем собственникам для голосования',
  getLinksRegistry: 'Получить реестр уникальных ссылок',
  linksRegistryComment: 'Уникальные ссылки на петицию для каждого собственника. Можно использовать для рассылки в мессенджерах',
  downloadPrintingDocuments: 'Скачать печатные материалы',
  printingDocumentsComment: 'Файл с петициями для подписания одним собственником/аналог бюллетеней; Реестр для поквартирного обхода и сбора подписей; Реестр подписавших петицию с помощью ПЭП; Объявление для информационного стенда',
  notifyUsers: 'Уведомить собственников',
  copyLink: 'Копировать ссылку на петицию',
  deletePetition: 'Удалить петицию',
  closePetition: 'Закрыть петицию',
  editPetition: 'Редактировать петицию',
  clonePetition: 'Сделать копию',
  sendLinksRegistrySuccess: 'Файл с уникальными ссылками был отправлен вам на e-mail',
  sendPrintingDocumentsSuccess: 'Файл с печатными материалами был отправлен вам на e-mail',
  refuseModalTitle: 'Отказ в петиции',
  refuseModalMessage: 'Вы отказываетесь подписывать петицию! Хотите оставить комментарий?',
  signModalTitle: 'Подписание петиции',
  signModalMessage: 'Вы подписываете петицию! Хотите оставить комментарий?',
  signingSuccess: 'Вы подписали петицию!',
  rejectSuccess: 'Вы отказались подписывать петицию!',
  rejectStatus: 'Вы отказались подписать петицию {{date}}',
  signStatus: 'Вы подписали петицию {{date}}',
  revokeSign: 'Отозвать подпись',
  revokeSignSuccess: 'Вы успешно отозвали подпись',
  revokeReject: 'Отозвать отказ',
  revokeRejectSuccess: 'Вы успешно отозвали отказ',
  signRevoking: 'Отзыв подписи',
  rejectRevoking: 'Отзыв отказа',
  revokeComment: 'Вы собираетесь отозвать свою подпись.',
  revokeCommentPlaceholder: 'Обязательно прокомментируйте причину отзыва',
  refuseCommentPlaceholder: 'Прокомментируйте причину отказа',
  deleteCommentPlaceholder: 'Прокомментируйте причину удаления',
  closeCommentPlaceholder: 'Прокомментируйте причину закрытия',
  loginToSign: 'Войдите для подписи петиции',
  loginToSignMessage: 'Чтобы подписать петицию вам нужно войти в существующий аккаунт или присоединиться к объекту отправив заявку администратору.',
  guestWarning: 'В результатах петиций учитываются только голоса зарегистрированных собственников. Вы можете войти или зарегистрироваться.',
  petitionCreating: 'Создание новой петиции',
  templateApply: 'Применение шаблона',
  petitionCreateFromTemplateMessage: 'Хотите создать петицию на основе шаблона "{{template}}"?',
  petitionTemplateApplyMessage: 'Хотите применить шаблон петиции?',
  templateApplySuccess: 'Шаблон петиции успешно применен',
  cancelTemplateSelect: 'Не использовать, вернуться к шаблонам',
  petitionTemplate: 'Шаблон петиции',
  commonResults: 'Общие',
  rollCallResults: 'Поимённые',
  usersSigned: 'Подписали',
  usersRejected: 'Отказались',
  usersAbstained: 'Воздержались',
  usersNotRead: 'Не ознакомились',
  usersRevoked: 'Отозвали подпись',
  sign: 'Подпись',
  resultButtonTooltip: 'Результаты подписи петиции',
  noTitle: 'Не указано название',
  noDesription: 'Не указано описание',
  wrongDesription: 'Описание должно содержать минимум {{count}} символов',
  wrongFinishDate: 'Неверная дата окончания сбора подписей',
  signPetition: 'Подписать петицию',
  petitionLink: 'Ссылка на петицию',
  abstainedUsersTooltip: 'Собственники, которые посмотрели петицию, но пока не стали подписывать или явно отказываться от подписания',
  notReadUsersTooltip: 'Собственники, которые не заходили на страницу петиции',
  notRegisteredUserWarning: 'Уведомление не будет доставлено незарегистрированным собственникам',
  signCommentPlaceholder: 'Комментарий',
  loginToSendVoteResultTitle: 'Войдите для отправки голоса',
  loginToSendVoteResultMessage: 'Чтобы ваш голос был учтен в результатах петиции, вам нужно войти в существующий аккаунт или присоединиться к объекту отправив заявку администратору',
  voteCommentPlaceholder: 'Хотите объяснить своё решение? Можете оставить здесь комментарий',
  withoutComment: 'Не оставлять',
  withComment: 'Сохранить комментарий',
  signCommentTitle: 'Вы подписали петицию',
  revokeCommentTitle: 'Вы отозвали свою подпись под петицией',
  refuseCommentTitle: 'Вы отказались подписывать петицию',
  tariff: 'Недоступно для бесплатного тарифа',
  closePetitionHint: 'Закрытие доступно только для активной опубликованной петиции, у которой не установлен срок окончания',
  editPetitionHint: 'Нельзя редактировать опубликованную петицию',
  backAndChangePhone: 'изменить номер',
  petitionsResultInfoModalTitle: 'Подсчет голосов',
  petitionsResultInfoModalText1: '<span style="font-weight: 600; color: #EC586A">ВАЖНО!!!</span> Обратите внимание, что площадь помещений и количество голосов в % может быть больше чем площадь дома в настройках и больше чем 100% голосов. Это сделано сознательно, потому что при подписании петиций гораздо важнее знать общее количество собственников в штуках, чем % голосов. Расхождения могут возникать из-за совместных собственников. Для точного подсчета % голосов используйте таблицу “Реестр подписания петиции с помощью ПЭП”, которая доступна вам в дополнительном меню “три точки” опция “Скачать печатные материалы”.',
  petitionsResultInfoModalText2: 'Обычные пользователи, без прав модераторов, видят только информацию о количестве собственников, подписавших петицию и отказавшихся подписывать петицию.'
};