"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadFetch = exports.UPLOAD_CANCELED = void 0;
exports.UPLOAD_CANCELED = 'uploadCanceled';
const parseHeaders = rawHeaders => {
  const headers = new Headers();
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach(line => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};
const uploadFetch = (url, options) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.onload = () => {
    const opts = {
      status: xhr.status,
      statusText: xhr.statusText,
      headers: parseHeaders(xhr.getAllResponseHeaders() || '')
    };
    opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
    if (xhr.status === 204) {
      resolve(null);
    } else {
      const body = 'response' in xhr ? xhr.response : xhr.responseText;
      resolve(new Response(body, opts));
    }
  };
  xhr.onerror = () => {
    reject(new TypeError('Network request failed'));
  };
  xhr.ontimeout = () => {
    reject(new TypeError('Network request failed'));
  };
  xhr.open(options.method, url, true);
  Object.keys(options.headers).forEach(key => {
    xhr.setRequestHeader(key, options.headers[key]);
  });
  if (xhr.upload) {
    xhr.upload.onprogress = options.onProgress;
  }
  if (options.onAbort) {
    options.onAbort(() => {
      xhr.abort();
      reject(new TypeError(exports.UPLOAD_CANCELED));
    });
  }
  xhr.send(options.body);
});
exports.uploadFetch = uploadFetch;