"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  sqvr: 'SQVR',
  street: 'Street',
  house: 'House',
  address: 'Address',
  registry: 'Registry',
  polls: 'Polls',
  naturalPerson: 'Individual',
  legalPerson: 'Legal entity',
  title: 'SQVR — service for managing the house and the village'
};