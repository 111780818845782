"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  comments: 'Comments',
  show: 'Show',
  showMoreComments: 'Show more comments',
  showPrevComments: 'Show previous comments',
  addComment: 'Add the comment',
  answer_0: '{{count}} response',
  answer_1: '{{count}} response',
  answer_2: '{{count}} replies',
  hide: 'Hide',
  notCommentedYet: 'You have not left a comment yet. What do you think about the draft decision?',
  commentPlaceholder: "If you'd like too, comment",
  save: 'Save',
  withoutComment: 'No comments',
  cancel: 'Cancel',
  addNewCommentToThread: 'Add a new comment to this discussion',
  changeReaction: 'Do you want to change the evaluation of the draft decision?',
  saveSuccess: 'The comment has been successfully saved.',
  from: ' from ',
  edit: 'Edit',
  change: 'Change',
  delete: 'Delete',
  editComment: 'Editing a comment',
  deleteSuccess: 'The comment has been successfully deleted.',
  commendedDecision: 'Comment on the draft decision'
};