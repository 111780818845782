"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  indicated: 'Указан',
  unknown: 'Не указан',
  buildingId_village: 'Адрес',
  buildingId_town: 'Адрес',
  buildingStreet_village: 'Все улицы',
  buildingStreet_town: 'Все улицы',
  propertyUsageType_village: 'Тип строения',
  propertyUsageType_town: 'Тип помещения',
  personType_village: 'Тип собственника',
  personType_town: 'Тип собственника  ',
  ownershipType_village: 'Тип собственности',
  ownershipType_town: 'Тип собственности',
  ownerDocument_village: 'Документы личности',
  ownerDocument_town: 'Документы личности',
  ownershipDocument_village: 'Документ на собственность',
  ownershipDocument_town: 'Документ на собственность',
  phone_village: 'Мобильный телефон',
  phone_town: 'Мобильный телефон',
  email_village: 'Электронная почта',
  email_town: 'Электронная почта',
  member_village: 'Членство в Сообществе',
  member_town: 'Членство в Сообществе',
  entrance_village: 'Подъезд',
  entrance_town: 'Подъезд',
  isRegistered_village: 'Регистрация собственников',
  isRegistered_town: 'Регистрация собственников',
  allowMeeting_village: 'Исключенные из голосования',
  allowMeeting_town: 'Исключенные из голосования',
  lName_village: 'Фамилия/Форма',
  lName_town: 'Фамилия/Форма',
  fName_village: 'Имя/Название',
  fName_town: 'Имя/Название',
  mName_village: 'Отчество/ОГРН',
  mName_town: 'Отчество/ОГРН',
  cadastreCode_village: 'Кадастровый номер',
  cadastreCode_town: 'Кадастровый номер',
  shareArea_village: 'Доля',
  shareArea_town: 'Доля/площадь',
  shareArea_specified: '$t(modules.registry.filterTitles.indicated)',
  shareArea_unspecifiedTotalArea: 'Не указана площадь',
  shareArea_unspecifiedOwnedArea: 'Не указана доля',
  shareArea_unspecified: '$t(modules.registry.filterTitles.unknown)',
  entrance_unspecified: '$t(modules.registry.filterTitles.unknown)',
  entrance_value: '{{number}} Подъезд',
  propertyUsageType_living: 'Жилые',
  propertyUsageType_nonliving: 'Нежилые',
  propertyUsageType_room: 'Комната в ком. квартире',
  propertyUsageType_house: 'Дом',
  propertyUsageType_land: 'Земельный участок',
  propertyUsageType_townhouse: 'Квартира в таунхаусе',
  propertyUsageType_other: 'Другое',
  propertyUsageType_unknown: '$t(modules.registry.filterTitles.unknown)',
  personType_government: 'Государство',
  personType_natural: 'Физические лица',
  personType_legal: 'Юридические лица',
  personType_unknown: '$t(modules.registry.filterTitles.unknown)',
  ownershipType_share: 'Долевая',
  ownershipType_join: 'Совместная',
  ownershipType_unknown: '$t(modules.registry.filterTitles.unknown)',
  ownerDocument_specified: '$t(modules.registry.filterTitles.indicated)',
  ownerDocument_unspecified: '$t(modules.registry.filterTitles.unknown)',
  ownershipDocument_specified: '$t(modules.registry.filterTitles.indicated)',
  ownershipDocument_unspecified: '$t(modules.registry.filterTitles.unknown)',
  phone_specified: '$t(modules.registry.filterTitles.indicated)',
  phone_unspecified: '$t(modules.registry.filterTitles.unknown)',
  email_specified: '$t(modules.registry.filterTitles.indicated)',
  email_unspecified: '$t(modules.registry.filterTitles.unknown)',
  allowMeeting_allowMeeting: 'Допущенные',
  allowMeeting_notAllowMeeting: 'Исключенные',
  lName_specified: '$t(modules.registry.filterTitles.indicated)',
  lName_unspecified: '$t(modules.registry.filterTitles.unknown)',
  fName_specified: '$t(modules.registry.filterTitles.indicated)',
  fName_unspecified: '$t(modules.registry.filterTitles.unknown)',
  mName_specified: '$t(modules.registry.filterTitles.indicated)',
  mName_unspecified: '$t(modules.registry.filterTitles.unknown)',
  cadastreCode_specified: 'Указан',
  cadastreCode_unspecified: '$t(modules.registry.filterTitles.unknown)',
  member_member: 'Член Товарищества',
  member_notMember: 'Не член Товарищества',
  member_unknown: '$t(modules.registry.filterTitles.unknown)',
  isRegistered_isRegistered: 'Зарегистрированные',
  isRegistered_notIsRegistered: 'Незарегистрированные',
  isRegistered_isEmpty: 'Собственник не указан',
  isRegistered_specified: '$t(modules.registry.filterTitles.indicated)',
  isRegistered_unspecified: '$t(modules.registry.filterTitles.unknown)',
  filterTitle: 'Каждое число в фильтре показывает кол-во строк в реестре'
};