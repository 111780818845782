"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  registryColumnSettings: 'Настройка столбцов реестра',
  cellWithError: 'Так показываются ячейки с противоречивой информацией',
  discardChanges: 'Отменить все внесенные изменения',
  joinRequests: 'Просмотреть заявки на присоединение',
  registryHistory: 'Посмотреть версии реестра',
  registryFilters: 'Используйте фильтры и поиск, чтобы быстро найти нужное',
  registryMenu: 'Дополнительное меню',
  unknown: 'Неизвестно',
  personTypeNatural: 'Физ лицо',
  personTypeLegal: 'Юр лицо',
  personTypeGoverment: 'Государство',
  membershipTypeNotMember: 'Не член Товарищества',
  membershipTypeMember: 'Член Товарищества',
  total: 'Итого',
  reformaGKH: 'Reformagkh',
  valFromSettings: 'Настройки',
  spaceSettingsDiffReestr: 'Данные в реестре отличаются от данных в настройках',
  areaSettingsGtReestr: 'Площадь в настройках больше чем в реестре. Изменить?',
  roomsSettingsGtReestr: 'Кол-во помещений в настройках больше чем в реестре. Изменить?',
  columnNotSelected: 'Не выбран столбец',
  columnSuccessCleared: 'Столбец успешно очищен',
  columnStatusUnknown: 'Статус удаления строк:',
  notSelectRow: 'Не помечены строки для скачивания',
  notSelectRowToDelete: 'Нечего удалять. Сначала выделите строку',
  notSelectRowToNotification: 'Некому отправить сообщение. Сначала выберите собственников',
  downloadRows: 'Скачать строки',
  clearRows: 'Очистить строки',
  clearCol: 'Очистить столбец',
  unknownU: 'НЕИЗВЕСТЕН',
  unknownUCellLabel: 'НЕТ СОБСТВЕННИКА НА ЭТУ ДОЛЮ',
  visitUserCard: 'Перейти в карточку пользователя',
  cellHaveErrors: 'Строка содержит ошибку, данные не сохранены',
  cellHaveErrorsAndSave: 'Строка сохранена, но она содержит ошибки',
  successUpdate: 'Успешно обновлено',
  successAdded: 'Успешно добавлено',
  successUploaded: 'Успешно загружено',
  successDeleted: 'Успешно удалено',
  colDisabled: 'Вы не можете скрыть столбец, пока он содержит ошибки',
  notifyRequestErrorMessage: 'Произошла ошибка. Обратитесь, пожалуйста, в службу поддержки.',
  notifyRequestSuccessMessageNotify: 'Уведомления отправлены успешно.',
  notifyRequestSuccessMessageInvite: 'Приглашения отправлены успешно.',
  streetBlock: 'Адрес дома'
};