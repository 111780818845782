"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Maintaining the cadastral number of the building',
  modalTitleGroup: 'Maintaining cadastral numbers of a group of ICDs',
  modalYes: 'yes, keep',
  modalNo: 'do not save',
  cadastralNumber: 'Inventory number',
  cadastralChangeNotification0: 'Check the number carefully. Then it can be changed only after contacting technical support.',
  cadastralChangeNotification1: 'Check the numbers carefully. Then they can be changed only after contacting technical support.',
  tryingToCheck: 'we are trying to verify your address, please wait',
  tooltip: 'An invalid cadastral number has been entered. Enter the correct number',
  addressNotFound: 'Cadastral code not found, you may have entered a cadastral code not related to the MFB(OCS)',
  wrongObjectType: 'The entered cadastral number does not belong to the house',
  badRequest: 'Failed to execute the request.',
  tryLater: 'Try again later',
  sameNumbers: 'You have entered the same cadastral numbers for different houses',
  notFound: 'Address not found',
  noCadastralNumbersForHoa: 'Please note that your Object has several condo groups at once. As soon as you enter the correct cadastral numbers for each condo group and we can verify them in EGRN, paid services will become available.  Calculation of the cost of paid services depends on the number of premises in all condo groups included in the given Object.',
  noCadastralNumbersForOther: 'As soon as you enter the correct cadastral number and we can verify it in the registration of real estate, paid services will become available, because the calculation of their cost depends on the number of rooms in your house.'
};