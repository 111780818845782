"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  byRequest: 'On request',
  nothingFound: 'Nothing found',
  contractors: 'Contractors',
  owners: 'Contacts',
  budget: 'Budget',
  works: 'Works and contracts',
  complaints: 'Claims',
  meetings: 'Meetings',
  documents: 'Documents',
  announcements: 'Announcements',
  managementAnnouncement: 'Board Announcement',
  urgentManagementAnnouncement: 'Important',
  ownAnnouncement: 'Personal announcement',
  urgentOwnAnnouncement: 'SOS',
  newComplaint: 'New',
  completedComplaint: 'Completed',
  inWorkComplaint: 'In progress',
  rejectedComplaint: 'Rejected',
  employeesInnerGroup: 'Contact persons',
  budgetInnerGroup: 'Financial Reports',
  worksInnerGroup: 'Works',
  contractsInnerGroup: 'Agreements',
  normalMeeting: 'In person ',
  virtualMeeting: 'Virtual',
  semiVirtualMeeting: 'In person and Virtual',
  ownersMeeting: 'Meeting of owners',
  managementMeeting: 'Board Meeting',
  committeeMeeting: 'Committee Meeting',
  residentsMeeting: 'Meeting of participants of the partnership',
  finishedRejectedMeeting: 'Did not take place',
  rejectedMeeting: 'Canceled',
  closedMeeting: 'Closed (Canceled by the Board)',
  draftMeeting: 'Draft',
  visibleDraftMeeting: 'Published draft',
  activeMeeting: 'Currently underway',
  calcResultsMeeting: 'Vote counting',
  registrationMeeting: 'Registration is in progress',
  votingMeeting: 'Voting in progress',
  finishedAcceptedMeeting: 'Took place',
  protocolReviewMeeting: 'Votes are being counted',
  resident: 'Proprietor',
  hoaEmployee: 'Employee of the Partnership',
  contractor: 'Contractor',
  integrator: 'Expert',
  moderator: 'Moderator',
  managementStuff: 'Employee of the management company',
  admin: 'Administrator',
  resultSearchText: 'The search results will be displayed here',
  emptyResultSearchText1: 'No results found for this query',
  emptyResultSearchText2: 'Please enter a different query',
  workOn: 'Work on',
  unknown: 'Unknown'
};