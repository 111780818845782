"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  allowMeeting: 'You cannot vote at the meeting, contact the administrator or moderators for details',
  needToVerifyEmail: 'You need to confirm this e-mail. Once you have confirmed the e-mail address, you will be able to assign it as the primary e-mail address',
  needToVerifyPhone: 'You need to confirm this phone number. After confirming the phone number, you can assign it as the primary phone number',
  addedEmailVerificationWarning: 'After saving the changes in the card, an e-mail with a link to confirm it will be sent to this e-mail address',
  notVerifiedPhone: 'Unconfirmed phone number',
  notVerifiedEmail: 'Unconfirmed e-mail',
  additionalPhone: 'Additional phone number',
  additionalEmail: 'Additional e-mail',
  canNotChangeMainPhone: 'You cannot switch the primary phone of a registered user',
  canNotChangeMainEmail: 'You cannot switch the primary e-mail address of a registered user',
  setMainPhone: 'Assign the main telephone number',
  setMainEmail: 'Assign a primary e-mail address',
  needSendRegCodeForUser: 'The user is not registered yet. Send him the registration code.',
  sendRegCode: 'The user is not registered yet. Send him the registration code.',
  mainOwnEmailInfo: 'Primary email address. This is where you get emails about events in your home',
  contactPersonVerifiedEmail: 'Mail is confirmed. It will receive copies of all user notifications',
  contactPersonDeniedMailing: 'The user has opted out of receiving the newsletter',
  mainPhone: 'Primary phone number (login)',
  mainEmail: 'Primary E-mail (login)',
  avatar: 'Upload a picture of you so neighbors can recognize you.',
  minimize: 'Collapse the user profile. After that it will be displayed on the right side of the screen and you can quickly expand it again by clicking on it',
  close: 'Close the card',
  history: 'Change history',
  addPhoneNumber: 'Add another phone number',
  deletePhoneNumber: 'Delete phone number',
  deletePhoneNumberImpossible: 'Unable to delete the primary phone number',
  addEmail: 'Add another e-mail address',
  deleteEmail: 'Delete e-mail address',
  deleteEmailImpossible: 'Unable to delete primary email address',
  mainPhoneForRegistration: 'This phone number was used during user registration',
  mainEmailForRegistration: 'This email was used during user registration',
  addContact: 'Add a contact person',
  deleteContact: 'Delete contact person',
  president: 'Chairman of the Board',
  boardMember: 'Board member',
  commiteeMember: 'Member of committees',
  tsnMember: 'Member of the Partnership',
  notTsnMember: 'Not a member of the Partnership',
  unknownMember: 'Unknown membership status in the Partnership',
  companyMember: 'Management employee',
  contractor: 'Contractor',
  admin: 'Administrator',
  moderator: 'Moderator',
  notActive: 'User not registered',
  profileIsLocked: 'Closed Profile. ',
  profileIsOpen: 'Open Profile. ',
  privateProfileIsAMI: 'The user is not yet registered.',
  publicProfileIsAMI: 'All user information is available, except for identity documents.',
  privateProfileIsNotOwner: 'Only the nickname or initials of the owner and room numbers where the owner has ownership interests are visible.',
  publicProfileIsNotOwner: 'All information is available except title and proof of identity.',
  privateProfile: 'Users without administrative rights can only see your nickname or initials and the numbers of rooms where you have an ownership interest. ',
  publicProfile: 'Other users will be able to see all information about you except your documents. ',
  canToggleProfile: 'You can always close or open your profile.',
  publicAdminProfile: 'An administrator, moderator or expert cannot make their profile private',
  unlockProfile: 'Your profile is now closed. Other owners can only see your full name, and the address of your house. Click on the lock if you want to open your profile for viewing',
  lockProfile: 'Click on the lock if you want to close your profile for viewing',
  editMode: 'Go to edit mode and enter the missing data',
  ownershipShow: 'Unfold property card',
  ownershipHide: 'Collapse property card',
  deleteOwner: 'Remove the owner from this apartment?',
  chooseProperty: 'Select the type of room',
  chooseOwnershipType: 'Select the type of ownership',
  choosePersonType: 'Select the user type',
  addInFavorite: 'Add user to favorites',
  userInFavorite: 'User in favorites. Click to remove it from the list',
  addInBlacklist: 'Add a user to the blacklist',
  userInBlacklist: 'The user is blacklisted. Click to remove it from the list',
  regCode: 'The user is not registered yet. Send him the registration code.',
  mainPhoneInfo: 'phone',
  otherPhoneInfo: 'Additional phone',
  mainEmailInfo: 'e-mail',
  otherEmailInfo: 'Additional e-mail',
  mainPhoneDisabled: 'Phone number cannot be edited from a registered user',
  otherPhoneDisabled: 'You cannot edit a user-confirmed phone number',
  mainEmailDisabled: 'E-mail cannot be edited from a registered user',
  otherEmailDisabled: "You cannot edit a user's confirmed e-mail",
  reports: 'Responsible for filing reports',
  rating: "Average rating according to owners' assessments, given on a 10-point scale. 1 is the lowest score, 10 is the highest score.",
  forDisabledMainField: 'This field cannot be edited by a registered user',
  disabledPersonalNotifications: 'Disabled receiving personal notifications',
  disabledAutomaticNotifications: 'Disabled receiving automatic notifications',
  thinkWeSpammers: 'The e-mail newsletters have been disabled.',
  disabledAllNotifications: 'Disabled receipt of all notifications',
  userIsInFavorite1: "You have added the user to your favorite contacts list. This allows you to group users by any characteristic, in order to find and highlight them more easily. If you want to remove a user from your favorite contacts list, click on their avatar here. This will take you to that user's full profile. In the upper right corner, select the additional menu ",
  userIsInFavorite2: ' three dots and remove it from your favorite contacts list.',
  userIsInBlackList1: "The user has been blacklisted by you. This means that you cannot see his Announcements and neither he nor you can write messages to each other. If you want to start contacting him, click on his avatar here. This will take you to that user's full profile. In the upper right corner, select the additional menu ",
  userIsInBlackList2: ' three dots and take it off the blacklist.',
  writeUser: 'Write to user',
  openProfile: 'Open profile',
  closeProfile: 'Close profile',
  voteMembership: 'Number of votes in % held by this member of the Partnership at the meeting of the Partnership members',
  voteOwnership: 'Number of votes in % held by this owner at the general meeting of owners',
  votingIsNotAllowed: "Can't vote",
  unableToSendInvitation: "You can't send an invitation on WhatsApp because the number doesn't match your phone number.",
  phoneNumberIsAlreadyInSystem: 'This phone number is already registered with Square. You cannot send an invitation via WhatsApp. Send the registration code by sms directly to this phone number. ',
  menu: 'Extra Menu',
  editOnlyInRegistryVariant1Part2: 'registry',
  editOnlyInRegistryVariant2Part2: 'contact the administrator!',
  cannotEditTypeOfExistingRoom: 'Unable to edit the type of an already existing room',
  editOnlyInRegistryVariant1Part1: 'Editing these fields is only possible in the registry. Go to',
  toViewPaymentHistoryGoToPage: 'To view the payment history, you need to go to the page',
  makePhoto: 'Make a photo',
  editOnlyInRegistryVariant2Part1: 'To change the data, you need to',
  closeModal: 'Close the window'
};