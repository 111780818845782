"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  lastNameOfOwner: "Enter the owner's last name",
  nameOfOwner: "Enter the owner's name",
  middleNameOfOwner: "Enter the owner's middle name",
  juristicName: "Enter the organization's name",
  juristicLegalForm: "Enter the organization's legal form",
  juristicOgrn: "Enter the organization's OGRN",
  juristicInn: "Enter the organization's INN",
  phone: "Enter the owner's phone",
  email: "Enter the owner's email",
  personType: "Select the owner's type",
  contactPerson: "Add information about the owner's contact person",
  dateOfBirth: "Enter the owner's date of birth",
  documentType: "Enter the name of the document confirming the owner's identity",
  documentNumber: "Enter the number of the document confirming the owner's identity",
  documentIssueDate: "Enter the date of issuance of the document confirming the owner's identity",
  personalFiles: "Attach, if you have, a copy of the document confirming the owner's identity",
  addressSelect: 'Enter the ownership address',
  buildingStreet: 'Enter the street',
  apartmentNumber: 'Enter the apartment number',
  buildingNumber: 'Enter the building number',
  totalArea: 'Enter the total area',
  ownershipShare: 'Enter the ownership share',
  propertyUsageType: 'Select the property usage type',
  ownerType: 'Select the ownership type',
  ownershipDocumentName: 'Enter the name of the document confirming the ownership',
  ownershipDocumentNumber: 'Enter the number of the document confirming the ownership',
  ownershipDocumentIssueDate: 'Enter the date of issuance of the document confirming the ownership',
  ownershipFiles: 'Attach, if you have, a copy of the document on ownership of the property'
};