"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  presentInPerson: 'Present in person',
  representativePresent: 'The Representative is present',
  editRepresentativeData: "Edit the Representative's data",
  total: 'Total',
  appartments: 'Appartments',
  votePercents: '% of votes',
  withFilters: 'Taking into account the selected filters',
  registration: 'Registration',
  bulletins: 'Bulletins',
  contacts: 'Contacts',
  notificationUsers: 'Notify users',
  notifyParticipants: 'Notify participants',
  notifyDisallowedForNotAMI: 'Newsletters are available to administrator, moderator, expert',
  notifyNoOneSelected: 'No participant have been selected',
  nextContactDate: 'Date of next contact',
  useFilter: 'Use filters',
  showDetails: 'Show detailed statistics',
  sumVoted: 'the sum of votes of owners who have already voted',
  sumNotAcceptedVoted: 'the sum of votes of owners whose ballots have not yet been approved by the Chairperson and/or the Secretary',
  sumNotVoted: 'the sum of votes of owners who have not yet voted',
  sortByFio: 'Sort by last name',
  sortByApartment: 'Sort by apartments',
  sortByAddress: 'Sort by address',
  sortByPercent: 'Sort by voting percentage',
  sortByRegistration: 'Sort by registration status',
  sortByBulletins: 'Sort by bulletin status',
  notEditable: "Didn't vote",
  visitedButNotEdited: 'The user logged into the meeting at that time, but did not vote',
  sortByDate: 'Sort by date',
  lastModifyDate: 'Voting time',
  voting: 'Ballot status',
  votePercentInMeeting: '% of votes at meetings',
  sortByContacts: 'Sort by contact status and next contact date',
  sortByComment: 'Sort by comment',
  cantEditRegistrationStatus: 'Unable to change registration status',
  openMenuRegistrationAlertTitle: 'User is not allowed to vote',
  openMenuRegistrationAlertText: 'Reason:',
  openMenuRegistrationAlertAcceptButton: 'Okay',
  updateButtonTooltip: 'Update the table to synchronize with possible editing by other users',
  downloadBulletinsChooseParticipants: 'Downloading ballots. First, select the participants of the meeting',
  downloadBulletinsSingleParticipant: 'Download a blank ballot',
  downloadBulletinsSingleParticipantSuccess: 'You have received a link to view and download the newsletter',
  downloadBulletinsSelectedParticipants: 'We will send the selected ballots to your mailbox. You will be able to download and print them',
  archiveSendToEmail: 'The archive with ballots for the elected owners has been sent to the post office',
  yet: 'More',
  collapse: 'Expand',
  cantEditBulletin: "You can't edit the bulletin",
  votingNotStarted: "The meeting hasn't started yet. You cannot go to the voting card.",
  thisMeetingDontHaveBulletins: 'There is no voting card in this meeting.',
  countBulletins: 'Number of unique ballots participating in this meeting, taking into account the filters applied',
  countUsers: 'Number of assembly participants shown in the table, taking into account the filters applied',
  countVotePercents: '% of votes of the assembly participants shown in the table, taking into account the filters applied',
  meetingMembers: 'Meeting participants',
  adressTooltip: 'Numbers or names of rooms related to the ballot',
  warningDataMayBeStaleRefreshTable: 'The table data may be outdated. Refresh the table to see the latest data.'
};