"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  error404Title: 'Looks like you got lost :(',
  error404Description: 'the page you are looking for is not available!',
  goHome: 'Go home',
  mobileAppInfo1: 'Information for this section is available only in the mobile application',
  mobileAppInfo2: 'To view information for the section «{{pageName}}», please go to the mobile application.',
  mobileAppInfo3: 'If you do not have the mobile application, you can download it from one of the buttons below.'
};