"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  EVoteAccepted: 'Проголосовано электронно. Голос учтён.',
  EVoteAcceptedAsMinor: 'Проголосовано электронно, но кроме этого есть утверждённый бумажный бюллетень, который имеет приоритет над электронным голосованием.',
  EVoteIgnored: 'Проголосовано электронно, но в данном собрании учитываются только бумажные бюллетени.',
  EVoteNothing: 'Не проголосовано электронно.',
  EVoteNothingIgnored: 'Не проголосовано электронно. Электронное голосование в данном собрании не учитывается.',
  InProcess: 'Бюллетень не обработан и не вынесен на утверждение Председателя и Секретаря собрания.',
  Accept: 'Бюллетень утверждён. Голос учтён.',
  IsAcceptedPresident: 'Бюллетень ожидает утверждения со стороны Председателя собрания.',
  IsAcceptedSecretary: 'Бюллетень ожидает утверждения со стороны Секретаря собрания.',
  SendToAccept: 'Бюллетень ожидает утверждения Председателем и Секретарем собрания.',
  Nothing: 'Бюллетень не загружен. Нет ни одной страницы скан-копии бюллетеня.'
};