"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Что мне надо сделать?',
  goToPrevAppealButtonTooltip: 'Перейти к предыдущей подсказке',
  goToNextAppealButtonTooltip: 'Перейти к следующей подсказке',
  collapseAppeals: 'Свернуть подсказки',
  expandAppeals: 'Раскрыть все подсказки'
};