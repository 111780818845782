"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  linkToSurvey: 'Link to survey',
  selectOwner: 'Select owner',
  searchUser: 'Search user',
  selectAll: 'Select all',
  street: 'Street',
  embankment: 'Embankment',
  avenue: 'Avenue',
  boulevard: 'Boulevard',
  lane: 'Lane',
  deadEnd: 'Dead end',
  square: 'Square',
  directions: 'Directions',
  tract: 'Tract',
  alley: 'Alley',
  highway: 'Highway',
  row: 'Row',
  line: 'Line',
  user_0: 'user',
  user_1: 'user',
  user_2: 'users',
  registryRow_0: 'row',
  registryRow_1: 'rows',
  registryRow_2: 'rows',
  registryCol_0: 'column',
  registryCol_1: 'columns',
  registryCol_2: 'columns',
  registryChoose_0: 'Selected',
  registryChoose_1: 'Selected',
  registryChoose_2: 'Selected',
  registryChooseCols_0: 'Selected',
  registryChooseCols_1: 'Selected',
  registryChooseCols_2: 'Selected',
  filtersChoose_0: 'Selected',
  filtersChoose_1: 'Selected',
  filtersChoose_2: 'Selected',
  filterRow_0: 'filter',
  filterRow_1: 'filters',
  filterRow_2: 'filters',
  rowsAfterAction_0: 'row',
  rowsAfterAction_1: 'rows',
  rowsAfterAction_2: 'rows',
  rowsChoose_0: 'rows',
  rowsChoose_1: 'rows',
  rowsChoose_2: 'rows',
  rowsRelate_0: 'relate',
  rowsRelate_1: 'relate',
  rowsRelate_2: 'relate'
};