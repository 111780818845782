"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  history: 'История изменений',
  writeToUser: 'Написать пользователю',
  saveInPhone: 'Сохранить контакт в телефоне',
  addToFavorites: 'Добавить в избранное',
  addToBlacklist: 'Добавить в черный список',
  deleteFromFavorites: 'Удалить из избранного',
  deleteFromBlacklist: 'Удалить из черного списка',
  delete: 'Удалить пользователя',
  deleteSelf: 'Удалить свой профиль',
  copyLink: 'Скопировать ссылку на профиль',
  photo: 'Загруженное фото',
  initials: 'Инициалы',
  uploadPhoto: 'Загрузить фото',
  photograph: 'Сфотографировать',
  allowMeeting: 'Лишить возможности голосовать',
  downloadContractWithUK: 'Скачать договор с УК'
};