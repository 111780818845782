"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  whoAreYou: 'Кто вы?',
  multipleHOAHint: 'В данном товариществе зарегистрировано несколько объектов с разными адресами, выберите нужный вам дом',
  selectHouse: 'Выберите адрес дома',
  yourData: 'Ваши данные',
  totalArea: 'Общая площадь',
  fractionValidationMessage1: 'Формат: 1/2. Не больше 1',
  fractionValidationMessage2: 'Формат (число/число): 1/2',
  fractionValidationMessage3: 'Формат (число/число): 1/2 - числа должны быть больше',
  fractionValidationMessage4: 'Значение не должно быть больше 1',
  fractionValidationMessage5: 'Формат (число/число): 1/2 - числа должны быть больше 0',
  valueMustBeNumber: 'Значение должно быть числом',
  percentValidationMessage1: 'Значение не должно быть больше',
  valueMustntBeNumber: 'Значение не должно быть меньше',
  areaValidationMessage1: 'Значение не должно превышать общую площадь'
};