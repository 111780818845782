"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeTypeNameFieldFromResponse = exports.GraphQLRequestError = exports.getErrorMessageFromGraphQLResponse = void 0;
const ApolloClient_1 = require("../apollo/ApolloClient");
const apollo_client_1 = require("apollo-client");
const i18n_1 = require("@sqvr/i18n");
const uploadFetch_1 = require("../apollo/addons/uploadFetch");
const {
  common: commonI18n
} = i18n_1.i18n;
function getErrorMessageFromGraphQLResponse(response) {
  var _a;
  const firstError = response.errors ? response.errors[0] : null;
  const errorMessage = firstError === null || firstError === void 0 ? void 0 : firstError.message;
  const isUploadCanceled = errorMessage && ((_a = errorMessage.split(':')[1]) === null || _a === void 0 ? void 0 : _a.trim()) === uploadFetch_1.UPLOAD_CANCELED;
  if (errorMessage && !isUploadCanceled) {
    return errorMessage;
  } else if (isUploadCanceled) {
    return uploadFetch_1.UPLOAD_CANCELED;
  } else {
    return commonI18n.unhandledError;
  }
}
exports.getErrorMessageFromGraphQLResponse = getErrorMessageFromGraphQLResponse;
class GraphQLRequestError extends Error {
  constructor(response) {
    const message = getErrorMessageFromGraphQLResponse(response);
    super(message);
    this.message = message;
    this.response = response;
  }
}
exports.GraphQLRequestError = GraphQLRequestError;
function removeTypeNameFieldFromResponse(data) {
  return JSON.parse(JSON.stringify(data), (key, value) => key === '__typename' ? undefined : value);
}
exports.removeTypeNameFieldFromResponse = removeTypeNameFieldFromResponse;
function handleQueryError(error, requestOptions) {
  let message = String(error);
  if (error instanceof apollo_client_1.ApolloError) {
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      message = error.graphQLErrors[0].message;
    } else {
      message = error.message;
    }
  }
  const fakeResponse = {
    errors: [{
      message
    }],
    loading: false,
    networkStatus: apollo_client_1.NetworkStatus.error,
    data: null,
    stale: false
  };
  if (!requestOptions.withoutErrorHandlers) {
    BaseGraphQLService.commonQueryErrorHandlers.forEach(handler => {
      handler(fakeResponse);
    });
  }
  throw new GraphQLRequestError(fakeResponse);
}
class BaseGraphQLService {
  constructor() {
    this.query = (tag, variables, options = {}, requestOptions = {}) => __awaiter(this, void 0, void 0, function* () {
      const client = (0, ApolloClient_1.getApolloClient)();
      let computedOptions = {
        query: tag,
        variables
      };
      if (!requestOptions.withoutOptionsMiddlewares) {
        BaseGraphQLService.commonQueryOptionsMiddlewares.forEach(middleware => {
          computedOptions = middleware(computedOptions);
        });
      }
      computedOptions = Object.assign(Object.assign({}, computedOptions), options);
      let response;
      try {
        response = yield client.query(computedOptions);
      } catch (e) {
        handleQueryError(e, requestOptions);
      }
      if (response.data) {
        response.data = removeTypeNameFieldFromResponse(response.data);
      }
      if (response.errors && !requestOptions.withoutErrorHandlers) {
        BaseGraphQLService.commonQueryErrorHandlers.forEach(handler => {
          handler(response);
        });
        throw new GraphQLRequestError(response);
      }
      return response;
    });
    this.mutate = (tag, variables, options = {}, requestOptions = {}) => __awaiter(this, void 0, void 0, function* () {
      const client = (0, ApolloClient_1.getApolloClient)();
      let computedOptions = {
        mutation: tag,
        variables
      };
      if (!requestOptions.withoutOptionsMiddlewares) {
        BaseGraphQLService.commonMutationOptionsMiddlewares.forEach(middleware => {
          computedOptions = middleware(computedOptions);
        });
      }
      computedOptions = Object.assign(Object.assign({}, computedOptions), options);
      let response;
      try {
        response = yield client.mutate(computedOptions);
      } catch (e) {
        handleQueryError(e, requestOptions);
      }
      if (response.data) {
        response.data = removeTypeNameFieldFromResponse(response.data);
      }
      if (response.errors && !requestOptions.withoutErrorHandlers) {
        BaseGraphQLService.commonMutationErrorHandlers.forEach(handler => {
          handler(response);
        });
        throw new GraphQLRequestError(response);
      }
      return response;
    });
  }
}
BaseGraphQLService.commonQueryOptionsMiddlewares = [options => Object.assign(Object.assign({}, options), {
  fetchPolicy: 'no-cache'
})];
BaseGraphQLService.commonMutationOptionsMiddlewares = [options => Object.assign(Object.assign({}, options), {
  fetchPolicy: 'no-cache'
})];
BaseGraphQLService.commonQueryErrorHandlers = [];
BaseGraphQLService.commonMutationErrorHandlers = [];
exports.default = BaseGraphQLService;