"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  yes: 'Yes',
  no: 'No',
  ok: 'OK',
  save: 'Save',
  notSave: 'Do not save',
  close: 'Close',
  cancel: 'Cancel',
  retry: 'Repeat',
  cancelShort: 'Cancel',
  cancelChanges: 'Undo Changes',
  exit: 'Log out',
  select: 'Select',
  search: 'Search',
  all: 'All',
  settings: 'Settings',
  home: 'Home',
  registry: 'Registry',
  userRights: 'User rights',
  committees: 'Committees',
  contractors: 'Contractors',
  generalInfo: 'Information disclosure',
  enter: 'Log in',
  entered: 'Login',
  reset: 'Reset',
  warning: 'Attention',
  forgotPassword: 'Forgot password?',
  continue: 'Continue',
  add: 'Add',
  create: 'Create',
  createNew: 'Create a new one',
  requestError: 'An error occurred in the request',
  required: 'Required Field',
  return: 'Back',
  share: 'Share',
  m2: 'm²',
  and: 'and',
  inDevelopment: 'The section is under development',
  authorizationRequired: 'Available only to authorized users',
  unhandledError: 'Something went wrong. Our engineers have already received a message about the error and will start fixing it in the near future. We apologize for the inconvenience.',
  badGatewayError: 'At the moment, technical work is being carried out on the server. Try the again later',
  contacts: 'Contacts',
  messages: 'Messages',
  description: 'Description',
  witnessInfo: 'Information about the witness',
  selectedName: 'Name',
  email: 'E-mail',
  useEmail: 'Use E-mail',
  phone: 'Phone',
  sms: 'SMS',
  useSms: 'Use SMS',
  delete: 'Delete',
  logoutFromAccount: 'Log out of your account',
  loginInObject: 'Enter the property',
  changesHistory: 'Changes history',
  register: 'Register',
  author: 'Author',
  status: 'Status',
  other: 'Other',
  filters: 'Filters',
  noName: 'Untitled',
  generated: 'Created',
  date: 'Date',
  endDate: 'Expiration date',
  copy: 'Copy',
  publish: 'Publish',
  hide: 'Hide',
  detail: 'Learn more',
  questionNumber: 'Question N. {{count}}',
  averageMark: 'Average rating',
  ball: 'Points',
  fromTen: 'out of 10',
  additionalMenu: 'Extra Menu',
  template: 'Template',
  useTemplate: 'Use template',
  applyTemplate: 'Apply Template',
  makeCopy: 'Make a copy',
  goToEdit: 'Go to editing',
  noVote: "You haven't voted yet.",
  vote: 'Vote',
  inputYourOption: 'Enter your option',
  watchResults: 'See the results',
  reVote: 'Revote',
  preview: 'Preview',
  questions: 'Questions',
  edit: 'Edit',
  backToEditing: 'Back to editing',
  preliminaryResults: 'Preliminary results',
  templateChoosing: 'Choosing a template',
  noTemplates: 'No survey templates found',
  coverChoosing: 'Choosing a cover',
  uploadImage: 'Upload image',
  pollPreview: 'Survey Preview',
  pollPublish: 'Post a poll',
  pollDelete: 'Delete a survey',
  name: 'Title',
  addQuestion: 'Add a question',
  choose: 'Select and insert in description',
  chooseDate: 'Select and insert a date in the description',
  chooseUserName: 'Select and insert the Surname and Username in the description',
  addLink: 'Add a link to the description',
  uploadLimit: 'You have reached the limit of 10 files',
  maxFilesCountExceed: "You can't attach more than 10 files",
  questionName: 'Question title',
  questionType: 'Question type',
  option: 'Variant',
  deleteOption: 'Remove variant',
  addOption: 'Add option',
  userInputOption: 'Allow the user to enter their own variant',
  attachFile: 'Attach a file',
  useFilters: 'Use filters and search to quickly find what you need',
  showAllFilters: 'Show all filters',
  error: 'Error',
  send: 'Send',
  secondsShort: '<span>To receive emails again, you first need to:</span><br/><br/><span> a) enable the Telergram-bot of our service<br/> or </span><br/><span>b) download and log in with your username and password in the Square mobile application and check that push notifications are enabled in the account settings.<span><br/><br/><span>Click on the \\"Connect Telegram-bot\\" button or click on the button leading to your app store for downloading mobile applications.</span><br/><br/><span>As soon as we see that the Telegram bot is connected or that you have enabled push notifications in our application, the email newsletter will be enabled automatically.</span><br/><br/><span>If you do not want to activate the email newsletter, just close this window or click on the \\"Cancel\\" button.</span></span></span>',
  uploadError: 'Loading error',
  confirm: 'Confirm',
  inputMessageText: 'Write your message here',
  change: 'Edit',
  theme: 'Subject',
  notificationTitle: 'Notification header',
  notifyUsers: 'Notify members',
  nothingFound: 'Nothing to show',
  noMatch: 'No coincidences',
  clearSelection: 'Clear Selection',
  complete: 'Complete',
  comment: 'Comment',
  usersSearch: 'Search for users',
  loadMore: 'Load more',
  pay: 'Pay',
  active: 'Active',
  notActive: 'Not active',
  more: 'Learn more',
  polls: 'Polls',
  meetings: 'Meeting',
  loading: 'Loading',
  noData: 'No data',
  noDataToFilter: 'There is nothing to filter. There is no data to filter.',
  filtersDeleted: 'There is no longer any data that falls under the {{filters}} filters. The data is displayed without these filters',
  filterDeleted: 'There is no longer any data that falls under the {{filters}} filter. The data is displayed without this filter',
  filterDeletedAll: 'There is no longer any data that falls under the {{filters}} filter. The data is displayed without filtering.',
  sqvrSystemUser: 'SQVR system',
  addCover: 'Add cover',
  sign: 'Sign',
  refuse: 'Cancel',
  commentAndClose: 'Comment and close',
  doComment: 'Comment',
  smsVerificationCodeSent: 'Check the SMS, we have sent you a verification code.',
  days_0: 'day',
  days_1: 'days',
  days_2: 'days',
  votes_0: 'Vote',
  votes_1: 'Votes',
  votes_2: 'Votes',
  join: 'Join',
  joinToObject: 'Join the facility',
  results: 'Results',
  countShortTitle: 'qty',
  squareTitle: 'Area, m2',
  votePercentsTitle: 'Votes, %',
  total: 'Total',
  notSpecified: 'Not Selected',
  copyLink: 'Copy link',
  apply: 'Apply',
  important: 'Important!!!',
  confirmSending: 'Yes, send',
  yesDelete: 'Yes, remove it',
  rejectSending: 'No, do not send',
  noUser: 'No owner',
  hiddenUser: 'Hidden owner',
  hiddenData: 'Hidden data',
  legalUser: 'Legal entity',
  individualUser: 'Individual',
  cancelChoise: 'Deselect',
  whatsapp: 'WhatsApp',
  telegram: 'Telegram',
  telegramBot: 'Telegram-bot',
  byCall: 'Call',
  byEmail: 'Write an email',
  byWhatsApp: 'Contact on WhatsApp',
  byTelegram: 'Write to Telegram',
  bySMS: 'Write an SMS',
  rejectDeleting: 'No, do not delete',
  paymentType: 'Payment Method',
  done: 'Complete',
  inviteLink: 'Share a link to the profile of your house in the SQVR using social networks or messengers',
  appealsTooltip: 'Tip on what to do',
  addComment: 'Add a comment',
  view: 'View',
  noMoreShow: 'Do not show again',
  rulesForSendingNotifications: 'Rules for sending notifications',
  rulesForSendingInvitations: 'Rules for sending invitations',
  rulesForSendingInvitationsTelegram: 'Rules for sending invitations to Telegram-bot',
  download: 'Download',
  bold: 'Highlight text in bold',
  italic: 'Italicize text',
  underline: 'Highlight text with underlining',
  viewDocument: 'Click to view the document',
  menu: 'Menu',
  meetingToastText: 'Quick transition to the Admin panel. You may want to review the latest events in the congregation.',
  meetingToastTitle: 'Gathering!',
  meetingToastButton: 'Go to a meeting',
  payTariff: 'Pay for the tariff',
  fillFields: 'Fill in the fields to be able to pay for the tariff',
  gotItText: 'OK',
  clickToSort: 'Click to sort',
  notingToSort: 'Nothing to sort',
  selectItemForSort: 'Select the rows to sort',
  or: 'or',
  your: 'Your',
  selectAll: 'Highlight all',
  forDeleteRegisteredUsers: 'For deleting registered users, click on their avatar and go to the user profile.',
  acceptApplication: 'Accept application',
  errorInFileFormation: 'Error in file formation',
  acceptedApplication: 'Application accepted successfully!',
  row_1: 'rows',
  addPerson: 'Add owner',
  onlyJoinOwnershipIsAvailableForAddition: 'Only joint ownership is available for addition, you have no free ownership left',
  columnSettings: 'Column settings',
  concern_1: 'concern',
  answer: 'Answer:',
  notRegistered: 'Not registered',
  rowsDeleted: 'rows deleted',
  was_2: 'was',
  copied: 'Copied!',
  notJoin: 'I do not want to join',
  captchaError: 'Incorrect captcha. Please start again',
  she_0: 'she',
  property: 'Property',
  dateHasError: 'The date you entered contains an error',
  union: 'Combine',
  edited: 'Editing',
  comments: 'Comments',
  serverErrorWithUploadFile: 'Server error when uploading a file',
  success: 'Success!',
  reply: 'Reply',
  row_0: 'row',
  she_1: 'they',
  was_1: 'were',
  attach: 'Attach',
  russia: 'Russia',
  was_0: 'was',
  piecesShort: 'pcs.',
  rowHasErrors: 'Row contains errors, check the cells',
  possibleOnly: 'possible only through',
  errorWithAcceptedApplication: 'An error occurred while approving the application',
  concern_2: 'concern',
  availableIn: 'Available in',
  complaints: 'Complaints',
  password: 'Password',
  successfully: 'successfully',
  buildingSuffix2: 'Build.',
  unknown: 'Unknown',
  confirmPassword: 'Confirm password',
  dateCantBeMoreCurrentDate: 'The date cannot be later than the current date of the calendar',
  defaultSettings: 'default settings',
  she_2: 'they',
  fileIsEmpty: 'File is empty',
  street: 'Street',
  not: 'not',
  downloadedIn: 'Download in',
  row_2: 'rows',
  alredyRegisteredUers: 'to already registered users.',
  codeSent: 'Code confirmation',
  housing: 'Corpus',
  deleted_0: 'deleted',
  admin: 'Administrator',
  goTo: 'Go to',
  house: 'House',
  profile: 'Profile',
  find: 'find',
  toAddOwnerFillFields: 'To add an owner, you need to fill in the following fields: ',
  clearColumns: 'Clear column',
  organization: 'Organization',
  from: 'from',
  registration: 'Registration',
  clearRows: 'Clear rows',
  today: 'Today',
  registered: 'Registered',
  concern_0: 'concern',
  highPriority: 'High priority',
  buildingSuffix: 'bld.',
  userAvatar: 'User avatar',
  fieldCantBeEmpty: 'Field cannot be empty',
  timeAt: 'in',
  ruble: 'RUB.',
  noInfoForCopy: 'No information for copying!',
  deleted_1: 'deleted',
  deleteed_2: 'deleted',
  registryUnlocked: 'Registry unlocked, you can start editing!',
  fixedAllErrors: 'You have fixed all the errors!'
};