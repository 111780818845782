"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  settingsMismatch: 'Несоответствие реестра, настроек и публичной информации',
  hideDetails: 'Скрыть подробнее.',
  showDetails: 'Смотреть подробнее.',
  allThisMakesItDifficult: 'Все это затрудняет или делает невозможным:',
  reachingQuorum: 'Достижение кворума на собрании',
  automaticVoteCounting: 'Автоматический подсчет голосов.',
  sendingNotifications: 'Рассылку напоминаний и уведомлений собственникам.',
  generatingDocuments: 'Генерацию полностью заполненных документов собрания.',
  incorrectVoteCount: 'Неправильный подсчет голосов может привести к оспариванию решений собрания'
};