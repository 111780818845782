"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  settingsMismatch: 'Inconsistency of registry, settings and public information',
  hideDetails: 'Hide details.',
  showDetails: 'See details.',
  allThisMakesItDifficult: 'All of this makes it difficult or impossible:',
  reachingQuorum: 'Achieving a quorum at the meeting',
  automaticVoteCounting: 'Automatic vote counting.',
  sendingNotifications: 'Sending out reminders and notices to owners.',
  generatingDocuments: 'Generation of fully completed meeting documents.',
  incorrectVoteCount: 'Incorrect counting of votes may lead to a challenge to the decisions of the meeting'
};