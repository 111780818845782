"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatSum = void 0;
const lodash_1 = require("lodash");
function formatSum(sum, currency = '₽') {
  if ((0, lodash_1.isNil)(sum)) sum = 0;
  const sumText = sum.toLocaleString('en', {
    maximumFractionDigits: 2
  }).replace(/,/g, ' ').replace(/\./, ',');
  return `${sumText}${currency ? ` ${currency}` : ''}`;
}
exports.formatSum = formatSum;