"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'Название',
  category: 'Категория',
  date: 'Последнее изменение',
  favorite: 'Избранное',
  menuItem1: 'По названию от А до Я',
  menuItem2: 'По названию от Я до А',
  menuItem3: 'Сначала избранные',
  menuItem4: 'Дата редактирования сначала новые',
  menuItem5: 'Дата редактирования сначала старые',
  menuItem6: 'Отменить сортировку',
  removeFavorite: 'Исключить из избранного',
  addFavorite: 'Добавить в избранное',
  download: 'Скачать',
  copy: 'Скопировать ссылку',
  edit: 'Редактировать',
  delete: 'Удалить',
  sort: 'Нажмите для сортировки',
  deletedTooltip: 'Документ удален',
  editTableColumns: 'Редактировать отображение столбцов',
  menuTooltip: 'Дополнительное меню. <br> Посмотрите доступные <br> опции',
  favoriteIconTooltip: 'Ваш избранный <br> документ'
};