"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  uploadError: 'Loading error',
  maxFileSizeExceed: 'The file you are uploading is too large. It is more than the set limit of 15Mb',
  maxFileSizeExceedCustom: 'Among the uploaded files, there are those that exceed the maximum allowed size in {{limit}}',
  maxFilesCountExceed: "You can't attach more than 10 files",
  maxFilesCountExceedCustom_0: "You can't upload more than {{count}} file",
  maxFilesCountExceedCustom_1: "You can't upload more than {{count}} files",
  maxFilesCountExceedCustom_2: "You can't upload more than {{count}} files",
  preventSelectMoreFiles_0: "You can't select more than {{count}} files",
  preventSelectMoreFiles_1: "You can't select more than {{count}} files",
  preventSelectMoreFiles_2: "You can't select more than {{count}} files",
  preventSelectMoreFilesText1_0: 'Number of uploaded files {{count}} piece',
  preventSelectMoreFilesText1_1: 'Number of uploaded files {{count}} pieces',
  preventSelectMoreFilesText1_2: 'Number of uploaded files {{count}} pieces',
  preventSelectMoreFilesText2_0: 'more than the maximum allowable {{count}} piece',
  preventSelectMoreFilesText2_1: 'More than the maximum allowable {{count}} pieces',
  preventSelectMoreFilesText2_2: 'More than the maximum allowable {{count}} pieces',
  preventSelectNewFiles: "You've already selected the maximum number of files",
  filesUpload: 'File Uploads',
  filesUploadTitle_0: 'Uploading {{count}} file...',
  filesUploadTitle_1: 'Uploading {{count}} files...',
  filesUploadTitle_2: 'Uploading {{count}} files...',
  successUpload_0: 'The download of {{count}} file is complete',
  successUpload_1: '{{count}} File Upload Complete',
  successUpload_2: '{{count}} File Upload Complete',
  cancelUpload: 'Stop the download',
  filesUploadRestriction: 'Select a maximum of 10 files. File size - no more than 15Mb.',
  upload: 'Upload',
  availableFileFormats: 'File formats available for download',
  image: 'Image',
  audio: 'Audio',
  video: 'Video',
  archive: 'Archive',
  document: 'Document',
  selectFiles_0: 'Select the file you want to upload.',
  selectFiles_1: 'Select up to {{count}} files.',
  selectFiles_2: 'Select up to {{count}} files.',
  fileSizeLimit: 'File size - no more than {{maxSize}}Mb.',
  availableSpace: 'Available space',
  selectedFiles: 'Selected files',
  wrongFormat: 'Unavailable file format',
  noSpaceOnTheDisk: 'There is no disk space:',
  messageOfAvailableDiskSpace: 'You only have access to {{availableSpace}} and you are trying to download {{filesTotalSize}} data. Go to the plans and pay for additional disk space',
  successFilesUpload: 'File uploaded successfully',
  errorFileUpload: 'The download is interrupted. Try again',
  estimatedTimeForUpload_0: '{{count}} minute left',
  estimatedTimeForUpload_1: '{{count}} minutes left',
  estimatedTimeForUpload_2: '{{count}} minutes left',
  switchToTariffs: 'Go to plans',
  reloadFileUpload: 'The download is interrupted. Try again',
  reloadFileUploadS3: 'File Uploads<br><br> Within 15 minutes, all uploaded ballots will be processed automatically and added to the voting participants. You can see all unrecognized files in the "Unrecognized documents" tab on the "Meetings" page<strong> Wait 15 minutes.</strong>',
  countFilesWithErrors_0: 'The download of {{count}} file was canceled',
  countFilesWithErrors_1: 'The download of {{count}} files has been canceled',
  countFilesWithErrors_2: 'The download of {{count}} files has been canceled'
};