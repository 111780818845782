"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  importTitle: 'Upload data to the registry',
  importText: 'To load data from the excel file into the registry, use',
  template: 'template',
  importButton: 'SELECT FILE',
  return: 'Back',
  resetLoad: 'cancel<br />download',
  notChange: "no, don't change<br />and continue",
  change: 'yes, change <br /> and continue',
  continue: 'Continue',
  success: 'Success!',
  warning: 'File download results',
  changeSettings: 'Change the Object settings so that all data from the file is loaded?',
  errorStrings: 'Line edits are required: <span style=\\"font-weight: 500\\">{{count}}</span>. It may be easier to edit the excel file and try again.',
  allUploadStrings_0: 'Total in the file <span style=\\"font-weight: 500\\">{{count}}</span> line',
  allUploadStrings_1: 'Total in the file <span style=\\"font-weight: 500\\">{{count}}</span> lines',
  allUploadStrings_2: 'Total in the file <span style=\\"font-weight: 500\\">{{count}}</span> lines',
  successLoadString: 'New lines uploaded: <span style=\\"font-weight: 500\\">{{count}}</span>',
  updateOwners: 'Updated strings: <span style=\\"font-weight: 500\\">{{count}}</span>',
  stringsWithErrors_0: '{{count}} string requires updating and manual processing',
  stringsWithErrors_1: '{{count}} rows need to be updated and manually processed',
  stringsWithErrors_2: '{{count}} rows require updating and manual processing',
  houseSettings: 'In order to enter all data from the file, you must change the object settings:',
  accessDeniedTitle: 'Access denied!',
  accessDeniedMessage: 'Access to the registry page is denied to owners. If you close this window, you will be taken to the main page of your object.',
  toMainPage: 'Back to the homepage',
  inviteModalTitle: 'Invitation preview',
  notificationModalTitle: 'Notification preview',
  successEmailSended_0: 'The letter was successfully sent',
  successEmailSended_1: 'Letters sent successfully',
  successEmailSended_2: 'Letters sent successfully',
  successEmailsSended_0: 'It may take some time for the letter to be delivered',
  successEmailsSended_1: 'Delivery of emails ({{count}} pcs) may take some time',
  successEmailsSended_2: 'Delivery of emails ({{count}} pcs) may take some time',
  inviteSentSuccessfully_0: 'The invitation was successfully sent',
  inviteSentSuccessfully_1: 'Invitations have been successfully sent',
  inviteSentSuccessfully_2: 'Invitations have been successfully sent',
  successInviteSent_0: 'It may take some time for the invitation to be delivered',
  successInviteSent_1: 'Invitations ({{count}} pieces) may take some time to be delivered',
  successInviteSent_2: 'Invitations ({{count}} pieces) may take some time to be delivered',
  notifySentSuccessfully_0: 'Notification sent successfully',
  notifySentSuccessfully_1: 'Notifications successfully sent',
  notifySentSuccessfully_2: 'Notifications successfully sent',
  successNotifySent_0: 'It may take some time for the notification to be delivered',
  successNotifySent_1: 'Notifications ({{count}} pcs) may take some time to be delivered',
  successNotifySent_2: 'Notifications ({{count}} pcs) may take some time to be delivered',
  lostWarning: 'Closing will result in the loss of the data you have entered',
  closeInvite: 'Close the invitation?',
  closeNotify: 'Close the notice?',
  spaces: 'Spaces',
  inRegistryCell: 'Now:',
  inSettingsCell: 'Change to:',
  rowsClean: 'Clearing rows',
  rowsCleanWarning_0: 'The line you highlighted cannot be cleared because it contains a registered user.',
  rowsCleanWarning_1: 'The lines you highlighted cannot be cleared because they contain a registered user.',
  rowsCleanWarning_2: 'The lines you highlighted cannot be cleared because they contain a registered user.',
  attention: 'Note that the number of rooms specified in the Object settings in the ',
  house: 'house - ',
  houses: 'houses - ',
  village: 'settlement - ',
  resizeInfo: '{{settingsCount}}, and {{uploadCount}} in the registry to be uploaded. The number of rooms you will not be able to load into the registry: {{uploadCount}} - {{settingsCount}} = {{resizeCount}}. If you need to change the number of rooms in Settings, ',
  goToSupport: 'contact technical support',
  items: 'qty'
};