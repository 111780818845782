"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isFoundEmployee = exports.isFoundContractor = exports.isFoundContract = exports.isFoundWork = void 0;
function isFoundWork(value) {
  return value && value.type === 'works';
}
exports.isFoundWork = isFoundWork;
function isFoundContract(value) {
  return value && value.type === 'contracts';
}
exports.isFoundContract = isFoundContract;
function isFoundContractor(value) {
  return value && value.type === 'contractors';
}
exports.isFoundContractor = isFoundContractor;
function isFoundEmployee(value) {
  return value && value.type === 'employees';
}
exports.isFoundEmployee = isFoundEmployee;