"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Inventory number',
  yesLabel: 'Enter Inventory number',
  noLabel: 'Back',
  info: 'The cadastral number of a capital construction object (CCI) serves as a reliable way of unambiguous identification of the object. We use the cadastral number to refer to the USRN and obtain information about the number of rooms in the building. Based on the received value of the number of premises, the cost of paid tariffs and services in our service is calculated.',
  condoGroup: 'Since there are several condo groups in your facility, we need to know the cadastral numbers of all condo groups to calculate the paid services.',
  enterNumber: 'Enter the inventory number',
  planToPlay: 'if you plan to use a paid service, namely:',
  itemA: '(a) Order a register of owners from us,',
  itemB: 'b) update the data in the register prior to the holding of assemblies,',
  itemC: 'c) buy paid tariff plans.',
  ifSaveNumber: 'If you keep the inventory number now, we will',
  warning: 'Note to self. ',
  cantAddNewBuldings: 'Once you add a cadastral code, you will not be able to add or remove enclosures in your property.',
  dontEnterNumber: 'Do not enter the cadastral number',
  dontPlanToPay: 'if you do not plan to use paid services. This will allow you to flexibly change the settings of the Object and continue to use all the free functionality.',
  ifDontKnowNumber: 'If you do not know the cadastral number, use the public cadastral map ',
  forSearch: ' to find your house.',
  alreadyOrderedTitle: 'The registry has already been ordered',
  alreadyOrdered: ' has already paid for the order of the register of owners.',
  wait: 'In approximately {{days}} business days, you will receive a message with a file of the ownership roster attached.',
  questions: 'If you have any questions, ',
  support: 'contact our tech support',
  close: 'Close',
  noNeed: "You don't have to pay for the registry order again.",
  again: 'If for any reason you feel you need to pay again to order the registry, click on the "Yes, Order Registry" button',
  yesOrder: 'Yes, Order the registry',
  alreadyActualize: ' has already ordered a service to update the ownership registry.',
  waitActualize: 'In approximately {{days}} business days, you will receive a message with a file containing the updated ownership register attached.'
};