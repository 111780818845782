"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Нет кадастрового номера',
  noSettingsTitle: 'В Настройках дома не указано количество помещений',
  noLabel: 'Вернуться',
  yesLabel: 'Перейти в настройки',
  text1: 'Услуга Вам не доступна. Для заказа реестра не хватает:',
  text2: '1. Нет кадастрового номера для МКД. ',
  text3: 'Перейти в Настройки',
  text4: ', чтобы ввести кадастровый номер.',
  text5: '2. Мы пока не знаем количество помещений в МКД. А именно на этой основе рассчитываются все платные тарифы и большинство отдельных лимитов.',
  text6: '3. В Настройках дома не введено количество помещений.',
  text7: '4. После выполнения пп. 1-3 появится возможность оплатить заказ реестра',
  tooltip1: 'Для того, чтобы сформировать достоверный реестр собственников нам необходимо знать:',
  tooltip2: '1. Кадастровый номер дома',
  tooltip3: '2. Кадастровые номера помещений в доме',
  tooltip4: '3. Общее количество помещений в доме.',
  tooltip5: 'Если какой-то информации нет, мы не можем рассчитать стоимость услуги, и приступить к выполнению заказа.',
  condo1: '1. Не для каждого МКД  указан кадастровый номер. ',
  condo2: ', чтобы ввести кадастровые номера для каждого МКД.',
  condo3: '2. Мы пока не знаем количество помещений для каждого МКД. А именно на этой основе рассчитываются все платные тарифы и большинство отдельных лимитов. ',
  condo4: '3. В Настройках Объекта не для каждого МКД введено количество помещений.',
  condo5: ''
};