"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  history: 'Change history',
  writeToUser: 'Write to user',
  saveInPhone: 'Save a contact in your phone',
  addToFavorites: 'Add to bookmarks',
  addToBlacklist: 'Add to the blacklist',
  deleteFromFavorites: 'Remove from favorites',
  deleteFromBlacklist: 'Remove from blacklist',
  delete: 'Delete user',
  deleteSelf: 'Delete your profile',
  copyLink: 'Copy profile link',
  photo: 'Uploaded photo',
  initials: 'Initials',
  uploadPhoto: 'Upload photo',
  photograph: 'Take a picture',
  allowMeeting: 'Remove ability to vote',
  downloadContractWithUK: 'Download the contract with the Management Company'
};