"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  street: 'Street',
  streetType: 'Street type',
  houseNumber: 'House number',
  unit: 'Block',
  info: 'Additional information',
  entrancesAmount: 'Entrances',
  floorsAmount: 'Floors',
  area: 'm2',
  amount: 'Units',
  address: 'Address',
  type: 'Property type',
  form: 'Form of management',
  count: 'Number of houses and buildings in the settlement',
  property: 'Appartments',
  identicalHouse: 'Identical houses are listed',
  zipError: 'House index not found',
  addHouseTooltip: 'If there are multiple homes in your community, you can add them all at once',
  addHouseDisabledTooltip: "Adding several houses is possible only for a homeowners' association (HOA, HOA, HSC, etc.)",
  share: 'Share',
  fraction: 'Fraction'
};