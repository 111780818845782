"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  sendingEmailForAddressError: 'Произошла ошибка при отправке письма на почту {{email}}',
  successPasswordReset_email: 'На почтовый адрес {{login}} выслано письмо с дальнейшими указаниями по сбросу пароля',
  successPasswordReset_phone: 'На телефонный номер {{login}} выслано sms с дальнейшими указаниями по сбросу пароля',
  errorPasswordReset_email: 'Произошла ошибка при отправке письма на почту {{login}}',
  errorPasswordReset_phone: 'Произошла ошибка при отправке sms на телефонный номер {{login}}',
  wrongPassword: 'Пароль введен не верно',
  emailVerificationError: 'Ошибка при подтверждении адреса почты',
  emailVerificationSent: 'На адрес {{email}} выслано письмо. Перейдите по ссылке в письме для подтверждения e-mail',
  socialAuthError: 'Для продолжения необходимо получить разрешение от социальной сети',
  recaptchaModalTitle: 'Установите флажок в поле:'
};