"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  incorrectValue: 'Вы ввели некорректное значение. Данные в ячейке будут возвращены к последнему корректному значению',
  fractionCantBeMore: 'Дробь не может быть больше 1',
  procentCantBeMore: 'Значение в процентах не может превышать 100 и не может быть меньше 0',
  totalAreaCantBeMore: 'Значение в м.кв. не может превышать {{totalArea}} м.кв. и не может быть меньше 0 м.кв.',
  specifyTotalArea: 'Укажите общую площадь помещения, а после заполните долю владения',
  totalAreaCantBeLow: 'Укажите общую площадь помещения отличную от 0 м.кв., а после заполните долю владения',
  editIncorrectData: 'Редактирование не корректных данных заявки',
  attentionToRedCell: 'Обратите внимание на все ячейки выделенные красным',
  deleteDow: 'Удалить строки',
  forEditHaveColumn: 'Для редактирования доступны столбцы - Доля, Площадь(S)',
  serverError: 'Ошибка сервера при загрузке файла',
  successImport: 'Данные реестра успешно импортированы',
  cantDeleteRegisteredUser: 'Нельзя удалять зарегистрированных пользователей',
  cantDeleteInvitedUser: 'Нельзя удалять пользователя чья заявка принимается',
  resultsDeleteregistry: 'Результаты удаления реестра',
  cantDeleteRegistryRows: 'Строки реестра не могут быть удалены, так как они относятся к уже зарегистрированным пользователям. ' + 'Для их удаления кликните на аватарку, перейдите в профиль пользователя и выберите удаление.',
  errorFileExport: 'Ошибка экспорта файла\n{{message}}',
  exportErrorNoFile: 'Ошибка экспорта файла\nНе получен файл реестра',
  clearingAColumn: 'Очистка столбца',
  cellsWithDataRegisteredUsers: 'В выбранном Вами столбце есть ячейки с данными зарегистрированных пользователей. Эти данные не будут очищены.',
  newApplicationToJoin: 'У вас новая заявка на присоединение',
  errorSavingResults: 'Ошибка сохранения результатов',
  editRegistrySettings: 'Изменение настроек реестра',
  cantUloadThisFile: 'Мы не смогли загрузить данный файл. Возможно формат этого файла не соответствует нужному шаблону. ' + 'Если Вы испытываете трудности, напишите в техническую поддержку. Спасибо',
  changeTypeOwner: 'Смена типа собственника',
  afterChangeOwnerType: '<br><p>При смене типа собственника с {{beforeText}} на {{currentText}} в обязательном порядке потребуется указать (фокус в поле будет установлен автоматически):</p>' + '<p style="padding-left: 10px;"><span role="img" aria-label="dot">&#9899;</span> Имя</p>' + '<p style="padding-left: 10px;"><span role="img" aria-label="dot">&#9899;</span> Фамилию</p>',
  onChangeOwnerType: '<p>При смене типа собственника с {{beforeText}} на {{currentText}} будет автоматически прозведено:</p>' + '<p style="padding-left: 10px;">- перенос информации о физ. лице в контактные данные;</p>' + '<br><p>Также для {{currentText}} в обязательном порядке потребуется указать (фокус в поле будет установлен автоматически):</p>' + '<p style="padding-left: 10px;">- Название</p>',
  requiredField: 'Поле обязательно к заполнению',
  notEndedEditing: 'Вы не завершили редактирование типа собственника, хотите продолжить?'
};