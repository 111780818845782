"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const documentsArchiveText = 'All selected by you documents will be formed as an archive.';
const deliveryTimeText = 'You will receive a link to download this archive';
exports.default = {
  disableDownloadTooltipText: 'Upload documents. First mark the documents you want to upload.',
  disableDeleteTooltipText: 'Delete documents. First, mark the documents you want to delete.',
  modalAlertTitle: 'Obtaining an archive of documents',
  gotItText: 'OK',
  successDeletedDocumentsText: 'The documents have been deleted',
  inputSearchPlaceholder: 'Search by title or tags',
  selectAll: 'Select all',
  successDownloadDocsText: 'The link to the archive has been generated',
  sentToSQVRAppText: `<ol style="list-style-type: decimal; padding-left: 24px;">
    <li>${documentsArchiveText}</li>
    <li>${deliveryTimeText} in the Square app within 15 minutes.</li>
  </ol>`,
  sentToTgBotText: `<ol style="list-style-type: decimal; padding-left: 24px;">
    <li>${documentsArchiveText}</li>
    <li>${deliveryTimeText} in the Telegram-bot of the Square service within 15 minutes.</li>
  </ol>`,
  sentToEmailText: `<ol style="list-style-type: decimal; padding-left: 24px;">
    <li>${documentsArchiveText}</li>
    <li>${deliveryTimeText} to your e-mail address within 15 minutes.</li>
  </ol>`,
  warningDeleteText: `<ol style="list-style-type: decimal; padding-left: 24px;">
    <li>Once deleted, documents cannot be restored.</li>
    <li>Access to documents for users without administrative rights will be denied immediately.</li>
    <li>Users with administrative rights will be able to see the deleted documents for another 
90 days. After that, the documents will be inaccessible to everyone.</li>
  </ol>`,
  urlCopy: 'The link has been successfully copied',
  addFavorite: 'Document added to favorites',
  removedFavorite: 'The document is no longer in favorites',
  emptyText: 'Nothing found'
};