"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  houses_change: 'Changes in the structure of the house',
  structure_type_actions: 'Changes in the management of the house',
  houses_change_amount: 'Changes in the number of spaces',
  reason_for_editing: 'Causes of change',
  registry_actions: 'Actions with the registry',
  owners_actions: 'Changing user profiles',
  commitee_actions: 'Changes in committees',
  meetings_actions: 'Changes in the life of the house',
  unit_actions: 'Changes to the apartments',
  rights_actions: 'Changes in user access rights'
};