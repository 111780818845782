"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  noContacts: 'No added contact persons',
  contactInfo: 'Contact information:',
  documents: 'Documents:',
  documentIsValidPersonality: 'Document, confirming identity:',
  ownershipDocument: 'Ownership document:',
  ownership: 'Ownership:',
  ownershipDocuments: "Documents confirming the owner's identity"
};