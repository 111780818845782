"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  selectedAllText: 'Выбраны все строки в таблице',
  settingsUpColumns: 'Настройка столбцов',
  defaultSettings: 'Настройки по умолчанию',
  close: 'Закрыть'
};