"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  downloadProtocol: 'Получить протокол, подготовленный Сквером',
  protocolDownloaded: 'Протокол скачан',
  downloadProtocolTooltip: 'Вы получите автоматически сгенерированный протокол собрания со всеми результатами.',
  markSigners: 'Отметить кто подписал протокол',
  protocolSigned: 'Протокол подписан',
  markSignersTooltip: 'Внимательно прочтите полученный протокол. Если нужно, отредактируйте протокол(вдруг там есть опечатки). Подпишите его. Обязательны подписи Председателя и Секретаря собрания. Не обязательные подписи, но мы очень рекомендуем так же поставить, подписи членов Счётной Комиссии и Инициаторов собрания.',
  uploadSignedProtocol: 'Загрузить подписанный протокол собрания',
  protocolUploaded: 'Загружен подписанный протокол собрания',
  uploadSignedProtocolTooltipFirstPart: 'Для того, чтобы эта кнопка стала активной необходимо сначала',
  uploadSignedProtocolTooltipSecondPart: 'скачать протокол, подготовленный Сквером. Потом',
  uploadSignedProtocolTooltipThirdPart: 'его нужно будет подписать у Секретаря и Председателя собрания.',
  uploadSignedProtocolActiveTooltip: 'Если у Вас уже есть подписанный протокол, пожалуйста, отсканируйте его и загрузите. После этого станет активной кнопка',
  uploadSignedProtocolDoneTooltip: 'загрузил файл со сканкопией итогового протокола собрания, но пока не опубликовал его. Внимательно ознакомьтесь с загруженным протоколом. Если видите ошибки и не точности, Вы можете удалить этот файл и пройти все шаги публикации протокола заново. Если Вы считаете, что протокол составлен правильно, и в нем корректно отражены итоги голосования, Вы можете инициировать публикацию протокола. Для этого кликните на кнопку -',
  initiateProtocolPublication: 'Инициировать публикацию протокола',
  protocolPublicationInitiated: 'Инициирована публикация протокола',
  initiateProtocolPublicationTooltip: 'После загрузки файла, с подписанным протоколом, Вы сможете инициировать его публикацию.',
  initiateProtocolPublicationDoneTooltip: 'инициировал публикацию протокола. Вы можете скачать протокол и ознакомится с его содержанием. Если у Вас есть замечания или Вы видите ошибки и не точности в загруженном файле, Вы можете удалить его и пройти все шаги публикации протокола заново. Если с протоколом все в порядке и он готов к публикации кликните на кнопку',
  confirmPublication: 'Подтвердить публикацию',
  protocolPublishedStatus: 'Протокол опубликован',
  confirmPublicationTooltipFirstPart: 'Как только публикация протокола будет подтверждена, сканкопия подписанного протокола будет доступна для всех участников голосования. Сквер автоматически уведомит всех о завершении собрания и публикации итогового протокола. Если Вы  видите неточности или в протокол нужно внести исправления, на шаге № 3 можно удалить файл с загруженным протоколом. После этого нужно пройти все шаги публикации протокола заново.',
  confirmPublicationTooltipSecondPart: 'После публикации протокола на странице “Документы” будут сохранены два документа:',
  confirmPublicationTooltipListFirstItem: 'а. Автоматически сгенерированный протокол;',
  confirmPublicationTooltipListSecondItem: 'б. Сканкопия подписанного итогового протокола.',
  afterConfirmPublicationTooltip: 'инициировал публикацию протокола. После подтверждение публикации Председателя или Секретаря собрания он будет опубликован.',
  at: 'в',
  uploadedFinishProtocol: 'загрузил файл со сканкопией итогового протокола.',
  initiatedProtocolPublication: 'инициировал публикацию протокола.',
  protocolPublished: 'Протокол собрания уже опубликован'
};