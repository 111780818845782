"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Select the object you want to enter',
  selectPlaceholder: 'Start typing the name of the object',
  loadingMessage: 'Loading...',
  nothingFound: 'No matches'
};