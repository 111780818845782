"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  unknown: 'Не указан',
  apartment_living: 'Жилое',
  apartment_nonliving: 'Нежилое',
  apartment_room: 'Комната в ком. квартире',
  apartment_unknown: '$t(modules.app.types.unknown)',
  apartment_house: 'Дом',
  apartment_land: 'Земельный участок',
  apartment_townhouse: 'Квартира в таунхаусе',
  membership_unknown: '$t(modules.app.types.unknown)',
  membership_member: 'Член Товарищества',
  membership_notMember: 'Не член Товарищества',
  ownership_unknown: '$t(modules.app.types.unknown)',
  ownership_share: 'Долевая',
  ownership_join: 'Совместная',
  person_unknown: '$t(modules.app.types.unknown)',
  person_natural: 'Физ. лицо',
  person_legal: 'Юр. лицо',
  person_government: 'Государство'
};