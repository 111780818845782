"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  tooltipForWarningIcon: 'Пользователь отключил получение уведомлений и сообщений',
  menuItem1: 'Добавить собственника',
  menuItem2ManagementStuff: 'Добавить сотрудника УК',
  menuItem2HOAEmployee: 'Добавить сотрудника Товарищества',
  menuInvite1: 'Отправьте приглашения по электронной почте или смс',
  menuInvite2: 'Распечатайте приглашения и раздайте',
  successInviteMessage: 'Вы получите приглашения на свою почту ({{email}}) в течение 15 минут',
  notifyNoOneSelected: 'Ни один пользователь не выбран',
  addUserButtonTooltipText: 'Добавить пользователя'
};