"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  incorrectValue: 'You entered an incorrect value. The data in the cell will be returned to the last correct value',
  fractionCantBeMore: 'The fraction cannot be more than 1',
  procentCantBeMore: 'The percentage value cannot exceed 100 and cannot be less than 0',
  totalAreaCantBeMore: 'The value in m.sq. cannot exceed {{totalArea}} m.sq. and cannot be less than 0 m.sq.',
  specifyTotalArea: 'Specify the total area of the room, and then fill in the ownership share',
  totalAreaCantBeLow: 'Specify a total area of the room different from 0 m.sq., and then fill in the ownership share',
  editIncorrectData: 'Editing incorrect data',
  attentionToRedCell: 'Pay attention to all cells highlighted in red',
  deleteDow: 'Delete rows',
  forEditHaveColumn: 'For editing, the columns - Share, Area(S) are available',
  serverError: 'Server error when loading the file',
  successImport: 'The registry data has been successfully imported',
  cantDeleteRegisteredUser: 'You cannot delete registered users',
  cantDeleteInvitedUser: 'You cannot delete a user whose application is being accepted',
  resultsDeleteregistry: 'Registry deletion results',
  cantDeleteRegistryRows: 'Registry rows cannot be deleted because they belong to already registered users. ' + 'To delete them, click on the avatar, go to the user profile and select deletion.',
  errorFileExport: 'Export file error\n{{message}}',
  exportErrorNoFile: 'Export file error\nThe registry file was not received',
  clearingAColumn: 'Clearing a column',
  cellsWithDataRegisteredUsers: 'In the column you selected, there are cells with data from registered users. These data will not be cleared.',
  newApplicationToJoin: 'You have a new application to join',
  errorSavingResults: 'Error saving results',
  editRegistrySettings: 'Editing registry settings',
  cantUloadThisFile: 'We were unable to load this file. The format of this file may not match the required template. ' + 'If you are experiencing difficulties, write to technical support. Thank you',
  changeTypeOwner: 'Changing the owner type',
  afterChangeOwnerType: '<br><p>When changing the owner type from {{beforeText}} to {{currentText}}, the following will be required in order to specify (the focus will be automatically set to the field):</p>' + '<p style="padding-left: 10px;"><span role="img" aria-label="dot">&#9899;</span> Name</p>' + '<p style="padding-left: 10px;"><span role="img" aria-label="dot">&#9899;</span> Surname</p>',
  onChangeOwnerType: '<p>When changing the owner type from {{beforeText}} to {{currentText}}, the following will be automatically performed:</p>' + '<p style="padding-left: 10px;">- transfer information about a physical person to contact details;</p>' + '<br><p>Also for {{currentText}} the following will be required in order to specify (the focus will be automatically set to the field):</p>' + '<p style="padding-left: 10px;">- Name</p>',
  requiredField: 'The field is required',
  notEndedEditing: 'You have not finished editing the owner type, do you want to continue?'
};