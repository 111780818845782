"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  openedApartmentChipTitle: 'Все собственники помещения №{{apartmentNumber}}',
  showAllOwnersOfOneApartment: 'Показать всех собственников одного помещения',
  youAlreadyInApartment: 'Вы уже в помещении',
  notSelectedOrMultipleSelected: 'Показать всех собственников одного помещения невозможно. Сейчас не выбрано ни одного или выбрано сразу несколько помещений',
  isOneUserSelectedForConnect: 'Для присоединения помещений выберите как минимум 2 строки',
  isUnknownSelectedForConnect: 'Присоединение недоступно для пользователей с типом "НЕИЗВЕСТЕН" - это служебные строки',
  isSameApartmentSelectedForConnect: 'Невозможно объединить пользователей в рамках одного помещения',
  addOwner: 'Добавить собственника',
  deleteRows: 'Удалить строки',
  sendNotification: 'Уведомить пользователей',
  connectApartmentToOwner: 'Присоединить помещения к собственнику',
  search: 'Поиск'
};