"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  registryColumnSettings: 'Customizing registry columns',
  cellWithError: 'This is how cells with conflicting information are shown',
  discardChanges: 'Undo all changes made',
  joinRequests: 'View applications for connection',
  registryHistory: 'View registry versions',
  registryFilters: 'Use filters and search to quickly find what you need',
  registryMenu: 'Extra Menu',
  membershipTypeNotMember: 'Not a member of the Association',
  roomsSettingsGtReestr: 'The number of rooms in the settings is greater than in the registry. Change?',
  notSelectRowToDelete: 'Nothing to delete. Select a row first',
  successUpdate: 'Successfully updated',
  columnSuccessCleared: 'Column successfully cleared',
  areaSettingsGtReestr: 'The area in the settings is greater than in the registry. Change?',
  membershipTypeMember: 'Member of the Association',
  notifyRequestSuccessMessageInvite: 'Invitations sent successfully.',
  personTypeLegal: 'Legal entity',
  clearRows: 'Clear rows',
  successDeleted: 'Successfully deleted',
  colDisabled: 'You cannot hide the column, it contains errors',
  notifyRequestErrorMessage: 'An error occurred. Please contact the support service.',
  cellHaveErrorsAndSave: 'The row is saved, but it contains errors',
  personTypeGoverment: 'Government',
  downloadRows: 'Download rows',
  clearCol: 'Clear column',
  notSelectRow: 'Not marked rows for download',
  total: 'Total',
  notifyRequestSuccessMessageNotify: 'Notifications sent successfully.',
  unknown: 'Unknown',
  unknownUCellLabel: 'NO OWNER ON THIS SHARE',
  reformaGKH: 'Reformagkh',
  spaceSettingsDiffReestr: 'The data in the registry differs from the data in the settings',
  personTypeNatural: 'Natural person',
  visitUserCard: 'Go to the user card',
  successUploaded: 'Successfully uploaded',
  cellHaveErrors: 'The row contains an error, the data is not saved',
  columnStatusUnknown: 'Row deletion status:',
  notSelectRowToNotification: 'No one to send a message. Select owners first',
  streetBlock: 'House address',
  columnNotSelected: 'Column not selected',
  successAdded: 'Successfully added',
  valFromSettings: 'Settings',
  unknownU: 'UNKNOWN'
};