"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  presentInPerson: 'Присутствует лично',
  representativePresent: 'Присутствует Представитель',
  editRepresentativeData: 'Редактировать данные Представителя',
  total: 'Итого',
  appartments: 'Помещения',
  votePercents: '% голосов',
  withFilters: 'С учетом выбранных фильтров',
  registration: 'Регистрация',
  bulletins: 'Бюллетени',
  contacts: 'Контакты',
  notificationUsers: 'Уведомить пользователей',
  notifyParticipants: 'Уведомить участников',
  notifyDisallowedForNotAMI: 'Рассылки доступны администратору, модератору, эксперту',
  notifyNoOneSelected: 'Ни один участник не выбран',
  nextContactDate: 'Дата следующего контакта',
  useFilter: 'Использовать фильтры',
  showDetails: 'Показать подробную статистику',
  sumVoted: 'сумма голосов собственников, которые уже проголосовали',
  sumNotAcceptedVoted: 'сумма голосов собственников, чьи бюллетени пока не утверждены Председателем и/или Секретарем',
  sumNotVoted: 'сумма голосов собственников, которые пока не проголосовали',
  sortByFio: 'Сортировать по фамилии',
  sortByApartment: 'Сортировать по помещениям',
  sortByAddress: 'Сортировать по адресу',
  sortByPercent: 'Сортировать по проценту голосования',
  sortByRegistration: 'Сортировать по статусу регистрации',
  sortByBulletins: 'Сортировать по статусу бюллетеня',
  notEditable: 'Не голосовал',
  visitedButNotEdited: 'Пользователь заходил в это время в собрание, но не проголосовал',
  sortByDate: 'Сортировать по дате',
  lastModifyDate: 'Время голосования',
  voting: 'Голосование',
  votePercentInMeeting: '% голосов на собраниях',
  adressTooltip: 'Номера или названия помещений, относящихся к бюллетеню',
  sortByContacts: 'Сортировать по статусу контакта и дате следующего контакта',
  sortByComment: 'Сортировать по комментарию',
  cantEditRegistrationStatus: 'Невозможно изменить статус регистрации',
  openMenuRegistrationAlertTitle: 'Пользователю запрещено голосовать',
  openMenuRegistrationAlertText: 'По причине:',
  openMenuRegistrationAlertAcceptButton: 'Хорошо',
  updateButtonTooltip: 'Обновите таблицу для синхронизации с возможным редактированием других пользователей',
  warningDataMayBeStaleRefreshTable: 'Данные таблицы, возможно, устарели. Обновите таблицу для того, чтобы увидеть актуальные данные.',
  downloadBulletinsChooseParticipants: 'Скачивание бюллетеней. Сначала выберите участников собрания',
  downloadBulletinsSingleParticipant: 'Скачать незаполненный бюллетень',
  downloadBulletinsSingleParticipantSuccess: 'Вы получили ссылку для просмотра и скачивания бюллетеня',
  downloadBulletinsSelectedParticipants: 'Мы отправим выбранные бюллетени Вам на почту. Вы сможете их скачать и распечатать',
  archiveSendToEmail: 'Архив с бюллетенями для выбранных собственников отправлен на почту',
  yet: 'Еще',
  collapse: 'Свернуть',
  cantEditBulletin: 'Нельзя редактировать бюллетень',
  votingNotStarted: 'Собрание ещё не началось. Вы не можете перейти в карточку голосования.',
  thisMeetingDontHaveBulletins: 'В данном собрании нет карточки голосования.',
  countBulletins: 'Количество уникальных бюллетеней участвующих в данном собрании, с учетом примененных фильтров',
  countUsers: 'Количество участников собрания, показываемых в таблице, с учетом примененных фильтров',
  countVotePercents: '% голосов участников собрания, показываемых в таблице, с учетом примененных фильтров',
  meetingMembers: 'Участники собрания'
};