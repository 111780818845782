"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  publishedDraftHeaderThereIsNew: 'Есть новый',
  publishedDraft: 'Опубликованный черновик',
  publishedDraftHeaderOfMeeting: 'собрания',
  publishedDraftBodyNewMeetingIsPlanned: 'Планируется проведение нового собрания.',
  publishedDraftBodyMeetingTitleIs: 'Заголовок собрания:',
  publishedDraftBodyHelpText: 'Выскажите свое мнение еще до голосования. Вы можете повлиять на формирование проекта решения. Кликайте на любой вопрос. Читайте проект решения и оставляйте свои комментарии. Возможно, Вы предложите лучшее решение.',
  publishedDraftOpenMeeting: 'Перейти к собранию.',
  active: 'Ожидается',
  activeHeader: 'начало собрания',
  activeBodyLeftBeforeStart: 'До начала собрания осталось',
  activeBodyLeftDays_0: 'день',
  activeBodyLeftDays_1: 'дня',
  activeBodyLeftDays_2: 'дней',
  activeBodyStartVoting: 'начнется голосование по вопросам повестки дня',
  activeBodyNormal: 'пройдет очная часть собрания',
  activeBodyFinishVoting: 'голосование завершится',
  activeBodyAgenda: 'Вопросы вынесенные на голосование',
  activeBodyHelpText: 'Ознакомиться со всеми вопросами дня собрания, распечатать свой бюллетень можно пройдя по ссылке',
  activeBodyLink: 'по этой ссылке',
  meeting: 'Собрание',
  voting: 'Проходит в настоящее время',
  votingBodyMeetingInProcess: 'В настоящий момент проходит голосование по вопросам повестки дня',
  votingBodyUsual: 'очередного',
  votingBodySudden: 'внеочередного',
  votingBodySemiVirtual: 'очно-заочного собрания',
  votingBodyVirtual: 'заочного собрания',
  votingBodyNormal: 'очного собрания',
  votingBodyOwners: 'собственников',
  votingBodyResidents: 'членов товарищества',
  votingBodyCommittee: 'участников комитета',
  votingBodyCanVoteUntil: 'Вы можете проголосовать или изменить свое голосование вплоть до даты окончания голосования',
  votingBodyYear: 'года',
  votingResult: 'Предварительные итоги голосования: уже проголосовало - ',
  votingBodyOwnersCount_0: 'собственник',
  votingBodyOwnersCount_1: 'собственника',
  votingBodyOwnersCount_2: 'собственников',
  votingBodyResidentsCount_0: 'член товарищества',
  votingBodyResidentsCount_1: 'члена товарищества',
  votingBodyResidentsCount_2: 'членов товарищества',
  votingBodyCommitteeCount_0: 'участник комитета',
  votingBodyCommitteeCount_1: 'участника комитета',
  votingBodyCommitteeCount_2: 'участников комитета',
  votingBodyHas_0: 'имеющий',
  votingBodyHas_1: 'имеющие',
  votingBodyHas_2: 'имеющие',
  votingBodyOfVotes: 'голосов',
  votingBodyNoQuorum: 'Кворума нет',
  votingBodyHasQuorum: 'Кворум есть',
  votingBodyNoResults: 'Ни одно решение не принято',
  votingBodyHelpText: 'Просмотреть предварительные итоги голосования, распечатать свой бюллетень и проголосовать можно, пройдя',
  votingBodyLinkText: 'по этой ссылке',
  calcResults: 'Идет подсчет голосов',
  calcResultsBodyFinished: 'Голосование завершено',
  calcResultsBodyDays_0: '{{count}} день',
  calcResultsBodyDays_1: '{{count}} дня',
  calcResultsBodyDays_2: '{{count}} дней',
  calcResultsBodyCalculation: 'идет подсчет голосов',
  calcResultsBodyHelpText: 'Просмотреть предварительные итоги голосования можно, пройдя',
  calcResultsLinkText: 'по этой ссылке',
  closed: 'Отменено',
  closedBodyHelpText: 'Посмотреть подробности и написать своё мнение Вы',
  closedBodyLinkText: 'можете здесь',
  meetingWasClosedWithComment: 'Собрание было закрыто со следующим комментарием:',
  meetingWasClosedByUserWithComment: 'закрыл собрание со следующим комментарием:',
  finishedAccepted: 'Состоялось',
  finishedBodyFinished: 'Собрание завершено. Итоговый протокол опубликован.',
  finishedBodyHasQuorum: 'Кворум есть',
  finishedBodyNoQuorum: 'Кворума нет',
  finishedBodyHelpText: 'Посмотрите итоги голосования, пройдя',
  finishedBodyLinkText: 'по этой ссылке',
  finishedRejected: 'Не состоялось'
};