"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  ownCard: 'Ваш профиль',
  ownEditing: 'Редактирование Вашего профиля',
  delete: 'Пользователь удален или не найден',
  editing: 'Редактирование профиля',
  editingSpecial: 'Редактирование профиля сотрудника',
  creating: 'Добавление собственника помещения',
  creatingContractor: 'Добавление сотрудника подрядчика',
  creatingManager: 'Добавление сотрудника УК',
  creatingHoaEmployee: 'Добавление сотрудника товарищества',
  noMembership: 'Не указано членство в товариществе',
  changeMembership1: 'Для изменения значения членства в товариществе кликните на иконке',
  changeMembership2: 'в аватарке пользователя.'
};