"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  createAccount: 'Создайте аккаунт с помощью e-mail или телефона',
  accountExists: 'У Вас уже есть аккаунт в Сквере',
  accountExists2: 'Такой аккаунт уже существует',
  accountExistsInHOA: 'Вы уже зарегистрированы в данном объекте',
  canNotUseThisLogin: 'Нельзя использовать данный e-mail/телефонный номер',
  accountsJoining: 'Введите пароль и мы добавим этот Объект в Ваш аккаунт.',
  alreadyRegistered_email: 'Ваш e-mail уже зарегистрирован в данном Объекте. Кликните',
  alreadyRegistered_phone: 'Ваш телефонный номер уже зарегистрирован в данном Объекте. Кликните',
  here: 'здесь',
  inputPassword: 'и введите пароль для входа.',
  canNotUseExistAccount_email: 'Вы уже зарегистрировали аккаунт в этом же Объекте и с этим e-mail. Нельзя использовать один и тот же e-mail для разных ролей в одном и том же Объекте',
  canNotUseExistAccount_phone: 'Вы уже зарегистрировали аккаунт в этом же Объекте и с этим телефонным номером. Нельзя использовать один и тот же телефонный номер для разных ролей в одном и том же Объекте',
  accountExistsInSocialAuth_email: 'Вы ранее авторизовывались с этим емейл через социальную сеть. Войдите через соц. сети.',
  accountExistsInSocialAuth_phone: 'Вы ранее авторизовывались с этим телефонным номером через социальную сеть. Войдите через соц. сети.',
  inputAnotherLogin: 'Если Вы хотите использовать разные аккаунты для каждого Объекта, введите e-mail/телефонный номер, который ранее не использовался.',
  youCanUseAnotherLogin: 'Вы можете использовать другой e-mail/телефонный номер, если хотите создать новый аккаунт в данном Объекте',
  youCanUseAnotherLoginInThisHOA: 'Используйте другой e-mail/телефонный номер для регистрации аккаунта в данном Объекте',
  youCanUseAnotherLoginOrUseSocialNetwork: 'Вы можете ввести другой e-mail/телефонный номер для регистрации в данном Объекте или использовать соц. сеть для входа во все свои Объекты'
};