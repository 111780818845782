"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'To whom it applies',
  sortByName: 'Sort by last name',
  document: 'Document',
  sortByDocumentName: 'Sort by file name',
  uploader: 'Who uploaded',
  sortByUploader: "Sort by uploader's last name",
  dateUploaded: 'Date of loading',
  sortByUploadedDate: 'Sort by download date',
  unrecognizedDocument: 'Press to recognize documents',
  disableTootltipText: 'First, mark the documents you need.',
  disableTootltipTextToDelete: 'First, mark the unrecognized documents.',
  infoModalTitle: 'What can be done in unrecognized documents?',
  content: '<ol style=\\"list-style-type: decimal; margin-left: 16px;\\"><li>If you have signed original decisions of the meeting participants (ballots), scan them and upload them using the "Upload ballots" button. Note that you can upload either individual files or an archive of files. Once the files are uploaded, the Square system will try to automatically recognize which meeting participant a particular document belongs to. In addition, it will recognize the participant\'s voting results.<li>Any documents that the system cannot recognize will be reflected in the table of unrecognized documents.<li>Users with the Moderator, Administrator, Meeting Chair, Meeting Secretary, and Counting Committee rights have the right to view unrecognized documents and edit them to determine which user they belong to, and to select the type of document ("Ballot Page" or "Proxy Document").<li>You can also use the checkboxes in the "View unrecognized documents" section of the table. Use the checkboxes and download or delete icons to do this.<li>Use column sorting or the search bar to find the document you need faster.<li>You can see deleted documents in the change history.</ol>',
  tryDeleteDocuments_1: 'You are about to delete {{count}} unrecognized documents. Are you sure?',
  tryDeleteDocuments_0: 'You are about to delete {{count}} unrecognized document. Are you sure?',
  tryDeleteDocuments_2: 'You are about to delete {{count}} unrecognized documents. Are you sure?',
  fileDeleted: 'Files deleted.',
  recognizedFilesNotDeleted: 'Files deleted. The files attached to the ballot have not been deleted, if you want to, delete them in the ballot card under the admin panel tab.',
  deleteFile: 'Are you sure you want to delete the file?',
  sendToEmail: 'You will receive the selected documents in your mail',
  fileAttached: 'Recognized document',
  fileNotAttached: 'Unrecognized document',
  pageNumber: 'Page number',
  bulletinsPage: 'Bulletin page',
  documentsOfAuthorizedPerson: 'Documents of the trustee',
  save: 'Save',
  edit: 'Edit',
  tryToMoveDocument: 'Comment on the changes made to the recognized document',
  disabledInputText: 'First, click on the "Edit" button to edit the data',
  dasableInputPageNumber: 'You cannot enter a bulletin page number because a different document type is selected.',
  canHaveMoreBulletinsPage_2: 'Note that a normal ballot for this meeting must have {{count}} pages. The ballot can only have more than {{count}} pages if the owner has a) many ownership interests or b) many joint owners. You have entered the number {{pageNumber}}. You can save the entered page number or change it if you accidentally make a mistake.',
  canHaveMoreBulletinsPage_0: 'Note that a normal ballot for this meeting must have {{count}} page. There can only be more than {{count}} page on the ballot if the owner has a) many ownership interests or b) many joint owners. You have entered the number {{pageNumber}}. You can save the entered page number or change it if you accidentally make a mistake.',
  canHaveMoreBulletinsPage_1: 'Note that a normal ballot for this meeting must have {{count}} pages. The ballot can only have more than {{count}} page if the owner has a) many ownership interests or b) many joint owners. You have entered the number {{pageNumber}}. You can save the entered page number or change it if you accidentally make a mistake.',
  disabledButtonText: 'First, you must fill out the information for the document',
  needChanges: 'Make the changes first.',
  documentSaved: 'The document has been successfully saved in the user card.',
  chooseUser: 'Select a participant',
  info: 'Info',
  fileName: 'File name',
  editerUser: 'Who edited and when',
  uploaderUser: 'Who uploaded it and when',
  choosePageNumber: 'Enter number',
  userFromMeeting: 'Meeting participant',
  documentType: 'Document type',
  infoDocumentModalTitle: 'How do I process an unrecognized document?',
  contentModal: '<ol style=\\"list-style-type: decimal; margin-left: 16px;\\"><li>In the "Meeting participant" field, select which meeting participant the document to be processed belongs to.<li>Two types of documents are possible in a meeting: either participant ballots or proxy documents of meeting participants. In the "Document Type" field, the default is "Ballot Page", but you can select "Proxy Documents". <li>If the document is a scan copy of a ballot, you should specify the page number of the ballot.<li>If the document is a scan copy of proxy documents (power of attorney, birth certificate, passport copy, etc.), you do not need to fill in the field with the page number of the ballot.<li>Once you have filled in all the required fields, click on the "Save" button. The document will then be attached to the voting card of the meeting participant and will no longer appear in the list of unrecognized documents.<li>If you are not sure which meeting participant the unrecognized document belongs to, you can leave it unprocessed and move on to the next documents using the left and right arrows.</ol>',
  important: 'Important!!!',
  contentModal2: 'If you make a mistake and incorrectly select a meeting participant, specify the wrong document type or the wrong ballot page, you have the option to search for that meeting participant in the [Admin Panel] tab and remove the incorrect document from their voting card.',
  warningModalText1: 'In the voting card ',
  warningModalText2: "there's already a {{count}}th page of a scanned copy of the ballot.",
  warningModalText3: 'We recommend you ',
  warningModalText4: 'view a previously uploaded page in the voting card ',
  warningModalText5: 'and decide which page is the right one.',
  warningModalText6: 'If you are sure that this is the correct page and the previously uploaded page is incorrect, click on the "Replace Page" button. Then the previously uploaded page of the scan copy of the ballot will be deleted from the voting card.',
  warningModalText7: 'The ballot has already been processed. ',
  warningModalText8: 'In order to replace a scan copy of a ballot page, you must ',
  warningModalText9: 'go to the voting card ',
  warningModalText10: 'and delete the previously downloaded page of the scan copy of the bulletin by clicking on the file cross.',
  noMoreUnrecognizedDocuments: 'No more unrecognized files',
  unrecognizedDocuments: 'Unrecognized documents',
  continue: 'continue recognizing',
  finaly: 'finalize',
  allDocumentsRecognized: 'You can close the Document Recognition window and return to the meeting materials.',
  countRecognizedDocuments: 'Successfully recognized documents - {{count}} pcs.',
  countUnrecognizedDocuments: 'No documents recognized yet - {{count}} pcs.',
  chooseOptionContinue: 'Select the "Continue Recognition" option if you want to recognize all remaining unrecognized documents.',
  chooseOptionFinaly: 'Select the "Finish" option if you do not want to recognize all remaining documents and prefer to return to the collection materials.',
  unsavedData: 'You have not saved the entered data. If you select "Continue", the document will remain unrecognized.',
  notSave: 'Continue',
  return: 'no, go back',
  warning: 'Important!',
  resetBulletinStatusText: 'The ballot has already been processed. If you attach a file to this ballot, the ballot will return for processing.',
  helperText: 'Recognized documents will disappear from the "Unrecognized documents" table when refreshing the page, searching, applying filters, sorting',
  canSelectUser: 'Click here, and start recognizing. You will be able to specify which participant in the meeting this document refers to.',
  userSelected: 'The document has already been recognized. You can change and re-specify which participant of the meeting this document refers to.',
  warningTextForNotCorrectedBulletinStatus1: 'Ballot of the participant of the meeting ',
  warningTextForNotCorrectedBulletinStatus2: 'has already been processed.',
  warningTextForNotCorrectedBulletinStatus3: 'We recommend you ',
  warningTextForNotCorrectedBulletinStatus4: 'and view the processed bulletin and decide whether to replace or add a page to the bulletin. You can then return to this document and finish recognizing the document.',
  warningTextForNotCorrectedBulletinStatus5: 'If you are sure that this page of the ballot should be added, you can do it immediately without going to the voting card of the meeting participant.  To do so, click on the "Continue" button.',
  warningTextForNotCorrectedBulletinStatus6: 'Note that adding a new bulletin page will cancel the previously done processing of the bulletin and it will revert to the status "Pending Processing"(B-gray).',
  commentModalTextForNotCorrectedBulletinStatus1: "To save changes to a meeting participant's voting card ",
  commentModalTextForNotCorrectedBulletinStatus2: ' you must leave a mandatory comment.',
  disabledMeetingStatus: 'Document recognition is available in the "Currently Taking Place" and "Vote Counting" meeting statuses',
  dontReplacePage: 'Don\'t replace page',
  numberOfPage: 'Number',
  replacePage: 'Replace page',
  deleteDocument: 'Delete document'
};