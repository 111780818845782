"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  rowsDelete: 'Deleting rows',
  canNotDeleteSelectedRowsCause: 'The lines you highlighted cannot be deleted because they contain:',
  canNotDeleteSelectedRowsMessage1: "1. Registered users. You can only delete them one at a time using each person's profile. To do this, click on the user's avatar and go to their profile.",
  canNotDeleteSelectedRowsMessage2: '2. Users with the type "UNKNOWN". These are technical lines showing you which premises or shares in premises are not occupied by real owners.',
  canNotDeleteSelectedRowsMessage3_0: 'The row you highlighted cannot be deleted because it contains a user with the type "UNKNOWN". This is a technical row showing you which premises or shares in premises are not occupied by real owners.',
  canNotDeleteSelectedRowsMessage3_1: 'The rows you highlighted cannot be deleted because they contain users with the type "UNKNOWN". These are technical rows showing you which premises or shares in premises are not occupied by real owners.',
  canNotDeleteSelectedRowsMessage3_2: 'The rows you highlighted cannot be deleted because they contain users with the type "UNKNOWN". These are technical rows showing you which premises or shares in premises are not occupied by real owners.',
  canNotDeleteSelectedRowsMessage4_0: 'The line you highlighted cannot be deleted because it contains a registered user. It can only be deleted through the profile. You can open the profile by clicking on the avatar.',
  canNotDeleteSelectedRowsMessage4_1: "The rows you highlighted cannot be deleted because they contain registered users. You can only delete them one by one using each person's profile. To do this, click on the user's avatar and go to their profile.",
  canNotDeleteSelectedRowsMessage4_2: "The rows you highlighted cannot be deleted because they contain registered users. You can only delete them one by one using each person's profile. To do this, click on the user's avatar and go to their profile.",
  approveRowsDelete_0: 'Confirm deletion of the row',
  approveRowsDelete_1: 'Confirm deletion of {{count}} rows',
  approveRowsDelete_2: 'Confirm deletion of {{count}} rows',
  rowsSelected_0: 'You have selected {{count}} line',
  rowsSelected_1: 'You have selected {{count}} lines',
  rowsSelected_2: 'You have selected {{count}} rows',
  rows_0: '{{count}} line',
  rows_1: '{{count}} lines',
  rows_2: '{{count}} lines',
  thisRows_0: 'This line',
  thisRows_1: 'These lines',
  thisRows_2: 'These lines',
  ofThem: 'of which',
  but: 'but',
  rowsRelatedToRegisteredUsers_0: '{{count}} refers to users already registered',
  rowsRelatedToRegisteredUsers_1: '{{count}} refer to already registered users',
  rowsRelatedToRegisteredUsers_2: '{{count}} refer to already registered users',
  rowsRelatedToUnknownUsers_0: '{{count}} refers to users with the type "UNKNOWN"',
  rowsRelatedToUnknownUsers_1: '{{count}} refer to users with type "UNKNOWN"',
  rowsRelatedToUnknownUsers_2: '{{count}} refer to users with type "UNKNOWN"',
  rowsRelatedToJoiningRequest_0: '{{count}} refers to applications for accession (applications are rejected if deleted!)',
  rowsRelatedToJoiningRequest_1: '{{count}} refer to applications for accession (applications are rejected if deleted!)',
  rowsRelatedToJoiningRequest_2: '{{count}} refer to applications for accession (applications are rejected if deleted!)',
  rowsWouldNotBeDeleted_0: 'will not be deleted',
  rowsWouldNotBeDeleted_1: 'will not be deleted',
  rowsWouldNotBeDeleted_2: 'will not be deleted',
  canNotDeleteSelectedRowsMessage5_0: 'because it contains a registered user. It can only be deleted via the profile. The profile can be opened by clicking on the avatar.',
  canNotDeleteSelectedRowsMessage5_1: "because they contain registered users. You can only delete them one at a time using each person's profile. To do this, click on the user's avatar and go to their profile",
  canNotDeleteSelectedRowsMessage5_2: "because they contain registered users. You can only delete them one at a time using each person's profile. To do this, click on the user's avatar and go to their profile",
  canNotDeleteSelectedRowsMessage6_0: 'because it contains an owner with the type "UNKNOWN". These are technical lines showing you which premises or shares in premises are not occupied by real owners.',
  canNotDeleteSelectedRowsMessage6_1: 'because they contain owners with the type "UNKNOWN". These are technical lines that show you which rooms or shares in rooms are not occupied by real owners.',
  canNotDeleteSelectedRowsMessage6_2: 'because they contain owners with the type "UNKNOWN". These are technical lines that show you which rooms or shares in rooms are not occupied by real owners.',
  joiningRequestsInSelection_0: 'The line with the application for accession is selected, if deleted, this application will be rejected!',
  joiningRequestsInSelection_1: 'Rows with applications for accession are selected, if deleted, these applications will be rejected!',
  joiningRequestsInSelection_2: 'Rows with applications for accession are selected, if deleted, these applications will be rejected!'
};