"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  protocolPublished: 'Протокол опубликован',
  protocolUploaded: 'Протокол загружен',
  protocolWaitingForPublishing: 'Протокол ждет подтверждения публикации',
  rejectProtocolPublishing: 'Отменить публикацию протокола',
  removePublishedProtocol: 'Удалить опубликованный протокол',
  beginProtocolPublishing: 'Начать публикацию протокола',
  disabledButton: 'Кнопка будет доступна после завершения голосования',
  protocolCreatingSigningAndPublishing: 'Формирование, подписание и публикация протокола.',
  haveNotProtocol: 'Нет протокола',
  protocolNotPublishingWaitingForPublishing: 'Протокол не опубликован. Ждет подтверждения публикации',
  meetingWasHeld: 'Собрание состоялось.',
  meetingNotSucceed: 'Собрание не состоялось',
  initiatedTheReplacementOfProtocol: 'Внимание! Инициирована замена протокола!',
  youCanMakeChangeToVotingResults: '{Пользователь} {Время}{Дата} инициировал замену протокола. Вы можете внести изменение в итоги голосования, добавить бюллетени. После Вам необходимо пройти все шаги публикации протокола заново. Прошлую версию протокола можно скачать здесь.',
  finalProtocolHasBeenPublished: 'Итоговый протокол опубликован. Ознакомиться с итоговым протоколом можно на странице “Документы” или скачав материалы собрания. Всем участникам были отправлены соответствующие уведомления.',
  completeAllFiveSteps: 'Пройдите все пять шагов и опубликуйте протокол собрания. Как только протокол будет опубликован, он будет размещен на странице “Документы” и загружен в материалы собрания.  Всем участникам будут отправлены соответствующие уведомления.'
};