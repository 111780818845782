"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  joiningRequestsInSelection_0: 'Выбрана строка с заявкой на присоединение, при очистке данная заявка будет отклонена!',
  joiningRequestsInSelection_1: 'Выбраны строки с заявками на присоединение, при очистке данные заявки будет отклонены!',
  joiningRequestsInSelection_2: 'Выбраны строки с заявками на присоединение, при очистке данные заявки будет отклонены!',
  fromThisRoom: 'из этого помещения',
  fromTheseRooms: 'из {{ownershipsInSelectedRows}}-х помещений',
  deleteJuristicPerson: 'Удаление зарегистрированного собственника {{juristicPersonLegalForm}} {{juristicPersonName}} {{ownershipCountText}} невозможно, у пользователя не останется собственности в объекте.',
  deletePerson: 'Удаление зарегистрированного собственника {{firstName}} {{lastName}} {{ownershipCountText}} невозможно, у пользователя не останется собственности в объекте.',
  sureWantDelete: 'Вы уверены, что хотите удалить зарегистрированного собственника {{firstName}} {{lastName}} {{ownershipCountText}}?',
  acceptClearing: 'Подтвердите очистку',
  select: 'Вы выбрали',
  ofThese: 'из них',
  registeredUsers: 'к уже зарегистрированным пользователям.',
  rowsClearing: 'Очистка строк'
};