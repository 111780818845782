"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  name: 'Name',
  category: 'Category',
  date: 'Last change',
  favorite: 'Favorites',
  menuItem1: 'By name from A to Z',
  menuItem2: 'By name from I to A',
  menuItem3: 'First the selected ones',
  menuItem4: 'Date edited first new',
  menuItem5: 'Date of edit first old',
  menuItem6: 'Cancel sorting',
  removeFavorite: 'Unfavorite',
  addFavorite: 'Add to bookmarks',
  download: 'Download',
  copy: 'Copy link',
  edit: 'Edit',
  delete: 'Delete',
  sort: 'Click to sort',
  deletedTooltip: 'Document deleted',
  editTableColumns: 'Edit column display',
  menuTooltip: 'Additional Menu. <br> View the available <br> options',
  favoriteIconTooltip: 'Your favorite <br> document'
};