"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  createSuccess: 'A new claim has been successfully created',
  editFieldTitle: 'Claim header',
  editFieldDate: 'Date and time the claim started',
  editFieldRSO: 'Select controlling org',
  editFieldDescriptionRSO: 'Description of the claim to resource supply org',
  daysLeftTooltip: 'After this period of time has elapsed, it will not be possible to sign the claim',
  requestCountTooltip: 'Number of references',
  appeal: 'appeals',
  days: 'days',
  filterInitiator: 'Initiator',
  filterStatus: 'Status',
  filterRSO: 'RDF',
  notSelectRowsToDelete: 'There is nothing to delete. First, highlight the line',
  notSelectRowsToExport: 'Download lines are not labeled',
  notSelectRowsToSend: 'Rows for sending notifications are not marked',
  pretensionCreate: 'Create a new claim',
  pretensionCreature: 'Creating a new claim',
  exportPretension: 'Export of the claim and selected appeals',
  sendingNotification: 'Notify users',
  selectedDelete: 'Delete selected',
  pretensionEmpty: 'Claims not found',
  requestEdit: 'Editing a request',
  newRequest: 'New application/application',
  whoAsked: 'Who contacted',
  requestNumber: 'Reference/application number',
  disabledDateTooltip: 'The date and time are available for editing in a claim with a status of "In Progress"',
  notificationHint: 'Describe the problem that has arisen. Give the owners instructions on how to act in this situation: A) where to call, B) what to say and C) what to do. The more owners call and record their appeal to the RSO/UK, the faster the problem will be fixed and the easier it will be to get a recalculation.',
  freeTariff: 'Not available with free tariff',
  requests: {
    title: 'Appeals of owners of the controlling org',
    addRequest: 'Add an appeal',
    createBeforeAdd: 'You can only add an appeal in a created claim',
    columns: {
      owner: 'Full name',
      address: 'Property',
      id: '#',
      number: 'Number'
    },
    filter: {
      street: 'House address',
      entrance: 'Entrance number',
      isRegistered: 'Registration of owners',
      contactInfo: 'Contact Information'
    },
    modalView: {
      titleEdit: 'Editing a request',
      titleNew: 'New application/application',
      fields: {
        whoApplied: 'Who contacted',
        number: 'Reference/application number'
      }
    },
    registered: 'Registered',
    unregistered: 'Unregistered',
    notSpecified: 'Not specified',
    emailSpecified: 'Selected Email',
    phoneSpecified: 'Selected phone number',
    titleDelete: 'Do you want to delete the claim?'
  },
  inProgress: 'In progress',
  deadlineWasExceededWithMoney: 'Fixed. Recalculation is due',
  fixedInDueTimeWithoutMoney: 'Fixed. Without recalculation',
  success: 'Recalculation received'
};