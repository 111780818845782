"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  inputLink: 'Enter the url address',
  userNotFound: 'No users found',
  underlineToolTooltip: 'Underline text',
  italicToolTooltip: 'Italic text',
  attachFilesToolDisabledTooltip: 'Attach documents can only in authorized mode',
  boldToolTooltip: 'Bold text',
  commentPlaceholder: 'Your comment',
  sendToolTooltip: 'Send',
  discardToolTooltip: 'Exit editing without saving changes.',
  attachFilesToolTooltip: 'Attach photos and documents',
  mentionToolTooltip: 'Mention user',
  insertDateToolTooltip: 'Insert date'
};