"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  isVerifiedChangesModalText1: '1. We will check if it is already used or not in the SQVR service.',
  isVerifiedChangesModalText2Part1: '2. In case this new',
  isVerifiedChangesModalText2Part2: 'is not used in the SQVR service, you will receive',
  isVerifiedChangesModalText2Part3: 'a letter',
  isVerifiedChangesModalText2Part4: 'with a unique link, after clicking on which your',
  isVerifiedChangesModalText2Part5: 'will be changed.',
  isVerifiedChangesModalText3Part1: '3. In case the one you entered',
  isVerifiedChangesModalText3Part2: 'is already used, then in the',
  isVerifiedChangesModalText3Part3: 'letter',
  isVerifiedChangesModalText3Part4: 'you will be given instructions on further actions to change it.',
  isVerifiedChangesModalText4Part1: '4. Until you confirm the new',
  isVerifiedChangesModalText4Part2: 'You can use the previous one',
  importChangeTownSettingsModalText: 'In the house settings, it is indicated',
  totalArea: 'total area',
  inImportedRegistry: 'In the imported registry',
  selectedSpaces: 'Selected spaces:',
  dataOfAllUnregisteredUsersWillBeDeleted: 'Data of all unregistered users will be deleted.',
  changeSettingsOfHouseToRequiredValues: 'Change the house settings to the required values?',
  importChangeVillageSettingsModalText: 'and in the object settings, it is indicated',
  ownershipMergeModalText: 'Specify the owner to whom you want to transfer all these spaces.',
  ownershipMergeForManyRegisteredUsersModalTextPart1: 'It is not possible to attach spaces to several registered owners at once:',
  ownershipMergeForManyRegisteredUsersModalTextPart2: 'To attach the selected spaces, specify only one registered owner.',
  ownershipMergeForOneRegisteredUserModalText1: 'In addition, one registered user',
  ownershipMergeForOneRegisteredUserModalText2: 'Do you really want to transfer all these spaces',
  registryBlockedModalText1: 'The registry is being edited at the moment',
  registryBlockedModalText2: 'try to return to editing later.',
  selectPeopleModalTitle: 'Select owners',
  selectPeopleModalSubTitleV1: 'Select all owners to whom you want to send a notification',
  selectPeopleModalSubTitleV2: 'Invite no one. All are already registered',
  selectPeopleModalSubTitleV3: 'Select all owners you want to invite to SQVR',
  selectPeopleModalTextPart1: 'From',
  selectPeopleModalTextPart2: 'unregistered owners selected',
  selectPeopleModalTextPart3: 'there is no information about phones and e-mail. Therefore, you will receive a file with printed invitation forms for these owners. The rest will receive an invitation by e-mail and SMS.'
};