"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.prepareFilters = exports.cleanFilters = void 0;
function cleanFilters(filters) {
  return (filters || []).filter(filterItem => filterItem.items.some(item => item.isVisible)).map(group => Object.assign(Object.assign({}, group), {
    items: group.items.filter(item => item.isVisible)
  }));
}
exports.cleanFilters = cleanFilters;
function prepareFilters(availableFilters, filtersToApply) {
  return (filtersToApply || []).filter(filterItem => {
    return filterItem.type && !!(availableFilters || []).find(f => f.type === filterItem.type);
  });
}
exports.prepareFilters = prepareFilters;