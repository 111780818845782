"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  ownContactDetails: 'Мои данные',
  contactDetails: 'Контактные данные',
  ownOwnership: 'Моя собственность',
  ownership: 'Собственность',
  settings: 'Мои настройки',
  ownActivity: 'Моя активность',
  activity: 'Активность',
  password: 'Смена пароля'
};