"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  selectedAllText: 'All rows in the table are selected',
  defaultSettings: 'Default settings',
  settingsUpColumns: 'Column settings',
  close: 'Close'
};