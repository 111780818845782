"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  byRequest: 'По запросу',
  nothingFound: 'ничего не найдено',
  contractors: 'Подрядчики',
  owners: 'Контакты',
  budget: 'Бюджет',
  works: 'Работы и договоры',
  workOn: 'Работа по',
  complaints: 'Жалобы',
  meetings: 'Собрания',
  documents: 'Документы',
  announcements: 'Объявления',
  managementAnnouncement: 'Объявление правления',
  urgentManagementAnnouncement: 'Важно',
  ownAnnouncement: 'Личное объявление',
  urgentOwnAnnouncement: 'SOS',
  newComplaint: 'Новая',
  completedComplaint: 'Выполнена',
  inWorkComplaint: 'В работе',
  rejectedComplaint: 'Отклонена',
  employeesInnerGroup: 'Контактные лица',
  budgetInnerGroup: 'Финансовые отчеты',
  worksInnerGroup: 'Работы',
  contractsInnerGroup: 'Договоры',
  normalMeeting: 'Очное',
  virtualMeeting: 'Заочное',
  semiVirtualMeeting: 'Очно-заочное',
  ownersMeeting: 'собрание собственников',
  managementMeeting: 'заседание правления',
  committeeMeeting: 'собрание комитета',
  residentsMeeting: 'собрание участников товарищества',
  finishedRejectedMeeting: 'Не состоялось',
  rejectedMeeting: 'Отменено',
  closedMeeting: 'Закрыто (отменено правлением)',
  draftMeeting: 'Черновик',
  visibleDraftMeeting: 'Опубликованный черновик',
  activeMeeting: 'Проходит в настоящее время',
  calcResultsMeeting: 'Подсчет голосов',
  registrationMeeting: 'Идет регистрация',
  votingMeeting: 'Идет голосование',
  finishedAcceptedMeeting: 'Состоялось',
  protocolReviewMeeting: 'Идет подсчет голосов',
  resident: 'Собственник',
  hoaEmployee: 'Сотрудник Товарищества',
  contractor: 'Подрядчик',
  integrator: 'Эксперт',
  moderator: 'Модератор',
  managementStuff: 'Сотрудник УК',
  admin: 'Администратор',
  resultSearchText: 'Здесь будут отражаться результаты поиска',
  emptyResultSearchText1: 'По данному запросу ничего не&nbsp;найдено',
  emptyResultSearchText2: 'Пожалуйста, введите другой запрос',
  unknown: 'Неизвестный'
};