"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Settings',
  commonData: 'Common data',
  registry: 'Registry',
  informationDisclosure: 'Information disclosures',
  userRights: 'User rights'
};