"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  getVotingBulletin: 'Receive ballot papers',
  get: 'Get',
  selectSettingsForPrinting: 'For easy handling of bulletins, select the settings for printing downloadable files:',
  singleSidedPrinting: 'Single-sided printing',
  doubleSidedPrinting: 'Double-sided printing',
  sortByLastName: 'Sorting by last name',
  sortByAppartaments: 'Sorting by apartments'
};