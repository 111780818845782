"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  agendaSolution: 'Проект решения',
  rationale: 'Обоснование',
  documents: 'Документы',
  questionDescription: 'Описание вопроса',
  agendas: 'Вопросы повестки дня',
  selectedNumOfNumQuestions: 'Выбрано {{count}} из {{total}}',
  selectedNumOfNumShortWithBr: 'Выбрано<br />{{count}} из {{total}}',
  hasNumQuestionsInside: 'Входит<br />{{total}} вопросов',
  agendaTemplateModalInfoPartOne: 'Вы можете выбрать или целиком собрание со всеми вопросами, или только некоторые вопросы, входящие в него. Выбранные вопросы вместе с проектами решений и всеми приложениями окажутся в черновике собрания.',
  agendaTemplateModalInfoPartTwo: 'Вы сможете потом их редактировать и удалять.',
  agendaTemplateModalResetBtn: 'Сбросить выбранные вопросы',
  agendaTemplateModalMainHeader: 'Выбор шаблонных вопросов',
  showAllQuestions: 'Показать все вопросы',
  showSelectedQuestions: 'Показать выбранные вопросы',
  selected: 'Выбрано',
  agendaTemplateModalWarnInfo: 'Данный вопрос обычно рассматривается с другими вопросами собрания',
  agendaTemplateModalWarnRecommendation: 'Мы настоятельно рекомендуем использовать все вопросы из этого собрания вместе, не выбирая их по-одному.',
  agendaTemplateSelectedModalConfirm: 'Добавить вопросы в повестку дня',
  agendaTemplateSelectedModalReturn: 'Продолжить выбор вопросов',
  agendaTemplateSelectedModalHeader: 'Выбрано вопросов',
  agendaTemplateSelectedModalBody: 'Все выбранные вопросы вместе с проектами решений и всеми приложениями окажутся в черновике собрания. Вы сможете потом их редактировать и удалять.',
  removeQuestionWarnMessage: 'Вы исключили вопрос из повестки дня.',
  noQuestionsAreSelected: 'Не выбран ни один вопрос',
  successMessage_0: 'Ура, вы добавили {{count}} шаблонный вопрос в свое собрание',
  successMessage_1: 'Ура, вы добавили {{count}} шаблонных вопроса в свое собрание',
  successMessage_2: 'Ура, вы добавили {{count}} шаблонных вопросов в свое собрание',
  chooseOne: 'Выбрать один',
  chooseAll: 'Выбрать все',
  deleteOne: 'Убрать один',
  deleteAll: 'Убрать все'
};