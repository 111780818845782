"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  close: 'Назад',
  login: 'Войти',
  ok: 'OK',
  emailUsedForResidentInCurrentHoaContent: 'Введенный Вами e-mail уже зарегистрирован в этом Объекте. Вы не можете создать с ним заявку на присоединение. Вернитесь назад и используйте другой e-mail. Если Вы не хотите продолжать регистрацию, Вы можете войти в свой Объект.',
  phoneUsedForResidentInCurrentHoaContent: 'Введенный Вами номер телефона уже зарегистрирован в этом Объекте. Вы не можете добавить его в свой аккаунт. Используйте другой  номер телефона или нажмите на кнопку “Войти” для входа в этот Объект со своим паролем.',
  phoneUsedForResidentInCurrentHoaTitle: 'Номер телефона уже зарегистрирован',
  emailUsedForResidentInCurrentHoaTitle: 'E-mail уже зарегистрирован',
  differentUserTypesTitle: 'E-mail уже зарегистрирован',
  differentUserRolesTitle: 'E-mail уже зарегистрирован',
  differentUserTypesContent: 'Введенный Вами e-mail уже зарегистрирован. Вы не можете объединять физ. лицо и юр. лицо. Используйте другой e-mail. Если Вы не хотите продолжать, Вы можете войти в свой Объект.',
  differentUserRolesContent: 'Введенный Вами e-mail уже зарегистрирован. Вы не можете объединять пользователей с разными ролями внутри одного Объекта. Используйте другой e-mail. Если Вы не хотите продолжать, Вы можете войти в свой Объект.'
};