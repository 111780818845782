"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  copywrite: 'SQVR. All rights reserved, {{year}}',
  policyAndLicenseLinkText: 'Privacy Policy and Terms of Use',
  policyAndLicenseModalTitle: 'Privacy Policy and Terms of Use',
  policyAndLicenseModalButtonClose: 'Close',
  googleAppLink: 'https://play.google.com/store/apps/details?id=com.sqvr.app',
  appStoreAppLink: 'https://apps.apple.com/us/app/sqvr/id6443655174',
  ruStoreAppLink: 'https://www.rustore.ru/catalog/app/com.sqvr.app',
  commonAppLink: 'https://app.sqvr.ru/',
  googleAppLinkText: 'Download the app from Google Play',
  appStoreAppLinkText: 'Download the app from the App Store',
  ruStoreAppLinkText: 'Download the application in RuStore',
  commonAppLinkText: 'Download the app'
};