"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  ownersNotify: 'Уведомления собственников',
  canNotSendNotifyForNotRegisteredUsers: 'Невозможно отправить уведомления выбранным пользователям. Они еще не зарегистрированы в Сквере.',
  rows_0: 'строка',
  rows_1: 'строки',
  rows_2: 'строк',
  rowsSelect_0: '{{count}} строку',
  rowsSelect_1: '{{count}} строки',
  rowsSelect_2: '{{count}} строк',
  rowsRelate_0: '{{count}} строка относится',
  rowsRelate_1: '{{count}} строки относятся',
  rowsRelate_2: '{{count}} строк относятся',
  selectedUser_0: '{{count}} выбранного пользователя',
  selectedUser_1: '{{count}} выбранных пользователей',
  selectedUser_2: '{{count}} выбранных пользователей',
  notRegisteredUser_0: '{{count}} пользователь не зарегистрирован',
  notRegisteredUser_1: '{{count}} пользователя не зарегистрированы',
  notRegisteredUser_2: '{{count}} пользователей не зарегистрированы',
  userWithoutContactData: 'У {{selectedUsers}} из {{allUsersCount}} отсутствует email или телефон для связи.',
  notRegisteredUserAndWithoutEmail: 'У {{selectedUsers}} пользователей отсутствует email или телефон для связи и {{notRegisteredUsers}} из {{allUsersCount}}.',
  selectionWithoutNotRegisteredUsers: 'Вы выбрали {{selectedRows}}, из них {{relatedRows}} к пользователям, еще не зарегистрированным в Сквер, и им невозможно отправить уведомление'
};