"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  usersInvite: 'User invites',
  usersNotification: 'User Notification',
  userNameTemplate: 'Full name',
  selectedRows_0: '{{count}} line',
  selectedRows_1: '{{count}} lines',
  selectedRows_2: '{{count}} lines',
  rowsRelated_0: 'of which {{count}} line refers to',
  rowsRelated_1: 'of which {{count}} lines refer to',
  rowsRelated_2: 'of which {{count}} lines refer to',
  registeredUsersWarning1_0: 'You cannot invite the user you selected because they are already registered in Sqvr',
  registeredUsersWarning1_1: 'You cannot invite the users you selected because they are already registered in Sqvr',
  registeredUsersWarning1_2: 'You cannot invite the users you selected because they are already registered in Sqvr',
  registeredUsersWarning2: 'You have selected {{selection}}, {{relation}} to already registered users. We will only send invitations to unregistered users.',
  registeredUsersWarning3_0: 'You cannot invite the user you selected because the user is in the temporary (blue) row and has not yet been saved',
  registeredUsersWarning3_1: 'You cannot invite any of your selected users because they are in temporary (blue) rows and have not yet been saved',
  registeredUsersWarning4: 'Unable to invite users because the registry is empty',
  notRegisteredNotContactUsersSingleWarning_0: 'The unregistered owner you have chosen does not have phone or e-mail information. Therefore, you will receive a file with printed invitation forms for this owner by e-mail.',
  notRegisteredNotContactUsersSingleWarning_1: 'Of the selected {{count}} unregistered owners, one unregistered owner has no phone or e-mail information. Therefore, you will receive a file with printed invitation forms for this owner by e-mail.',
  notRegisteredNotContactUsersSingleWarning_2: 'Of the selected {{count}} unregistered owners, one unregistered owner has no phone or e-mail information. Therefore, you will receive a file with printed invitation forms for this owner by e-mail.',
  notRegisteredNotContactUsersMultipleWarning: 'Of the selected {{count}} unregistered owners, {{noContactUsersNum}} owners do not have phone or e-mail information. Therefore, you will receive a file with printed invitation forms for these owners by e-mail.',
  otherWillReceiveInvite: 'The rest of you will receive an invitation via email and text message.',
  invitesHaveSuccessfullySent: 'Invitations have been successfully sent',
  deliveryCanTakeSomeTime_0: 'It may take some time for the invitation to be delivered',
  deliveryCanTakeSomeTime_1: 'Invitations ({{count}} pieces) may take some time to be delivered',
  deliveryCanTakeSomeTime_2: 'Invitations ({{count}} pieces) may take some time to be delivered',
  notificationDeliveryCanTakeSomeTime_0: 'It may take some time for the notification to be delivered',
  notificationDeliveryCanTakeSomeTime_1: 'Notifications ({{count}} pcs) may take some time to be delivered',
  notificationDeliveryCanTakeSomeTime_2: 'Notifications ({{count}} pcs) may take some time to be delivered',
  noOneInviteSendingChannelSelected: 'You have not selected any delivery channel. We will send printed invitation forms to your mailbox',
  inviteSendingChannelWarning: 'The invitation will not be sent to users who do not have {{sendingMethod}}. We will send a printed invitation form for them to your mail.',
  notifySendingChannelWarning: 'Notification will not be sent to users who do not have {{sendingMethod}} specified.',
  emailOrPhoneChannel: 'phone number or e-mail',
  emailChannel: 'e-mail',
  phoneChannel: 'phone number',
  inviteSentSuccessfully_0: 'The invitation was successfully sent',
  inviteSentSuccessfully_1: 'Invitations have been successfully sent',
  inviteSentSuccessfully_2: 'Invitations have been successfully sent',
  notificationSentSuccessfully_0: 'Notification sent successfully',
  notificationSentSuccessfully_1: 'Notifications successfully sent',
  notificationSentSuccessfully_2: 'Notifications successfully sent',
  inviteAllNotRegisteredUsers: 'Invite all unregistered users',
  inviteOwnersIntelegram: 'Invitation to Telegram bot',
  inviteOwnersInSqvr: 'Invite owners to the Sqvr',
  smsEnableHint: 'If you need to send SMS, please contact technical support to activate this service!',
  addUsersHint: 'To add recipients click here',
  changeUsersHint: 'To add or remove recipients click here',
  userNameMailHint: 'Here we will insert the names and patronymics of the selected users that we know. If we do not know the name, then nothing will be inserted',
  notEditableFieldsHint: 'These fields are not editable and are filled in automatically by the system',
  previewHint: "Click here to see what the message will look like on the recipient's screen",
  cantSend: 'Action is not available. Not all selected delivery methods (e-mail or SMS) have the text of the message itself. Enter the text of the message for each delivery method or reduce the ways of sending the message.',
  greetings: 'Hello',
  nameMailSubstitution: "{recipient's name will be entered automatically}!",
  smsInvitePlaceholder: 'SQVR. Welcome to {{hoaName}} https://{{appHost}}/api/v1/u/XXXXXX {{currentUserName}}',
  smsInvitePlaceholderHoaName: '_OBJECT_NAME_',
  smsInvitePlaceholderLink: '_LINK_',
  smsInvitePlaceholderUserName: '_INVITE_NAME',
  notifications: 'Notification',
  inviteToSqvr: 'Invitation to the SQVR',
  notificationsPreview: 'Preview',
  invitePreview: 'Invitation preview',
  whom: 'To whom',
  highPriority: 'High priority',
  recipientsCount_0: ' ({{count}} recipient)',
  recipientsCount_1: ' ({{count}} recipient)',
  recipientsCount_2: ' ({{count}} recipients)',
  recipientsCountNoRecipients: ' (No recipients)',
  smsUsedSymbols_0: '{{count}} character from {{total}}',
  smsUsedSymbols_1: '{{count}} character from {{total}}',
  smsUsedSymbols_2: '{{count}} characters from {{total}}',
  smsUsedSymbolsShort_0: '{{count}} character',
  smsUsedSymbolsShort_1: '{{count}} character',
  smsUsedSymbolsShort_2: '{{count}} characters',
  smsCount: '{{count}} SMS',
  smsTotal: '{{total}} total',
  saveDialogTitle: 'Saving the entered data!',
  saveDialogText: 'When you close it, you will lose the data you entered. Do you want to save them?',
  saveDialogYesLabel: 'Save and exit',
  saveDialogNoLabel: 'Delete and exit',
  inviteUserSelectionHint: 'Select all of the owners you wish to invite to the Square',
  notifyUserSelectionHint: 'Select all owners to whom you want to send the notification',
  inviteMailPreview1: 'Once registered, you will be able to:',
  inviteMailPreview2: 'receive reliable and timely information on the state of affairs at {Type of Facility}',
  inviteMailPreview3: 'vote electronically',
  inviteMailPreview4: 'receive reports on performance and remediation of deficiencies',
  inviteMailPreview5: 'create and participate in surveys',
  inviteMailPreview6: 'and more',
  inviteMailPreview7: 'To register, click the "APPLY" button below.',
  inviteMailPreview8: 'Accept the invitation',
  inviteMailPreview9: 'Your personal data was obtained in preparation for the general meeting of owners in accordance with paragraph 3.1. of Article 45 of the Housing and Communal Services Code of the Russian Federation.',
  inviteMailPreview10: 'Owner {ИМЯ+ОТЧЕСТВО}',
  inviteMailPreview11: 'If you still have questions, you can contact me by mail:',
  youHaveSelected: "You've have selected",
  onlyGroups_0: '{{count}} ownership group',
  onlyGroups_1: '{{count}} ownership groups',
  onlyGroups_2: '{{count}} ownership groups',
  onlyUsers_0: '{{count}} owner',
  onlyUsers_1: '{{count}} owner',
  onlyUsers_2: '{{count}} owners',
  notifyNotRegisteredUsersWarning: 'Cannot send notifications to selected users. They are not yet registered to Svqr.',
  registryNotifyNotRegisteredUsersWarning: 'You have selected {{selection}}, {{relation}} to users who are not yet registered with Sqvr and cannot be notified',
  selectedUser_0: '{{count}} of the selected owner',
  selectedUser_1: '{{count}} elected owners',
  selectedUser_2: '{{count}} elected owners',
  notRegisteredUser_0: '{{count}} owner not registered',
  notRegisteredUser_1: '{{count}} owner not registered',
  notRegisteredUser_2: '{{count}} no owners registered',
  usersRelated_0: '{{count}} proprietor refers to',
  usersRelated_1: '{{count}} of the proprietor include',
  usersRelated_2: '={{count}} owners refer to',
  userWithoutContactData: '{{selectedUsers}} of {{allUsersCount}} do not have an e-mail or phone number to contact.',
  selectionWithNotRegisteredUserAndWithoutEmail: '{{selectedUsers}} does not have an e-mail or phone number to contact and {{notRegisteredUsers}} of {{allUsersCount}}.',
  selectionWithNotRegisteredUsers_0: '{{count}} the selected owner refers to users not yet registered in Sqvr, and it is not possible to send a notification to them',
  selectionWithNotRegisteredUsers_1: '{{count}} selected owners refer to users not yet registered with Sqvr, and it is not possible to send notifications to them',
  selectionWithNotRegisteredUsers_2: '{{count}} of the selected owners refer to users not yet registered with Square, and it is not possible to send notification to them',
  selectionWithNoContactUsersSingle: 'The selected owner does not have an e-mail or phone number to contact and cannot be notified',
  selectionWithNoContactUsers: '{{selectedUsers}} of {{allUsersCount}} do not have an e-mail address or phone number to contact and cannot be notified',
  needToSelectSendingChannel: 'At least one delivery channel must be selected',
  usersNotificationConfirmationTitle: 'Confirm sending',
  usersNotificationConfirmationRecipientsTotal_0: 'Recipient selected {{count}}',
  usersNotificationConfirmationRecipientsTotal_1: 'Recipient selected {{count}}',
  usersNotificationConfirmationRecipientsTotal_2: 'Selected {{count}} recipients',
  usersNotificationConfirmationCannotBeNotified_0: '{{count}} user will not receive a message: the user has no contact information or notifications are disabled.',
  usersNotificationConfirmationCannotBeNotified_1: '{{count}} users will not receive a message: users do not have contact information, or notifications are disabled.',
  usersNotificationConfirmationCannotBeNotified_2: '{{count}} users will not receive a message: users do not have contact information, or notifications are disabled.',
  chooseUsers: 'Select recipients',
  sendMe: 'Send a copy to yourself',
  sendMeInfoText: '<ol style="list-style-type: decimal; padding-left: 24px; font-weight: 500"><li>You can enable or disable sending your notification to yourself.</li><li>Usually, mailing initiators want to receive a copy of their own notification so they can see how it looked when it was sent and make sure the mailing was successful.</ol>',
  choseOrDeleteUsersTooltip: 'To add or remove recipients click here',
  choseUsersTooltip: 'To add recipients click here',
  change: 'Change the recipient list',
  statisticsTooltipUsers: 'You have not yet selected any owners. We cannot show the statistics of the mailing list',
  hasNoLimit: 'Not enough limits',
  statisticsTooltipText: 'You have not entered the message text. We cannot show the statistics of the mailing list',
  statisticsTooltipText1: 'Click to view the preliminary results of the invitation mailing. Click on the button to the right "Edit Recipient List" to view the named recipient list and make changes to it.',
  statisticsTooltipText2: 'Click to see the preliminary results of the invitation mailing.',
  recipients: 'Recipients',
  cancelChoice: 'Clear selection',
  cancelChoiceAlertText: 'You want to clear the list of selected users. This action cannot be canceled!',
  clean: 'Clear',
  revertChoice: 'Refuse to change the choice',
  revertChoiceAlertText: 'Do you want to discard the changes made and return to the previous list of selected users? This action cannot be undone!',
  revert: 'Refuse',
  close: 'Close',
  cancel: 'Cancel',
  continue: 'Select and continue',
  notHaveSelectedUsers: 'No recipient selected',
  continueDisabledTooltip: 'First, you need to select the recipients',
  notificationWarningTooltip: 'Users who may not receive the message',
  statisticsTitle: 'Newsletter statistics',
  detailStatisticsTitle: 'Detailed mailing statistics',
  detailStatisticsText0: 'Note that from',
  detailStatisticsText1: 'Log out of your account ',
  detailStatisticsText2: ' of selected users:',
  detailStatisticsText3: ' can be notified immediately',
  detailStatisticsText4: " could have gotten the notification, but there's not enough limits",
  detailStatisticsText5: " can't get notice either way",
  detailStatisticsText6: "So that you don't have to manually search for users to whom we can't send notifications because of insufficient limits, you need to buy the missing limits first and then send the notification.",
  items: 'qty',
  allSelected: 'Total recipients selected',
  canNotify: 'Can get',
  notReceive: "Can't get",
  needEmailLimit: 'Not enough e-mail limits',
  needSmsLimit: 'Not enough SMS limits',
  receive: 'Can get it right away',
  finalResult: 'Final result of the mailing',
  usedLimits: 'Use of limits in this mailing',
  counts: ' {{current}} pcs. of {{total}}',
  limitsError: ' (insufficient limits)',
  buyMore: 'Buy more',
  showMore: 'View more',
  yesBuy: 'Yes, buy more',
  yseSend: 'Yeah, send it',
  buyLimitsButton: 'Add limits',
  hasNoLimits: 'Not enough limits',
  afterYouCanReturn: 'After purchasing limits, you can return to this page and finish sending notifications.',
  sendWithoutLimitText: "Are you sure you only want to send the notification to those who can receive it and don't buy limits?",
  area: 'Area, m²',
  vote: 'Votes',
  include: 'These include:',
  hasEmail: 'E-mail',
  hasPhone: 'SMS',
  hasPush: 'Push notifications in the Sqvr app',
  hasTgBot: 'Telegram-bot',
  hasApp: 'Connected the Square mobile app',
  notRegistered: 'Not registered in Sqvr',
  isRegistered: 'Already registered in Sqvr',
  disabledNotification: 'Disabled receiving messages from Sqvr',
  spamList: 'Put Sqvr on the spam list',
  onlySms: 'Will only receive sms messages',
  onlyEmail: 'Will only receive an e-mail message',
  bothOrMore: 'Will receive the message in two or more ways',
  moreNotification: 'For more audience reach you can add',
  forEmail: 'e-mail newsletter',
  forSms: 'SMS distribution',
  aboutSmsCountSymbols_0: 'Please note that your notification consists of a {{count}} character, and will be automatically split by the service provider into {{smsCount}} SMS.',
  aboutSmsCountSymbols_1: 'Please note that your notification consists of {{count}} characters and will be automatically split by the service provider into {{smsCount}} SMS.',
  aboutSmsCountSymbols_2: 'Please note that your notification consists of {{count}} characters and will be automatically split by the service provider into {{smsCount}} SMS.',
  aboutSmsCountRecipients_0: 'Given that {{count}} user will receive SMS messages, the total number of SMS will be',
  aboutSmsCountRecipients_1: 'Given that {{count}} users will receive SMS messages, the total number of SMS will be',
  aboutSmsCountRecipients_2: 'Given that {{count}} users will receive SMS messages, the total number of SMS will be',
  hintTextPush: 'These users will only receive the message in the Sqvr mobile app. This delivery method is the highest priority by default. Other ways to send notifications to these users will not be used.',
  hintTextEmail: 'These users will only receive the message to their verified e-mail address. This delivery method will be applied to all users who have not activated the Square mobile app and have not activated the Telegram-bot. Other ways of sending notifications to these users will not be used.',
  hintTextPhone: 'These users will only receive the message as an SMS to their previously confirmed cell phone number, as they have not yet connected either the Square mobile app or the Telegram-bot, and do not yet have a confirmed e-mail address. This delivery method will be applied last.',
  hintTextTg: 'These users will only receive the message in Telegram. This delivery method will be applied to all users who have connected Telegram-bot but have not yet connected the mobile app. Other ways of sending notifications to these users will not be used.',
  hintBoth: 'e-mail or SMS',
  sendSuccessMessage_0: '<ol style=\\"list-style-type: decimal; padding-left: 24px;\\"><li>Notifications have been sent {{count}} to the recipient.<li>Information about this mailing will be stored in the change history.<li>A file with the mailing results by name will be sent to your e-mail.</ol>',
  sendSuccessMessage_1: '<ol style=\\"list-style-type: decimal; padding-left: 24px;\\"><li>Notifications have been sent to {{count}} recipients.<li>Information about this mailing will be stored in the change history.<li>A file with the mailing results by name will be sent to your e-mail.</ol>',
  sendSuccessMessage_2: '<ol style=\\"list-style-type: decimal; padding-left: 24px;\\"><li>Notifications have been sent to {{count}} recipients.<li>Information about this mailing will be stored in the change history.<li>A file with the mailing results by name will be sent to your e-mail.</ol>',
  sendSuccessInviteMessage_0: '<ol style=\\"list-style-type: decimal; padding-left: 24px;\\"><li>Invitations have been sent {{count}} to the recipient.<li>An archive with printed versions of invitations has been e-mailed to you for all unregistered users who will not receive an invitation.<li>Information about this mailing will be stored in the history of changes.<li>A file with the results of the mailing by name will be sent to your e-mail.</ol>',
  sendSuccessInviteMessage_1: '<ol style=\\"list-style-type: decimal; padding-left: 24px;\\"><li>Invitations have been sent to {{count}} recipients.<li>An archive with printed versions of invitations has been e-mailed to you for all unregistered users who will not receive an invitation.<li>Information about this mailing will be kept in the history of changes.<li>A file with the results of the mailing by name will be sent to your e-mail.</ol>',
  sendSuccessInviteMessage_2: '<ol style=\\"list-style-type: decimal; padding-left: 24px;\\"><li>Invitations have been sent to {{count}} recipients.<li>An archive with printed versions of invitations has been e-mailed to you for all unregistered users who will not receive an invitation.<li>Information about this mailing will be kept in the history of changes.<li>A file with the results of the mailing by name will be sent to your e-mail.</ol>',
  back: 'Back',
  notIndicated: 'Not Selected',
  cantSend_0: 'You have selected {{count}} recipient. We will not be able to send messages to him/her because there are not enough message limits.',
  cantSend_1: 'You have selected {{count}} recipients. We will not be able to send messages to all of them because there are not enough message limits.',
  cantSend_2: 'You have selected {{count}} recipients. We will not be able to send messages to all of them because there are not enough message limits.',
  availableLimits: 'Available Limits:',
  emailLimits: ' There must be {{willBeSent}} emails in this mailing. In order to make a mailing',
  smsLimits: ' There should be {{willBeSent}} SMS in this mailing. In order to make a mailing',
  notEnoughEmail_0: ' is missing {{count}} piece of e-mail in the limits.',
  notEnoughEmail_1: ' is missing {{count}} piece of e-mail in the limits.',
  notEnoughEmail_2: ' is missing {{count}} pieces of e-mail in the limits.',
  notEnoughSms_0: ' lacking {{count}} sms qty in limits.',
  notEnoughSms_1: ' lacking {{count}} sms qty in limits.',
  notEnoughSms_2: ' is missing {{count}} sms pieces in the limits.',
  recomendedBuyLimits: 'If you are satisfied that not all selected users will receive the notification, then click on the \\"Submit\\" button',
  recomendedBuyLimitsLong: 'You can purchase the missing limits so that these users can receive the notification as well.',
  onlyNotRigisteredUsers: 'The list shows only unregistered users to whom you can send an invitation to SQVR',
  useEmail: 'Use e-mail newsletters as an additional way to deliver your message to reach a larger audience',
  useSms: 'Use sms mailing as an additional way to deliver your message for greater audience reach',
  youCan: 'To send messages you can:',
  buyLimits: '1. Supplement the missing limits',
  reduceCount: '2. Reduce the number of recipients',
  continueWithReducingCount: '2. Continue but reduce the number of selected recipients',
  selectEmail: 'Use e-mail as a delivery method',
  selectSms: 'Use sms as a delivery method',
  reduceText: 'Shorten the message itself',
  countOfMessages_0: ' {{count}} qty.',
  countOfMessages_1: ' {{count}} pieces.',
  countOfMessages_2: ' {{count}} pieces.',
  unlimited: ' is unlimited.',
  enough: ' Enough',
  canReceiveEmail: 'Can get an e-mail',
  canReceiveSms: 'Can get a text message',
  cantReceive: "Can't receive either e-mail or SMS",
  cantSendWarning: 'We will not be able to send messages to all selected recipients. There are not enough limits for sending',
  notReceiveInvite: "Can't get notifications",
  needLimit: 'Not enough limits',
  alreadyRegistered: 'Already registered in SQVR',
  disableNotify: 'Disabled notifications',
  spammers: 'Put Square on the spam list',
  notOwners: 'Not the owners',
  noContacts: "There's no e-mail or phone number",
  noEmail: 'No e-mail',
  noPhone: 'No cell phone',
  noLimitsText1: 'We will not be able to send any messages because there are no limits on sending e-mail and SMS.',
  noLimitsText2: 'You can go to the tariffs and purchase limits.',
  limitsForSending: 'Available limits for sending messages:',
  messages_0: ' {{count}} message.',
  messages_1: ' {{count}} messages.',
  messages_2: ' {{count}} messages.',
  notUse: ' (not to be used in the newsletter)',
  alreadyHasTgBot: 'We already have a Telegram bot connected',
  noValidUsersSelected: 'Selected users cannot receive the notification',
  noUsersSelected: 'No recipient selected',
  isInviteInfoPopperText1: '1. First we will try to send a notification to all selected users using only e-mail.',
  isInviteInfoPopperText2: '2. Selected users who do not have e-mail but have a cell phone number will be sent SMS. For this purpose it is necessary to have enough SMS sending limits.',
  isInviteInfoPopperText5: '- We will only send notifications to non-registered users.',
  filterPopperText1: 'Description of filter operation',
  filterPopperText2: '1. When you select one filter, only those rows that satisfy that particular filter are shown in the table.',
  filterPopperText3: '2. If multiple filters are selected, only those rows that simultaneously satisfy all selected filters are shown.',
  filterPopperText4: '3. Note that if you select users first and then apply new filters or reset previously used filters, this will also reset all previously selected users.',
  filterPopperText5: '4. Resetting previously selected rows is done in two ways: by clicking the checkbox next to the row or by two clicks in the checkbox in the table header. The first click on the checkbox in the table header leads to selection of all rows of the visible part of the table, and the second click leads to deselecting all rows of the visible part of the table.',
  telegramInfoPopperText1: '2. If not all selected users have enabled push notifications, then notifications are sent to all users who have registered their e-mail address but have not enabled push notifications.',
  telegramInfoPopperText2: '3. Last of all, SMS mailing is used for users who have not connected any push notifications and do not have a confirmed e-mail address.',
  infoPopperText1: '1. First we will try to send a notification using only push notifications in the mobile app.',
  infoPopperText2: '2. If not all selected users have enabled push notifications, then notifications are then sent to all users who have enabled Telegram-bot but have not enabled push notifications in the mobile app.',
  infoPopperText3: '3. In the third turn, notifications are sent to all users who have confirmed their e-mail, but have not connected either push notifications or Telegram-bot.',
  infoPopperText4: '4. Last of all, SMS mailing is used for users who have not connected either push notifications or Telegram-bot, and who do not have a confirmed e-mail address.',
  infoPopperText5: 'This is to avoid duplicating channels for sending notification, and to minimize costs for the most expensive limits.',
  infoPopperText6: 'Important!!!',
  infoPopperText7: "- We will not send notifications to users who have already connected Telegram-bot, users who are not registered, users who have turned off receiving notifications from the Square service, and users who have put Square on the spammer's list.",
  infoPopperText8: '- We will not immediately send notifications to users who do not have enough e-mail or SMS limits, but you will have the opportunity to purchase the missing limits.',
  infoPopperText9: '- We will not send notifications to unregistered users who have no e-mail or phone number in their profile.',
  infoPopperText10: '- The results of the mailing will be shown in the change history of this page.',
  infoPopperText11: '- The initiator of the mailing will receive an e-mail with a file showing the results of the mailing.',
  total: 'Total:',
  recipientsSelected: 'Recipients selected.',
  youHaveNotCompletedSendingNotification: 'You have not completed sending the notification. Your message and recipient list are saved and will be displayed in the notification window if you send the notification later.'
};