"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  goToEVoting: 'Go to virtual voting',
  goToNormalVoting: 'Go to the paper newsletter',
  scannedCopy: 'Scan copy of the paper bulletin',
  agendaIssues: 'Items on the agenda',
  questionNumber: 'Question number',
  userParticipant: "Participant's decision",
  noOneBulletinFiles: 'There is not a single page of a scanned copy of the ballot',
  pageIsMissing: 'Page {{number}} is missing!',
  voiceHasNotCountedYet: 'The vote has not yet been counted',
  voiceHasNotCounted: 'Vote not counted',
  eVotingIsNotAccepted: 'Electronic voting is not counted in this meeting',
  acceptedByEVoting: 'Vote counted by electronic voting',
  acceptedByPaper: 'Vote counted on paper ballot',
  paperBulletinIsApproved: 'Paper ballot approved.',
  paperBulletinIsApprovedMyBulletin: 'Thank you for voting using a paper ballot.',
  paperBulletinIsNotApproved: 'The paper ballot has not been approved',
  paperBulletinIsNotApprovedMyBulletin: 'Unfortunately, only paper ballots are counted in this meeting and your paper ballot has not yet been approved.',
  userIsVotedByEVoteButInThisMeetingAcceptedOnlyPaperBulletins: 'The member voted electronically, but only paper ballots are counted in this meeting.',
  userIsVotedByEVoteButEVoteIsIgnored: 'The member voted electronically, but electronic voting is not counted in this meeting.',
  userIsVotedByEVoteButEVoteIsIgnoredMyBulletin: 'The member voted electronically, but only paper ballots are counted in this meeting.',
  userIsVotedButEVoteMyBulletin: 'Thank you for voting electronically.',
  userIsNotVotedByEVoteButEVoteIsIgnored: 'The member did not vote electronically, but only paper ballots are counted in this meeting.',
  userIsNotVotedByEVoteButEVoteIsIgnoredMyBulletin: 'You did not vote electronically, but only paper ballots count in this meeting.',
  userIsNotVotedByEVoteButEVoteIsAllowed: 'The participant did not vote electronically. In this meeting, electronic voting is taken into account.',
  userIsNotVotedByEVoteButEVoteIsAllowedMyBulletin1: 'You can vote electronically using the mobile app.',
  userIsNotVotedByEVoteButEVoteIsAllowedMyBulletin2: 'Please note that the paper ballot takes precedence over the electronic ballot, so if the paper ballot is approved, your vote will be counted on it, not the electronic ballot.',
  userIsVotedByEVoteButYouCantChangeEVote: "A participant has voted electronically. You cannot change a participant's electronic vote.",
  userIsVotedByEVoteMyBulletin: 'Thank you for voting electronically.',
  noPaperBulletin: 'There is no paper ballot.',
  noPaperBulletinMyBulletin1: 'Unfortunately, only paper ballots are counted in this meeting. Your paper ballot has not been uploaded. <a data-action=\\"downloadBulletin\\" style=\\"font-weight: 700; cursor: pointer\\">Download your paper ballot</a>, vote, sign and hand it in to the meeting organizers.',
  noPaperBulletinMyBulletin2: 'No paper ballot. <a data-action=\\"downloadBulletin\\" style=\\"font-weight: 700; cursor: pointer\\">Download your paper ballot</a>, vote, sign and turn it in to the meeting organizers.',
  noPaperBulletinMyBulletin3: 'Vote electronically using the mobile app or <a data-action=\\"downloadBulletin\\" style=\\"font-weight: 700; cursor: pointer\\">download your paper ballot</a>, cast <a data-action=\\"downloadBulletin\\" style=\\"font-weight: 700; cursor: pointer\\">your</a> vote, sign it and turn it in to the caucus organizers.',
  userIsVotedByEVoteButBulletinVoteIsPriority: 'The participant voted electronically, but the paper ballot takes precedence over the electronic ballot. Therefore, the results of the vote are counted according to the paper ballot.',
  userIsVotedByEVoteButBulletinVoteIsPriorityMyBulletin: 'You voted electronically, but the paper ballot takes precedence over the electronic ballot. Therefore, the results of the vote are counted according to the paper ballot.',
  helperText1: 'If you have any questions, please contact',
  helperText2: 'meetings.',
  toPresident: 'the meeting Chairman',
  toSecretary: ' the secretary',
  userIsNotVotedByEVote: 'The participant did not vote electronically.',
  userIsNotVotedByEVoteMyBulletin: "Even if you vote electronically, it won't change the outcome of the election because the paper ballot takes precedence over electronic voting.",
  userIsVotedByEVote: 'The participant voted electronically.',
  yes: 'For',
  yesForProjectNumber: 'For the {{number}}th project',
  against: 'Opposed',
  againstAll: 'Against all',
  abstained: 'Abstained',
  abstainedItem: 'Abstain',
  spoiled: 'Spoiled',
  notVoted: 'Unvoted',
  maximumCandidates_0: 'Only {{count}} candidate can be selected in this ballot',
  maximumCandidates_1: 'Only {{count}} candidates can be selected in this ballot',
  maximumCandidates_2: 'Only {{count}} candidates can be selected in this ballot',
  recomended: 'The manager recommends',
  notRecomended: 'The manager does not recommend',
  select: 'Select',
  selectMore: 'Choose more',
  candidates_0: 'candidate',
  candidates_1: 'candidates',
  candidates_2: 'candidates',
  cantSelectMore: 'Candidates can no longer be chosen',
  selectButton: 'Select',
  previousParticipant: 'Previous participant',
  nextParticipant: 'Next participant',
  votingEnded: "Voting is complete. Can't be changed.",
  cantVoteHasNoBulletin: "You can't choose solutions because there is no scan copy of the ballot.",
  clickForEdit: 'Click on the "Edit" button to change the decision.',
  clickToMoreMenuForEdit: 'To change a decision, click the additional menu <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">' + '<path d="M7.67969 6C7.9449 6 8.19926 6.10536 8.38679 6.29289C8.57433 6.48043 8.67969 6.73478 8.67969 7C8.67969 7.26522 8.57433 7.51957 8.38679 7.70711C8.19926 7.89464 7.9449 8 7.67969 8C7.41447 8 7.16012 7.89464 6.97258 7.70711C6.78504 7.51957 6.67969 7.26522 6.67969 7C6.67969 6.73478 6.78504 6.48043 6.97258 6.29289C7.16012 6.10536 7.41447 6 7.67969 6ZM7.67969 3C7.9449 3 8.19926 3.10536 8.38679 3.29289C8.57433 3.48043 8.67969 3.73478 8.67969 4C8.67969 4.26522 8.57433 4.51957 8.38679 4.70711C8.19926 4.89464 7.9449 5 7.67969 5C7.41447 5 7.16012 4.89464 6.97258 4.70711C6.78504 4.51957 6.67969 4.26522 6.67969 4C6.67969 3.73478 6.78504 3.48043 6.97258 3.29289C7.16012 3.10536 7.41447 3 7.67969 3ZM7.67969 0C7.9449 0 8.19926 0.105357 8.38679 0.292893C8.57433 0.48043 8.67969 0.734784 8.67969 1C8.67969 1.26522 8.57433 1.51957 8.38679 1.70711C8.19926 1.89464 7.9449 2 7.67969 2C7.41447 2 7.16012 1.89464 6.97258 1.70711C6.78504 1.51957 6.67969 1.26522 6.67969 1C6.67969 0.734784 6.78504 0.48043 6.97258 0.292893C7.16012 0.105357 7.41447 0 7.67969 0Z" fill="#263238"/><path d="M1.74 6.28795L0 4.01995L1.74 1.75195H2.532L0.828 4.01995L2.532 6.28795H1.74ZM3.876 6.28795L2.136 4.01995L3.876 1.75195H4.668L2.964 4.01995L4.668 6.28795H3.876Z" fill="#263238"/>' + '<path d="M11.4717 6.28795H10.6797L12.3837 4.01995L10.6797 1.75195H11.4717L13.2117 4.01995L11.4717 6.28795ZM13.6077 6.28795H12.8157L14.5197 4.01995L12.8157 1.75195H13.6077L15.3477 4.01995L13.6077 6.28795Z" fill="#263238"/> + ' + '</svg>' + ' in the upper right corner and select "Edit Bulletin".',
  clickHereForEdit: 'Click here to change your decision.',
  canEVoteOnlyMobileApp: 'You can only vote electronically on the mobile app.',
  canEVoteOnlyParticipant: 'Only the participant of the meeting can vote electronically.',
  eVoteIgnored: 'Voted electronically. Not counted in the meeting results because only paper ballots are counted in this meeting.',
  eVoteNothingIgnored: 'Not voted electronically. Not counted in the meeting results because only paper ballots are counted in this meeting.',
  eVoteAcceptedAsMinor: 'Voted electronically. Not counted in the outcome of the meeting because there is an approved paper ballot.',
  canEditEvoteButIgnored: 'E-voting can only be edited via the mobile app.</br></br>Unfortunately, e-voting is not counted in this meeting.',
  canEditEvoteButHasPaperBulletin: 'You can only edit the electronic ballot through the mobile app.</br></br>Since there is already an approved paper ballot, decisions will be counted on the paper ballot, not the electronic ballot.',
  saveAndSubmitForApproval: 'To retain and bring forward for approval',
  submitForApproval: 'Submit for approval',
  cancelEdit: 'Undo Editing',
  confirmThereIsAuthenticBulletin: 'I confirm that there is an authentic ballot',
  chooseSolutionForAllIssues: 'To place a ballot on the ballot for approval, select decisions on all agenda items. There are still issues that are marked "Not Voted".',
  confirmThereIsAuthenticBulletinTooltipText: 'Confirm that you are in possession of an authentic ballot paper. Highlight the checkbox on the left.',
  submit: 'Submit',
  edit: 'Edit',
  informationAboutTrustedPerson: 'Information about the trustee',
  copyLink: 'Copy link',
  linkCopied: 'Link copied to clipboard',
  editBulletin: 'Edit the bulletin',
  downloadScanCopie: 'Download a scan of the bulletin',
  cantDownloadScanCopie: "Can't download. No scan copy of the bulletin.",
  deleteBulletin: 'Delete the ballot',
  delete: 'remove',
  cancel: 'undo Changes',
  requiredComment: 'Obligatory comment',
  withoutCommentCantDelete_0: 'Explain your decision to the rest of the users. The file will not be deleted without a comment.',
  withoutCommentCantDelete_1: 'Explain your decision to the other users. Files will not be deleted without a comment.',
  withoutCommentCantDelete_2: 'Explain your decision to the other users. Files will not be deleted without a comment.',
  cancelAutomaticRecognition_0: 'Deleting the file will cause the automatic bulletin recognition results to be canceled.',
  cancelAutomaticRecognition_1: 'Deleting files will cause the results of automatic bulletin recognition to be canceled.',
  cancelAutomaticRecognition_2: 'Deleting files will cause the results of automatic bulletin recognition to be canceled.',
  clickToDeleteButton_0: 'If you really want to delete the file, click on the "Delete" button.',
  clickToDeleteButton_1: 'If you really want to delete the files, click on the "Delete" button.',
  clickToDeleteButton_2: 'If you really want to delete the files, click on the "Delete" button.',
  afterDeleteFilesTransfered_0: 'The file to be deleted will be moved to the Unrecognized Documents tab. ',
  afterDeleteFilesTransfered_1: 'The deleted files will be moved to the Unrecognized Documents tab. ',
  afterDeleteFilesTransfered_2: 'The deleted files will be moved to the Unrecognized Documents tab. ',
  deleteVoting: "Deleting a ballot will result in the deletion of the member's voting results.",
  presidentAndSecretaryHasEmail: 'A participant in the meeting, if registered in the Sqvr service, the Chairman and the Secretary will receive automatic notifications of this.',
  afterDeleteBulletin_0: 'Deleting the file will cause the ballot status to change to "Pending Processing" and reset all ballot results.',
  afterDeleteBulletin_1: 'Deleting files will cause the ballot status to change to "Pending Processing" and reset all ballot results.',
  afterDeleteBulletin_2: 'Deleting files will cause the ballot status to change to "Pending Processing" and reset all ballot results.',
  protocolPublishing: 'Cannot be deleted. The minutes from the meeting are published.',
  canEditProtocolPublishing: 'Cannot be edited. The minutes from the meeting are published.',
  cantDeleteMeetinEnded: "Can't be deleted. Meeting adjourned.",
  cantEditMeetinEnded: "Can't edit. The meeting has ended.",
  cantDeleteStatusNothing: "Can't be deleted. No scan copy of the bulletin.",
  cantEditStatusNothing: 'No editing. No scan copy of the bulletin.',
  allFilesDeleted: 'All scanned copies of the newsletter pages have been moved to the "Unrecognized Documents" tab',
  waitingForApprovalFromPresident: 'The ballot is awaiting the approval of the President',
  waitingForApprovalFromSecretary: "The ballot is awaiting the Secretary's approval",
  waitingForApproval: 'The ballot is awaiting the approval of both the Chair and the Secretary',
  bulletinApproved: 'The bulletin was approved',
  sendToTelegram: 'PDF file with scanned copies of the bulletin sent to Telegram-bot',
  sendToEmailOrTelegramMyBulletin: 'Your bulletin has been sent. Check your Telegram-bot or e-mail {{email}}. ',
  sendToTelegramMyBulletin: 'Your ballot has been sent. Check your Telegram-bot. ',
  sendToEmailMyBulletin: 'Your bulletin has been sent. Check your e-mail {{email}}. ',
  sendToEmailOrTelegram: 'The archive with scanned copies of selected pages of the bulletin has been sent. Check your Telegram-bot or e-mail {{email}}. ',
  sendToEmail: 'The archive with scanned copies of selected pages of the newsletter has been sent. Check your e-mail {{email}}. ',
  sentToTelegram: 'The archive with scanned copies of the bulletin has been sent. Check your Telegram-bot. ',
  cantDownloadBulletinText1: 'We only send the newsletter via email or Telegram messenger. Unfortunately, these are not yet available in your profile.',
  cantDownloadBulletinText2: 'Choose whichever way is more convenient for you:',
  cantDownloadBulletinText3: '1. Connect ',
  cantDownloadBulletinText4: '2. Connect ',
  bulletinWasAccepted: 'The ballot has already been approved by both the Chairman and the Secretary. The participant has been informed about it.',
  ifEditBulletin: "Editing the ballot will result in the cancellation of the participant's vote. The Chair and Secretary will have to re-approve the ballot.",
  userHasEmail: 'A voter, if registered for Sqvr service, will receive automatic notification of changes to their paper ballot.',
  clickToEditButton: 'If you really want to edit the ballot, click on the "Edit" button. After that, the edit mode will be enabled and you will be able to change the results of the paper ballot.',
  bulletinWasProcessed: 'The ballot has already been previously processed.',
  allBulletinNeedToAccept: 'Each paper ballot must be approved by both the Chair and the Secretary of the meeting.',
  editingModeStart: "Edit mode is enabled. Now you can change the participant's decisions.",
  youHaveEditingMode: 'You are already in edit mode.',
  forStartEditingMode: 'Click to activate the voting results editing mode.',
  stopEditing: 'Undo Editing',
  youWantToStopEdit: "Do you really want to undo the changes you've made?",
  allDataHasBeenReturned: 'All data entered will be lost.',
  continueWithoutSaving: 'Continue without saving',
  exitWithoutSaving: 'Are you sure you want to exit without saving your information?<br><br>All data entered will be lost.',
  isNotAllPagesInBulletinDescriptionText1_0: 'Page {{pageNums}} of a scan copy of the bulletin is missing.',
  isNotAllPagesInBulletinDescriptionText1_1: 'Pages {{pageNums}} of the scan copy of the bulletin are missing.',
  isNotAllPagesInBulletinDescriptionText1_2: 'Pages {{pageNums}} of the scan copy of the bulletin are missing.',
  isNotAllPagesInBulletinDescriptionTextForRole2: 'Are you sure you want to approve such a ballot? If yes, leave a mandatory comment and explain your decision.',
  isNotAllPagesInBulletinDescriptionText2: 'Are you sure you want to put such a ballot on the ballot for approval? If yes, leave a mandatory comment and explain your decision.',
  isNotAllPagesInBulletinDescriptionText3: ' This comment is mandatory and will be visible to the meeting participant, the Chair and the Secretary of the meeting.',
  isNotAllPagesInBulletinDescriptionText4ForRole: 'It is not possible to approve such a ballot without comment.',
  isNotAllPagesInBulletinDescriptionText4: 'It is not possible to approve such a ballot without comment.',
  makeChangesToVotingResultsForApproval: 'You have not made any changes, so there is nothing to save.',
  normalVoteResult: 'The result of voting by paper ballot.',
  eVoteResult: 'E-voting result.',
  pageScans: 'A scan of the bulletin pages',
  downloadMyBulletin: 'Download your bulletin',
  startVoting: 'Start voting',
  goToApp: 'Go to the app',
  downloadMyBulletinTooltip: 'Depending on the stage of the meeting and the actions of the organizers, you will receive an archive that may contain the following documents:<br/><br/><ol><li>A named paper ballot form</li><li>Scan copies of the pages of your paper ballot that are now in your voting card</li><li>After the conclusion of the meeting, provided you voted electronically, a PDF file with the Sqvr service marking the results of your electronic vote</li></ol>',
  cantDownloadMyBulletinTooltip: '<ol><li>You did not vote electronically and</li><li>Your paper ballot was not uploaded to the Sqvr service. </li></ol> <br/>You have nothing to download.',
  voting: 'Voting in progress',
  votingInApp: 'vote in the app',
  canEVoteOnlyMobileAppModal: 'You can vote electronically, view details about e-voting and paper ballot only on the mobile app.',
  canViewInfoOnlyMobilaAppModal: 'View detailed information about e-voting and paper ballot only on the mobile app.',
  ifYouDontHaveMobileApp: "If you don't have the mobile app installed, you can download it using one of the buttons below.",
  youCanDownloadBulletin: 'If you do not wish to vote via the mobile app, you can download a paper ballot, fill it out, sign it, and turn it in to the meeting organizers prior to the ',
  fileWasRemoved: 'The file has been deleted. See details in the change history.',
  noPage: 'There is no bulletin page. Documents can be uploaded in the Unrecognized Documents tab.',
  page: 'pg.',
  formReset: 'The changes have been canceled. The bulletin is in the same form and status as it was before the edit.',
  whyYouEditBulletin: 'Explain the reason why you have decided to edit the ballot so that both the meeting participant, the Secretary and the Chair understand why you are making the change. ',
  mandatoryComment: 'Without a mandatory comment, the changes made will not be saved.',
  changesAdded: 'Changes have been made. ',
  selectFiles: 'Select files to be sent to you by E-mail or Telegram-bot.',
  noUploadedScans: 'There are no scanned copies of the bulletin uploaded. Nothing can be done with the scans of pages.',
  allScansAreRemoved: 'All scans of the newsletter pages have been removed. Nothing can be done with the scans of the pages.',
  selectFilesForRemoval: 'Select the files to be deleted.',
  filesRemoved_0: 'The scan of the bulletin page has been successfully deleted',
  filesRemoved_1: 'Scans of the bulletin pages have been successfully deleted',
  filesRemoved_2: 'Scans of the bulletin pages have been successfully deleted',
  viewScans: 'Click to view scans of the newsletter pages ',
  noAppeals: 'No tips',
  showAppeals: 'Show tips',
  hideAppeals: 'Hide tips',
  addEmail: 'Add your current Email and click on the "SAVE" button at the top.',
  confirmEmail: 'Click on the "Confirm" button and a link will be sent to your e-mail address. Click on the link in the message and we will verify that it is your e-mail address. After that, you will be able to return to the meeting.',
  connectTelegram: 'Click on the "Connect Telegram-bot" button, then follow the instructions in Telegram-bot and connect it. After that, you will be able to return to the meeting.',
  damagedTooltip: 'Select "Spoiled" if you cannot unambiguously choose a user decision. For example, no check marks at all, simultaneous check marks in several check boxes, or it is not clear where exactly a check mark was made.',
  rotatePhoneWarningText: 'You would like to view the voting card. To easily process the information and view the voting card, either flip the screen to the horizontal orientation or go to the Sqvr system version on your laptop or computer (or any other large screen).',
  rotatePhoneWarningTitle: 'Viewing the voting card',
  exitEditMode: 'You are now in bulletin edit mode.<br><br>You must exit edit mode before you can proceed to deleting the file(s).<br><br>Are you sure you want to exit edit mode without saving your edits?',
  cantMettingAllow: 'You are not allowed to vote in the meeting.',
  availableIn: 'Available in',
  downloadedIn: 'Downloaded in',
  cantDownloadBulletinText5: '3. Download the mobile application:'
};