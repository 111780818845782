"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  checkCompare: 'Does not match the field - %FIELD_NAME%',
  checkCompareWithIndex: 'Does not match the index value %INDEX%',
  checkNotCompareWithIndex: 'Must not match the value at index %INDEX%',
  phone: 'Enter a valid phone number',
  email: 'Enter Email',
  minValue: 'The value must not be less than %VALUE%',
  maxValue: 'The value must be less than %VALUE%',
  lessOrEqualValue: 'The value must not be greater than %VALUE%',
  checkLetters: 'The string contains forbidden characters',
  onlyRussianLetters: 'The field can contain only Cyrillic letters',
  onlyLatinLetters: 'The field can only contain Latin letters',
  userNameLetters: "The field can contain only Cyrillic letters, spaces and symbols -.,;_'",
  onlyNumbers: 'The field can only contain numbers',
  undefinedRule: 'Error - Unknown rule',
  empty: 'Error - Value is empty',
  undefinedFunction: 'Function not found',
  bik: 'The value of this field is not BIC',
  inn: 'TIN of a legal entity has an incorrect format, the field value should be 10 characters long.',
  innFull: 'The TIN is not in the correct format.',
  snils: 'The value of this field is not SNILS',
  ogrn: 'The value of this field is not OGRN',
  cardNumber: 'Enter a valid card number',
  cvv: 'Enter a valid CVV',
  month: 'Enter a valid month',
  year: 'Enter the actual year',
  date: 'Incorrect date value',
  emailExistInHoa: 'Enter another email. This one cannot be reused.',
  phoneExistInHoa: 'Enter a previously unused phone number',
  dataIsExists_phone: 'This phone number is already in use at your facility',
  dataIsExists_email: 'This e-mail address is already used in your facility',
  minPasswordLength_0: 'The password must consist of at least {{count}} character',
  minPasswordLength_1: 'The password must be at least {{count}} characters long',
  minPasswordLength_2: 'The password must be at least {{count}} characters long',
  duplicatedEmail: 'You have already entered this email',
  duplicatedPhone: 'You have already entered this phone number',
  passwordConfirmError: "Password doesn't match",
  phoneUsed: 'This phone is already taken',
  phoneFormatHint: 'Enter phone number in the format: +19112223344',
  required: 'Required Field',
  invalidCardNumber: 'Incorrect card number',
  invalidCardHolder: 'Incorrect name',
  cardHasExpired: 'Expired',
  wrongCode: 'Incorrect code',
  wrongChars: 'Invalid characters',
  nickname: 'Russian, Latin letters, space or dashes only',
  minLength_0: 'Minimum {{count}} character.',
  minLength_1: 'Minimum {{count}} character.',
  minLength_2: 'Minimum {{count}} characters.',
  maxLength_0: 'Maximum {{count}} character.',
  maxLength_1: 'Maximum {{count}} characters.',
  maxLength_2: 'Maximum {{count}} characters.',
  remainingNumCharacters: 'Remaining {{count}}',
  minTextLength_0: 'Enter a minimum of {{count}} character.',
  minTextLength_1: 'Enter a minimum of {{count}} characters.',
  minTextLength_2: 'Enter a minimum of {{count}} characters.',
  maxTextLength: 'You have exceeded the limit, delete the extra characters.',
  extraCharacters: 'Extra {{count}}',
  enterValidMobilePhone: 'Enter a valid mobile phone number',
  enterValidPhone: 'Enter a valid phone number'
};