"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  joiningRequests: 'Applications to join',
  legendTitleNoRequests: "You have no new applications to join. Once you have applications, here's what you need to do:",
  legendTitlePart1: 'Total available',
  legendTitlePart2_0: "application to join. Here's what you need to do:",
  legendTitlePart2_1: "applications to join. Here's what you need to do:",
  legendTitlePart2_2: "applications to join. Here's what you need to do:",
  legendItem1Part1: 'Select applications (use the checkboxes',
  legendItem1Part2: ') that you want to accept and click on "Accept Applications".',
  legendItem2: 'All non-consistent applications will be automatically approved.',
  legendItem3: 'Applications that contradict the registry data will be highlighted as blue lines and the fields with errors will be underlined. You can hover your cursor over these fields and see a tooltip describing the error. Edit the register lines to remove the contradictions.',
  legendItem4: 'As soon as the data in the application (blue line) become consistent with the data in the register, the line will change its color and the application will be accepted.',
  legendItem5: 'Once the application is approved, the owner will be notified by e-mail.',
  legendItem6: 'Deleting or clearing blue lines will result in rejected applications. They will be deleted.',
  acceptRequests: 'Accept applications',
  rejectRequests: 'Reject applications',
  reject: 'Reject',
  owners: 'Owners',
  apartment: 'Apt.',
  requestsAcceptedSuccessfully_0: 'The application to join {{count}} pcs. has been successfully accepted. Now a notification will be automatically sent to the e-mail address or cell phone of the owner with a proposal to confirm his e-mail address or phone number. If some user did not receive or did not respond to the notification, you have the opportunity to send the same notification to this owner from his profile by clicking on the "Send invitation code" button.',
  requestsAcceptedSuccessfully_1: 'Connection requests {{count}} pcs. have been successfully accepted. Now notifications will be automatically sent to the e-mail addresses or cell phones of the owners with the offer to confirm their e-mail address or phone number. If some user did not receive or did not respond to the notification, you have the opportunity to send the same notification to this owner from his profile by clicking on the "Send invitation code" button.',
  requestsRejectedSuccessfully_0: '{{count}} application for accession successfully rejected',
  requestsRejectedSuccessfully_1: '{{count}} applications for accession successfully rejected',
  requestsRejectedSuccessfully_2: '{{count}} applications for affiliation successfully rejected',
  requestsAcceptedWithConflicts_0: '{{count}} application requires manual processing',
  requestsAcceptedWithConflicts_1: '{{count}} applications require manual processing',
  requestsAcceptedWithConflicts_2: '{{count}} applications require manual processing',
  rejectRequestsConfirmationTitle_0: 'You are about to reject {{count}} application',
  rejectRequestsConfirmationTitle_1: "You're going to reject {{count}} applications",
  rejectRequestsConfirmationTitle_2: "You're going to reject {{count}} applications",
  rejectionCommentPlaceholder: 'Comment on the cause of the deviation',
  buttonsDisabledText: 'Select the required applications'
};