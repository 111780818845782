"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  mixed: {
    default: 'Incorrect value',
    required: 'Required Field',
    oneOf: 'Unavailable option',
    notOneOf: 'Inadmissible value'
  },
  string: {
    length_0: 'The field must contain {{count}} character',
    length_1: 'The field must contain {{count}} characters',
    length_2: 'The field must contain {{count}} characters',
    min: 'Min. number of characters: ${min}',
    max: 'Max. number of characters: ${max}',
    matches: 'Doesn\'t fit the mask: "${regex}"',
    email: 'An invalid e-mail address has been entered',
    url: 'Incorrect URL',
    trim: 'The field must not start and end with spaces',
    lowercase: 'The field must be in lower case',
    uppercase: 'The field must be in upper case'
  },
  number: {
    min: 'The value must be less than or equal to ${min}',
    max: 'The value must be greater than or equal to ${max}',
    lessThan: 'The value must be strictly less than ${less}',
    moreThan: 'The value must be strictly greater than ${more}',
    positive: 'The value must be positive',
    negative: 'The value must be negative',
    integer: 'The value must be an integer'
  },
  date: {
    min: 'The date should be later than {{date}}',
    max: 'Date must be earlier than {{date}}'
  },
  object: {
    noUnknown: 'The object must not contain unnecessary fields'
  },
  array: {
    min_0: 'It is necessary to specify min. {{count}} element',
    min_1: 'It is necessary to specify min. {{count}} elements',
    min_2: 'It is necessary to specify min. {{count}} elements',
    max_0: 'Only one element needs to be specified',
    max_1: 'It is necessary to specify max. {{count}} element',
    max_2: 'It is necessary to specify max. {{count}} elements'
  }
};