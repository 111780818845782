"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  searchType: 'Тип поиска',
  addressType: 'по адресу',
  hoaType: 'по названию',
  zip: 'Индекс',
  region: 'Субъект Федерации',
  city: 'Город/населенный пункт',
  settlement: 'Район',
  streetType: 'Тип улицы',
  street: 'Название улицы',
  house: 'Номер дома',
  block: 'Корпус',
  unitType: 'Тип',
  info: 'Доп. информация',
  address: 'Адрес',
  withSocialNetworks: 'либо через социальные сети',
  withEmail: 'или через e-mail',
  forgotPassword: 'забыли пароль?',
  newUserRegistration: 'Регистрация'
};