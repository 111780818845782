"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  userCanNotBeAdd: 'Для добавления собственника в данное помещение, необходимо сначала уменьшить долю' + ' владения существующего/их собственника/ов. Освободить площадь для нового собственника',
  userIsRegisteredCanNotEditPersonType: 'Нельзя менять тип собственника у зарегистрированного пользователя',
  userIsRegisteredCanNotEditContacts: 'Нельзя менять контакты у зарегистрированного пользователя',
  forChangeGoToUserCard: 'Вы можете изменить свой {{fieldType}} в  профиле пользователя',
  phoneNumber: 'номер телефона',
  email: 'e-mail',
  userIsRegisteredCanNotEditName: 'Нельзя редактировать ФИО зарегистрированного пользователя',
  forChangeNameGoToUserCard: 'Вы можете изменить ФИО в своем профиле пользователя'
};