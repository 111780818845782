"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  userCanNotBeAdd: 'To add an owner to the space, you must first reduce the ownership share of the existing owner(s). To free up the space for the new owner',
  userIsRegisteredCanNotEditPersonType: 'You cannot change the owner type of a registered user',
  userIsRegisteredCanNotEditContacts: 'You cannot change the contacts of a registered user',
  forChangeGoToUserCard: 'You can change your {{fieldType}} in your user profile',
  phoneNumber: 'phone number',
  email: 'e-mail',
  userIsRegisteredCanNotEditName: 'You cannot edit the name of a registered user',
  forChangeNameGoToUserCard: 'You can change your full name in your user profile'
};