"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Выберите объект, в который хотите войти',
  selectPlaceholder: 'Начните вводить название объекта',
  loadingMessage: 'Загрузка...',
  nothingFound: 'Нет совпадений'
};