"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  accept: 'Я принимаю',
  rules: 'условия использования',
  and: 'и',
  policy: 'политику конфиденциальности'
};