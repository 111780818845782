"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Внимание!',
  modalContent: 'С 01 марта 2023 года ФГИС ЕГРН (Росреестр) прекратил выдавать выписки по помещениям с указанием ФИО собственников по запросам третьих лиц. Это означает, что теперь получить выписку с ФИО может только сам собственник или его законный представитель.Теперь мы можем сформировать реестр собственников с долями владения, но без ФИО. Если Вы хотите получить такой реестр нажмите «ПРОДОЛЖИТЬ» или не заказывайте реестр и вернитесь «НАЗАД».',
  modalYes: 'ПРОДОЛЖИТЬ',
  modalNo: 'НАЗАД'
};