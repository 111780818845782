"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  delete: 'Delete the poll?',
  cancel: 'The poll will be moved to the archive',
  results: 'You can now view the results of the poll. You have the option to change your vote to {{date}}.',
  voteWarning: 'Choose to answer all the questions and then your vote will be counted.',
  pollsAuthor: 'Survey author',
  pollDateCreate: 'Date the survey was created',
  pollStatus: 'Survey Status',
  daysToEndPoll: 'Days left to complete the survey',
  alreadyVoted: 'Already voted on the poll',
  createNewPoll: 'Create a new poll',
  selectFromTemplate: 'Select from template',
  deleteDraft: 'The draft poll will be deleted.',
  copyPoll: 'Copy the current poll?',
  changeCopyDraft: 'You have a draft of another survey. If you make a copy from the current survey, your draft will be replaced.',
  deleteDraftPoll: 'Creating a new survey will delete an existing draft survey',
  publishPoll: 'Publication of the poll',
  afterPublishNoEdit: 'Once the poll is published, you will not be able to make changes to it.',
  pollPublish: 'The survey has been published',
  pollShareLink: 'You can now share the survey via a link.',
  goToPoll: 'go to the poll',
  noPollsWithParameters: 'No surveys matching the selected parameters were found',
  noPollsCreated: 'No polls have been created yet',
  finishPollEditing: 'Finish editing the survey in the draft',
  chooseSeveralOptions: 'Select one or more options',
  chooseSeveralOptionsOrInputYour: 'Choose one or more options, or type in your own',
  markOneToTen: 'Score from 1 to 10, where 1 is the worst and 10 is the highest score',
  pleaseVote: 'Please vote in the poll.',
  pollResults: 'Survey results',
  pollAlreadyVoted: 'You have already voted in this poll',
  ownerVoting: 'Only owners can participate in voting',
  pollPost: 'Post a poll?',
  cannotChangeAfterPublishing: 'Once the survey is published, you will not be able to make changes.',
  noPoll: 'There is no such survey',
  changeDecision: 'You have already voted. If you want to change your decisions, select',
  downloadPollResults: 'Download the results of the survey',
  unreachablePollResults: 'Poll results unavailable - no votes counted',
  chooseSuitableTemplate: 'Choose the right template for your survey',
  search: 'Filters and poll search',
  pollIsFinishedTooltip: "The poll is closed. You can't vote.",
  selectQuestionOption: 'One of the options',
  pollsTemplates: 'poll templates',
  takeOneOfAvailableOnes: 'Take one of the available ones',
  checkboxQuestionOption: 'Multiple choice',
  usePreviouslyCreatedPollsPart2: 'and make a copy.',
  integerRangeQuestionOption: 'Evaluate from 1 to 10',
  usePreviouslyCreatedPollsPart1: 'Use for creating a new poll previously created polls? Find the one you need, click on'
};