"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  slideTitle1: 'House and settlement management system',
  slideText1: 'We do service so that owners and management companies find a common language and the quality of life improves',
  slideTitle2: 'Control the budget',
  slideSubtitle2: 'Sqvr Allows:',
  slideText2Line1: 'Understand what the funds in the house are being spent on',
  slideText2Line2: 'Participate in the preparation and adoption of the budget',
  slideText2Line3: 'Check compliance of actual expenses with the plan and analyze deviations',
  slideTitle3: 'Complaints',
  slideText3Line1: 'Sqvr has implemented a complaints mechanism that allows management companies to more quickly process appeals from homeowners and residents, as well as to analyze the performance of their own employees.',
  slideText3Line2: 'For residents, this functionality allows them to promptly solve problems and not waste time. It is always possible to see who? what? and when? has done on a complaint.'
};