"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  order: 'Order',
  orderRegistry: 'Order the register',
  orderRegistrySqvr_notVip: '$t(modules.app.payment.orderRegistry) in Sqvr without fixes',
  orderRegistrySqvr_vip: '$t(modules.app.payment.orderRegistry) in Sqvr with check for backtracking and errors',
  orderRegistryDescription_vip: 'Extract of the register of owners in Sqvr with a check for backdating and errors for the apartment building at the address:',
  orderRegistryDescription_notVip: 'Extract of the register of owners for the apartment building as it will be formed by the Rosreestr at the address:',
  orderRegistryDescription_emptyType: 'Extract of the register of owners for the apartment building at:',
  orderRegistrySecondaryDescription: 'Download and fill out the application for the registry. Give the application to the management organization',
  download: 'Download',
  downloadStatement: '$t(modules.app.payment.download) statement',
  apply: 'Submit',
  applyStatement: '$t(modules.app.payment.apply) statement',
  free: 'Free',
  noWarranties: 'No warranties',
  submitAStatement: 'Provide an application to the management organization',
  statementRosreestr: 'Extract from the registry',
  statementRosreestrWithCheckData: '$t(modules.app.payment.statementRosreestr) with data validation',
  orderTotalCost: 'Full Value of the Order:',
  currencyValue_0: '{{count}} dollar',
  currencyValue_1: '{{count}} dollars',
  currencyValue_2: '{{count}} dollars',
  cost: 'Cost:',
  dataValidity: 'Data Reliability:',
  days_0: '{{count}} day',
  days_1: '{{count}} of the day',
  days_2: '{{count}} days',
  unitCost_vip: '1 dollar/1 square meter.',
  unitCost_notVip: '20 cents/1 square meter.',
  fullRosreestrOrderText_vip: "We will order the register from Rosreestr, verify the data, eliminate errors and backdating. Term - $t(modules.app.payment.days, {'count': {{days}} })",
  fullRosreestrOrderText_notVip: "We will order and provide the register as it will be generated by Rosreestr. Term - $t(modules.app.payment.days, {'count': {{days}} })",
  editOrder: 'Change order',
  proceedToCheckout: 'Proceed to payment',
  toPay: 'pay now',
  customer: 'Customer',
  delivery: 'Delivery',
  email: 'E-mail:',
  executionPeriod: 'Due Date:',
  proceed: 'Continue',
  cardData: 'Card data',
  cardDataDescription: 'Enter your bank card details for payment:',
  policyDescription: 'By clicking on the pay button, you accept our',
  policy_termsAndConditions: 'Terms of use',
  policy_privatePolicy: 'Privacy policy',
  policy_refund: 'Refund policy'
};