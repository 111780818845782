"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  linkToSurvey: 'Ссылка на опрос',
  selectOwner: 'Выберите собственника',
  searchUser: 'Поиск пользователя',
  selectAll: 'Выбрать всех',
  street: 'Улица',
  embankment: 'Набережная',
  avenue: 'Проспект',
  boulevard: 'Бульвар',
  lane: 'Переулок',
  deadEnd: 'Тупик',
  square: 'Площадь',
  directions: 'Проезд',
  tract: 'Тракт',
  alley: 'Аллея',
  highway: 'Шоссе',
  row: 'Ряд',
  line: 'Линия',
  user_0: 'пользователь',
  user_1: 'пользователя',
  user_2: 'пользователей',
  registryRow_0: 'строка',
  registryRow_1: 'строки',
  registryRow_2: 'строк',
  registryCol_0: 'столбец',
  registryCol_1: 'столбцы',
  registryCol_2: 'столбцов',
  registryChoose_0: 'Выбрана',
  registryChoose_1: 'Выбрано',
  registryChoose_2: 'Выбрано',
  registryChooseCols_0: 'Выбран',
  registryChooseCols_1: 'Выбрано',
  registryChooseCols_2: 'Выбрано',
  filtersChoose_0: 'Выбран',
  filtersChoose_1: 'Выбрано',
  filtersChoose_2: 'Выбрано',
  filterRow_0: 'фильтр',
  filterRow_1: 'фильтра',
  filterRow_2: 'фильтров',
  rowsAfterAction_0: 'строку',
  rowsAfterAction_1: 'строки',
  rowsAfterAction_2: 'строк',
  rowsChoose_0: 'строки',
  rowsChoose_1: 'строк',
  rowsChoose_2: 'строк',
  rowsRelate_0: 'относится',
  rowsRelate_1: 'относятся',
  rowsRelate_2: 'относятся'
};