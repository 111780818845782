"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  phone: 'Введите Ваш номер телефона. Нам нужно сохранить его для того чтобы при электронном голосовании в собрании Вы могли использовать двухфакторную авторизацию и подтверждать свое голосование простой электронной подписью ПЭП.',
  email: 'Введите также Ваш адрес электронной почты. Тогда Вы сможете получать сообщения обо всех событиях в доме или посёлке и уменьшите затраты на отправку смс.',
  password: 'Придумайте пароль.',
  passwordConfirm: 'Повторите пароль, чтобы избежать ошибки.'
};