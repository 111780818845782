"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Important!',
  modalContent: 'Since March 01, 2023, FGIS USRN (Rosreestr) has stopped issuing extracts for premises with the full name of the owners at the request of third parties. This means that now only the owner himself or his legal representative can receive an extract with the full name. Now we can form a register of owners with ownership shares, but without full name. If you would like to receive such a register click "CONTINUE" or do not order the register and return "BACK".',
  modalYes: 'CONTINUE',
  modalNo: 'BACK'
};