"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  address: 'Адрес собственности',
  share: 'Доля',
  entrance: 'Подъезд',
  floor: 'Этаж',
  ownershipType: 'Тип собственности',
  documents: 'Документы, подтверждающие право собственности:',
  documentsName: 'Вид документа',
  documentsNumber: 'Номер документа',
  documentsDate: 'Дата выдачи',
  owners: 'Собственники в помещении',
  deleteOwnership: 'Удалить собственность',
  living_town: 'Жилое помещение',
  living_village: 'Жилое строение',
  noLiving_town: 'Нежилое помещение',
  noLiving_village: 'Нежилое строение',
  house: 'Дом',
  land: 'Земельный участок',
  townhouse: 'Квартира в таунхаусе',
  room: 'Комната в ком. квартире',
  unknown: 'Неизвестно',
  other: 'Другое',
  owner: 'Собственник'
};