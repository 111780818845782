"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  useMailAndPass: 'Или используйте E-mail и пароль',
  enterWithoutPass: 'Войти без пароля',
  noAccount: 'Нет аккаунта?',
  registrationCode: 'Ввести код регистрации',
  checkMail: 'Проверьте e-mail',
  sentCode: 'Мы отправили вам шестизначный код на адрес: {{email}}. Введите полученный код или пройдите по ссылке в сообщении.',
  resendMail: 'Мне не пришло письмо. Отправить еще раз',
  instructionMail: 'Мы отправим на ваш e-mail/телефон письмо/sms с инструкцией по восстановлению пароля',
  spamNotificationMessage: 'Письмо может попасть в спам.',
  passwordRecoverLinkSent_email: 'На указанный вами e-mail: {{login}} было отправлено письмо с уникальной ссылкой для сброса пароля.',
  passwordRecoverLinkSent_phone: 'На указанный вами телефон: {{login}} было отправлено sms с уникальной ссылкой для сброса пароля.',
  recover: 'Восстановить',
  unregisteredEmail: 'E-mail не зарегистрирован в СКВЕР, проверьте правильность введенного e-mail или перейдите по ссылке для',
  unregisteredPhone: 'Телефон не зарегистрирован в СКВЕР, проверьте правильность введенного телефона или перейдите по ссылке для',
  registrations: 'регистрации',
  newAccout: ' нового аккаунта.',
  mailSent: 'Письмо отправлено',
  sentError: 'Прошло более 15 минут после первой отправки',
  smsSent: 'SMS отправлено',
  specifiedEmail: 'На указанный Вами e-mail {{login}} ',
  specifiedPhone: 'На указанный Вами номер телефона {{login}} ',
  uniqueLinkWasSent: 'отправлена уникальная ссылка для входа.',
  instructionRecoveryPasswordWasSent: 'была отправлена инструкция по смене пароля.',
  resend: 'Отправить повторно',
  uniqueLinkEmail: 'Мы отправим на ваш e-mail/телефон письмо/sms письмо с уникальной ссылкой для входа. Письмо может попасть в спам.',
  followMailLink: 'Вы можете ввести код вручную или перейти по ссылке из письма',
  followSmsLink: 'Вы можете ввести код вручную или перейти по ссылке из sms',
  waitLink: 'Дождитесь получения и перейдите по ссылке. Проверяйте спам.',
  waitComment: 'Если Вы не получите сообщение через {{timeout}} секунд, кликните на кнопку ниже.',
  waitRecovery: 'Дождитесь получения сообщения. Проверяйте спам.',
  willBeAvailableAfter_0: 'Будет доступно через {{count}} секунду',
  willBeAvailableAfter_1: 'Будет доступно через {{count}} секунды',
  willBeAvailableAfter_2: 'Будет доступно через {{count}} секунд',
  fogotPassword: 'Забыли пароль?',
  wrongFormat: 'Неправильный формат данных',
  fogotPasswordText: 'Введите e-mail или телефон и выберите удобный вариант для входа',
  linkForLogin: 'ссылка для входа',
  linkForLoginTooltip: 'Мы отправим Вам уникальную одноразовую ссылку для входа. Пароль менять не обязательно',
  recoverPassword: 'восстановить пароль',
  recoverPasswordTooltip: 'Мы отправим Вам инструкцию по смене пароля',
  linkWasSent: 'Ссылка отправлена',
  instructionWasSent: 'Инструкция отправлена'
};