"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BulletinFileStatusEnum = exports.QuestionVoteType = void 0;
var QuestionVoteType;
(function (QuestionVoteType) {
  QuestionVoteType["YES"] = "yes";
  QuestionVoteType["AGAINST"] = "no";
  QuestionVoteType["ABSTAINED"] = "nothing";
  QuestionVoteType["DAMAGED"] = "damaged";
  QuestionVoteType["NOT_VOTED"] = "unvoted";
})(QuestionVoteType || (exports.QuestionVoteType = QuestionVoteType = {}));
var BulletinFileStatusEnum;
(function (BulletinFileStatusEnum) {
  BulletinFileStatusEnum["UPLOADED"] = "uploaded";
  BulletinFileStatusEnum["NOT_UPLOADED"] = "not_uploaded";
  BulletinFileStatusEnum["REMOVED"] = "removed";
})(BulletinFileStatusEnum || (exports.BulletinFileStatusEnum = BulletinFileStatusEnum = {}));