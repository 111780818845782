import { i18n } from '@sqvr/i18n';

const { user: userI18n, sqvr: sqvrI18n } = i18n;
const { messages: messagesI18n, labels: labelsI18n } = i18n.modules.app;

export const MSG_ERROR_STATUS = () => messagesI18n.serverConnectionError;
export const MSG_ERROR_STATUS_REQ = () => messagesI18n.serverRequestError;
export const MSG_ERROR_500 = () => messagesI18n.serverRequestErrorDescription;
export const MSG_ERROR_502 = () => messagesI18n.atTheMomentTheServerIsUnderMaintenance;
export const MSG_SAVE_SUCCESS = () => messagesI18n.successfullySaved;

export const meta = () => [
  { charset: 'utf-8' },
  { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
  { name: 'format-detection', content: 'telephone=no' },
  { name: 'keywords', content: 'sqvr, front' },
  {
    name: 'description',
    content: sqvrI18n.title,
  },
  {
    property: 'og:title',
    content: sqvrI18n.title,
  },
  {
    property: 'og:site_name',
    content: sqvrI18n.title,
  },
  {
    property: 'og:description',
    content: sqvrI18n.title,
  },
  {
    name: 'apple-mobile-web-app-title',
    content: sqvrI18n.title,
  },
  {
    name: 'application-name',
    content: sqvrI18n.title,
  },
  { name: 'msapplication-TileColor', content: '#2b5797' },
  {
    name: 'msapplication-TileImage',
    content: '/images/icons/mstile-150x150.png',
  },
  {
    name: 'msapplication-config',
    content: '/images/icons/browserconfig.xml',
  },
  { name: 'theme-color', content: '#ffffff' },
];

export const link = [
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/images/icons/apple-touch-icon.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/images/icons/favicon-32x32.png',
    sizes: '32x32',
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: '/images/icons/favicon-16x16.png',
    sizes: '16x16',
  },
  {
    rel: 'mask-icon',
    href: '/images/icons/safari-pinned-tab.svg',
    color: '#2c83b5',
  },
  { rel: 'shortcut icon', href: '/favicon.ico?v=214' },
];

export const STREET_TYPES = () =>
  [
    { label: labelsI18n.street, value: 'street' },
    { label: labelsI18n.embankment, value: 'embankment' },
    { label: labelsI18n.avenue, value: 'avenue' },
    { label: labelsI18n.boulevard, value: 'boulevard' },
    { label: labelsI18n.lane, value: 'lane' },
    { label: labelsI18n.deadEnd, value: 'deadEnd' },
    { label: labelsI18n.square, value: 'square' },
    { label: labelsI18n.directions, value: 'directions' },
    { label: labelsI18n.tract, value: 'tract' },
    { label: labelsI18n.alley, value: 'alley' },
    { label: labelsI18n.highway, value: 'highway' },
    { label: labelsI18n.row, value: 'row' },
    { label: labelsI18n.line, value: 'line' },
  ].sort((e1, e2) => {
    // do not replace sorting function with manual sorting.
    if (e1.label > e2.label) return 1;
    if (e1.label < e2.label) return -1;
    return 0;
  });

export const CUSTOM_OBJECT_TYPES = {
  form: 'form',
  formField: 'formField',
};

export const COLORS = {
  primaryBlue: '#2E87FF',
  primaryRed: '#FF6268',
  primaryGreen: '#00DA71',
  primaryYellow: '#FFC64C',
  primaryCyan: '#7BDCD6',
  primaryViolet: '#CF91E4',
  accentBlue: '#3D7CF6',
  accentRed: '#EC586A',
  accentGreen: '#0AC16F',
  accentYellow: '#FAA940',
  accentCyan: '#6CC2BD',
  darkYellow: '#CC9E29',
  dark: '#263238',
  gray: '#7F7F83',
  light: '#D6D6D8',
  white: '#FFFFFF',
  errorRed: '#f44336',
  purple: '#A266C1',
  selectedBg: 'rgba(214, 214, 216, 0.33)',
  lightGrayBg: 'rgba(214, 214, 216, 0.2)',
  divider: 'rgba(0, 0, 0, 0.12)',
  mainText: 'rgba(0, 0, 0, 0.87)',
  secondaryText: 'rgba(0, 0, 0, 0.6)',
  lightText: 'rgba(0, 0, 0, 0.38)',
  /* for registry  */
  defaultRow: '#EEEEF0',
  defaultRowHover: '#DCDCDE',
  defaultRow2nd: '#FCFDFF',
  defaultRow2ndHover: '#E8E9EB',
  selectedRow: '#F0EED9',
  selectedRowHover: '#DEDBCA',
  selectedRow2nd: '#FFFAE6',
  selectedRow2ndHover: '#EAE7D6',
  tempRow: '#C7DAFA',
  tempRowHover: '#BCCCE6',
  tempRow2nd: '#DBE8FB',
  tempRow2ndHover: '#CDD6E7',
  selectedRowTempRow: '#B2C9F2',
  selectedRowTempRowHover: '#AABEDF',
  selectedRowTempRow2nd: '#BFD3F6',
  selectedRowTempRow2ndHover: '#B4C5E3',
  hoverGray: '#CBCBCB',
  transparentRed: 'rgba(255,98,104,0.12)',
  red: 'rgb(255, 98, 104)',
  addOwnerRow: '#D8EEEC',
  addOwnerRowHover: '#C8DCDB',
  addOwnerRowEditing: '#C2E2DF',
  addOwnerRowEditingHover: '#B5D2D0',
  unknownRow: '#eddfee',
  unknownRowHover: '#dbd0de',
  unknownRow2nd: '#ddcfde',
  unknownRow2ndHover: '#cdc2d0',
  errorBlock: '#FFE8E8',
  /* for notify */
  underlineColor: '#EAEAEA',
  labelColor: '#A3A3A3',
  pink: '#CF91E4',
  /* for svg */
  svgColor: '#757575',
  /* for user popover */
  contractor: '#56CCF2',
};

export const BOX_SHADOW = { paper: '0px 4px 8px rgba(176, 190, 197, 0.24)' };

export const BORDER_COLOR = {
  Resident: COLORS.accentCyan,
  ManagementStuff: COLORS.primaryViolet,
  Integrator: COLORS.accentYellow,
  Contractor: COLORS.contractor,
  HOAEmployee: COLORS.primaryViolet,
};

export const MEDIA_SIZES = {
  phone: 320,
  phablet: 420,
  tablet: 768,
  desktop: 1024,
  desktopXL: 1370,
};

export const JURISTIC_LEGAL_FORMS = [
  'ОАО',
  'ООО',
  'АНО',
  'АУ',
  'АП',
  'АО',
  'Арт',
  'АСКФХ',
  'БФ',
  'БСП',
  'ВБФ',
  'ГК',
  'ГКУ',
  'ГУП',
  'ДТ',
  'Д(С)У',
  'ЖД',
  'З-д',
  'Ин-т',
  'КФ',
  'КЦ',
  'КБ',
  'Корп',
  'КФХ',
  'ЛПХ',
  'МСЧ',
  'МВБД',
  'Миссия',
  'МКУ',
  'МУЧ',
  'НПО',
  'НТЦ',
  'НОТК',
  'ОБП',
  'ОКФХ',
  'ОО',
  'ООБ',
  'ОФ',
  'ОП',
  'ОТ',
  'ОТД',
  'ОВБД',
  'Партия',
  'МУП',
  'ППО',
  'ПТ',
  'ПС',
  'ПОО',
  'ПРЕД',
  'ПО',
  'ПКФ',
  'ПК',
  'ПРОФКОМ',
  'РО',
  'РСУ',
  'СТ',
  'СХПК',
  'СМТ',
  'СВХ',
  'СоюзКФХ',
  'СКБ',
  'СТП',
  'ТОС',
  'ТСЖ',
  'ТПП',
  'ТФПГ',
  'Уч',
  'УОО',
  'ФКП',
  'ФГУ',
  'ФКУ',
  'ФФ',
  'ФПГ',
  'Фирма',
  'ХОЗУ',
  'ЦБУХ',
  'ЦРБ',
  'ЧОП',
  'ЧИФ',
  'Я/С',
  'МУУП',
];

export const USER_ROLES = () => ({
  Integrator: userI18n.Integrator,
  Resident: userI18n.Resident,
  Contractor: userI18n.Contractor,
  HOAEmployee: userI18n.HOAEmployee,
  ManagementStuff: userI18n.ManagementStuff,
});

export const FONTS = {
  titleFontFamily: 'Fira Sans',
};

export const ALLOWED_PICTURE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'bmp', 'tif', 'tiff', 'tga', 'gif'];

export const ALLOWED_AUDIO_EXTENSIONS = ['mp3', 'wma', 'amr', 'ogg', 'aac', 'wav', 'flac'];

export const ALLOWED_VIDEO_EXTENSIONS = [
  'avi',
  'mp4',
  '3gp',
  'mpeg',
  'mov',
  'flv',
  'wmv',
  'mkv',
  'ts',
  'vob',
  'm4a',
  'mpg',
];
export const ALLOWED_DOCUMENT_EXTENSIONS = ['txt', 'pdf', 'doc', 'docx', 'rtf', 'xlsx', 'xls'];
export const ALLOWED_ARCHIVE_EXTENSIONS = ['zip', 'rar'];

export const ALLOWED_EXTENSIONS = [
  ...ALLOWED_AUDIO_EXTENSIONS,
  ...ALLOWED_VIDEO_EXTENSIONS,
  ...ALLOWED_PICTURE_EXTENSIONS,
  ...ALLOWED_DOCUMENT_EXTENSIONS,
  ...ALLOWED_ARCHIVE_EXTENSIONS,
];

export const COUNTRIES = {
  AF: {
    name: 'Afghanistan',
    code: '+93',
  },
  AL: {
    name: 'Albania',
    code: '+355',
  },
  DZ: {
    name: 'Algeria',
    code: '+213',
  },
  AS: {
    name: 'American Samoa',
    code: '+1-684',
  },
  AD: {
    name: 'Andorra',
    code: '+376',
  },
  AO: {
    name: 'Angola',
    code: '+244',
  },
  AI: {
    name: 'Anguilla',
    code: '+1-264',
  },
  AQ: {
    name: 'Antarctica',
    code: '+672',
  },
  AG: {
    name: 'Antigua and Barbuda',
    code: '+1-268',
  },
  AR: {
    name: 'Argentina',
    code: '+54',
  },
  AM: {
    name: 'Armenia',
    code: '+374',
  },
  AW: {
    name: 'Aruba',
    code: '+297',
  },
  AU: {
    name: 'Australia',
    code: '+61',
  },
  AT: {
    name: 'Austria',
    code: '+43',
  },
  AZ: {
    name: 'Azerbaijan',
    code: '+994',
  },
  BS: {
    name: 'Bahamas',
    code: '+1-242',
  },
  BH: {
    name: 'Bahrain',
    code: '+973',
  },
  BD: {
    name: 'Bangladesh',
    code: '+880',
  },
  BB: {
    name: 'Barbados',
    code: '+1-246',
  },
  BY: {
    name: 'Belarus',
    code: '+375',
  },
  BE: {
    name: 'Belgium',
    code: '+32',
  },
  BZ: {
    name: 'Belize',
    code: '+501',
  },
  BJ: {
    name: 'Benin',
    code: '+229',
  },
  BM: {
    name: 'Bermuda',
    code: '+1-441',
  },
  BT: {
    name: 'Bhutan',
    code: '+975',
  },
  BO: {
    name: 'Bolivia',
    code: '+591',
  },
  BA: {
    name: 'Bosnia and Herzegovina',
    code: '+387',
  },
  BW: {
    name: 'Botswana',
    code: '+267',
  },
  BR: {
    name: 'Brazil',
    code: '+55',
  },
  IO: {
    name: 'British Indian Ocean Territory',
    code: '+246',
  },
  VG: {
    name: 'British Virgin Islands',
    code: '+1-284',
  },
  BN: {
    name: 'Brunei',
    code: '+673',
  },
  BG: {
    name: 'Bulgaria',
    code: '+359',
  },
  BF: {
    name: 'Burkina Faso',
    code: '+226',
  },
  BI: {
    name: 'Burundi',
    code: '+257',
  },
  KH: {
    name: 'Cambodia',
    code: '+855',
  },
  CM: {
    name: 'Cameroon',
    code: '+237',
  },
  CA: {
    name: 'Canada',
    code: '+1',
  },
  CV: {
    name: 'Cape Verde',
    code: '+238',
  },
  KY: {
    name: 'Cayman Islands',
    code: '+1-345',
  },
  CF: {
    name: 'Central African Republic',
    code: '+236',
  },
  TD: {
    name: 'Chad',
    code: '+235',
  },
  CL: {
    name: 'Chile',
    code: '+56',
  },
  CN: {
    name: 'China',
    code: '+86',
  },
  CX: {
    name: 'Christmas Island',
    code: '+61',
  },
  CC: {
    name: 'Cocos Islands',
    code: '+61',
  },
  CO: {
    name: 'Colombia',
    code: '+57',
  },
  KM: {
    name: 'Comoros',
    code: '+269',
  },
  CK: {
    name: 'Cook Islands',
    code: '+682',
  },
  CR: {
    name: 'Costa Rica',
    code: '+506',
  },
  HR: {
    name: 'Croatia',
    code: '+385',
  },
  CU: {
    name: 'Cuba',
    code: '+53',
  },
  CW: {
    name: 'Curacao',
    code: '+599',
  },
  CY: {
    name: 'Cyprus',
    code: '+357',
  },
  CZ: {
    name: 'Czech Republic',
    code: '+420',
  },
  CD: {
    name: 'Democratic Republic of the Congo',
    code: '+243',
  },
  DK: {
    name: 'Denmark',
    code: '+45',
  },
  DJ: {
    name: 'Djibouti',
    code: '+253',
  },
  DM: {
    name: 'Dominica',
    code: '+1-767',
  },
  DO: {
    name: 'Dominican Republic',
    code: '+1-809',
  },
  TL: {
    name: 'East Timor',
    code: '+670',
  },
  EC: {
    name: 'Ecuador',
    code: '+593',
  },
  EG: {
    name: 'Egypt',
    code: '+20',
  },
  SV: {
    name: 'El Salvador',
    code: '+503',
  },
  GQ: {
    name: 'Equatorial Guinea',
    code: '+240',
  },
  ER: {
    name: 'Eritrea',
    code: '+291',
  },
  EE: {
    name: 'Estonia',
    code: '+372',
  },
  ET: {
    name: 'Ethiopia',
    code: '+251',
  },
  FK: {
    name: 'Falkland Islands',
    code: '+500',
  },
  FO: {
    name: 'Faroe Islands',
    code: '+298',
  },
  FJ: {
    name: 'Fiji',
    code: '+679',
  },
  FI: {
    name: 'Finland',
    code: '+358',
  },
  FR: {
    name: 'France',
    code: '+33',
  },
  PF: {
    name: 'French Polynesia',
    code: '+689',
  },
  GA: {
    name: 'Gabon',
    code: '+241',
  },
  GM: {
    name: 'Gambia',
    code: '+220',
  },
  GE: {
    name: 'Georgia',
    code: '+995',
  },
  DE: {
    name: 'Germany',
    code: '+49',
  },
  GH: {
    name: 'Ghana',
    code: '+233',
  },
  GI: {
    name: 'Gibraltar',
    code: '+350',
  },
  GR: {
    name: 'Greece',
    code: '+30',
  },
  GL: {
    name: 'Greenland',
    code: '+299',
  },
  GD: {
    name: 'Grenada',
    code: '+1-473',
  },
  GU: {
    name: 'Guam',
    code: '+1-671',
  },
  GT: {
    name: 'Guatemala',
    code: '+502',
  },
  GG: {
    name: 'Guernsey',
    code: '+44-1481',
  },
  GN: {
    name: 'Guinea',
    code: '+224',
  },
  GW: {
    name: 'Guinea-Bissau',
    code: '+245',
  },
  GY: {
    name: 'Guyana',
    code: '+592',
  },
  HT: {
    name: 'Haiti',
    code: '+509',
  },
  HN: {
    name: 'Honduras',
    code: '+504',
  },
  HK: {
    name: 'Hong Kong',
    code: '+852',
  },
  HU: {
    name: 'Hungary',
    code: '+36',
  },
  IS: {
    name: 'Iceland',
    code: '+354',
  },
  IN: {
    name: 'India',
    code: '+91',
  },
  ID: {
    name: 'Indonesia',
    code: '+62',
  },
  IR: {
    name: 'Iran',
    code: '+98',
  },
  IQ: {
    name: 'Iraq',
    code: '+964',
  },
  IE: {
    name: 'Ireland',
    code: '+353',
  },
  IM: {
    name: 'Isle of Man',
    code: '+44-1624',
  },
  IL: {
    name: 'Israel',
    code: '+972',
  },
  IT: {
    name: 'Italy',
    code: '+39',
  },
  CI: {
    name: 'Ivory Coast',
    code: '+225',
  },
  JM: {
    name: 'Jamaica',
    code: '+1-876',
  },
  JP: {
    name: 'Japan',
    code: '+81',
  },
  JE: {
    name: 'Jersey',
    code: '+44-1534',
  },
  JO: {
    name: 'Jordan',
    code: '+962',
  },
  KZ: {
    name: 'Kazakhstan',
    code: '+7',
  },
  KE: {
    name: 'Kenya',
    code: '+254',
  },
  KI: {
    name: 'Kiribati',
    code: '+686',
  },
  XK: {
    name: 'Kosovo',
    code: '+383',
  },
  KW: {
    name: 'Kuwait',
    code: '+965',
  },
  KG: {
    name: 'Kyrgyzstan',
    code: '+996',
  },
  LA: {
    name: 'Laos',
    code: '+856',
  },
  LV: {
    name: 'Latvia',
    code: '+371',
  },
  LB: {
    name: 'Lebanon',
    code: '+961',
  },
  LS: {
    name: 'Lesotho',
    code: '+266',
  },
  LR: {
    name: 'Liberia',
    code: '+231',
  },
  LY: {
    name: 'Libya',
    code: '+218',
  },
  LI: {
    name: 'Liechtenstein',
    code: '+423',
  },
  LT: {
    name: 'Lithuania',
    code: '+370',
  },
  LU: {
    name: 'Luxembourg',
    code: '+352',
  },
  MO: {
    name: 'Macau',
    code: '+853',
  },
  MK: {
    name: 'Macedonia',
    code: '+389',
  },
  MG: {
    name: 'Madagascar',
    code: '+261',
  },
  MW: {
    name: 'Malawi',
    code: '+265',
  },
  MY: {
    name: 'Malaysia',
    code: '+60',
  },
  MV: {
    name: 'Maldives',
    code: '+960',
  },
  ML: {
    name: 'Mali',
    code: '+223',
  },
  MT: {
    name: 'Malta',
    code: '+356',
  },
  MH: {
    name: 'Marshall Islands',
    code: '+692',
  },
  MR: {
    name: 'Mauritania',
    code: '+222',
  },
  MU: {
    name: 'Mauritius',
    code: '+230',
  },
  YT: {
    name: 'Mayotte',
    code: '+262',
  },
  MX: {
    name: 'Mexico',
    code: '+52',
  },
  FM: {
    name: 'Micronesia',
    code: '+691',
  },
  MD: {
    name: 'Moldova',
    code: '+373',
  },
  MC: {
    name: 'Monaco',
    code: '+377',
  },
  MN: {
    name: 'Mongolia',
    code: '+976',
  },
  ME: {
    name: 'Montenegro',
    code: '+382',
  },
  MS: {
    name: 'Montserrat',
    code: '+1-664',
  },
  MA: {
    name: 'Morocco',
    code: '+212',
  },
  MZ: {
    name: 'Mozambique',
    code: '+258',
  },
  MM: {
    name: 'Myanmar',
    code: '+95',
  },
  NA: {
    name: 'Namibia',
    code: '+264',
  },
  NR: {
    name: 'Nauru',
    code: '+674',
  },
  NP: {
    name: 'Nepal',
    code: '+977',
  },
  NL: {
    name: 'Netherlands',
    code: '+31',
  },
  AN: {
    name: 'Netherlands Antilles',
    code: '+599',
  },
  NC: {
    name: 'New Caledonia',
    code: '+687',
  },
  NZ: {
    name: 'New Zealand',
    code: '+64',
  },
  NI: {
    name: 'Nicaragua',
    code: '+505',
  },
  NE: {
    name: 'Niger',
    code: '+227',
  },
  NG: {
    name: 'Nigeria',
    code: '+234',
  },
  NU: {
    name: 'Niue',
    code: '+683',
  },
  KP: {
    name: 'North Korea',
    code: '+850',
  },
  MP: {
    name: 'Northern Mariana Islands',
    code: '+1-670',
  },
  NO: {
    name: 'Norway',
    code: '+47',
  },
  OM: {
    name: 'Oman',
    code: '+968',
  },
  PK: {
    name: 'Pakistan',
    code: '+92',
  },
  PW: {
    name: 'Palau',
    code: '+680',
  },
  PS: {
    name: 'Palestine',
    code: '+970',
  },
  PA: {
    name: 'Panama',
    code: '+507',
  },
  PG: {
    name: 'Papua New Guinea',
    code: '+675',
  },
  PY: {
    name: 'Paraguay',
    code: '+595',
  },
  PE: {
    name: 'Peru',
    code: '+51',
  },
  PH: {
    name: 'Philippines',
    code: '+63',
  },
  PN: {
    name: 'Pitcairn',
    code: '+64',
  },
  PL: {
    name: 'Poland',
    code: '+48',
  },
  PT: {
    name: 'Portugal',
    code: '+351',
  },
  PR: {
    name: 'Puerto Rico',
    code: '+1-787, 1-939',
  },
  QA: {
    name: 'Qatar',
    code: '+974',
  },
  CG: {
    name: 'Republic of the Congo',
    code: '+242',
  },
  RE: {
    name: 'Reunion',
    code: '+262',
  },
  RO: {
    name: 'Romania',
    code: '+40',
  },
  RU: {
    name: 'Russia',
    code: '+7',
  },
  RW: {
    name: 'Rwanda',
    code: '+250',
  },
  BL: {
    name: 'Saint Barthelemy',
    code: '+590',
  },
  SH: {
    name: 'Saint Helena',
    code: '+290',
  },
  KN: {
    name: 'Saint Kitts and Nevis',
    code: '+1-869',
  },
  LC: {
    name: 'Saint Lucia',
    code: '+1-758',
  },
  MF: {
    name: 'Saint Martin',
    code: '+590',
  },
  PM: {
    name: 'Saint Pierre and Miquelon',
    code: '+508',
  },
  VC: {
    name: 'Saint Vincent and the Grenadines',
    code: '+1-784',
  },
  WS: {
    name: 'Samoa',
    code: '+685',
  },
  SM: {
    name: 'San Marino',
    code: '+378',
  },
  ST: {
    name: 'Sao Tome and Principe',
    code: '+239',
  },
  SA: {
    name: 'Saudi Arabia',
    code: '+966',
  },
  SN: {
    name: 'Senegal',
    code: '+221',
  },
  RS: {
    name: 'Serbia',
    code: '+381',
  },
  SC: {
    name: 'Seychelles',
    code: '+248',
  },
  SL: {
    name: 'Sierra Leone',
    code: '+232',
  },
  SG: {
    name: 'Singapore',
    code: '+65',
  },
  SX: {
    name: 'Sint Maarten',
    code: '+1-721',
  },
  SK: {
    name: 'Slovakia',
    code: '+421',
  },
  SI: {
    name: 'Slovenia',
    code: '+386',
  },
  SB: {
    name: 'Solomon Islands',
    code: '+677',
  },
  SO: {
    name: 'Somalia',
    code: '+252',
  },
  ZA: {
    name: 'South Africa',
    code: '+27',
  },
  KR: {
    name: 'South Korea',
    code: '+82',
  },
  SS: {
    name: 'South Sudan',
    code: '+211',
  },
  ES: {
    name: 'Spain',
    code: '+34',
  },
  LK: {
    name: 'Sri Lanka',
    code: '+94',
  },
  SD: {
    name: 'Sudan',
    code: '+249',
  },
  SR: {
    name: 'Suriname',
    code: '+597',
  },
  SJ: {
    name: 'Svalbard and Jan Mayen',
    code: '+47',
  },
  SZ: {
    name: 'Swaziland',
    code: '+268',
  },
  SE: {
    name: 'Sweden',
    code: '+46',
  },
  CH: {
    name: 'Switzerland',
    code: '+41',
  },
  SY: {
    name: 'Syria',
    code: '+963',
  },
  TW: {
    name: 'Taiwan',
    code: '+886',
  },
  TJ: {
    name: 'Tajikistan',
    code: '+992',
  },
  TZ: {
    name: 'Tanzania',
    code: '+255',
  },
  TH: {
    name: 'Thailand',
    code: '+66',
  },
  TG: {
    name: 'Togo',
    code: '+228',
  },
  TK: {
    name: 'Tokelau',
    code: '+690',
  },
  TO: {
    name: 'Tonga',
    code: '+676',
  },
  TT: {
    name: 'Trinidad and Tobago',
    code: '+1-868',
  },
  TN: {
    name: 'Tunisia',
    code: '+216',
  },
  TR: {
    name: 'Turkey',
    code: '+90',
  },
  TM: {
    name: 'Turkmenistan',
    code: '+993',
  },
  TC: {
    name: 'Turks and Caicos Islands',
    code: '+1-649',
  },
  TV: {
    name: 'Tuvalu',
    code: '+688',
  },
  VI: {
    name: 'U.S. Virgin Islands',
    code: '+1-340',
  },
  UG: {
    name: 'Uganda',
    code: '+256',
  },
  UA: {
    name: 'Ukraine',
    code: '+380',
  },
  AE: {
    name: 'United Arab Emirates',
    code: '+971',
  },
  GB: {
    name: 'United Kingdom',
    code: '+44',
  },
  US: {
    name: 'United States',
    code: '+1',
  },
  UY: {
    name: 'Uruguay',
    code: '+598',
  },
  UZ: {
    name: 'Uzbekistan',
    code: '+998',
  },
  VU: {
    name: 'Vanuatu',
    code: '+678',
  },
  VA: {
    name: 'Vatican',
    code: '+379',
  },
  VE: {
    name: 'Venezuela',
    code: '+58',
  },
  VN: {
    name: 'Vietnam',
    code: '+84',
  },
  WF: {
    name: 'Wallis and Futuna',
    code: '+681',
  },
  EH: {
    name: 'Western Sahara',
    code: '+212',
  },
  YE: {
    name: 'Yemen',
    code: '+967',
  },
  ZM: {
    name: 'Zambia',
    code: '+260',
  },
  ZW: {
    name: 'Zimbabwe',
    code: '+263',
  },
};

export const COUNTRY_CODES = Object.keys(COUNTRIES).map(item => COUNTRIES[item].code);

export const ACCESS_LEVEL = {
  Admin: 'Admin',
  Moderator: 'Moderator',
  Integrator: 'Integrator',
};

export const PERSON_TYPE = {
  Unknown: 'Unknown',
  Natural: 'Natural',
  Legal: 'Legal',
  Government: 'Government',
};

export const HOUSES_TYPE_TOWN = 'Town';
export const HOUSES_TYPE_VILLAGE = 'Village';
export const ALL_HOUSE_TYPES = [HOUSES_TYPE_TOWN, HOUSES_TYPE_VILLAGE];
export const PASSWORD_MIN_LENGTH = 4;
