"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Info',
  whoEdited: 'Who edited and when',
  size: 'Size',
  deleted: 'Removed',
  sizeInMb: 'Mb',
  sizeInKb: 'Kb'
};