"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  noNumberTitle: ' Нет кадастрового номера',
  noOrderedTitle: 'Реестр ни разу не заказывался',
  noLimitTitle: 'Нет действующего лимита на актуализацию собрания',
  noMeetingTitle: 'Нет ни одного собрания, находящегося в стадии “Ожидается” или “Проходит в настоящее время”',
  noOrderTitle: 'Реестр ни разу не заказывался',
  actualizationTitle: 'Актуализация реестра',
  alreadyOrderedTitle: 'Реестр уже заказан',
  alreadyActualizationTitle: 'Актуализация реестра уже заказана',
  alreadyCompleteTitle: 'Актуализация реестра уже выполнена',
  uknownCountTitle: 'Мы не знаем количество помещений в Объекте',
  unavailable: 'Услуга Вам не доступна.',
  noNumber: '1. Нет кадастрового номера для МКД. ',
  noNumberCondo: '1. Не для каждого МКД  указан кадастровый номер. ',
  goToSettings: 'Перейти в Настройки',
  enterNumber: ', чтобы ввести кадастровый номер.',
  enterNumberCondo: ', чтобы ввести кадастровые номера для каждого МКД.',
  noOrder: 'Вы ни разу не заказывали реестр и не покупали платный тариф.',
  noLimit: 'Нет оплаченного доступного лимита на актуализацию реестра.',
  orderRegistry: 'Заказать реестр',
  or: ' или ',
  buyTariff: 'купить платный тариф',
  noMeetings: 'Нет ни одного собрания, находящегося в стадии “Ожидается” или “Проходит в настоящее время”. Сначала опубликуйте собрание.',
  noLabel: 'Закрыть',
  goToTariffs: 'Перейти В тарифы',
  noLimitDescription: 'У Вас нет действующего лимита на актуализацию реестра. Перейдите в ',
  tariffs: 'Тарифы',
  chooseTariffPlan: ' и выберите любой тарифный план или выберите услугу актуализации реестра из ',
  oneTimeTariff: '"Отдельных лимитов"',
  alreadyOrdered: 'Данная услуга уже была заказана ',
  registry: 'реестр.',
  actialization: 'актуализацию реестра.',
  wait: 'Дождитесь выполнения заказа (примерно {{days}} рабочих дней) и загрузите обновленный реестр.',
  orderCompletes: 'Заказ был исполнен. Вам не следует снова актуализировать реестр.',
  questions: 'Если у Вас есть вопросы, ',
  writeToSupport: 'напишите нам в техподдержку',
  click: 'Если Вы все-таки считаете необходимым еще раз заказать актуализацию реестра, нажмите на кнопку “Актуализировать реестр”.',
  payForTariff: 'оплатите любой из Тарифных планов',
  actualize: 'Актуализировать реестр',
  noNumberTooltip1: '   Мы не можем актуализировать реестр собственников, потому что не знаем что с чем сравнивать. Вы ни разу не заказывали у нас услугу по формированию самого реестра. Актуализация реестра подразумевает, что ранее уже был сформирован достоверный реестр собственников, который формируется на основании выписок из ЕГРН по каждому помещению, входящему в состав МКД.',
  noNumberTooltip2: '   Мы не можем быть уверенными, что данные, хранящиеся сейчас в реестре,  полные и не противоречивые.',
  noNumberTooltip3: '   Начните с того, чтобы ввести правильный кадастровый номер в ',
  noNumberTooltip4: 'Настройках',
  unknownCountTooltip: '   Сейчас мы работаем над получением выписки по МКД. Как только мы закончим эту работу у Вас появится возможность заказать и оплатить реестр собственников.',
  orderRunning: 'Идет выполнение заказа реестра',
  orderRunningTooltip1: '   Мы сейчас работаем над первым заказом реестра собственников Вашего дома. Мы не можем актуализировать реестр собственников, потому что не знаем что с чем сравнивать. Актуализация реестра подразумевает, что ранее уже есть достоверный реестр собственников, который сформировался на основании выписок из ЕГРН по каждому помещению, входящему в состав МКД.',
  orderRunningTooltip2: 'Дождитесь заказанного реестра.',
  yet: 'Услуга пока Вам не доступна.',
  waitOrder: 'Дождитесь выполнение заказа реестра собственников для Вашего дома (примерно {{count}} рабочих дней). Как только заказ будет исполнен, Вы получите автоматическое уведомление об этом.',
  attention1: 'Обратите внимание, что актуализация реестра будет доступна после того как в Вашем доме будет хотя бы одно собрание в стадии “Ожидается” или “Проходит в настоящее время”.',
  after: 'После этого Вы сможете актуализировать реестр собственников.',
  noLimit2: '2. После этого нужно будет оплатить услугу по актуализации реестра. Для этого Вам нужно выбрать любой из платных тарифных планов или оплатить услугу актуализации реестра из “Отдельных Лимитов“.',
  attention2: `Обратите внимание, что актуализация реестра будет доступна после того как Вы:
а) оплатите эту услугу. Выберите любой из платных тарифных планов или оплатите услугу актуализации реестра из “Разовых тарифов”.

б) в Вашем доме будет хотя бы одно собрание в стадии “Ожидается” или “Проходит в настоящий момент”. `,
  available: 'Вы можете актуализировать реестр собственников.',
  lastLimit: 'Это последний, пока еще не израсходованный лимит по данной услуге.',
  limits: 'После этого Вы сможете актуализировать реестр еще ',
  limitsCount1: '{{count}} раза',
  limitsCount2: '{{count}} раз',
  moreLimits1: 'Для проведения актуализации реестра собственников в будущем Вам нужно будет перейти в Тарифы, выбрать и оплатить любой тарифный план или оплатить услугу актуализации реестра из “Отдельных Лимитов“.',
  moreLimits2: 'Для увеличения лимита по актуализации реестра собственников Вам нужно будет перейти в Тарифы, выбрать и оплатить любой тарифный план или оплатить услугу актуализации реестра из “Отдельных Лимитов“.',
  complete: ' и выполнена',
  noNeedOrderAgain: '. Вам не нужно еще раз заказывать актуализацию реестра собственников.',
  noAgain: `Вы не можете еще раз актуализировать реестр потому что:

а) Нет оплаченного лимита на актуализацию реестра. Перейдите в Тарифы и выберите и оплатите любой тарифный план или выберите и оплатите услугу актуализации реестра из “Отдельных Лимитов“.

б) Сейчас нет ни одного собрания в статусе “Ожидается” или “Проходит в настоящий момент”. Сначала опубликуйте собрание.`,
  noLimit3: 'Вы не можете еще раз актуализировать реестр, потому что нет оплаченного лимита на актуализацию реестра. Перейдите в Тарифы и выберите и оплатите любой тарифный план или выберите и оплатите услугу актуализации реестра из “Отдельных Лимитов“.',
  noMeetingsNoNumber: 'Нет ни одного собрания, находящегося в стадии “Ожидается” или “Проходит в настоящее время”. После выполнения пп. 1-3  опубликуйте собрание.',
  noMeetings2: 'Нет ни одного собрания в статусе “Ожидается” или “Проходит в настоящее время”. После выполнения п.1 и п.2 опубликуйте собрание.'
};