"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.capitalizeFirstChar = exports.convertUser = exports.convertHistoryData = void 0;
function convertHistoryData(data) {
  var _a;
  let newData = {
    id: data.id,
    body: data.info.comment || data.body,
    date: data.date,
    isRead: data.is_read,
    documents: data.documents,
    updater: data.owner ? convertUser(data.owner) : null,
    systemText: data.system || data.info.system,
    historyType: data.type || ((_a = data.info) === null || _a === void 0 ? void 0 : _a.type) || null,
    target: data.target ? convertUser(data.target) : null,
    user: data.user ? convertUser(data.user) : null
  };
  return newData;
}
exports.convertHistoryData = convertHistoryData;
function convertUser(user) {
  let newUser = {
    id: user.id,
    firstName: user.first_name,
    lastName: user.last_name,
    juristicPersonLegalForm: user.juristic_person_legal_form,
    juristicPersonName: user.juristic_person_name,
    role: capitalizeFirstChar(user.role),
    accessLevel: capitalizeFirstChar(user.access_level),
    nickname: user.nickname,
    avatarUrl: user.avatar_url,
    avatarColor: user.avatar_color,
    avatarBorderColor: user.avatar_border_color,
    avatarText: user.avatar_text,
    avatarDisplayName: user.avatar_display_name,
    showMyAvatar: user.show_my_avatar,
    personType: user.person_type,
    ownershipAddress: user.address,
    isBlocked: user.is_blocked_for_current_user,
    isBlockedFromCurrentUser: user.is_blocked_from_current_user,
    allowsPrivateNotifications: user.allows_private_notifications,
    active: user.active,
    textUser: !!user.text,
    text: user.text
  };
  if (!user.is_juristic_person) {
    newUser.personType = 'Natural';
  } else {
    newUser.personType = 'Legal';
  }
  if (user.role === 'hoa_employee') {
    newUser.role = 'HOAEmployee';
  }
  return newUser;
}
exports.convertUser = convertUser;
function capitalizeFirstChar(s) {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
}
exports.capitalizeFirstChar = capitalizeFirstChar;