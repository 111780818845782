"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  serverError: 'Ошибка сервера',
  stepPart1: 'Шага',
  stepPart2: 'не существует в списке шагов onboarding',
  serverConnectionError: 'Произошла ошибка при соединении с сервером',
  serverRequestError: 'Извините произошла ошибка при запросе',
  serverRequestErrorDescription: 'Что-то пошло не так. Наши инженеры уже получили сообщение об ошибке и в ближайшее время приступят к её устранению. Приносим извинения за доставленные неудобства',
  atTheMomentTheServerIsUnderMaintenance: 'В данный момент выполняются технические работы на сервере. Попробуйте выполнить действие позже',
  successfullySaved: 'Успешно сохранено!',
  innerErrorApp: 'Внутренняя ошибка приложения. Пожалуйста, попробуйте ещё раз или сообщите в поддержку.',
  notPossibleToSwitch: 'Не получается переключиться между объектами',
  emailOfCurrentUserIsNotSpecified: 'Не указан email у текущего пользователя',
  selectedAddressWasNotFound: 'Не найден выбранный адрес',
  addressIdIsNotRecognized: 'Не распознан идентификатор адреса, попробуйте перевыбрать адрес'
};