"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  addDocumentTitle: 'Saving a new document',
  editDocumentTitle: 'Editing a document',
  chipsInputBtmText: 'Enter key tags. Maximum {{count}} tags.',
  nameInputHelperText: 'Enter a title. Maximum {{count}} characters.',
  nameInputBottomHelperText: 'You can rename it and give it a more universally understandable name',
  tagsError: 'You need to specify max. {{count}} tags',
  selectPlaceholder: 'Select the category',
  createCategory: 'Create a new category',
  createCategoryModalTitle: 'Creating a new category',
  editCategoryModalTitle: 'Editing a category',
  inputLabelOptionCreate: 'Create a category and add a document to it',
  inputLabelOptionEdit: 'Come up with a clearer name',
  maxLength: 'Maximum {{count}} characters',
  documentSuccessfullyAddedText: 'Document successfully added'
};