"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  Integrator: 'Эксперт',
  Resident: 'Собственник',
  Contractor: 'Подрядчик',
  HOAEmployee: 'Сотрудник Товарищества',
  ManagementStuff: 'Сотрудник УК'
};