"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  lastActives: 'Last activity',
  thisHouseAlreadyRegistered: 'Wow! This house is already registered in Sqr!',
  noteOnlyOne: 'And not one!',
  selectHouseToJoin: 'Select the house you want to join',
  registeredNewHouse: 'In Sqr there is no such house. Do you want to register a new Object?',
  findHouse: 'Find your house',
  system: 'House and village management system',
  weDoServices: 'We make a service so that owners and management companies find common ground and improve the quality of life',
  controlBudjets: 'Control the budget',
  sqvrAllows0: 'Sqr allows:',
  sqvrAllows1: '- Understand what funds are spent in the house',
  sqvrAllows2: '- Participate in the preparation and adoption of the budget',
  sqvrAllows3: '- Check the correspondence of actual expenses to the plan and analyze deviations',
  hasComplaitns: ' In Sqvr, the complaint mechanism is implemented, which allows management companies to process complaints from owners and residents of the house more quickly, as well as analyze the efficiency of their own employees.',
  solveProblems: 'For residents, this functionality allows them to quickly solve emerging problems and not waste time. Always you can see who? what? and when? did by complaint.',
  backToStep: 'Back to step',
  deleteDataAboutHouse: 'Delete data about this house?',
  awaitAnswer: 'The house administrator has received your request to join. Wait for the answer.',
  createAnotherHouse: 'Create an alternative house?',
  recomendedDoThisIf: 'We recommend doing this only if the previously created Objects do not suit you',
  second: 'Second',
  third: 'Third',
  fourth: 'Fourth',
  fifth: 'Fifth',
  sixth: 'Sixth',
  seventh: 'Seventh',
  eighth: 'Eighth',
  ninth: 'Ninth',
  tenth: 'Tenth',
  eleventh: 'Eleventh',
  twelfth: 'Twelfth',
  thirteenth: 'Thirteenth',
  fourteenth: 'Fourteenth',
  addHouse: 'add another house',
  user: 'About myself',
  realty: 'Real estate',
  login: 'Continue with',
  activate: 'Registration code',
  addressNotFound: ' Address not found',
  alreadyRegistered: 'already registered!',
  positionAlreadyRegistered: 'Position already registered!',
  continueSearch: 'Continue extended search',
  search: 'If you do not see the appropriate options, use the extended search to more accurately enter the address.',
  changeSearch: 'You can switch the search for a house or village to search by its name. This will improve the search results for already registered objects in Sqr',
  selectHoa: 'You can switch to searching for a house or village only by address',
  advancedSearch: 'Extended search',
  street: 'ul',
  addNew: 'Create new',
  city: 'City',
  cityDistrict: 'City district',
  federalSignificance: 'City of federal significance',
  settlement: 'Town of urban type',
  town: 'Town',
  urbanSettlement: 'Urban settlement',
  password: 'Passwords must match',
  userAlreadyRegistered: 'Your user is already registered in Sqvr',
  youCanAddNew: 'You can add a new object to an already existing account for this confirm the action and click "Add"',
  youCanCreateNew: 'You can create a new account to add an object, for this register in Sqvr from another social account',
  wrongLogin: 'Incorrect login or password',
  errorLogin: 'Authorization error through a social network. Try again if the error persists, ' + 'contact technical support or register via email',
  inSelectedRoom: 'The selected room has insufficient free area',
  format5: 'The value must be a number',
  format9: 'The room area cannot be greater than the total area of all rooms in the building',
  unknownType: 'Unknown type of property',
  selectUsageType: 'Specify the type of property',
  selectOwnerType: 'Select the type of property',
  selectAddress: 'Select the house address',
  selectNeededYouAddress: 'In this co-op, several objects with different addresses are registered, select the one you need',
  apartmentNumber: 'Apartment number',
  totalArea: 'Total area',
  ownershipShare: 'Share',
  selectFormVillage: 'Specify the type of property',
  usageTypeOther: 'Enter the type of property',
  houseNumber: 'House number',
  buildingNumber: 'Building number',
  landNumber: 'Land number',
  appartment: 'Apartment',
  moreInfo: 'Additional information',
  accepted: 'I accept',
  policy: 'Terms of use and privacy policy',
  addInfo: 'Now you need to fill in information about yourself and your property, so that the administrator can contact you and accept your request to join. Required fields are marked with *.',
  infoAboutUk: 'Information about the management company',
  youSelectRole: '<p>You selected the role of a manager, which means that you do not have property in this object, since you represent a management company. Click "Back" if you are a property owner.</p> <p>Registering an object in Sqr, you will always be the administrator of your object, and you will receive the full set of rights.</p>',
  appartementBuilding: 'Apartment building',
  settlement1: 'Village',
  selfGoverment: 'Self-government',
  partnershipOwner: 'Cooperative of owners',
  tsj: 'JSC, JSC, GSK, JSK, cooperatives, etc.',
  managementCompany: 'Management company',
  living: 'Residential property',
  noLiving: 'Commercial property',
  room: 'Room in a communal apartment',
  land: 'Land plot',
  townhouse: 'Apartment in a townhouse',
  share: 'Share',
  join: 'Joint',
  fraction: 'Fraction',
  used: 'Your e-mail is already registered in Sqr',
  usedInHOA: 'Your e-mail is already registered in this object',
  notUsed: 'E-mail is not registered in Sqr',
  continueWith: 'Continue with',
  withEmail: 'or via email',
  withSocial: 'or via social networks',
  incorrectResponse: 'An incorrect request was sent to the server. Please try again in 5 minutes or contact technical support.',
  createJoinRequestTitle: 'The join request was sent to the object administrator %NAME%',
  createJoinRequestMsg: 'In case of approval, you will receive a registration code by email or SMS.\nNow you will be redirected to the object page as a guest.',
  socialNotFoundTitle: 'No account is associated with the specified e-mail',
  socialNotFoundMsg: 'To continue, you need to enter the password from the specified e-mail',
  emailNotAttachedToSocialTitle: 'The specified e-mail belongs to an account associated with a social network',
  emailNotAttachedToSocialMsg: 'To continue, you need to authorize through a social network',
  accountAlreadyExistsTitle: 'Unable to join again',
  accountAlreadyExistsMsg: '<p>You have already joined this object with this social network account. Log in or use another account if you really need to join</p>',
  unknown: 'Something went wrong, please try again in 5 minutes or contact technical support',
  unknownSubdomen: 'Something went wrong, please try again in 5 minutes or contact technical support. Unable to get the object code.',
  owner: 'Owner',
  ownerRole: 'In Sqvr, the owner role implies participation in meetings and decision-making on the agenda, as well as receiving reports and information about the object.',
  manager: 'Manager',
  managerRole: 'The manager is not an owner. This is a staff member of the management company, a staff member of the Owners Association. The manager cannot vote at meetings. If you are both an owner of a room and a staff member of the Owners Association, then choose "Owner"',
  contractor: 'Contractor',
  contractorRole: 'In Sqvr, contractors ...',
  expert: 'Expert',
  expertRole: 'An expert is not an owner. This is a staff member of the management company, a staff member of the Owners Association.',
  avatarError: 'The size of the uploaded file must be no more than 10MB',
  uploadAvatar: 'Upload photo'
};