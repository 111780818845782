"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  registryProblems: 'Проблемы Реестра',
  registryError: 'Ошибка реестра',
  inconsistencyInTheNumberOfRooms: 'Несоответствие количества помещений и/или общей площади помещений, указанных в Настройках, Реестре и в публичных источниках информации',
  inconsistencyInTheNumberOfRoomsShort: 'Несоответствие количества строений, указанных в Настройках и Реестре',
  notAllFields: 'В реестре собственников заполнены не все поля',
  allThisMakesItDifficult: 'Все это затрудняет или делает невозможным:',
  incorrectCountingOfVotes: 'Неправильный подсчет голосов может привести к оспариванию решений собрания.',
  getQuorum: 'Достижение кворума на собрании.',
  automaticCountingOfVotes: 'Автоматический подсчет голосов.',
  sendingReminders: 'Рассылку напоминаний и уведомлений собственникам.',
  generationDocuments: 'Генерацию полностью заполненных документов собрания.',
  noInfoAbout: 'В реестре нет информации:',
  rowInRegistry: 'Строк в реестре, шт',
  votesOnMeeting: 'Голоса на собрании, %',
  allProblemsFixed: 'Все ошибки уже исправлены!',
  registryHasBeenCorrect: 'В настоящее время в реестре исправлены все ошибки и неточности.',
  countAppartments: 'Количество помещений и метраж',
  countBuildings: 'Количество строений',
  inSettings: 'В настройках',
  inRegistry: 'В реестре',
  inOpenSource: 'Реформа ЖКХ',
  m2: 'Площадь, м²'
};