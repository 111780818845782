"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  checkCompare: 'Не совпадает с полем - %FIELD_NAME%',
  checkCompareWithIndex: 'Не совпадает со значением по индексу %INDEX%',
  checkNotCompareWithIndex: 'Не должно совпадать со значением по индексу %INDEX%',
  phone: 'Введите действительный телефонный номер',
  email: 'Введите Email',
  minValue: 'Значение не должно быть меньше %VALUE%',
  maxValue: 'Значение должно быть меньше %VALUE%',
  lessOrEqualValue: 'Значение не должно быть больше %VALUE%',
  checkLetters: 'В строке содержаться запрещенные символы',
  onlyRussianLetters: 'Поле может содержать только русские буквы',
  onlyLatinLetters: 'Поле может содержать только латинские буквы',
  userNameLetters: "Поле может содержать только русские буквы, пробелы и символы -.,;_'",
  onlyNumbers: 'Поле может содержать только цифры',
  undefinedRule: 'Ошибка - Неизвестное правило',
  empty: 'Ошибка - Значение пустое',
  undefinedFunction: 'Функция не найдена',
  bik: 'Значение данного поля не является БИК',
  inn: 'ИНН юр. лица имеет не корректный формат, значение поля должно быть длиной 10 символов.',
  innFull: 'ИНН имеет не корректный формат.',
  snils: 'Значение данного поля не является СНИЛС',
  ogrn: 'Значение данного поля не является ОГРН',
  cardNumber: 'Введите действительный номер карты',
  cvv: 'Введите действительный CVV',
  month: 'Введите действительный месяц',
  year: 'Введите действительный год',
  date: 'Неверное значение даты',
  emailExistInHoa: 'Введите другой email. Этот нельзя использовать повторно.',
  phoneExistInHoa: 'Введите ранее не использованный номер телефона',
  dataIsExists_phone: 'Данный номер телефона уже используется в Вашем объекте',
  dataIsExists_email: 'Данный e-mail уже используется в Вашем объекте',
  minPasswordLength_0: 'Пароль должен состоять минимум из {{count}} символа',
  minPasswordLength_1: 'Пароль должен состоять минимум из {{count}} символов',
  minPasswordLength_2: 'Пароль должен состоять минимум из {{count}} символов',
  duplicatedEmail: 'Вы уже ввели данный email',
  duplicatedPhone: 'Вы уже ввели данный телефон',
  passwordConfirmError: 'Пароль не совпадает',
  phoneUsed: 'Данный телефон уже занят',
  phoneFormatHint: 'Введите телефон в формате: +79112223344',
  required: 'Обязательное поле',
  invalidCardNumber: 'Некорректный номер карты',
  invalidCardHolder: 'Некорректное имя',
  cardHasExpired: 'Закончился срок действия',
  wrongCode: 'Неверный код',
  wrongChars: 'Недопустимые символы',
  nickname: 'Только русские, латинские буквы, пробел или тире',
  minLength_0: 'Минимум {{count}} символ.',
  minLength_1: 'Минимум {{count}} символа.',
  minLength_2: 'Минимум {{count}} символов.',
  maxLength_0: 'Максимум {{count}} символ.',
  maxLength_1: 'Максимум {{count}} символов.',
  maxLength_2: 'Максимум {{count}} символов.',
  remainingNumCharacters: 'Осталось {{count}}',
  minTextLength_0: 'Введите минимум {{count}} символ.',
  minTextLength_1: 'Введите минимум {{count}} символа.',
  minTextLength_2: 'Введите минимум {{count}} символов.',
  maxTextLength: 'Вы превысили лимит, удалите лишние символы.',
  extraCharacters: 'Лишние {{count}}',
  enterValidMobilePhone: 'Введите действительный мобильный телефон',
  enterValidPhone: 'Введите действительный телефонный номер'
};