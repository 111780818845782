"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  EVoteAccepted: 'Voted electronically. Vote counted.',
  EVoteAcceptedAsMinor: 'Voted electronically, but in addition there is an approved paper ballot that takes precedence over electronic voting.',
  EVoteIgnored: 'Voted electronically, but only paper ballots count in this meeting.',
  EVoteNothing: 'Not voted electronically.',
  EVoteNothingIgnored: 'Not voted electronically. Electronic voting is not counted in this meeting.',
  InProcess: 'The ballot has not been processed and submitted to the Chairman and Secretary of the meeting for approval.',
  Accept: 'The ballot is approved. Vote counted.',
  IsAcceptedPresident: 'The ballot is awaiting approval from the Chair of the meeting.',
  IsAcceptedSecretary: 'The ballot is awaiting approval by the Secretary of the meeting.',
  SendToAccept: 'The ballot is awaiting approval by the Chairman and the Secretary of the meeting.',
  Nothing: 'The ballot has not been uploaded. There is not a single page of the scanned copy of the ballot.'
};