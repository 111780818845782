"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  registryProhibitionAlertModalTitle: 'Not enough authority',
  registryProhibitionAlertModalText: 'You do not have sufficient authorization to perform this operation. To add this data about your property, please contact the Administrator or Moderator of your property',
  checkSettingsWarningModalText2: 'In the settings of the object there is not enough free area to add a new room. To add a new owner, you need to increase the area of the rooms in the settings',
  changePersonTypeModalTitle: 'Change user type!',
  checkContactDetailsModalTitle: 'Save entered data?',
  userDeleteWarningModalText: 'Attention! You are about to delete your profile from this object! This action will result in the fact that you will no longer be able to enter this object, you will no longer receive notifications from it. If you have other objects in SQVR, then this will not affect them, you will still be able to use them.',
  warningModalText: '<p>This action will result in the loss of previously entered data. Are you sure you want to change the display format of the share?</p>',
  itIsNotPossibleToDeletePropertyModalText: 'If you want to assign this room to another owner, you can either delete this owner and then add this room to another owner, or reduce the ownership share of this owner and write another owner on the freed up ownership share.',
  joinSocialModalTitle: 'Account merging!',
  registryRedirectModalYesLabel: 'Go to the registry',
  verifiedChangesModalTitlePart2Variant1: 'phone number and e-mail',
  successModalText3: 'you can enter the following objects:',
  itIsNotPossibleToDeletePropertyModalTitle: 'Deleting property is not possible',
  verifiedChangesModalTitlePart1: 'You have made changes to an already registered',
  checkUnsavedDataModalText2: 'You have unsaved data, if you leave the page, they will be lost',
  registryRedirectModalTitle: 'Go to the registry page?',
  userDeleteWarningModalTitleOwn: 'You are about to delete yourself from this object!',
  verifiedChangesModalTitlePart2Variant2: 'phone number',
  checkSettingsWarningModalText1: 'The number of rooms in the settings matches the number of rooms already occupied by owners. To add a new owner, you need to increase the number of rooms in the settings',
  registryRedirectModalText: 'Editing property fields is only possible from the registry.',
  resetCreatingModalText: 'You have unsaved data, if you close the window, they will be lost',
  successModalText2: 'Now with the phone',
  webCamErrorModalText: 'Unable to connect to the camera.',
  checkUnsavedDataModalText1: 'You have made changes to the user card. Save?',
  changePersonTypeModalText: 'You want to change the user type, already registered in the system, while your data will be moved to the "contact person" section.',
  successModalText1: 'Now with e-mail',
  userDeleteWarningModalTitleNotOwn: 'You are deleting a user from the system',
  deleteSelectedPropertyModalTitle: 'Delete selected property?',
  joinSocialModalText: `<p>This social network is already registered in SQVR.</p>
      <p>You can combine accounts and use this social network when logging in.</p>
      <p>Please note, this action cannot be undone!</p>`
};