"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  Integrator: 'Expert',
  Resident: 'Proprietor',
  Contractor: 'Contractor',
  HOAEmployee: 'Employee of the Partnership',
  ManagementStuff: 'Employee of the management company'
};