"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  copyAddress: 'Скопировать ссылку',
  editAddress: 'Редактировать',
  deleteAddress: 'Удалить',
  deleteAddressModalTitle: 'Удалить адрес',
  deleteAddressModalText: 'Вы действительно хотите удалить адрес?',
  deleteAddressModalYes: 'Да, Удалить',
  deleteAddressModalNo: 'Нет, не удалять',
  addressLabel: 'Адрес',
  contactsLabel: 'Контакты',
  descriptionLabel: 'Дополнительная информация',
  reviewLabel: 'Отзывы',
  deleteAddressSuccess: 'Адрес успешно удален',
  deleteReviewSuccess: 'Отзыв успешно удален',
  addFavoriteToast: 'Адрес добавлен в избранное',
  removeFavoriteToast: 'Адрес удален из избранного',
  sendReview: 'Написать отзыв',
  more: 'ещё',
  noReview: 'Пока нет отзывов.',
  beeFirst: 'Будьте первым!',
  clickToButton: 'Нажмите на кнопку, чтобы добавить Ваш первый отзыв.',
  nothingFound: 'Ничего не найдено',
  noAddresses: 'Пока нет полезных адресов.',
  addFirstAddress: 'Нажмите на синюю круглую кнопку с + внизу справа на карте, чтобы добавить Ваш первый полезный адрес.',
  deleteAddressReview: 'Хотите удалить отзыв?',
  deleteAddressReviewText: 'Вы уверены, что хотите удалить отзыв? Внесённые данные будут потеряны.',
  createReviewSuccess: 'Отзыв успешно сохранен.',
  editReviewSuccess: 'Адрес успешно отредактирован',
  createReviewTitle: 'Мой отзыв',
  yourReviewTitle: 'Ваш отзыв',
  ratingLabel: 'Оценка*',
  commentLabel: 'Отзыв',
  submitButton: 'Сохранить',
  cancelButton: 'Отменить',
  tooltipTitle: 'Поставьте оценку',
  copyUrl: 'Ссылка на полезный адрес скопирована',
  emptyReviews: 'Нет отзывов',
  notificationModalTitle: 'Обязательный комментарий',
  notificationReviewModalDesc: 'Вы удаляете отзыв. Прокомментируйте это решение. Этот комментарий обязателен и будет виден всем пользователям. Без комментария отзыв удалить нельзя.',
  notificationAddressModalDesc: 'Вы удаляете адрес. Прокомментируйте это решение. Этот комментарий обязателен и будет виден всем пользователям. Без комментария адрес удалить нельзя.'
};