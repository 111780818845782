"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  registryProblems: 'Registry issues',
  registryError: 'Registry error',
  inconsistencyInTheNumberOfRooms: 'Inconsistency in the number of premises and/or total area of premises indicated in the Settings, the Registry and public sources of information',
  inconsistencyInTheNumberOfRoomsShort: 'Inconsistency of the number of buildings indicated in the Settings and the Register',
  notAllFields: 'Not all fields in the register of owners are filled in',
  allThisMakesItDifficult: 'All of this makes it difficult or impossible:',
  incorrectCountingOfVotes: 'Incorrect counting of votes may lead to a challenge to the decisions of the meeting.',
  getQuorum: 'Achieving a quorum at the meeting.',
  automaticCountingOfVotes: 'Automatic vote counting.',
  sendingReminders: 'Sending out reminders and notices to owners.',
  generationDocuments: 'Generation of fully completed meeting documents.',
  noInfoAbout: 'There is no information in the registry:',
  rowInRegistry: 'Strings in the register, qty',
  votesOnMeeting: 'Votes at the meeting, %',
  allProblemsFixed: 'All the bugs have already been fixed!',
  registryHasBeenCorrect: 'The registry has now corrected all errors and inaccuracies.',
  countAppartments: 'Number of rooms and meterage',
  countBuildings: 'Number of buildings',
  inSettings: 'In the settings',
  inRegistry: 'In the registry',
  inOpenSource: 'Housing and utilities reform',
  m2: 'Area, m²'
};