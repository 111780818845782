"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  noNumberTitle: ' No cadastral number',
  noOrderedTitle: 'The registry has never been ordered',
  noLimitTitle: 'No current limit on the actualization of the meeting',
  noMeetingTitle: 'There are no meetings in the "Pending" or "Currently Taking Place" stage',
  noOrderTitle: 'The registry has never been ordered',
  actualizationTitle: 'Register update',
  alreadyOrderedTitle: 'The registry has already been ordered',
  alreadyActualizationTitle: 'An update of the registry has already been ordered',
  alreadyCompleteTitle: 'The registry has already been updated',
  uknownCountTitle: 'We do not know the number of rooms in the Facility',
  unavailable: 'The service is not available to you.',
  noNumber: '1. There is no cadastral number for the ICD. ',
  noNumberCondo: '1. Not every ICD has a cadastral number. ',
  goToSettings: 'Go to Settings',
  enterNumber: 'to enter the cadastral number.',
  enterNumberCondo: 'to enter cadastral numbers for each condo.',
  noOrder: 'You have never ordered a registry or purchased a paid plan.',
  noLimit: 'There is no paid available limit on registry updates.',
  orderRegistry: 'Order the register',
  or: ' or ',
  buyTariff: 'buy paid plan',
  noMeetings: 'There are no meetings in the "Pending" or "Currently Taking Place" stage. Publish the meeting first.',
  noLabel: 'Close',
  goToTariffs: 'Go to plans',
  noLimitDescription: 'You do not have a valid limit for updating the registry. Go to ',
  tariffs: 'Tariffs',
  chooseTariffPlan: ' and choose any tariff plan or select the registry update service from ',
  oneTimeTariff: '"Separate limits."',
  alreadyOrdered: 'This service has already been ordered ',
  registry: 'registry.',
  actialization: 'updating the registry.',
  wait: 'Wait for the order to be fulfilled (approximately {{days}} business days) and download the updated registry.',
  orderCompletes: 'The order has been filled. You should not update the register again.',
  questions: 'If you have any questions, ',
  writeToSupport: 'contact our tech support',
  click: 'If you still find it necessary to order the registry update again, click on the "Update Registry" button.',
  payForTariff: 'pay for any of the Service Plans',
  actualize: 'Update the register',
  noNumberTooltip1: '   We cannot update the register of owners because we do not know what to compare it with. You have never ordered a service from us for the formation of the register itself. Updating of the register implies that a reliable register of owners has already been formed earlier, which is formed on the basis of extracts from the USRN for each premises included in the MFB.',
  noNumberTooltip2: '   We cannot be certain that the data currently stored in the registry is complete and not inconsistent.',
  noNumberTooltip3: '   Start by entering the correct cadastral number in the ',
  noNumberTooltip4: 'Settings',
  unknownCountTooltip: '   We are currently working on obtaining an extract for the building. As soon as we finish this work you will be able to order and pay for the register of owners.',
  orderRunning: 'The registry order is in progress',
  orderRunningTooltip1: '   We are currently working on the first order of your homeowner registry. We cannot update the register of owners because we do not know what to compare it with. Updating the register implies that there is already a reliable register of owners, which was formed on the basis of extracts from the USRN for each premises included in the MFB.',
  orderRunningTooltip2: 'Wait for the ordered registry.',
  yet: 'The service is not yet available to you.',
  waitOrder: "Wait for the order for the Owners' Register for your building to be fulfilled (approximately {{count}} working days). As soon as the order is fulfilled, you will receive an automatic notification.",
  attention1: 'Please note that a roster update will be available once your home has at least one meeting in the "Pending" or "Currently Taking Place" stage.',
  after: 'After this you will then be able to update the ownership register.',
  noLimit2: '2. After that you will need to pay for the registry update service. To do this, you need to choose any of the paid tariff plans or pay for the registry actualization service from "Separate Limits".',
  attention2: 'Please note that the registry update will be available after you have',
  available: 'You can update the ownership registry.',
  lastLimit: 'This is the last limit for this service that has not yet been used up.',
  limits: 'You will then be able to update the registry again ',
  limitsCount1: '{{count}} times',
  limitsCount2: '{{count}} time',
  moreLimits1: 'For future owner registry updates, you will need to go to Fees, select and pay for any plan or pay for the registry update service from "Separate Limits".',
  moreLimits2: 'In order to increase the limit for updating the register of owners you will need to go to Tariffs, select and pay for any tariff plan or pay for the service of updating the register from "Separate Limits".',
  complete: ' and completed',
  noNeedOrderAgain: '. You do not need to order another update of the register of owners.',
  noAgain: 'You cannot update the registry again because',
  noLimit3: 'You cannot update the registry again because there is no paid limit for registry update. Go to Tariffs and select and pay for any tariff plan or select and pay for the registry update service from "Separate Limits".',
  noMeetingsNoNumber: 'There are no meetings in the "Pending" or "In progress" stage. After completing paras. 1-3, publish the meeting.',
  noMeetings2: 'There are no meetings in "Pending" or "Currently in progress" status. After completing step 1 and step 2, publish the meeting.'
};