"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  Promise: 'Обещает проголосовать',
  NoActions: 'Не контактировали',
  Inaccessible: 'Не получается связаться',
  AbsentContacts: 'Нет контактов',
  DenyParticipate: 'Не может участвовать',
  Dismiss: 'Отказался',
  NextDateContact: 'Дата следующего контакта'
};