"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  greetings: 'Hello',
  owner: 'owner',
  rooms_0: 'rooms',
  rooms_1: 'rooms',
  rooms_2: 'rooms',
  phone: 'Your phone',
  getValidationCode: 'Get code',
  getValidationCodeAgain: 'Request code again',
  resendingCodeTime: 'in {{time}} sec.',
  signContract: 'You have signed the management contract!',
  registerText1: 'Register in Sqver',
  registerText2: 'to use all the features of the system to participate in the life of your home!',
  signInToSQVRText1: 'You can',
  signInToSQVRText2: 'sign in to Sqvr',
  contractHasAlreadySigned: 'The contract at your link has already been signed earlier!',
  viewContract: 'View contract',
  pageNotFound: 'Such a page does not exist.',
  infoModalText1: 'In accordance with part 1 of Article 162 of the Housing Code of the USA, the management contract can be concluded in electronic form using the system by compiling a single document signed by the parties. We offer you to sign the following text of the contract. Signing is carried out using a simple electronic signature (PEP, Federal Law “On Electronic Signature” of 06.04.2011 No. 63-FZ Article 9) by sending a unique identifier: SMS message to the mobile phone number of the owner or his legal representative. By signing the contract, you confirm that you have the necessary powers by law or on the basis of a duly executed power of attorney.',
  infoModalText2: 'What do you need to do?',
  infoModalText3: '1. Carefully review the contract',
  infoModalText4: '2. Check the correctness of entering your phone number',
  infoModalText5: '3. If you agree to conclude the management contract, click the “Get code” button',
  infoModalText6: '4. You will receive an SMS with a unique identifier - a six-digit code',
  infoModalText7: '5. Enter the received code',
  infoModalText8: 'The contract will come into force as soon as it is signed by the owners who have more than 50% of the votes from the total number of votes of the owners of the rooms in your home.',
  checkSms: 'Check SMS, we sent you a confirmation code.',
  wrongCode: 'Incorrect confirmation code.'
};