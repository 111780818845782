"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  moderatorTolltipTitle: 'You are a moderator and cannot disable receiving information',
  adminTooltipTitle: 'You are an administrator and cannot turn off receiving information',
  tooltipTitle: 'You can change these settings by going to edit mode',
  settingTitle: 'Notification settings',
  commonNotificaionLabel: 'Receive news about the house and upcoming events in the house to your inbox',
  privateNotificationLabel: 'Receive personal notifications and messages to your email',
  telegramNotificationLabel: 'Receive notifications in telegram',
  modalTextOff: 'You disable receiving notifications from SQVR in Telegram - bot, are you sure ?',
  noOff: "No, don't turn it off",
  yesOff: 'Yeah, shut it down',
  tgConnect: 'Connect Telegram bot',
  multiInfo: 'Please note that when you connect a telegram bot, you will receive telegram notifications for all your items.',
  sendLink: 'Send a link to connect a Telegram bot',
  telegramConnected: 'Telegram bot connected',
  linkWasSent: 'The link was sent',
  success: 'Connection link successfully sent {{user}}',
  social: 'Social networks',
  email: 'Email',
  socialText: 'Use social networks to log in to SQVR. We will never publish messages to social networks or send messages to your friends without your permission.'
};