"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const link = '<a href="{{link}}" style="font-weight: 500; font-size: 14px;">Go to settings»</a>';
exports.default = {
  modalTitle: 'Necessary conditions are not met',
  settingsTitle: 'The number of rooms is not specified in the House Settings',
  modalOk: 'Close',
  noNumber1: 'The correct cadastral number for the condo is not entered.',
  noNumberForGroup1: 'The correct cadastral numbers for each condo included in your Facility have not been entered.',
  noNumber2: '${link}, enable the edit mode and enter the corresponding cadastral number of the capital construction object.',
  noNumberForGroup2: '${link}, enable the edit mode and enter the corresponding cadastral numbers for each capital construction object.',
  thereIsNumber: 'There is a correct cadastral number for the condo.',
  thereIsNumberForGroup: 'The cadastral numbers for each condo included in your Facility are known.',
  statementWasNotReceivedText1: 'Extract from the registration of real estate with cadastral numbers of all premises has not been received.',
  statementWasNotReceivedTextForGroup1: 'Extracts from the registration of real estate with cadastral numbers of premises for each condo included in your Facility have not been received.',
  statementWasNotReceivedText2: 'Wait until you receive this statement from Sqvr.',
  statementHasBeenReceived: 'Extract from the registration of real estate with cadastral numbers of all premises has not been received.',
  statementHasBeenReceivedForGroup: 'Extracts from the registration of real estate with cadastral numbers of all premises for each condo included in your Facility have been obtained.',
  noApartment1: 'The number of spaces in the condo is not known.',
  noApartmentForGroup1: 'The number of rooms in each condo included in your Facility is not known.',
  noApartment2: '${link}, enable edit mode and enter a valid value for the number of rooms.',
  noApartmentForGroup2: '${link}, enable edit mode and enter the allowable value for the number of rooms in each ICD included in your Facility.',
  numbersOfApartmentsIsKnown: 'The number of premises in the condo is known.',
  numbersOfApartmentsIsKnownForGroup: 'The number of rooms in each condo included in your Facility is not known.',
  wait: 'Wait until you receive your statement. We will send you a message.',
  item3: '3. After completing steps. after steps 1 and 2, you will be able to order the registry or pay for a plan that already includes ordering the registry.',
  cadastralDescriptionThird: 'If you believe that the cadastral number of your apartment building is incorrect, please contact',
  cadastralDescriptionThirdLink: 'technical support',
  goToSettings: 'You cannot order a register of owners now because the number of rooms is not specified in the "Settings" of your building. We need this data to calculate the cost of paid services. Without them you will not be able to use any paid services. Go to \'',
  settings: 'Settings',
  enterData: '" and enter the required data.',
  settingsButton: 'Go to Settings',
  unavailable: 'The service of ordering a register of owners is not available.',
  noSettings: 'You must specify the total number of rooms in the House Settings.',
  after: 'Once you have done this, the registry ordering service will be available to you.',
  cadastralNumberYourHouse: 'The cadastral number of your house',
  cadastralNumbersYourHouses: 'Cadastral numbers of your houses',
  noNumberCondo: '1. We do not yet know the number of premises and their cadastral numbers for each condo included in your Object.',
  noApartmentCondo: '2. The number of rooms is not entered for each condo in the Object Settings.',
  noSettingsCondo: 'It is necessary to specify the total number of rooms for each condo in the Object Settings.',
  modalSubtitle: 'The service is not available to you. To order the registry, the following conditions must be met at the same time',
  cadastralNumberWithCountApartmentsText: 'In a residential building with cadastral number',
  cadastralNumberWithCountApartmentsForCondoText: 'In the group of condo with cadastral numbers'
};