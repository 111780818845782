"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var PostAuthScenarioType;
(function (PostAuthScenarioType) {
  PostAuthScenarioType["Poll"] = "poll";
  PostAuthScenarioType["Meeting"] = "meeting";
  PostAuthScenarioType["Signing"] = "signing";
})(PostAuthScenarioType || (PostAuthScenarioType = {}));
exports.default = PostAuthScenarioType;