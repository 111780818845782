"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const link = '<a href="{{link}}" style="font-weight: 500; font-size: 14px;">Перейдите в «Настройки»</a>';
exports.default = {
  modalTitle: 'Необходимые условия не выполнены',
  settingsTitle: 'В Настройках дома не указано количество помещений',
  modalOk: 'Закрыть',
  noNumber1: 'Не введён правильный кадастровый номер для МКД.',
  noNumberForGroup1: 'Не введены правильные кадастровые номера для каждого МКД, входящего в ваш Объект.',
  noNumber2: `${link}, включите режим редактирования и внесите соответствующий кадастровый номер объекта капитального строительства.`,
  noNumberForGroup2: `${link}, включите режим редактирования и внесите соответствующие кадастровые номера для каждого объекта капитального строительства.`,
  thereIsNumber: 'Есть правильный кадастровый номер для МКД.',
  thereIsNumberForGroup: 'Известны кадастровые номера для каждого МКД, входящего в ваш Объект.',
  statementWasNotReceivedText1: 'Не получена выписка из ЕГРН с кадастровыми номерами всех помещений.',
  statementWasNotReceivedTextForGroup1: 'Не получены выписки из ЕГРН с кадастровыми номерами помещений для каждого МКД, входящего в ваш Объект.',
  statementWasNotReceivedText2: 'Дождитесь получения от Сквера этой выписки.',
  statementHasBeenReceived: 'Получена выписка из ЕГРН с кадастровыми номерами всех помещений.',
  statementHasBeenReceivedForGroup: 'Получены выписки из ЕГРН с кадастровыми номерами всех помещений для каждого МКД, входящего в ваш Объект.',
  noApartment1: 'Не известно количество помещений в МКД.',
  noApartmentForGroup1: 'Не известно количество помещений в каждом МКД, входящем в ваш Объект.',
  noApartment2: `${link}, включите режим редактирования и внесите допустимое значение количества помещений.`,
  noApartmentForGroup2: `${link}, включите режим редактирования и внесите допустимое значение количества помещений в каждом МКД, входящем в ваш Объект.`,
  numbersOfApartmentsIsKnown: 'Известно количество помещений в МКД.',
  numbersOfApartmentsIsKnownForGroup: 'Известно количество помещений в каждом МКД, входящем в ваш Объект.',
  wait: 'Дождитесь получения выписки. Мы пришлем Вам сообщение.',
  item3: '3. После выполнения пп. 1 и 2 появится возможность заказать реестр или оплатить тарифный план, в который заказ реестра уже включен.',
  cadastralDescriptionThird: 'Если Вы считаете, что здесь указан неправильный кадастровый номер Вашего МКД обратитесь пожалуйста в ',
  cadastralDescriptionThirdLink: 'техническую поддержку',
  goToSettings: 'Вы не можете сейчас заказать реестр собственников, потому что в “Настройках” Вашего дома не указано количество помещений. Эти данные нам необходимы для рассчета стоимости платных услуг. Без них Вы не сможете использовать никакие платные услуги. Перейдите в “',
  settings: 'Настройки',
  enterData: '“ и внесите нужные данные.',
  settingsButton: 'Перейти в Настройки',
  unavailable: 'Услуга заказа реестра собственников не доступна.',
  noSettings: 'Необходимо указать в Настройках дома  общее количество помещений.',
  after: 'После этого Вам будет доступна услуга заказа реестра.',
  cadastralNumberYourHouse: 'Кадастровый номер Вашего дома - ',
  cadastralNumbersYourHouses: 'Кадастровые номера Ваших домов:',
  noNumberCondo: '1. Мы пока не знаем количество помещений и их кадастровые номера для каждого МКД, входящих в Ваш Объект.',
  noApartmentCondo: '2. В Настройках Объекта не для каждого МКД введено количество помещений.',
  noSettingsCondo: 'Необходимо указать в Настройках Объекта  общее количество помещений для каждого МКД.',
  modalSubtitle: 'Услуга Вам не доступна. Для заказа реестра необходимо, чтобы одновременно выполнялись следующие условия:',
  cadastralNumberWithCountApartmentsText: 'В МКД с кадастровым номером ',
  cadastralNumberWithCountApartmentsForCondoText: 'В группе МКД с кадастровыми номерами: '
};