"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  copywrite: 'Сквер. Все права защищены, {{year}}',
  policyAndLicenseLinkText: 'Политика конфиденциальности и условия использования',
  policyAndLicenseModalTitle: 'Политика конфиденциальности и условия использования',
  policyAndLicenseModalButtonClose: 'Закрыть',
  googleAppLink: 'https://play.google.com/store/apps/details?id=com.sqvr.app',
  appStoreAppLink: 'https://apps.apple.com/us/app/sqvr/id6443655174',
  ruStoreAppLink: 'https://www.rustore.ru/catalog/app/com.sqvr.app',
  commonAppLink: 'https://app.sqvr.ru/',
  googleAppLinkText: 'Скачать приложение в Google Play',
  appStoreAppLinkText: 'Скачать приложение в App Store',
  ruStoreAppLinkText: 'Скачать приложение в RuStore',
  commonAppLinkText: 'Скачать приложение'
};