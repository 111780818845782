"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  phone: 'Enter your phone number. We need to save it so that you can use two-factor authorization and confirm your vote with a simple electronic signature when voting electronically in the meeting.',
  email: 'Also enter your e-mail address. Then you will be able to receive messages about all events in the house or township and reduce your sms costs.',
  password: 'Make up a password.',
  passwordConfirm: 'Repeat the password to avoid the error.'
};