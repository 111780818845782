"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  joiningRequests: 'Заявки на присоединение',
  legendTitleNoRequests: 'У Вас нет новых заявок на присоединение. После появления заявок вот что Вам нужно сделать:',
  legendTitlePart1: 'Всего имеется',
  legendTitlePart2_0: 'заявка на присоединение. Вот что Вам нужно сделать:',
  legendTitlePart2_1: 'заявки на присоединение. Вот что Вам нужно сделать:',
  legendTitlePart2_2: 'заявок на присоединение. Вот что Вам нужно сделать:',
  legendItem1Part1: 'Выберите заявки (используйте чекбоксы',
  legendItem1Part2: '), которые хотите принять и кликните на "Принять заявки".',
  legendItem2: 'Все непротиворечивые заявки будут автоматически одобрены.',
  legendItem3: 'Заявки, противоречащие данным реестра, будут выделены как голубые строки, и в них  будут подчеркнуты поля с ошибками. Вы можете навести курсор на эти поля и увидеть подсказку с описанием ошибки. Отредактируйте строки реестра для того, чтобы снять противоречия.',
  legendItem4: 'Как только данные в заявке (голубой строке) станут непротиворечащими данным реестра, строка поменяет свой цвет, и заявка будет принята.',
  legendItem5: 'После одобрения заявки собственник получит об этом уведомление на свой e-mail.',
  legendItem6: 'Удаление или очистка голубых строк ведет к отклонению заявок. Они будут удалены.',
  acceptRequests: 'Принять заявки',
  rejectRequests: 'Отклонить заявки',
  reject: 'Отклонить',
  owners: 'Собственники',
  apartment: 'Пом.',
  requestsAcceptedSuccessfully_0: 'Заявка на присоединение {{count}} шт. успешно принята. Сейчас на адрес электронной почты или на мобильный телефон собственника будет автоматически выслано уведомление с предложением подтвердить свой адрес электронной почты или свой номер телефона. Если какой-то пользователь не получил или не отреагировал на уведомление, у вас есть возможность отправить такое же уведомление этому собственнику из его профиля нажав на кнопку "Отправить код приглашения".',
  requestsAcceptedSuccessfully_1: 'Заявки на присоединение {{count}} шт. успешно приняты. Сейчас на адреса электронной почты или на мобильные телефоны собственников будут автоматически высланы уведомления с предложением подтвердить свой адрес электронной почты или свой номер телефона. Если какой-то пользователь не получил или не отреагировал на уведомление, у вас есть возможность отправить такое же уведомление этому собственнику из его профиля нажав на кнопку "Отправить код приглашения".',
  requestsRejectedSuccessfully_0: '{{count}} заявка на присоединение успешно отклонена',
  requestsRejectedSuccessfully_1: '{{count}} заявки на присоединение успешно отклонены',
  requestsRejectedSuccessfully_2: '{{count}} заявок на присоединение успешно отклонены',
  requestsAcceptedWithConflicts_0: '{{count}} заявка требует ручной обработки',
  requestsAcceptedWithConflicts_1: '{{count}} заявки требуют ручной обработки',
  requestsAcceptedWithConflicts_2: '{{count}} заявок требуют ручной обработки',
  rejectRequestsConfirmationTitle_0: 'Вы собираетесь отклонить {{count}} заявку',
  rejectRequestsConfirmationTitle_1: 'Вы собираетесь отклонить {{count}} заявки',
  rejectRequestsConfirmationTitle_2: 'Вы собираетесь отклонить {{count}} заявок',
  rejectionCommentPlaceholder: 'Прокомментируйте причину отклонения',
  buttonsDisabledText: 'Выберите необходимые заявки'
};