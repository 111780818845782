"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  usersInvite: 'Приглашение пользователей',
  usersNotification: 'Уведомление пользователей',
  userNameTemplate: 'ФИО',
  selectedRows_0: '{{count}} строку',
  selectedRows_1: '{{count}} строки',
  selectedRows_2: '{{count}} строк',
  rowsRelated_0: 'из них {{count}} строка относится',
  rowsRelated_1: 'из них {{count}} строки относятся',
  rowsRelated_2: 'из них {{count}} строк относятся',
  registeredUsersWarning1_0: 'Невозможно пригласить выбранного Вами пользователя, потому что он уже зарегистрирован в Сквер',
  registeredUsersWarning1_1: 'Невозможно пригласить выбранных Вами пользователей, потому что они уже зарегистрированы в Сквер',
  registeredUsersWarning1_2: 'Невозможно пригласить выбранных Вами пользователей, потому что они уже зарегистрированы в Сквер',
  registeredUsersWarning2: 'Вы выбрали {{selection}}, {{relation}} к уже зарегистрированным пользователям. Мы отправим приглашения только незарегистрированным пользователям.',
  registeredUsersWarning3_0: 'Невозможно пригласить выбранного Вами пользователя, потому что он находится во временной (синей) строке и еще не сохранен',
  registeredUsersWarning3_1: 'Невозможно пригласить ни одного из выбранных Вами пользователей, потому что они находятся во временных (синих) строках и еще не сохранены',
  registeredUsersWarning4: 'Невозможно пригласить пользователей, так как реестр пуст',
  notRegisteredNotContactUsersSingleWarning_0: 'У выбранного Вами незарегистрированного собственника нет информации ни о телефонах, ни о e-mail. Поэтому на электронную почту Вы получите файл с печатными формами приглашений для этого собственника.',
  notRegisteredNotContactUsersSingleWarning_1: 'Из выбранных {{count}} незарегистрированных собственников у одного незарегистрированного собственника нет информации ни о телефонах, ни о e-mail. Поэтому на электронную почту Вы получите файл с печатными формами приглашений для этого собственника.',
  notRegisteredNotContactUsersSingleWarning_2: 'Из выбранных {{count}} незарегистрированных собственников у одного незарегистрированного собственника нет информации ни о телефонах, ни о e-mail. Поэтому на электронную почту Вы получите файл с печатными формами приглашений для этого собственника.',
  notRegisteredNotContactUsersMultipleWarning: 'Из выбранных {{count}} незарегистрированных собственников у {{noContactUsersNum}} собственников нет информации ни о телефонах, ни о e-mail. Поэтому на электронную почту Вы получите файл с печатными формами приглашений для этих собственников.',
  otherWillReceiveInvite: 'Остальные получат приглашение по электронной почте и СМС.',
  invitesHaveSuccessfullySent: 'Приглашения успешно отправлены',
  deliveryCanTakeSomeTime_0: 'Доставка приглашения может занять некоторое время',
  deliveryCanTakeSomeTime_1: 'Доставка приглашений ({{count}} шт.) может занять некоторое время',
  deliveryCanTakeSomeTime_2: 'Доставка приглашений ({{count}} шт.) может занять некоторое время',
  notificationDeliveryCanTakeSomeTime_0: 'Доставка уведомления может занять некоторое время',
  notificationDeliveryCanTakeSomeTime_1: 'Доставка уведомлений ({{count}} шт.) может занять некоторое время',
  notificationDeliveryCanTakeSomeTime_2: 'Доставка уведомлений ({{count}} шт.) может занять некоторое время',
  noOneInviteSendingChannelSelected: 'Вы не выбрали ни один канал доставки. Мы отправим печатные формы приглашения на вашу почту',
  inviteSendingChannelWarning: 'Приглашение не будет отправлено пользователям, у которых не указан {{sendingMethod}}. Мы отправим печатную форму приглашения для них на вашу почту.',
  notifySendingChannelWarning: 'Уведомление не будет отправлено пользователям, у которых не указан {{sendingMethod}}.',
  emailOrPhoneChannel: 'номер телефона или e-mail',
  emailChannel: 'e-mail',
  phoneChannel: 'номер телефона',
  inviteSentSuccessfully_0: 'Приглашение успешно отправлено',
  inviteSentSuccessfully_1: 'Приглашения успешно отправлены',
  inviteSentSuccessfully_2: 'Приглашения успешно отправлены',
  notificationSentSuccessfully_0: 'Уведомление успешно отправлено',
  notificationSentSuccessfully_1: 'Уведомления успешно отправлены',
  notificationSentSuccessfully_2: 'Уведомления успешно отправлены',
  inviteAllNotRegisteredUsers: 'Пригласить всех незарегистрированных пользователей',
  inviteOwnersIntelegram: 'Приглашение в Telegram-бот',
  inviteOwnersInSqvr: 'Пригласить собственников в Сквер',
  smsEnableHint: 'Если вам необходимо отправлять SMS, обратитесь в техподдержку для подключения данной услуги!',
  addUsersHint: 'Для добавления получателей кликните здесь',
  changeUsersHint: 'Для добавления или удаления получателей кликните здесь',
  userNameMailHint: 'Здесь будут вставлены известные нам Имена и Отчества выбранных Вами пользователей. Если нам не известно имя, то ничего вставлено не будет',
  notEditableFieldsHint: 'Данные поля не редактируемые и заполняются системой автоматически',
  previewHint: 'Кликните здесь для того, чтобы увидеть как будет выглядеть сообщение на экране получателя',
  cantSend: 'Действие не доступно. Не во всех выбранных Вами способах доставки(e-mail или SMS) есть текст самого сообщения. Внесите текст сообщения для каждого способа отправки или уменьшите способы отправки сообщения.',
  greetings: 'Здравствуйте',
  nameMailSubstitution: '{ФИО получателя будут вписаны автоматически}!',
  smsInvitePlaceholder: 'СКВЕР. Приглашаю в {{hoaName}} https://{{appHost}}/api/v1/u/XXXXXX {{currentUserName}}',
  smsInvitePlaceholderHoaName: '_НАЗВАНИЕ_ОБЪЕКТА_',
  smsInvitePlaceholderLink: '_ССЫЛКА_',
  smsInvitePlaceholderUserName: '_ИМЯ_ПРИГЛАШАЮЩЕГО_',
  notifications: 'Уведомление',
  inviteToSqvr: 'Приглашение в Сквер',
  notificationsPreview: 'Предпросмотр',
  invitePreview: 'Предпросмотр приглашения',
  whom: 'Кому',
  highPriority: 'Высокий приоритет',
  recipientsCount_0: ' ({{count}} получатель)',
  recipientsCount_1: ' ({{count}} получателя)',
  recipientsCount_2: ' ({{count}} получателей)',
  recipientsCountNoRecipients: ' (Нет получателей)',
  smsUsedSymbols_0: '{{count}} символ из {{total}}',
  smsUsedSymbols_1: '{{count}} символа из {{total}}',
  smsUsedSymbols_2: '{{count}} символов из {{total}}',
  smsUsedSymbolsShort_0: '{{count}} символ',
  smsUsedSymbolsShort_1: '{{count}} символа',
  smsUsedSymbolsShort_2: '{{count}} символов',
  smsCount: '{{count}} SMS',
  smsTotal: '{{total}} всего',
  saveDialogTitle: 'Сохранение введенных данных!',
  saveDialogText: 'При закрытии вы потеряете введенные данные. Хотите сохранить их?',
  saveDialogYesLabel: 'Сохранить и выйти',
  saveDialogNoLabel: 'Удалить и выйти',
  inviteUserSelectionHint: 'Выберите всех собственников, которых вы хотите пригласить в Cквер',
  notifyUserSelectionHint: 'Выберите всех собственников, которым хотите отправить уведомление',
  inviteMailPreview1: 'После регистрации Вы сможете:',
  inviteMailPreview2: 'получать достоверную и оперативную информацию о состоянии дел в {Тип Объекта}',
  inviteMailPreview3: 'принимать участие в электронном голосовании',
  inviteMailPreview4: 'получать отчеты о работе и устранению недостатков',
  inviteMailPreview5: 'создавать опросы и принимать в них участие',
  inviteMailPreview6: 'и многое другое',
  inviteMailPreview7: 'Для регистрации нажмите кнопку “ПРИНЯТЬ ПРИГЛАШЕНИЕ” ниже.',
  inviteMailPreview8: 'Принять приглашение',
  inviteMailPreview9: 'Ваши персональные данные получены в рамках подготовки к проведению общего собрания собственников в соответствии с п.3.1. статьи 45 ЖК РФ.',
  inviteMailPreview10: 'Собственник {ИМЯ+ОТЧЕСТВО}',
  inviteMailPreview11: 'Если у Вас остались вопросы, Вы можете связаться со мной по почте:',
  youHaveSelected: 'Вы выбрали',
  onlyGroups_0: '{{count}} группу собственников',
  onlyGroups_1: '{{count}} группы собственников',
  onlyGroups_2: '{{count}} групп собственников',
  onlyUsers_0: '{{count}} собственника',
  onlyUsers_1: '{{count}} собственника',
  onlyUsers_2: '{{count}} собственников',
  notifyNotRegisteredUsersWarning: 'Невозможно отправить уведомления выбранным пользователям. Они еще не зарегистрированы в Сквере.',
  registryNotifyNotRegisteredUsersWarning: 'Вы выбрали {{selection}}, {{relation}} к пользователям, еще не зарегистрированным в Сквер, и им невозможно отправить уведомление',
  selectedUser_0: '{{count}} выбранного собственника',
  selectedUser_1: '{{count}} выбранных собственников',
  selectedUser_2: '{{count}} выбранных собственников',
  notRegisteredUser_0: '{{count}} собственник не зарегистрирован',
  notRegisteredUser_1: '{{count}} собственника не зарегистрированы',
  notRegisteredUser_2: '{{count}} собственников не зарегистрированы',
  usersRelated_0: '{{count}} собственник относится',
  usersRelated_1: '{{count}} собственника относятся',
  usersRelated_2: '={{count}} собственников относятся',
  userWithoutContactData: 'У {{selectedUsers}} из {{allUsersCount}} отсутствует e-mail или телефон для связи.',
  selectionWithNotRegisteredUserAndWithoutEmail: 'У {{selectedUsers}} отсутствует e-mail или телефон для связи и {{notRegisteredUsers}} из {{allUsersCount}}.',
  selectionWithNotRegisteredUsers_0: '{{count}} выбранный собственник относится к пользователям, еще не зарегистрированным в Сквер, и ему невозможно отправить уведомление',
  selectionWithNotRegisteredUsers_1: '{{count}} выбранных собственника относятся к пользователям, еще не зарегистрированным в Сквер, и им невозможно отправить уведомление',
  selectionWithNotRegisteredUsers_2: '{{count}} выбранных собственников относятся к пользователям, еще не зарегистрированным в Сквер, и им невозможно отправить уведомление',
  selectionWithNoContactUsersSingle: 'У выбранного собственника отсутствует e-mail или телефон для связи и ему невозможно отправить уведомление',
  selectionWithNoContactUsers: 'У {{selectedUsers}} из {{allUsersCount}} отсутствует e-mail или телефон для связи и им невозможно отправить уведомление',
  needToSelectSendingChannel: 'Необходимо выбрать хотя бы один канал доставки',
  usersNotificationConfirmationTitle: 'Подтвердите отправку',
  usersNotificationConfirmationRecipientsTotal_0: 'Выбран {{count}} получатель ',
  usersNotificationConfirmationRecipientsTotal_1: 'Выбрано {{count}} получателя ',
  usersNotificationConfirmationRecipientsTotal_2: 'Выбрано {{count}} получателей ',
  usersNotificationConfirmationCannotBeNotified_0: '{{count}} пользователь не получит сообщение: у пользователя не указаны контактные данные, или отключены уведомления.',
  usersNotificationConfirmationCannotBeNotified_1: '{{count}} пользователя не получат сообщение: у пользователей не указаны контактные данные, или отключены уведомления.',
  usersNotificationConfirmationCannotBeNotified_2: '{{count}} пользователей не получат сообщение: у пользователей не указаны контактные данные, или отключены уведомления.',
  chooseUsers: 'Выбрать получателей',
  sendMe: 'Отправить копию себе',
  sendMeInfoText: '<ol style="list-style-type: decimal; padding-left: 24px; font-weight: 500">' + '<li>Вы можете включить или отключить отправку Вашего уведомления самому себе.' + '<li>Обычно инициаторы рассылки хотят получать копию своего же уведомления, чтобы видеть как оно выглядело при отправке и убедиться, что рассылка прошла успешно.</ol>',
  choseOrDeleteUsersTooltip: 'Для добавления или удаления получателей кликните здесь',
  choseUsersTooltip: 'Для добавления получателей кликните здесь',
  change: 'Изменить список получателей',
  statisticsTooltipUsers: 'Вы еще не выбрали ни одного собственника. Мы не можем показать статистику рассылки',
  hasNoLimit: 'Недостаточно лимитов',
  statisticsTooltipText: 'Вы не ввели текст сообщения. Мы не можем показать статистику рассылки',
  statisticsTooltipText1: 'Нажмите, чтобы посмотреть предварительные результаты рассылки приглашений. Для просмотра поименного списка получателей и внесения изменений в него нажмите на кнопку справа "Изменить список получателей".',
  statisticsTooltipText2: 'Нажмите, чтобы посмотреть предварительные результаты рассылки приглашений.',
  recipients: 'Получатели',
  cancelChoice: 'Очистить выбор',
  cancelChoiceAlertText: 'Вы хотите очистить список выбранных пользователей. Данное действие нельзя отменить!',
  clean: 'Очистить',
  revertChoice: 'Отказаться от изменения выбора',
  revertChoiceAlertText: 'Вы хотите отказаться от сделанных изменений и вернуться к предыдущему списку выбранных пользователей? Данное действие нельзя отменить!',
  revert: 'Отказаться',
  close: 'Закрыть',
  cancel: 'Отмена',
  continue: 'Выбрать и продолжить',
  notHaveSelectedUsers: 'Не выбран ни один получатель',
  continueDisabledTooltip: 'Сначала нужно выбрать получателей',
  notificationWarningTooltip: 'Пользователи, которые могут не получить сообщение',
  statisticsTitle: 'Статистика рассылки',
  detailStatisticsTitle: 'Подробная статистика рассылки',
  detailStatisticsText0: 'Обратите внимание, что из ',
  detailStatisticsText1: 'Из ',
  detailStatisticsText2: ' выбранных пользователей: ',
  detailStatisticsText3: ' сразу могут получить уведомление',
  detailStatisticsText4: ' могли бы получить уведомление, но не хватает лимитов',
  detailStatisticsText5: ' в любом случае не могут получить уведомление',
  detailStatisticsText6: 'Для того, чтобы Вы потом не искали вручную пользователей, которым мы не сможем отправить уведомления из-за нехватки лимитов, Вам сначала нужно докупить недостающие лимиты, а уже потом отправить уведомление.',
  items: 'шт',
  allSelected: 'Всего выбрано получателей',
  canNotify: 'Могут получить',
  notReceive: 'Не могут получить',
  needEmailLimit: 'Не хватает лимитов e-mail',
  needSmsLimit: 'Не хватает лимитов SMS',
  receive: 'Могут получить сразу',
  finalResult: 'Итоговый результат рассылки',
  usedLimits: 'Использование лимитов при данной рассылке',
  counts: ' {{current}} шт. из {{total}}',
  limitsError: ' (недостаточно лимитов) ',
  buyMore: ' Докупить',
  showMore: 'Посмотреть подробнее',
  yesBuy: 'Да, докупить',
  yseSend: 'Да, отправить',
  buyLimitsButton: 'Докупить лимиты',
  hasNoLimits: 'Не хватает лимитов',
  afterYouCanReturn: 'После покупки лимитов Вы можете вернуться на эту страницу и закончить рассылку уведомлений.',
  sendWithoutLimitText: 'Вы уверены, что хотите отправить уведомление только тем, кто может его получить и не докупать лимиты?',
  area: 'Площадь, м²',
  vote: 'Голоса, %',
  include: 'В том числе:',
  hasEmail: 'E-mail',
  hasPhone: 'SMS',
  hasPush: 'Push-уведомления в приложении Сквер',
  hasTgBot: 'Telegram-bot',
  hasApp: 'Подключили мобильное приложение Сквер',
  notRegistered: 'Не зарегистрированы в Сквере',
  isRegistered: 'Уже зарегистрированы в Сквере',
  disabledNotification: 'Отключили получение сообщений от Сквера',
  spamList: 'Внесли Сквер в список спаммеров',
  onlySms: 'Получат только sms сообщения',
  onlyEmail: 'Получат сообщение только на e-mail',
  bothOrMore: 'Получат сообщение двумя или более способами',
  moreNotification: 'Для большего охвата аудитории Вы можете добавить ',
  forEmail: 'e-mail рассылку',
  forSms: 'SMS-рассылку',
  aboutSmsCountSymbols_0: 'Обратите внимание, что Ваше уведомление состоит из {{count}} знака, и будет автоматически разделено оператором связи на {{smsCount}} SMS. ',
  aboutSmsCountSymbols_1: 'Обратите внимание, что Ваше уведомление состоит из {{count}} знаков, и будет автоматически разделено оператором связи на {{smsCount}} SMS. ',
  aboutSmsCountSymbols_2: 'Обратите внимание, что Ваше уведомление состоит из {{count}} знаков, и будет автоматически разделено оператором связи на {{smsCount}} SMS. ',
  aboutSmsCountRecipients_0: 'С учетом того, что {{count}} пользователь получат SMS-сообщения, общее количество SMS составит ',
  aboutSmsCountRecipients_1: 'С учетом того, что {{count}} пользователя получат SMS-сообщения, общее количество SMS составит ',
  aboutSmsCountRecipients_2: 'С учетом того, что {{count}} пользователей получат SMS-сообщения, общее количество SMS составит ',
  hintTextPush: 'Эти пользователи получат сообщение только в мобильном приложении Сквер. Этот способ доставки по умолчанию является самым приоритетным. Другие способы отправки уведомления для этих пользователей использоваться не будут. ',
  hintTextEmail: 'Эти пользователи получат сообщение только на свой подтвежденный e-mail. Этот способ доставки будет применен для всех пользователей, не подключивших мобильное приложение Сквер, и не подключивших Telegram-bot. Другие способы отправки уведомления для этих пользователей использоваться не будут. ',
  hintTextPhone: 'Эти пользователи получат сообщение только в виде SMS на свой подтвержденный ранее номер мобильного телефона, так как они еще не подключили ни мобильное приложение Сквер, ни Telegram-bot, и еще не имеют подтвержденного e-mail. Этот способ доставки будет применен в последнюю очередь.',
  hintTextTg: 'Эти пользователи получат сообщение только в Telegram. Этот способ доставки будет применен для всех пользователей, подключивших Telegram-bot, но еще не подключивших мобильное приложение. Другие способы отправки уведомления для этих пользователей использоваться не будут. ',
  hintBoth: 'e-mail или SMS',
  sendSuccessMessage_0: '<ol style="list-style-type: decimal; padding-left: 24px;"><li>Уведомления отправлены {{count}} получателю.' + '<li>Информация об этой рассылке будет храниться в истории изменений.' + '<li>К Вам на почту будет выслан файл с пофамильными результатами рассылки.</ol>',
  sendSuccessMessage_1: '<ol style="list-style-type: decimal; padding-left: 24px;"><li>Уведомления отправлены {{count}} получателям.' + '<li>Информация об этой рассылке будет храниться в истории изменений.' + '<li>К Вам на почту будет выслан файл с пофамильными результатами рассылки.</ol>',
  sendSuccessMessage_2: '<ol style="list-style-type: decimal; padding-left: 24px;"><li>Уведомления отправлены {{count}} получателям.' + '<li>Информация об этой рассылке будет храниться в истории изменений.' + '<li>К Вам на почту будет выслан файл с пофамильными результатами рассылки.</ol>',
  sendSuccessInviteMessage_0: '<ol style="list-style-type: decimal; padding-left: 24px;"><li>Приглашения отправлены {{count}} получателю.' + '<li>Вам на e-mail отправлен архив с печатными версиями приглашений для всех незарегистрированных пользователей, которые не получат приглашение.' + '<li>Информация об этой рассылке будет храниться в истории изменений.' + '<li>К Вам на почту будет выслан файл с пофамильными результатами рассылки.</ol>',
  sendSuccessInviteMessage_1: '<ol style="list-style-type: decimal; padding-left: 24px;"><li>Приглашения отправлены {{count}} получателям.' + '<li>Вам на e-mail отправлен архив с печатными версиями приглашений для всех незарегистрированных пользователей, которые не получат приглашение.' + '<li>Информация об этой рассылке будет храниться в истории изменений.' + '<li>К Вам на почту будет выслан файл с пофамильными результатами рассылки.</ol>',
  sendSuccessInviteMessage_2: '<ol style="list-style-type: decimal; padding-left: 24px;"><li>Приглашения отправлены {{count}} получателям.' + '<li>Вам на e-mail отправлен архив с печатными версиями приглашений для всех незарегистрированных пользователей, которые не получат приглашение.' + '<li>Информация об этой рассылке будет храниться в истории изменений.' + '<li>К Вам на почту будет выслан файл с пофамильными результатами рассылки.</ol>',
  back: 'Назад',
  notIndicated: 'Не указано',
  cantSend_0: 'Вы выбрали {{count}} получателя. Мы не сможем отправить ему сообщения, так как не хватает лимитов для отправки сообщений.',
  cantSend_1: 'Вы выбрали {{count}} получателей. Мы не сможем отправить им всем сообщения, так как не хватает лимитов для отправки сообщений.',
  cantSend_2: 'Вы выбрали {{count}} получателей. Мы не сможем отправить им всем сообщения, так как не хватает лимитов для отправки сообщений.',
  availableLimits: 'Имеющиеся лимиты:',
  emailLimits: ' В данной рассылке должно быть {{willBeSent}} e-mail. Для того, чтобы провести рассылку',
  smsLimits: ' В данной рассылке должно быть {{willBeSent}} SMS. Для того, чтобы провести рассылку',
  notEnoughEmail_0: ' не хватает {{count}} штуки e-mail в лимитах.',
  notEnoughEmail_1: ' не хватает {{count}} штуки e-mail в лимитах.',
  notEnoughEmail_2: ' не хватает {{count}} штук e-mail в лимитах.',
  notEnoughSms_0: ' не хватает {{count}} штуки sms в лимитах.',
  notEnoughSms_1: ' не хватает {{count}} штуки sms в лимитах.',
  notEnoughSms_2: ' не хватает {{count}} штук sms в лимитах.',
  recomendedBuyLimits: 'Если Вас устраивает, что не все выбранные пользователи получат уведомление, то нажмите на кнопку "Отправить"',
  recomendedBuyLimitsLong: 'Вы можете докупить недостающие лимиты, чтобы и эти пользователи смогли получить уведомление.',
  onlyNotRigisteredUsers: 'В списке показаны только незарегистрированные пользователи которым можно выслать приглашение в СКВЕР',
  useEmail: 'Используйте e-mail рассылку как дополнительный способ доставки сообщения для большего охвата аудитории',
  useSms: 'Используйте sms рассылку как дополнительный способ доставки сообщения для большего охвата аудитории',
  youCan: 'Для отправки сообщений Вы можете:',
  buyLimits: '1. Докупить недостающие лимиты',
  reduceCount: '2. Уменьшить количество получателей',
  continueWithReducingCount: '2. Продолжить, но уменьшить количество выбранных получателей',
  selectEmail: 'Использовать e-mail как способ доставки',
  selectSms: 'Использовать sms как способ доставки',
  reduceText: 'Сократить само сообщение',
  countOfMessages_0: ' {{count}} штука.',
  countOfMessages_1: ' {{count}} штуки.',
  countOfMessages_2: ' {{count}} штук.',
  unlimited: ' не ограничено.',
  enough: ' Достаточно',
  canReceiveEmail: 'Могут получить e-mail',
  canReceiveSms: 'Могут получить SMS',
  cantReceive: 'Не могут получить ни e-mail, ни SMS',
  cantSendWarning: 'Мы не сможем отправить сообщения всем выбранным получателям. Не хватает лимитов для отправки ',
  notReceiveInvite: 'Не могут получить уведомления',
  needLimit: 'Не хватает лимитов',
  alreadyRegistered: 'Уже зарегистрированы в Сквере',
  disableNotify: 'Отключили получение уведомлений',
  spammers: 'Внесли Сквер в список спаммеров',
  notOwners: 'Не собственники',
  noContacts: 'Нет ни e-mail, ни номера телефона',
  noEmail: 'Нет e-mail',
  noPhone: 'Нет мобильного телефона',
  noLimitsText1: 'Мы не сможем отправить ни одно сообщение, потому-что нет лимитов на отправку e-mail и SMS.',
  noLimitsText2: 'Вы можете перейти в тарифы и докупить лимиты.',
  limitsForSending: 'Имеющиеся лимиты для отправки сообщений:',
  messages_0: ' {{count}} сообщение.',
  messages_1: ' {{count}} сообщения.',
  messages_2: ' {{count}} сообщений.',
  notUse: ' (не используем в рассылке)',
  alreadyHasTgBot: 'Уже подключили Telegram-бот',
  noValidUsersSelected: 'Выбранные пользователи не могут получить уведомление',
  noUsersSelected: 'Не выбран ни один получатель',
  isInviteInfoPopperText1: '1. Сначала мы попытаемся отправить уведомление всем выбранным пользователям используя только e&#8209;mail.',
  isInviteInfoPopperText2: '2. Выбранным пользователям, которые не имеют e-mail, но имеют номер мобильного телефона будут отправлены SMS. Для этого необходимо иметь достаточное количество лимитов для отправки SMS.',
  isInviteInfoPopperText5: '• Мы отправим уведомления только не зарегистрированным пользователям.',
  filterPopperText1: 'Описание работы фильтров',
  filterPopperText2: '1. При выбое одного фильтра в таблице показываются только те строки, которые удовлетворяют именно этому фильтру.',
  filterPopperText3: '2. Если выбранно несколько фильтров, то показываются только те строки, которые одновременно удовлетворяют всем выбранным фильтрам.',
  filterPopperText4: '3. Обратите внимание, что если сначала выбрать пользователей, а потом применить новые фильтры или сбросить ранее использованные фильтры, то это приведет и к сбросу всех ранее выбранных пользователей.',
  filterPopperText5: '4. Сброс ранее выделенных строк производится двумя способами: поштучным кликом чекбокса напротив строки или два клика в чекбоксе в заголовке таблицы. Первый клик на чекбокс в заголовке таблицы приводит к выбору всех строк видимой части таблицы, а второй клик приводит к снятию выделения во всех строках видимой части таблицы.',
  telegramInfoPopperText1: '2. Если не все выбранные пользователи подключили push&#8209;уведомления, то потом отправляются уведомления всем пользователям, зарегистрировавшим свой e&#8209;mail, но не подключивших  push&#8209;уведомления.',
  telegramInfoPopperText2: '3. В последнюю очередь используется SMS рассылка пользователям, не подключившим ни push&#8209;уведомления, и не имеющим подтвержденного e&#8209;mail.',
  infoPopperText1: '1. Сначала мы попытаемся отправить уведомление используя только push-уведомления в мобильном приложении.',
  infoPopperText2: '2. Если не все выбранные пользователи подключили push&#8209;уведомления, то потом уведомления отправляются всем пользователям, кто подключил Telegram&#8209;bot, но не подключил  push&#8209;уведомления в мобильном приложении.',
  infoPopperText3: '3. В третью очередь уведомления отправляются всем пользователям подтвердившим свой e&#8209;mail, но не подключивших ни push&#8209;уведомления, ни Telegram&#8209;bot.',
  infoPopperText4: '4. В последнюю очередь используется SMS рассылка пользователям, не подключившим ни push&#8209;уведомления, ни Telegram&#8209;bot, и не имеющим подтвержденного e&#8209;mail.',
  infoPopperText5: 'Это сделано для того, чтобы не дублировать каналы отправки уведомления, и минимизировать расходы по самым дорогим лимитам.',
  infoPopperText6: 'ВАЖНО!!!',
  infoPopperText7: '• Мы не отправим уведомления пользователям, которые уже подключили Telegram-bot, не зарегистрированным пользователям, пользователям, отключившим получение уведомлений от сервиса Сквер, и пользователям, внесшим Сквер в список спамеров.',
  infoPopperText8: '• Мы не отправим сразу уведомления пользователям, для которых не будет хватать лимитов e-mail или SMS, но у Вас будет возможность докупить недостающие лимиты.',
  infoPopperText9: '• Мы не отправим уведомления не зарегистрированным пользователям у которых в профиле нет ни e-mail, ни номера телефона.',
  infoPopperText10: '• Итоги рассылки будут показаны в истории изменений данной страницы.',
  infoPopperText11: '• Инициатор рассылки получит e-mail с файлом, в котором будут показаны итоги рассылки.',
  total: 'Итого:',
  recipientsSelected: 'Получателей выбрано.',
  youHaveNotCompletedSendingNotification: 'Вы не завершили отправку уведомления. Ваше сообщение и список получателей сохранены и будут отображены в окне уведомления, если вы позже продолжите отправку уведомления.'
};