"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  indicated: 'Indicated',
  unknown: 'Not specified',
  buildingId_village: 'Address',
  buildingId_town: 'Address',
  buildingStreet_village: 'All streets',
  buildingStreet_town: 'All streets',
  propertyUsageType_village: 'Type of building',
  propertyUsageType_town: 'Room type',
  personType_village: 'Type of owner',
  personType_town: 'Type of owner',
  ownershipType_village: 'Type of ownership',
  ownershipType_town: 'Type of ownership',
  ownerDocument_village: 'Identity documents',
  ownerDocument_town: 'Identity documents',
  ownershipDocument_village: 'Property deed',
  ownershipDocument_town: 'Property deed',
  phone_village: 'Mobile phone',
  phone_town: 'Mobile phone',
  email_village: 'Email',
  email_town: 'Email',
  member_village: 'Membership in the Community',
  member_town: 'Membership in the Community',
  entrance_village: 'Entrence',
  entrance_town: 'Entrence',
  isRegistered_village: 'Registration of owners',
  isRegistered_town: 'Registration of owners',
  allowMeeting_village: 'Excluded from voting',
  allowMeeting_town: 'Excluded from voting',
  lName_village: 'Name/Form',
  lName_town: 'Name/Form',
  fName_village: 'Name/Title',
  fName_town: 'Name/Title',
  mName_village: 'Middle name',
  mName_town: 'Middle name',
  cadastreCode_village: 'Cadastral number',
  cadastreCode_town: 'Cadastral number',
  shareArea_village: 'Share',
  shareArea_town: 'Share/area',
  shareArea_specified: '$t(modules.registry.filterTitles.indicated)',
  shareArea_unspecifiedTotalArea: 'No area specified',
  shareArea_unspecifiedOwnedArea: 'Unspecified share',
  shareArea_unspecified: '$t(modules.registry.filterTitles.unknown)',
  entrance_unspecified: '$t(modules.registry.filterTitles.unknown)',
  entrance_value: '{{number}} Approach',
  propertyUsageType_living: 'Residential',
  propertyUsageType_nonliving: 'Non-residential',
  propertyUsageType_room: 'Room in apartment',
  propertyUsageType_house: 'House',
  propertyUsageType_land: 'Land plot',
  propertyUsageType_townhouse: 'Apartment in a townhouse',
  propertyUsageType_other: 'Other',
  propertyUsageType_unknown: '$t(modules.registry.filterTitles.unknown)',
  personType_government: 'State',
  personType_natural: 'Individuals',
  personType_legal: 'Legal entities',
  personType_unknown: '$t(modules.registry.filterTitles.unknown)',
  ownershipType_share: 'Equity',
  ownershipType_join: 'Joint',
  ownershipType_unknown: '$t(modules.registry.filterTitles.unknown)',
  ownerDocument_specified: '$t(modules.registry.filterTitles.indicated)',
  ownerDocument_unspecified: '$t(modules.registry.filterTitles.unknown)',
  ownershipDocument_specified: '$t(modules.registry.filterTitles.indicated)',
  ownershipDocument_unspecified: '$t(modules.registry.filterTitles.unknown)',
  phone_specified: '$t(modules.registry.filterTitles.indicated)',
  phone_unspecified: '$t(modules.registry.filterTitles.unknown)',
  email_specified: '$t(modules.registry.filterTitles.indicated)',
  email_unspecified: '$t(modules.registry.filterTitles.unknown)',
  allowMeeting_allowMeeting: 'Admissions',
  allowMeeting_notAllowMeeting: 'Excluded',
  lName_specified: '$t(modules.registry.filterTitles.indicated)',
  lName_unspecified: '$t(modules.registry.filterTitles.unknown)',
  fName_specified: '$t(modules.registry.filterTitles.indicated)',
  fName_unspecified: '$t(modules.registry.filterTitles.unknown)',
  mName_specified: '$t(modules.registry.filterTitles.indicated)',
  mName_unspecified: '$t(modules.registry.filterTitles.unknown)',
  cadastreCode_specified: 'Indicated',
  cadastreCode_unspecified: '$t(modules.registry.filterTitles.unknown)',
  member_member: 'Member of the Partnership',
  member_notMember: 'Not a member of the Partnership',
  member_unknown: '$t(modules.registry.filterTitles.unknown)',
  isRegistered_isRegistered: 'Registered',
  isRegistered_notIsRegistered: 'Unregistered',
  isRegistered_isEmpty: 'Owner not specified',
  isRegistered_specified: '$t(modules.registry.filterTitles.indicated)',
  isRegistered_unspecified: '$t(modules.registry.filterTitles.unknown)',
  filterTitle: 'Each number in the filter shows the number of rows in the registry'
};