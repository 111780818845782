"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  ownersSelect: "Owners' choice",
  ownersSearch: 'Find the owner',
  selectAll: 'Select all',
  needSelectSomeone: 'You need to pick someone'
};