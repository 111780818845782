"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  delete: 'Delete poll',
  cancel: 'Cancel the poll',
  allPolls: 'To the list of surveys',
  results: 'View results',
  backToVote: 'Back to voting',
  downloadVotersRegistry: 'Download the register of voters in the survey'
};