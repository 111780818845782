"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  order: 'Заказ',
  orderRegistry: 'Заказать реестр',
  managementOrganizationStatement: 'Заявление в Управляющую Организацию',
  free: 'Бесплатно',
  dataValidity: 'Достоверность данных',
  noWarranties: 'Без гарантий',
  statementHint: 'Скачайте и заполните заявление на получение реестра. Передайте заявление в управляющую организацию.',
  downloadStatement: 'Скачать заявление',
  propertyPassport: 'Имущественный паспорт Дома',
  pricePerMeterFrom: 'от {{price}} за помещение',
  propertyPassportDescription: 'Перечень помещений и доли владения в них',
  inExcelFormat: 'В формате файла excel',
  orderTerm: 'Срок — {{term}} рабочих дней.',
  goToPayment: 'Заказать',
  changeOrder: 'Изменить заказ',
  orderTotalCost: 'Полная стоимость Заказа:',
  inputAddressHint: 'Введите адрес дома и мы рассчитаем стоимость',
  registryExtractDiscountHint: 'Если Вы захотите в будущем заказать Выписку из ЕГРН, то стоимость заказа будет уменьшена на стоимость ранее выплаченную за Имущественный паспорт',
  overallSquare: 'Общее количество помещений: {{square}}',
  pricePerMeter: 'Стоимость за помещение: {{price}}',
  propertyPassportDescriptionFull: `
В имущественный паспорт входит перечень всех помещений в МКД с указанием всех их характеристик:
- площадь;
- подъезд;
- этаж;
- кадастровый номер;
- количество долей владения в каждом помещении;
- актуальные данные документа на право собственности: название, дата регистрации, номер.
`,
  lastOrderDate: 'Вы заказывали Имущественный паспорт Дома {{date}}',
  rosRegistryExtract: 'Выписки из ЕГРН',
  rosRegistryExtractDescription: 'Выписки по каждому помещению дома из ЕГРН',
  rosRegistryExtractDescriptionFull: `
В реестр, составленный из выписок, входит перечень всех помещений в МКД с указанием их характеристик:
- площадь;
- этаж;
- кадастровый номер;
- доля владения в каждом помещении;
- актуальные данные документа на право собственности: название, дата регистрации, номер.
`,
  registryFromExcelFormat: 'В формате файла excel и отдельных выписок из ЕГРН на каждое помещение.',
  registryExtractOrderDiscountHint: 'Стоимость изготовления выписки из ЕГРН уменьшена на стоимость ранее заказанного Имущественного паспорта Дома.',
  orderRegistryDescription_0: 'Выписка реестра собственников для многоквартирного дома по адресу:',
  orderRegistryDescription_1: 'Выписка реестра собственников для домов по адресам:',
  addressNotFoundAlertTitle: 'Внимание',
  addressNotFoundAlertDesriptionPart1: 'К сожалению, в публичных источниках информации (',
  addressNotFoundAlertDesriptionPart2: ') нет данных о количестве помещений в вашем доме и/или об общей площади этих помещений. Не обладая этой информацией, мы не можем рассчитать стоимость изготовления реестра.\n\nМы свяжемся с Вами в ближайшее время, чтобы предложить альтернативный вариант и уточнить информацию',
  orderContactsModalTitle: 'Внимание',
  orderContactsModalDescriptionPart1: 'К сожалению, в публичных источниках информации (',
  orderContactsModalDescriptionPart2: ') нет данных о количестве помещений в вашем доме и/или об общей площади этих помещений. Не обладая этой информацией, мы не можем рассчитать стоимость изготовления реестра.\n\nОставьте ваши данные и мы свяжемся с Вами в ближайшее время, чтобы предложить альтернативный вариант и уточнить информацию',
  fullNameLabel: 'ФИО',
  emailLabel: 'E-mail',
  phoneLabel: 'Телефон',
  inputValueRequired: 'Пожалуйста, заполните это поле',
  fullNameRequired: 'Укажите как минимум фамилию и имя',
  inputEmailRequired: 'Вы указали некорректный e-mail',
  inputPhoneRequired: 'Вы указали некорректный телефон',
  orderContactsModalSubmit: 'Отправить',
  customer: 'Заказчик',
  deliveryOnEmail: 'Доставка на эл.почту',
  executionPeriod: 'Срок исполнения',
  proceed: 'Продолжить',
  cardData: 'Данные карты',
  cardDataDescription: 'Для оплаты введите данные банковской карты',
  cardNumber: 'Номер карты',
  cardNumberRequired: 'Некорректный номер карты',
  cardMonth: 'Месяц',
  cardFieldError: 'Ошибка',
  cardYear: 'Год',
  cardCVV: 'CVV',
  goPay: 'Оплатить',
  savedContactsAlertTitle: 'Готово',
  savedContactsAlertDescription: 'Спасибо.\nМы получили вашу заявку.\nВ ближайшее время мы свяжемся с Вами, чтобы предложить альтернативный вариант и уточнить информацию.',
  policyDescription: 'Нажимая на кнопку оплатить, Вы принимаете наши',
  policyTermsAndConditions: 'Условия использования',
  policyPrivatePolicy: 'Политику конфиденциальности',
  policyRefund: 'Политику возврата денежных средств',
  goNext: 'Далее',
  goBack: 'Назад',
  addressSearch: 'Поиск по адресу',
  paymentAttemptFailed: 'Оплата не завершена. Код ошибки: {{code}}, {{error}}',
  successPaymentTitle: 'Вы успешно оплатили заказ!',
  successPaymentDescription: `На Ваш e-mail {{email}} отправлены все подробности по данному заказу.\n\nВремя выполнения заказа - {{days}} рабочих дней.`,
  failedPaymentTitle: 'Оплата не прошла',
  failedPaymentDescription: 'Сожалеем, платеж не был произведен.\n\nПожалуйста, произведите оплату снова. При возникновении подобной проблемы снова напишите на эл. почту: {{email}}',
  yourOrder: 'Ваш заказ: ',
  price: 'Стоимость',
  successActialization: 'Вы успешно заказали актуализацию реестра!\nНа Ваш e-mail {{email}} отправлены все подробности по данному заказу.\nВремя выполнения заказа - {{days}} рабочих дней.'
};