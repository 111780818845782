"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  registryProhibitionAlertModalTitle: 'Недостаточно полномочий',
  registryProhibitionAlertModalText: 'У Вас недостаточно полномочий, для выполнения данной операции. Чтобы добавить эти данные о своей собственности обратитесь к Администратору или Модератору Вашего объекта',
  changePersonTypeModalTitle: 'Изменение типа пользователя!',
  changePersonTypeModalText: 'Вы хотите изменить тип пользователя, уже зарегистрированного в системе, при этом ваши данные будут перемещены в раздел “контактное лицо”.',
  checkUnsavedDataModalText1: 'Вы внесли изменения в карточку пользователя. Сохранить?',
  checkUnsavedDataModalText2: 'У вас есть не сохраненные данные, если вы покинете страницу они будут потеряны',
  joinSocialModalTitle: 'Слияние аккаунтов!',
  joinSocialModalText: `<p>Данная социальная сеть уже зарегистрирована в СКВЕР.</p>
      <p>Вы можете объединить аккаунты и использовать данную социальную сеть при входе.</p>
      <p>Обратите внимание, данное действие нельзя будет отменить!</p>`,
  registryRedirectModalTitle: 'Перейти на страницу реестра?',
  registryRedirectModalText: 'Редактирование полей собственности доступно только из реестра.',
  registryRedirectModalYesLabel: 'Перейти к реестру',
  resetCreatingModalText: 'У вас есть не сохраненные данные, если вы закроете окно они будут потеряны',
  successModalText1: 'Теперь с e-mail',
  successModalText2: 'Теперь с телефоном',
  successModalText3: 'вы можете войти в следующие объекты:',
  webCamErrorModalText: 'Не удалось подключиться к камере.',
  warningModalText: '<p>Данное действие приведет к потере ранее введенных данных. Вы уверены, что хотите изменить формат отображения доли?</p>',
  checkSettingsWarningModalText1: 'Количество помещений в настройках совпадает с количеством уже занятых собственниками помещений. Для добавления нового собственника Вам нужно увеличить количество помещений в настройках',
  checkSettingsWarningModalText2: 'В настройках объекта недостаточно свободной площади для добавления нового помещения. Для добавления нового собственника Вам нужно увеличить площадь помещений в настройках',
  checkContactDetailsModalTitle: 'Сохранить внесенные данные?',
  verifiedChangesModalTitlePart1: 'Вы внесли изменения в уже зарегистрированный',
  verifiedChangesModalTitlePart2Variant1: 'номер телефона и e-mail',
  verifiedChangesModalTitlePart2Variant2: 'номер телефона',
  userDeleteWarningModalTitleOwn: 'Вы собираетесь удалить самого себя из этого объекта!',
  userDeleteWarningModalTitleNotOwn: 'Вы удаляете пользователя из системы',
  userDeleteWarningModalText: 'Внимание! Вы собираетесь удалить свой профиль из данного объекта! Это действие приведёт к тому, что Вы больше не сможете войти в этот объект, не будете получать уведомления из него. Если у Вас есть другие объекты в Сквере, то на них это никак не отразится, Вы по прежнему сможете их использовать.',
  deleteSelectedPropertyModalTitle: 'Удалить выбранную собственность?',
  itIsNotPossibleToDeletePropertyModalTitle: 'Удаление собственности невозможно',
  itIsNotPossibleToDeletePropertyModalText: 'Если Вы хотите это помещение присвоить другому собственнику, Вы можете или удалить данного собственника и потом это помещение добавить другому собственнику, или уменьшить долю владения этого собственника и на освободившуюся долю владения вписать другого собственника.'
};