"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Кадастровый номер',
  yesLabel: 'Ввести кадастровый номер',
  noLabel: 'Вернуться',
  info: 'Кадастровый номер Объекта капитального строительства (ОКС) служит надежным способом однозначной идентификации Объекта. Мы используем кадастровый номер для обращения в ЕГРН, и получения информации о количестве помещений в МКД. На основании получаемого значения количества помещений, рассчитывается стоимость платных тарифов и услуг в нашем сервисе.',
  condoGroup: 'Так как в Вашем объекте есть несколько МКД, то для расчета платных услуг нам нужно знать кадастровые номера всех МКД.',
  enterNumber: 'Введите кадастровый номер',
  planToPlay: ', если Вы планируете использовать платные услуги, а именно:',
  itemA: 'а) заказывать у нас реестр собственников,',
  itemB: 'б) актуализировать данные реестра перед проведением собраний,',
  itemC: 'в) покупать платные тарифные планы.',
  ifSaveNumber: `Если Вы сейчас сохраните кадастровый номер, то мы:
  - бесплатно сформируем файл со списком всех помещений МКД
  - зафиксируем максимальное количество помещений для данного МКД
  - позволим Вам оплачивать все платные услуги и тарифы.
Изменение сохраненного значения кадастрового номера будет возможно только после обращения в техподдержку.`,
  warning: 'Обратите внимание. ',
  cantAddNewBuldings: 'После добавления кадастрового кода, вы не сможете добавлять или удалять корпуса в Вашем объекте.',
  dontEnterNumber: 'Не вводите кадастровый номер',
  dontPlanToPay: ', если Вы не планируете использовать платные услуги. Это позволит Вам гибко изменять настройки Объекта и продолжать пользоваться всем бесплатным функционалом.',
  ifDontKnowNumber: 'Если Вы не знаете кадастровый номер, то используйте публичную кадастровую карту ',
  forSearch: ' для поиска именно Вашего дома.',
  alreadyOrderedTitle: 'Реестр уже заказан',
  alreadyOrdered: ' уже оплатил заказ реестра собственников.',
  wait: 'Примерно через {{days}} рабочих дней Вы получите сообщение, к которому будет приложен файл с реестром собственников.',
  questions: 'Если у Вас есть вопросы, ',
  support: 'напишите нам в техподдержку',
  close: 'Закрыть',
  noNeed: 'Вам не нужно еще раз оплачивать заказ реестра.',
  again: 'Если Вы по каким-либо причинам считаете, что нужно еще раз оплатить заказ реестра, нажмите на кнопку “Да, Заказать реестр”',
  yesOrder: 'Да, Заказать реестр',
  alreadyActualize: ' уже заказал услугу актуализации  реестра собственников.',
  waitActualize: 'Примерно через {{days}} рабочих дней Вы получите сообщение, к которому будет приложен файл с обновленным реестром собственников.'
};