"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  id: 'Ид',
  checkbox: 'Выделить сразу все строки',
  homeAddress: 'Адрес дома',
  homeAddressHeader: 'Краткое название для каждого дома, входящего в группу МКД',
  street: 'Улица',
  unit: 'Корпус',
  unitFull: 'Корпус дома',
  letter: 'Литера',
  addressErrors: 'Ошибки адреса',
  apartment: 'Пом.',
  apartmentFull: 'Помещение',
  apartmentFullSecond: '№ помещения',
  apartmentHeader: 'Номер или название помещения',
  totalArea: 'S, м2',
  totalAreaHeader: 'Общая площадь помещения',
  area: 'Площадь',
  share: 'Доля',
  shareHeader: 'Доля владения собственника внутри помещения',
  ownershipPercent: 'Голоса на собр. собст.',
  ownershipPercentHeader: 'Количество голосов в % на собраниях собственников',
  votePercent: 'Голоса на собр. товарищ.',
  votePercentHeader: 'Количество голосов в % на собраниях товарищества',
  voteGroup: 'Голоса',
  entrance: 'Под.',
  entranceFull: 'Подъезд',
  floor: 'Эт.',
  floorFull: 'Этаж',
  propertyUsageType: 'Тип помещения',
  propertyUsageTypeHeader: 'Тип помещения. Жилое или Нежилое',
  propertyUsageTypeFull: 'Тип помещения',
  ownershipType: 'Тип собственности',
  ownershipTypeFull: 'Долевая / Совместная',
  ownershipTypeHeader: 'Тип собственности. Долевая или Совместная',
  personType: 'Тип собственника',
  personTypeFull: 'Тип собственника: юр / физ / гос',
  membership: 'Членство',
  ownerGroup: 'Собственник',
  lastName: 'Фамилия/Форма',
  lastNameFull: 'Фамилия для физ. лиц / Форма собственности для юр. лиц',
  firstName: 'Имя/Название',
  firstNameFull: 'Имя для физ. лиц / Название для юр. лиц',
  middleName: 'Отчество/ОГРН',
  middleNameFull: 'Отчество для физ. лиц / ОГРН для юр. лиц',
  inn: 'ИНН',
  phones: 'Телефон',
  emails: 'Электронная почта',
  cadastreCode: 'Кадастровый номер',
  ownershipDocumentGroup: 'Документ на право собственности',
  ownershipDocumentName: 'Название',
  ownershipDocumentNameFull: 'Документ, подтверждающий собственность',
  ownershipDocumentNumber: 'Номер документа',
  ownershipDocumentNumberFull: 'Номер подтверждающего документа',
  ownershipDocumentIssueDate: 'Дата выдачи',
  ownershipDocumentIssueDateFull: 'Дата выдачи подтверждающего документа',
  ownerDocumentNumberFullName: 'Номер удостоверяющего документа',
  ownerDocumentGroup: 'Документ, удостоверяющий личность',
  ownerDocumentType: 'Название',
  ownerDocumentTypeFull: 'Документ, удостоверяющий личность',
  ownerDocumentNumber: 'Номер документа',
  ownerDocumentNumberFull: 'Номер удостоверяющего документа',
  ownerDocumentIssueDate: 'Дата выдачи',
  ownerDocumentIssueDateFull: 'Дата выдачи удостоверяющего документа',
  addressGroup: 'Адрес собственности',
  buildingNumber: 'Номер дома/строения',
  snils: 'СНИЛС'
};