"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  agendaSolution: 'Draft decision',
  rationale: 'Reasoning',
  documents: 'Documents',
  questionDescription: 'Question Description',
  agendas: 'Items on the agenda',
  selectedNumOfNumQuestions: 'Selected {{count}} from {{total}}',
  selectedNumOfNumShortWithBr: 'Selected<br />{{count}} from {{total}}',
  hasNumQuestionsInside: 'Includes<br />{{total}} questions',
  agendaTemplateModalInfoPartOne: 'You can select either the whole meeting with all the issues or only some of the issues included in it. The selected issues, together with the draft decisions and all annexes, will appear in the draft meeting.',
  agendaTemplateModalInfoPartTwo: 'You can then edit and delete them.',
  agendaTemplateModalResetBtn: 'Reset selected questions',
  agendaTemplateModalMainHeader: 'Selection of template questions',
  showAllQuestions: 'Show all questions',
  showSelectedQuestions: 'Show selected questions',
  selected: 'Selected',
  agendaTemplateModalWarnInfo: 'This issue is usually considered with other assembly issues',
  agendaTemplateModalWarnRecommendation: 'We strongly recommend that you use all the questions from this collection together without selecting them one at a time.',
  agendaTemplateSelectedModalConfirm: 'Add items to the agenda',
  agendaTemplateSelectedModalReturn: 'Continue with a selection of questions',
  agendaTemplateSelectedModalHeader: 'Selected questions',
  agendaTemplateSelectedModalBody: 'All selected issues, along with the draft decisions and all attachments, will appear in the draft meeting. You will be able to edit and delete them later.',
  removeQuestionWarnMessage: 'You took the matter off the agenda.',
  noQuestionsAreSelected: 'No question has been selected',
  successMessage_0: "Yay, you've added {{count}} template question to your meeting",
  successMessage_1: "Yay, you've added {{count}} template questions to your meeting",
  successMessage_2: "Yay, you've added {{count}} template questions to your meeting",
  chooseOne: 'Choose one',
  chooseAll: 'Select all',
  deleteOne: 'Take one out',
  deleteAll: 'Remove all'
};