"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  addToNewAccount: 'To add an object to a new account, enter an e-mail address not registered in SCVR',
  addToNewAccountWithPass: 'You can add the item to a new account, enter and confirm your password',
  addToExistAccount: 'To add an item to an existing account, maintain the e-mail and password for the account',
  addVillageToExistsAccount: 'You can add a new village to an existing account by entering the password',
  addTownToExistsAccount: 'You can add a new home to an existing account by entering your password to do so',
  accountAlreadyExists: 'Such an account already exists',
  socialNetworkAccountAlreadyExists: 'Registration is not possible. This social network is already registered in this Object.',
  logInObject: 'Enter the object',
  joiningRequestWasSent: 'The application to join has been sent to the Facility Administrator {{- hoaName}}',
  joiningRequestMessage: 'In case of its approval, you will receive a message with the registration code by e-mail or SMS',
  joiningRequestDoneMessageOne: 'You will receive an sms with a link, following which you will confirm your phone number and complete the registration.',
  joiningRequestDoneMessageTwo: 'You will now be directed to the Subject page as a guest.',
  joiningRequestDoneTitle: 'Registration is almost over',
  joiningAnExistingObject: 'You have decided to join an existing home. You need to enter your information and create an account. The house administrator will receive your application and make a decision.'
};