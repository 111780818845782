"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  id: 'Id',
  checkbox: 'Select all rows at once',
  homeAddress: 'House address',
  homeAddressHeader: 'Short name for each house included in the multi-apartment house group',
  street: 'Street',
  unit: 'Block',
  unitFull: 'The block of the house',
  letter: 'Litera',
  addressErrors: 'Address errors',
  apartment: 'Apt.',
  apartmentFull: 'Apartment',
  apartmentFullSecond: 'Number of Appartments',
  apartmentHeader: 'Apartment number or name',
  totalArea: 'S, m2',
  totalAreaHeader: 'Total floor area',
  area: 'Area',
  share: 'Share',
  shareHeader: "Owner's share of ownership within the premises",
  ownershipPercent: 'Votes at the meeting',
  ownershipPercentHeader: 'Number of votes in % at the meetings of owners',
  votePercent: 'Votes at the members meeting',
  votePercentHeader: 'Number of votes in % at the meetings of the partnership',
  voteGroup: 'Votes',
  entrance: 'Entr.',
  entranceFull: 'Entrence',
  floor: 'Floor.',
  floorFull: 'Floor',
  propertyUsageType: 'Room type',
  propertyUsageTypeHeader: 'Type of Premises. Residential or Non-residential',
  propertyUsageTypeFull: 'Room type',
  ownershipType: 'Type of ownership',
  ownershipTypeFull: 'Shared / Joint',
  ownershipTypeHeader: 'Type of Ownership. Equity or Joint',
  personType: 'Type of owner',
  personTypeFull: 'Type of owner: legal / physical / state',
  membership: 'Membership',
  ownerGroup: 'Owner',
  lastName: 'Name/Form',
  lastNameFull: 'Surname for individuals / Form of ownership for legal entities',
  firstName: 'Name/Title',
  firstNameFull: 'Name for individuals / Name for legal entities',
  middleName: 'Middle name',
  middleNameFull: 'Middle name for legal entities',
  inn: 'Tax number',
  phones: 'Phone',
  emails: 'Email',
  cadastreCode: 'Inventory number',
  ownershipDocumentGroup: 'Title deed',
  ownershipDocumentName: 'Name',
  ownershipDocumentNameFull: 'Document confirming ownership',
  ownershipDocumentNumber: 'Document number',
  ownershipDocumentNumberFull: 'Number of the supporting document',
  ownershipDocumentIssueDate: 'Date of issue',
  ownershipDocumentIssueDateFull: 'Date of issuance of the supporting document',
  ownerDocumentNumberFullName: 'Identification document number',
  ownerDocumentGroup: 'Identification document',
  ownerDocumentType: 'Name',
  ownerDocumentTypeFull: 'Identification document',
  ownerDocumentNumber: 'Document number',
  ownerDocumentNumberFull: 'Identification document number',
  ownerDocumentIssueDate: 'Date of issue',
  ownerDocumentIssueDateFull: 'Date of issue of the certifying document',
  addressGroup: 'Property address',
  buildingNumber: 'House/building number',
  snils: 'Personal ID'
};