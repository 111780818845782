"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  rowsDelete: 'Удаление строк',
  canNotDeleteSelectedRowsCause: 'Выделенные Вами строки удалить невозможно, потому что в них находятся:',
  canNotDeleteSelectedRowsMessage1: '1. Зарегистрированные пользователи. Удалять их можно только по одному, используя профиль каждого. Для этого кликните на аватар пользователя и перейдите в его профиль.',
  canNotDeleteSelectedRowsMessage2: '2. Пользователи с типом “НЕИЗВЕСТЕН”. Это технические строки, показывающие Вам, какие помещения или доли в помещениях не заняты реальными собственниками.',
  canNotDeleteSelectedRowsMessage3_0: 'Выделенную Вами строку удалить невозможно, потому что в ней находится пользователь с типом “НЕИЗВЕСТЕН”. Это техническая строка, показывающая Вам, какие помещения или доли в помещениях не заняты реальными собственниками.',
  canNotDeleteSelectedRowsMessage3_1: 'Выделенные Вами строки удалить невозможно, потому что в них находятся пользователи с типом “НЕИЗВЕСТЕН”. Это технические строки, показывающие Вам, какие помещения или доли в помещениях не заняты реальными собственниками.',
  canNotDeleteSelectedRowsMessage3_2: 'Выделенные Вами строки удалить невозможно, потому что в них находятся пользователи с типом “НЕИЗВЕСТЕН”. Это технические строки, показывающие Вам, какие помещения или доли в помещениях не заняты реальными собственниками.',
  canNotDeleteSelectedRowsMessage4_0: 'Выделенную Вами строку удалить невозможно, потому что в ней находится зарегистрированный пользователь. Удалить возможно только через профиль. Профиль можно открыть по клику на аватар.',
  canNotDeleteSelectedRowsMessage4_1: 'Выделенные Вами строки удалить невозможно, потому что в них находятся зарегистрированные пользователи. Удалять их можно только по одному, используя профиль каждого. Для этого кликните на аватар пользователя и перейдите в его профиль.',
  canNotDeleteSelectedRowsMessage4_2: 'Выделенные Вами строки удалить невозможно, потому что в них находятся зарегистрированные пользователи. Удалять их можно только по одному, используя профиль каждого. Для этого кликните на аватар пользователя и перейдите в его профиль.',
  approveRowsDelete_0: 'Подтвердите удаление строки',
  approveRowsDelete_1: 'Подтвердите удаление {{count}} строк',
  approveRowsDelete_2: 'Подтвердите удаление {{count}} строк',
  rowsSelected_0: 'Вы выбрали {{count}} строку',
  rowsSelected_1: 'Вы выбрали {{count}} строки',
  rowsSelected_2: 'Вы выбрали {{count}} строк',
  rows_0: '{{count}} строка',
  rows_1: '{{count}} строки',
  rows_2: '{{count}} строк',
  thisRows_0: 'Эта строка',
  thisRows_1: 'Эти строки',
  thisRows_2: 'Эти строки',
  ofThem: 'из них',
  but: 'а',
  rowsRelatedToRegisteredUsers_0: '{{count}} относится к уже зарегистрированным пользователям',
  rowsRelatedToRegisteredUsers_1: '{{count}} относятся к уже зарегистрированным пользователям',
  rowsRelatedToRegisteredUsers_2: '{{count}} относятся к уже зарегистрированным пользователям',
  rowsRelatedToUnknownUsers_0: '{{count}} относится к пользователям с типом “НЕИЗВЕСТЕН”',
  rowsRelatedToUnknownUsers_1: '{{count}} относятся к пользователям с типом “НЕИЗВЕСТЕН”',
  rowsRelatedToUnknownUsers_2: '{{count}} относятся к пользователям с типом “НЕИЗВЕСТЕН”',
  rowsRelatedToJoiningRequest_0: '{{count}} относится к заявкам на присоединение (при удалении заявки отклоняются!)',
  rowsRelatedToJoiningRequest_1: '{{count}} относятся к заявкам на присоединение (при удалении заявки отклоняются!)',
  rowsRelatedToJoiningRequest_2: '{{count}} относятся к заявкам на присоединение (при удалении заявки отклоняются!)',
  rowsWouldNotBeDeleted_0: 'не будет удалена',
  rowsWouldNotBeDeleted_1: 'не будут удалены',
  rowsWouldNotBeDeleted_2: 'не будут удалены',
  canNotDeleteSelectedRowsMessage5_0: 'потому что в ней находится зарегистрированный пользователь. Удалить его возможно только через профиль. Профиль можно открыть по клику на аватар.',
  canNotDeleteSelectedRowsMessage5_1: 'потому что в них находятся зарегистрированные пользователи. Удалять их можно только по одному, используя профиль каждого. Для этого кликните на аватар пользователя и перейдите в его профиль',
  canNotDeleteSelectedRowsMessage5_2: 'потому что в них находятся зарегистрированные пользователи. Удалять их можно только по одному, используя профиль каждого. Для этого кликните на аватар пользователя и перейдите в его профиль',
  canNotDeleteSelectedRowsMessage6_0: 'потому что в ней находится собственник с типом “НЕИЗВЕСТЕН”. Это технические строки, показывающие Вам, какие помещения или доли в помещениях не заняты реальными собственниками.',
  canNotDeleteSelectedRowsMessage6_1: 'потому что в них находятся собственники с типом “НЕИЗВЕСТЕН”. Это технические строки, показывающие Вам, какие помещения или доли в помещениях не заняты реальными собственниками.',
  canNotDeleteSelectedRowsMessage6_2: 'потому что в них находятся собственники с типом “НЕИЗВЕСТЕН”. Это технические строки, показывающие Вам, какие помещения или доли в помещениях не заняты реальными собственниками.',
  joiningRequestsInSelection_0: 'Выбрана строка с заявкой на присоединение, при удалении данная заявка будет отклонена!',
  joiningRequestsInSelection_1: 'Выбраны строки с заявками на присоединение, при удалении данные заявки будет отклонены!',
  joiningRequestsInSelection_2: 'Выбраны строки с заявками на присоединение, при удалении данные заявки будет отклонены!'
};