"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Вы внесли сервис Сквер в список спамеров',
  modalTitle: 'Внимание!',
  modalOkButton: 'Подключить рассылки',
  confirme: 'Понятно',
  modalTelegramButton: 'подключить telegram-bot',
  modalNoButton: 'Отмена',
  button: 'получать сообщения',
  text: 'Это означает, что Вы не получаете никаких сообщений от сервиса Сквер, кроме случаев, когда Вы забыли пароль. Если хотите снова получать сообщения от сервиса Сквер, нажмите «Получать сообщения».',
  resumedSendingMessagesToYourEmail: 'Вы возобновили отправку сообщений на Вашу электронную почту.',
  thinkWeSpammers: 'Имейл рассылки отключены',
  availableIn: 'Доступно в',
  downloadedIn: 'Загрузите в',
  notification: '<span>Вы не получаете сообщения по эл.почте, потому что внесли Сквер в список спамеров.</span>' + '<br/>' + '<br/>' + '<span>Если Вы хотите снова получать e-mail рассылки от нашего сервиса, нажмите на кнопку «Подключить рассылки».</span>' + '<br/>' + '<br/>' + '<span style="font-weight: 600;">ВАЖНО!!!</span>' + ' <span>Вы должны исключить сервис Сквер из списка спамеров в своем аккаунте электронной почты, иначе мы снова будем вынуждены отключить для Вас e-mail рассылку.</span>',
  offPushOffTelegramNotification: '<span>Чтобы снова получать сообщения по эл.почте Вам сначала нужно:</span>' + '<br/>' + '<br/>' + '<span> а). включить Telergram-bot нашего сервиса' + '<br/>' + ' или </span>' + '<br/>' + '<span>б). скачать и авторизоваться с Вашим логином и паролем в мобильном приложении Сквер и проверить, что в настройках аккаунта подключены push-уведомления.<span>' + '<br/>' + '<br/>' + '<span>Нажмите на кнопку “Подключить Telegram-bot” или нажмите на кнопку, ведущую в удобный для Вас магазин для скачивания мобильных приложений.</span>' + '<br/>' + '<br/>' + '<span>Как только мы увидим, что Telegram-bot подключен или что Вы подключили push-уведомления в нашем приложении, имейл рассылки будут включены автоматически.</span>' + '<br/>' + '<br/>' + '<span>Если Вы не хотите подключать имейл рассылки просто закройте это окно или нажмите на кнопку “Отмена”.</span>',
  onPushOffTelegramNotification: '<span>Чтобы снова получать сообщения по эл.почте Вам сначала нужно нажать на кнопку “Подключить Telegram-bot”</span>' + '<br/>' + '<br/>' + '<span>Как только мы увидим, что Telegram-bot подключен или что Вы авторизовались в нашем приложении, имейл рассылки будут включены автоматически.</span>' + '<br/>' + '<br/>' + '<span>Если Вы не хотите подключать имейл рассылки просто закройте это окно или нажмите на кнопку “Отмена”.</span>',
  offPushOnTelegramNotification: '<span>Чтобы снова получать сообщения по эл.почте Вам сначала нужно скачать и авторизоваться с Вашим логином и паролем в мобильном приложении Сквер и проверить, что в настройках аккаунта подключены push-уведомления.</span>' + '<br/>' + '<br/>' + '<span>Нажмите на кнопку, ведущую в удобный для Вас магазин для скачивания мобильных приложений.</span>' + '<br/>' + '<br/>' + '<span>Как только мы увидим, что Вы подключили push-уведомления в нашем приложении, имейл рассылки будут включены автоматически.' + '<br/>' + '<br/>' + '<span>Если Вы не хотите подключать имейл рассылки просто закройте это окно или нажмите на кнопку “Отмена”.</span> ',
  thinkWeSpammersNotification: 'Вы дважды внесли наш сервис в список спамеров в течение последних 90 дней. Поэтому имейл рассылки для Вас временно отключены. Вы можете или зарегистрировать в Сквере новый адрес эл.почты или попробовать активировать имейл рассылки не ранее {{date}}.',
  cantExcludeSpam: '<span>Вы дважды внесли наш сервис в список спамеров в течение последних 90 дней. Поэтому имейл рассылки для Вас временно отключены.</span>' + '<br/>' + '<span>Вы можете или зарегистрировать в Сквере новый адрес эл.почты или попробовать активировать имейл рассылки не ранее {{date}}.</span>'
};