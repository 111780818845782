"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  searchType: 'Search type',
  addressType: 'by address',
  hoaType: 'by name',
  zip: 'Zip code',
  region: 'Subject of the Federation',
  city: 'City/settlement',
  settlement: 'Neighborhood',
  streetType: 'Street type',
  street: 'Street name',
  house: 'House number',
  block: 'Block',
  unitType: 'Type',
  info: 'Additional information',
  address: 'Address',
  withSocialNetworks: 'or through social media',
  withEmail: 'or via e-mail',
  forgotPassword: 'forgot your password?',
  newUserRegistration: 'Registration'
};