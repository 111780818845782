"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Подключить Telegram-Бот',
  exit: 'Отменить',
  connectTelegram: 'Подключить Telegram-бот',
  chooseUsersOne: 'Вы выбрали {{users}} собственника.',
  chooseUsersFew: 'Вы выбрали {{users}} собственников.',
  ofThem: ' Из них:',
  noRegistreredOne: '{{users}} еще не зарегистрирован в Сквере. Мы не сможем отправить ему уведомление.',
  noRegistreredFew: '{{users}} еще не зарегистрированы в Сквере. Мы не сможем отправить им уведомления.',
  hasTelegramOne: '{{users}} уже подключил telegram-бот. Он не получит уведомление повторно.',
  hasTelegramFew: '{{users}} уже подключили telegram-бот. Они не получат уведомления повторно.',
  offNotificationsOne: '{{users}} отключил возможность получать личные уведомления от Сквера, и тоже не получит уведомление.',
  offNotificationsFew: '{{users}} отключили возможность получать личные уведомления от Сквера, и тоже не получат уведомления.',
  other: 'Все остальные выбранные Вами собственники получат уведомления e-mail или SMS с уникальной ссылкой, кликнув на которую они смогут подключить Telegram-бот',
  all: `Все выбранные Вами собственники получат уведомления e-mail или SMS с уникальной ссылкой, кликнув на которую они смогут подключить Telegram-бот`,
  uniqueLinks: 'У Вас есть возможность отправлять конкретным собственникам уникальные ссылки для подключения Telegram-бота из их Профиля',
  uploadQR: 'Скачать QR-код',
  or: ' или ',
  copyLink: 'скопировать ссылку',
  copySuccess: 'Ссылка успешно скопирована',
  forMessengerAndAd: ' подключения Telegram-бота для размещения в мессенджерах и печатных объявлениях',
  goToTariffs: 'Оплатить тариф',
  back: 'Нет, вернуться',
  noLimits: 'В настоящий момент у Вас нет возможности отправить уведомления выбранным собственникам ({{users}}), так как нет достаточного количества лимитов на отправку sms и e-mail.',
  mayGo: 'Вы можете перейти в “',
  tariffs: 'Тарифы',
  pay: '” и оплатить нужную Вам опцию.',
  wasSentOne: 'Уведомление отправлено {{user}} собственнику',
  wasSentFew: 'Уведомления отправлены {{users}} собственникам',
  noLimitsSms: 'В настоящий момент у Вас нет возможности отправить уведомления выбранным собственникам ({{users}}), так как нет достаточного количества лимитов на отправку sms.',
  noLimitsEmail: 'В настоящий момент у Вас нет возможности отправить уведомления выбранным собственникам ({{users}}), так как нет достаточного количества лимитов на отправку e-mail.',
  nobodyChoose: 'Все выбранные Вами пользователи не получат уведомления со ссылкой для подключения Telegram-бота, потому что они либо уже подключили Telegram, либо еще не зарегистрированы в Сквере, либо отключили получение уведомлений.',
  chooseOther: 'Выберите других пользователей.',
  tooltip: 'Пользователь отключил личные уведомления',
  invitationSMSTemplate: 'Подключение телеграм бота linklinklinklinklinklinklinklinkli'
};