"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  votePeople_0: 'voted',
  votePeople_1: 'voters',
  votePeople_2: 'voters',
  questionVoteRateByPeoplePercents: 'of the votes of the owners who participated in the voting',
  questionVoteBySquareRatePercents: 'of the votes of all owners'
};