"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  addressCreateTitle: 'Address creation',
  addressEditTitle: 'Editing an address',
  cancelAddressCreateTitle: 'Cancel the creation of an address',
  cancelAddressEditTitle: 'Undo Editing',
  cancelAddressCreateText: 'Are you sure you want to cancel the address creation? The entered data will be lost.',
  cancelAddressEditText: 'Are you sure you want to undo the edits you have made? The modified data will be lost.',
  phoneTypeCreateTitle: 'Create your own phone type',
  phoneTypeEditTitle: 'Edit your phone type',
  bottomTextForChipsInput: 'Enter keywords separated by commas',
  disabledTooltipText: 'Fill in the required fields',
  createAddressSuccess: 'The address has been successfully saved. If you want, you can add your first review of this address.',
  leaveReview: 'Add a review',
  editAddressSuccess: 'The address has been successfully saved',
  noOptions: 'Nothing found. Try entering other search words',
  showMore: 'Show more fields',
  errorTitleText: 'Enter a name',
  errorAddressText: 'Enter a valid address',
  typeWorkPhone: 'Work',
  typeHomePhone: 'Home',
  typeLandlinePhone: 'Landline',
  typeMobilePhone: 'Mobile'
};