"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  password: 'Пароль',
  oldPassword: 'Старый пароль',
  newPassword: 'Новый пароль',
  confirmPassword: 'Повторите новый пароль',
  lastName: 'Фамилия',
  firstName: 'Имя',
  middleName: 'Отчество',
  nickname: 'Никнейм',
  description: 'Должность',
  additionalInfo: 'Дополнительная информация:',
  position: 'Обязанности',
  juristicPersonName: 'Название',
  juristicPersonLegalForm: 'Форма',
  juristicPersonLegalFormFull: 'Организационно-правовая форма',
  juristicPersonOgrn: 'ОГРН',
  juristicPersonInn: 'ИНН',
  managementCompanyInn: 'ИНН управляющей компании',
  managementCompanyInnPlaceholder: 'Введите ИНН управляющей компании',
  managementCompanyLegalForm: 'Форма',
  managementCompanyLegalFormFull: 'Организационно-правовая форма',
  managementCompanyLegalName: 'Название организации',
  snils: 'СНИЛС',
  phone: 'Телефон',
  phoneNumber: 'Номер телефона',
  email: 'E-mail',
  dobDate: 'Дата рождения',
  fb: 'Facebook',
  twitter: 'Twitter',
  vk: 'Вконтакте',
  site: 'Адрес сайта',
  telegram: 'Telegram',
  whatsapp: 'WhatsApp',
  documentType: 'Название',
  documentNumber: 'Номер',
  documentIssueDate: 'Дата выдачи',
  personType: 'Тип собственника',
  buildingStreet: 'Улица',
  buildingNumber: '№ дома / строения',
  ownerType: 'Тип собственности',
  propertyUsageType: 'Тип помещения',
  apartmentNumber: '№ помещения',
  totalArea: 'Площадь',
  ownershipShare: 'Доля',
  entrance: 'Подъезд',
  floor: 'Этаж',
  allowMeeting_own: 'Вы лишены возможности голосовать',
  allowMeeting_all: 'Собственник лишен возможности голосовать',
  toAdmin: 'Для решения проблемы свяжитесь с администратором',
  reason: 'Причина:',
  additionalEmail: 'Дополнительный E-mail',
  mainEmail: 'Основной E-mail',
  additionalPhone: 'Дополнительный Телефон',
  mainPhone: 'Основной Телефон',
  organization: 'Организация',
  ownerDocumentType: 'Тип документа',
  docNumber: 'Номер документа',
  whenDocumentWasIssued: 'Когда выдан документ',
  apartNumber: 'Номер помещения',
  apartArea: 'Площадь помещения',
  entranceNumber: 'Номер подъезда',
  ownershipDocumentName: 'Вид документа',
  issueDate: 'Дата выдачи',
  phoneAlreadyUse: 'Данный номер телефона уже используется',
  emailAlreadyUse: 'Данный адрес электронной почты уже используется',
  ru: 'RU',
  whoAreYou: 'Кто вы?',
  resident: 'Собственник',
  manager: 'Управляющий',
  expert: 'Эксперт',
  natural: 'Физическое лицо',
  legal: 'Юридическое лицо',
  index: 'Индекс',
  town: 'Город/населенный пункт',
  area: 'Район',
  streetType: 'Тип улицы',
  streetName: 'Название улицы',
  houseNumber: 'Номер дома',
  unit: 'Корпус',
  federateEntity: 'Субъект Федерации',
  organizationName: 'Название организации',
  post: 'Должность',
  director: 'Директор',
  additionalInfoShort: 'Доп. информация',
  complaintsHaveBeenConsidered: 'Рассмотрено жалоб',
  complaintsHaveBeenSubmitted: 'Подано жалоб',
  reviewedAndSubmittedReports: 'Рассмотрено/подано отчетов',
  submittedReports: 'Подано отчетов',
  placedAds: 'Размещено объявлений',
  participatedInMeetings: 'Участвовал в собраниях',
  createdPolls: 'Создано опросов',
  conductedOnWebsite: 'Проведено на сайте',
  rating: 'Рейтинг',
  contractorForComplaints: 'Исполнитель по жалобам',
  lastVisit: 'Дата последнего посещения',
  sharedOwnership: 'Долевая собственность',
  jointOwnership: 'Совместная собственность',
  unknownOwnership: 'Собственность неизвестна',
  government: 'Государство',
  contactPerson: 'Контактное лицо',
  contactDetails: 'Контактные данные',
  contactPeople: 'Контактные лица',
  president: 'Председатель',
  vicePresident: 'Заместитель председателя',
  secretary: 'Секретарь',
  accountant: 'Бухгалтер',
  commiteeMember: 'Член комитета',
  changeSettings: 'Изменить настройки',
  changeRegistrySettings: 'Изменение настроек реестра'
};