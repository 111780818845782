"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  inputLink: 'Введите url-адрес',
  userNotFound: 'Пользователи не найдены',
  mentionToolTooltip: 'Упомянуть пользователя',
  insertDateToolTooltip: 'Вставить дату',
  attachFilesToolTooltip: 'Приложить фото и документы',
  attachFilesToolDisabledTooltip: 'Прикрепить документы можно только в авторизованном режиме',
  boldToolTooltip: 'Выделить текст жирным',
  italicToolTooltip: 'Выделить текст курсивом',
  underlineToolTooltip: 'Выделить текст подчеркиванием',
  sendToolTooltip: 'Отправить',
  discardToolTooltip: 'Выйти из редактирования без сохранения изменений.',
  commentPlaceholder: 'Ваш комментарий'
};