"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const apollo_link_context_1 = require("apollo-link-context");
const utils_1 = require("@sqvr/utils");
const hoaCodeLink = (0, apollo_link_context_1.setContext)((operation, prevContext) => {
  const hoaCode = utils_1.appConfig.hoaCode;
  if (!hoaCode || !hoaCode.trim()) return prevContext;
  return Object.assign(Object.assign({}, prevContext), {
    headers: Object.assign(Object.assign({}, prevContext && prevContext.headers || {}), {
      'X-HOA-Code': hoaCode
    })
  });
});
exports.default = hoaCodeLink;