"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lodash_1 = require("lodash");
exports.default = (initialValues, currentValues) => {
  return Object.keys(initialValues).reduce((acc, key) => {
    if (!(0, lodash_1.isEqual)(initialValues[key], currentValues[key])) {
      acc[key] = currentValues[key];
    }
    return acc;
  }, {});
};