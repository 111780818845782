"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  tooltips: {
    rows: {
      filtered: 'Отображено строк с учетом фильтров',
      total: 'Всего строк в реестре'
    },
    apartments: {
      filtered: 'Отображено помещений с учетом фильтров',
      total: 'Всего помещений согласно настройкам дома'
    },
    totalArea: {
      filtered: 'Сумма площадей отображенных помещений с учетом фильтров',
      total: 'Сумма всех площадей согласно настройкам дома'
    },
    owners: {
      filtered: 'Отображено собственников с учетом фильтров',
      total: 'Всего собственников в реестре'
    },
    ownerMeetingVotesPercents: {
      filtered: 'Сумма голосов отображенных собственников с учетом фильтров',
      total: 'Сумма голосов всех собственников в % от суммы голосов согласно настройкам дома'
    },
    communityMeetingVotesPercents: {
      filtered: 'Сумма голосов отображенных собственников-членов Товарищества с учетом фильтров',
      total: 'Сумма голосов всех членов Товарищества в % от всех голосов собственников дома'
    }
  }
};