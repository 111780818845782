"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  sendingEmailForAddressError: 'An error occurred while sending an email to {{email}}',
  successPasswordReset_email: 'An email has {{login}} sent to your email address with further instructions on how to reset your password',
  successPasswordReset_phone: 'An SMS was sent to the phone number {{login}} with further instructions on how to reset the password',
  errorPasswordReset_email: 'An error occurred while sending an email to {{login}}',
  errorPasswordReset_phone: 'An error occurred while sending an SMS to the phone number {{login}}',
  wrongPassword: 'Incorrect Password',
  emailVerificationError: 'Email Verification Error',
  emailVerificationSent: 'A letter has been sent to {{email}} address. Click on the link in the email confirmation email',
  socialAuthError: 'To continue, you need to get permission from the social network',
  recaptchaModalTitle: 'Check the box:',
  messageWasNotEdited: 'Message was not edited because it was already answered',
  messageCopied: 'Message text was successfully copied',
  adminModerators: 'Administrator, moderators'
};