"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Инфо',
  whoEdited: 'Кто и когда редактировал',
  size: 'Размер',
  deleted: 'Удалено',
  sizeInKb: 'Кб',
  sizeInMb: 'Мб'
};