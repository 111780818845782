"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  PollsCreated: 'Создан опрос',
  PollTitleChanged: 'Изменён заголовок опроса',
  PollDescAdded: 'Добавлено описание опроса',
  PollDescChangedDetail: 'Изменено описание опроса',
  PollDescDocAdded: 'В описание опроса добавлен документ',
  PollDescDocDeleted: 'Из описания опроса удален документ',
  PollQuestionAdded: 'Добавлен вопрос',
  PollQuestionTitleChanged: 'Изменено названия вопроса',
  PollQuestionItemsAdded: 'В вопрос добавлен вариант ответа',
  PollQuestionItemsDeleted: 'Из вопроса удалён вариант ответа',
  PollQuestionItemsChanged: 'В вопросе изменен вариант ответа',
  PollQuestionTypeChanged: 'Изменен тип вопроса',
  PollQuestionDocAdded: 'К вопросу добавлен документ',
  PollQuestionDocDeleted: 'Из вопроса удалён документ',
  PollQuestionDeleted: 'Удалён вопрос',
  PollWasPublished: 'Опубликован опрос',
  PollWasDeleted: 'Удалён опрос',
  PublishedPollWasCanceled: 'Отменен опубликованный опрос',
  PollWasFinished: 'Завершен опрос',
  PollDateFinishedChanged: 'Изменена дата окончания опроса',
  PollCreatedFromTemplate: 'Использован шаблон опроса',
  PollCreatedFromCopy: 'Создана копия опроса',
  PollVoted: 'Проголосовал(а) в опросе',
  PollExportResult: 'Скачана печатная версия опроса',
  FiltersAndSearch: 'Фильтры и поиск по опросам'
};