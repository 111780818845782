"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  ownershipsMerge: 'Присоединение помещений',
  noNotRegisteredUsersForMerge: 'Не выбраны помещения с незарегистрированными собственниками для объединения!',
  connectingRooms: 'Присоединение помещений',
  yesPass: 'Да, Передать'
};