"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  search: 'Что вы хотите найти?',
  tips: 'Подсказки',
  writeToSupport: 'Написать в службу технической&nbsp;поддержки',
  messages: 'Сообщения',
  integrator: 'Эксперт',
  resident: 'Собственник',
  contractor: 'Подрядчик',
  hoaEmployee: 'Сотрудник товарищества',
  managementStuff: 'Сотрудник УК',
  moderator: 'Модератор',
  admin: 'Администратор',
  changeObject: 'Сменить объект',
  areYouOwner: 'Вы собственник помещения?',
  areYouOwnerInObject: 'Вы собственник помещения в объекте',
  wantToUserSqvr: 'Хотите пользоваться Сквером?',
  thenFillTheForm: 'Тогда заполните анкету и присоединяйтесь к своим соседям.',
  fillTheForm: 'Заполнить анкету'
};