"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  allowMeeting: 'Вы не можете голосовать на собрании, свяжитесь с администратором или модераторами для получения сведений',
  needToVerifyEmail: 'Необходимо подтвердить этот e-mail. После подтверждения e-mail вы сможете назначить его основным',
  needToVerifyPhone: 'Необходимо подтвердить этот номер телефона. После подтверждения телефона вы сможете назначить его основным',
  addedEmailVerificationWarning: 'После сохранения изменений в карточке на данный e-mail придет письмо со ссылкой для его подтверждения',
  notVerifiedPhone: 'Неподтвержденный номер телефона',
  notVerifiedEmail: 'Неподтвержденный e-mail',
  additionalPhone: 'Дополнительный номер телефона',
  additionalEmail: 'Дополнительный e-mail',
  canNotChangeMainPhone: 'Нельзя переключить основной телефон у зарегистрированного пользователя',
  canNotChangeMainEmail: 'Нельзя переключить основной e-mail у зарегистрированного пользователя',
  setMainPhone: 'Назначить основным телефоном',
  setMainEmail: 'Назначить основным e-mail',
  needSendRegCodeForUser: 'Пользователь пока не зарегистрирован. Вышлите ему код регистрации.',
  sendRegCode: 'Пользователь пока не зарегистрирован. Вышлите ему код регистрации.',
  mainOwnEmailInfo: 'Основной адрес эл почты. На него приходят письма о событиях в вашем доме',
  contactPersonVerifiedEmail: 'Почта подтверждена. На неё будут приходить копии всех уведомлений пользователя',
  contactPersonDeniedMailing: 'Пользователь отказался от получения рассылки',
  mainPhone: 'Основной телефон (логин)',
  mainEmail: 'Основной E-mail (логин)',
  avatar: 'Загрузите вашу фотографию, чтобы соседи смогли вас узнать.',
  minimize: 'Свернуть профиль пользователя. После этого он будет отображаться в правой части экрана и Вы сможете снова его быстро развернуть по клику',
  close: 'Закрыть карточку',
  history: 'История изменений',
  addPhoneNumber: 'Добавить еще один номер телефона',
  deletePhoneNumber: 'Удалить номер телефона',
  deletePhoneNumberImpossible: 'Невозможно удалить основной номер телефона',
  addEmail: 'Добавить еще один адрес электронной почты',
  deleteEmail: 'Удалить адрес электронной почты',
  deleteEmailImpossible: 'Невозможно удалить основной адрес почты',
  mainPhoneForRegistration: 'Данный номер телефона использовался при регистрации пользователя',
  mainEmailForRegistration: 'Данный email использовался при регистрации пользователя',
  addContact: 'Добавить контактное лицо',
  deleteContact: 'Удалить контактное лицо',
  president: 'Председатель правления',
  boardMember: 'Член правления',
  commiteeMember: 'Член комитетов',
  tsnMember: 'Член Товарищества',
  notTsnMember: 'Не член Товарищества',
  unknownMember: 'Неизвестен статус членства в Товариществе',
  companyMember: 'Сотрудник УК',
  contractor: 'Подрядчик',
  admin: 'Администратор',
  moderator: 'Модератор',
  notActive: 'Пользователь не зарегистрирован',
  profileIsLocked: 'Закрытый профиль. ',
  profileIsOpen: 'Открытый профиль. ',
  privateProfileIsAMI: 'Пользователь еще не зарегистрирован.',
  publicProfileIsAMI: 'Доступна вся информация о пользователе, кроме документов, подтверждающих личность.',
  privateProfileIsNotOwner: 'Видны только никнейм или инициалы собственника и номера помещений, где у собственника есть доли владения.',
  publicProfileIsNotOwner: 'Доступна вся информация, кроме документов на право собственности и документов, подтверждающих личность.',
  privateProfile: 'Пользователи без административных прав могут видеть только Ваш никнейм или инициалы и номера помещений, где у Вас есть доля владения. ',
  publicProfile: 'Остальные пользователи смогут видеть всю информацию о Вас, кроме Ваших документов. ',
  canToggleProfile: 'Вы всегда можете закрыть или открыть свой профиль.',
  publicAdminProfile: 'Администратор, модератор или эксперт не могут сделать свой профиль приватным',
  unlockProfile: 'Сейчас Ваш профиль закрыт. Другие собственники видят только Ваши ФИО, и адрес  Вашего дома. Кликните на замок, если хотите открыть Ваш профиль для просмотра',
  lockProfile: 'Кликните на замок, если хотите закрыть Ваш профиль для просмотра',
  editMode: 'Перейти в режим редактирования и внести недостающие данные',
  ownershipShow: 'Развернуть карточку собственности',
  ownershipHide: 'Свернуть карточку собственности',
  deleteOwner: 'Удалить собственника из этой квартиры?',
  chooseProperty: 'Выберите тип помещения',
  chooseOwnershipType: 'Выберите тип владения',
  choosePersonType: 'Выберите тип пользователя',
  addInFavorite: 'Добавить пользователя в избранное',
  userInFavorite: 'Пользователь в избранном. Нажмите, чтобы удалить его из списка',
  addInBlacklist: 'Добавить пользователя в черный список',
  userInBlacklist: 'Пользователь в черном списке. Нажмите, чтобы удалить его из списка',
  regCode: 'Пользователь пока не зарегистрирован. Вышлите ему код регистрации.',
  mainPhoneInfo: 'телефон',
  otherPhoneInfo: 'Дополнительный телефон',
  mainEmailInfo: 'e-mail',
  otherEmailInfo: 'Дополнительный e-mail',
  mainPhoneDisabled: 'Номер телефона не может быть отредактирован у зарегистрированного пользователя',
  otherPhoneDisabled: 'Нельзя редактировать подтвержденный пользователем номер телефона',
  mainEmailDisabled: 'E-mail не может быть отредактирован у зарегистрированного пользователя',
  otherEmailDisabled: 'Нельзя редактировать подтвержденный пользователем e-mail',
  reports: 'Ответственный за подачу отчетов',
  rating: 'Средний рейтинг по оценкам собственников, выставляемых по 10-и бальной шкале. 1 - самый низкий бал, 10 - самый высокий бал.',
  forDisabledMainField: 'Данное поле нельзя редактировать у зарегистрированного пользователя',
  disabledPersonalNotifications: 'Отключено получение личных уведомлений',
  disabledAutomaticNotifications: 'Отключено получение автоматических уведомлений',
  thinkWeSpammers: 'Имейл рассылки отключены.',
  disabledAllNotifications: 'Отключено получение всех уведомлений',
  userIsInFavorite1: 'Пользователь внесен Вами в список избранных контактов. Это позволяет группировать пользователей по любому признаку, для того, чтобы легче их находить и выделять. Если Вы хотите исключить пользователя из списка избранных контактов, кликните здесь на его аватарку. Вы перейдете в полный профиль этого пользователя. В правом верхнем углу выберите дополнительное меню ',
  userIsInFavorite2: ' три точки и уберите его из списка избранных контактов.',
  userIsInBlackList1: 'Пользователь внесен Вами в черный список. Это означает, что Вы не видите его Объявления и ни он, ни Вы не можете написать друг другу сообщения. Если Вы хотите начать контактировать с ним, кликните здесь на его аватарку. Вы перейдете в полный профиль этого пользователя. В правом верхнем углу выберите дополнительное меню ',
  userIsInBlackList2: ' три точки и уберите его из черного списка.',
  writeUser: 'Написать пользователю',
  openProfile: 'Открыть профиль',
  closeProfile: 'Закрыть профиль',
  voteMembership: 'Количество голосов в %, которыми обладает данный член Товарищества на собрании членов Товарищества',
  voteOwnership: 'Количество голосов в %, которыми обладает данный собственник на общем собрании собственников',
  votingIsNotAllowed: 'Не может голосовать',
  unableToSendInvitation: 'Нельзя отправить приглашение в WhatsApp потому что этот номер не совпадает с номером телефона.',
  phoneNumberIsAlreadyInSystem: 'Этот номер телефона уже зарегистрирован в Сквере. Нельзя отправить приглашение по WhatsApp. Отправьте код регистрации по sms напрямую на этот номер телефона. ',
  menu: 'Дополнительное меню',
  toViewPaymentHistoryGoToPage: 'Для просмотра истории платежей необходимо перейти на страницу',
  cannotEditTypeOfExistingRoom: 'Невозможно редактировать тип уже существующего помещения',
  editOnlyInRegistryVariant1Part1: 'Редактирование этих полей возможно только в реестре. Перейти в',
  editOnlyInRegistryVariant1Part2: 'реестр',
  editOnlyInRegistryVariant2Part1: 'Для изменения данных необходимо',
  editOnlyInRegistryVariant2Part2: 'связаться с администратором!',
  closeModal: 'Закрыть окно',
  makePhoto: 'Сделать фото'
};