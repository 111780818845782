"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function getUserShortName(user, withoutMiddleName = false, parseLegalPerson = false) {
  if (!user) return '';
  let result = '';
  const {
    firstName = '',
    lastName = '',
    middleName = '',
    juristicPersonName = '',
    juristicPersonLegalForm = '',
    personType = 'Unknown'
  } = user;
  if (parseLegalPerson && ['Legal', 'Government'].includes(personType)) {
    if (juristicPersonLegalForm) {
      result += juristicPersonLegalForm;
    }
    if (juristicPersonName) {
      if (result) result += ' ';
      result += juristicPersonName.slice(0, 40);
      if (juristicPersonName.length > 40) {
        result += '(...)';
      }
    }
  } else {
    if (lastName) {
      result += lastName;
    }
    if (firstName) {
      if (result) result += ' ';
      result += `${firstName[0]}.`;
    }
    if (middleName && !withoutMiddleName) {
      if (result && !!firstName) result += ' ';
      result += `${middleName[0]}.`;
    }
  }
  return result;
}
exports.default = getUserShortName;