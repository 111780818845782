"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  petition: 'Petitions',
  generated: 'Created',
  published: 'Published',
  petitionCreate: 'Create a new petition',
  selectFromTemplate: 'Select from template',
  daysLeft_0: '{{count}} day left',
  daysLeft_1: '{{count}} days left',
  daysLeft_2: '{{count}} days left',
  lessThanDay: 'less than a day to go',
  voted_0: '{{count}} vote',
  voted_1: '{{count}} votes',
  voted_2: '{{count}} votes',
  charactersLeft: '{{count}} characters left',
  numberSignatories: 'Number of votes',
  petitionEmpty: 'No petitions found',
  petitionTemplatesEmpty: 'No petition templates found',
  filterCreator: 'Petitioner',
  filterStatus: 'Status',
  indefinitely: 'Indefinitely',
  remainingCompleteSignatures: 'After this deadline, it will be impossible to sign the petition',
  remainingCompleteSignaturesIndefinitely: 'The petition will be finalized manually by the administrator',
  signatureEndDate: 'Closing date for signing the petition',
  textPetitionMinSymbol: 'The text of the petition, at least 5 characters.',
  collectionSignaturesBefore: 'Collecting signatures before',
  statusActive: "There's a vote going on",
  statusDraft: 'Draft',
  statusVotingClosed: 'Closed',
  petitionSigning: 'Signing the petition',
  petitionSigningDescription1: 'Signing a petition is done using a simple electronic signature (SES). To do this, you need to ',
  petitionSigningDescription2: 'pick a phone or add',
  petitionSigningDescription3: ' new phone number. An SMS with a code will be sent to the specified number. ',
  createSuccess: 'A new petition has been successfully created',
  changesSaved: 'Saved',
  deleteSuccess: 'The petition has been successfully deleted',
  closeSuccess: 'The petition has been successfully closed',
  publishSuccess: 'The petition has been successfully published',
  cloneSuccess: 'The petition has been successfully copied',
  deleteThePetition: 'Delete the petition?',
  petitionDeleteWarning: 'The petition with all voting results will be deleted',
  petitionDraftDeleteWarning: 'The draft petition will be irrevocably deleted',
  closeThePetition: 'Close the petition?',
  petitionСloseWarning: "The petition will be closed, the owners' vote will be stopped",
  petitionPublish: 'Publication of the petition',
  petitionPublishWarning: 'Once the petition is published, it will be available for all owners to vote on',
  getLinksRegistry: 'Get a register of unique references',
  linksRegistryComment: 'Unique petition links for each owner. Can be used for distribution in messengers',
  downloadPrintingDocuments: 'Download printed materials',
  printingDocumentsComment: 'File with petitions for single-owner signatures/analog ballots; Roster for door-to-door canvassing and signature collection; Roster of petition signers using PEP; Announcement for information board',
  notifyUsers: 'Notify the owners',
  copyLink: 'Copy the link to the petition',
  deletePetition: 'Delete petition',
  closePetition: 'Close the petition',
  editPetition: 'Edit petition',
  clonePetition: 'Make a copy',
  sendLinksRegistrySuccess: 'A file with unique links has been e-mailed to you',
  sendPrintingDocumentsSuccess: 'A file with printed materials has been e-mailed to you',
  refuseModalTitle: 'Denial of petition',
  refuseModalMessage: 'You refuse to sign the petition! Would you like to leave a comment?',
  signModalTitle: 'Signing the petition',
  signModalMessage: 'You are signing the petition! Would you like to leave a comment?',
  signingSuccess: 'You signed the petition!',
  rejectSuccess: 'You refused to sign the petition!',
  rejectStatus: 'You refused to sign the petition {{date}}',
  signStatus: 'You have signed the petition {{date}}',
  revokeSign: 'Unsign',
  revokeSignSuccess: 'You have successfully withdrawn your signature',
  revokeReject: 'Withdrawal',
  revokeRejectSuccess: 'You have successfully withdrawn the waiver',
  signRevoking: 'Withdrawal of signature',
  rejectRevoking: 'Withdrawal of rejection',
  revokeComment: "You're going to withdraw your signature.",
  revokeCommentPlaceholder: 'Be sure to comment on the reason for the recall',
  refuseCommentPlaceholder: 'Comment on the reason for the rejection',
  deleteCommentPlaceholder: 'Comment on the reason for the deletion',
  closeCommentPlaceholder: 'Comment on the reason for the closure',
  loginToSign: 'Log in to sign the petition',
  loginToSignMessage: 'To sign the petition you need to log in to an existing account or join the site by sending a request to the administrator.',
  guestWarning: 'Only votes from registered owners are counted in petition results. You can login or register.',
  petitionCreating: 'Creating a new petition',
  templateApply: 'Template application',
  petitionCreateFromTemplateMessage: 'Want to create a petition based on the "{{template}}" template?',
  petitionTemplateApplyMessage: 'Would you like to apply the petition template?',
  templateApplySuccess: 'The petition template has been successfully applied',
  сancelTemplateSelect: "Don't use, go back to templates",
  petitionTemplate: 'Petition template',
  commonResults: 'General',
  rollCallResults: 'Names',
  usersSigned: 'Signed',
  usersRejected: 'Rejected',
  usersAbstained: 'Abstentions',
  usersNotRead: 'Not familiarized',
  usersRevoked: 'Unsigned',
  sign: 'Sign',
  resultButtonTooltip: 'Results of the petition signatures',
  noTitle: 'No name given',
  noDesription: 'No description given',
  wrongDesription: 'The description must contain a minimum of {{count}} characters',
  wrongFinishDate: 'Incorrect end date for signature collection',
  signPetition: 'Sign the petition',
  petitionLink: 'Link to the petition',
  abstainedUsersTooltip: 'Owners who have seen the petition but have not yet signed or expressly declined to sign',
  notReadUsersTooltip: 'Owners who have not visited the petition page',
  notRegisteredUserWarning: 'Notice will not be delivered to unregistered owners',
  signCommentPlaceholder: 'Comment',
  loginToSendVoteResultTitle: 'Log in to send a vote',
  loginToSendVoteResultMessage: 'To have your vote counted in the petition results, you need to log into an existing account or join the site by sending a request to the administrator',
  voteCommentPlaceholder: 'Want to explain your decision? Feel free to leave a comment here',
  withoutComment: "Don't leave",
  withComment: 'Save comment',
  signCommentTitle: 'You signed a petition',
  revokeCommentTitle: 'You have withdrawn your signature on the petition',
  refuseCommentTitle: 'You refused to sign the petition',
  tariff: 'Not available for free tariff',
  closePetitionHint: 'Closing is only available for an active published petition that does not have an expiration date set',
  editPetitionHint: 'You cannot edit a published petition',
  backAndChangePhone: 'change number',
  petitionsResultInfoModalTitle: 'Counting of votes',
  petitionsResultInfoModalText1: '<span style=\\"font-weight: 600; color: #EC586A\\">IMPORTANT!!!</span> Please note that the area of the premises and the number of votes in % can be more than the area of the house in the settings and more than 100% of the votes. This is done deliberately, because when signing petitions it is much more important to know the total number of owners in pieces than the % of votes. Discrepancies can occur due to joint owners. To accurately calculate the % of votes, use the table "Register of Petition Signing with PEP", which is available to you in the "three dots" option of the "Download Printed Materials" menu.',
  petitionsResultInfoModalText2: 'Ordinary users, without moderator rights, only see information about the number of owners who signed the petition and those who refused to sign the petition.',
  cancelTemplateSelect: "Don't use, return to templates"
};