"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  registryError: 'Данные в реестре отличаются от данных в настройках',
  notFilled_0: 'Не заполнено',
  notFilled_1: 'Не заполнены',
  notFilled_2: 'Не заполнены',
  apartments_0: 'помещение',
  apartments_1: 'помещения',
  apartments_2: 'помещений',
  buildings_0: 'строение',
  buildings_1: 'строения',
  buildings_2: 'строений',
  living_0: 'жилое',
  living_1: 'жилых',
  living_2: 'жилых',
  nonLiving_0: 'нежилое',
  nonLiving_1: 'нежилых',
  nonLiving_2: 'нежилых',
  sortButtonWarningHint0: 'В реестре не показано {{counters}}, так как там не указан ни один собственник.',
  sortButtonWarningHint1: 'В реестре не показаны {{counters}}, так как там не указан ни один собственник.',
  loseDataWarning: 'Вы внесли изменения в настройки реестра. Хотите сохранить их?',
  notAddress: 'Данные по данному адресу не найдены',
  items: 'шт',
  inRegistry: 'В реестре',
  inSettings: 'В настройках',
  inReforma: 'В reformagkh.ru',
  apart: 'Помещения',
  settingsModalTitle: 'Настройки'
};