"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  changesHistory: 'История изменений',
  hideFilters: 'Спрятать фильтры истории изменений',
  showFilters: 'Показать фильтры истории изменений',
  closeHistory: 'Закрыть историю изменений',
  profileChangedAction: 'Изменен профиль',
  ownerRemovedAction: 'Удален собственник',
  ownerAddedAction: 'Добавлен собственник',
  ownerDataHasBeenCleanedAction: 'Данные собственника очищены',
  apartmentWasDeletedAction: 'Удалена квартира',
  addressChangedAction: 'Изменен адрес',
  areaOfApartmentChangedAction: 'Изменена площадь квартиры',
  houseWasDeletedAction: 'Удален дом',
  houseWithApartmentWasDeletedAction: 'Удален дом c помещением',
  changeAccessLevelAction: 'Изменены права пользователя',
  changeContactInfoAction: 'Изменена контактная информация',
  thereWasImportOfRegistry: 'Был импорт реестра'
};