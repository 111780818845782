"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  repeatSend: 'Отправить повторно',
  sendCode: 'Отправить код регистрации',
  sendInvite: 'Отправить приглашение',
  sendVerificationEmail: 'Отправить письмо для подтверждения e-mail',
  sendVerificationSMS: 'Отправить sms для подтверждения телефона',
  appeals: 'Внести данные',
  passwordEditing: 'Смена пароля',
  password: 'Сменить пароль',
  allowMeeting: 'Вернуть возможность голосовать',
  toggleMain: 'Подтвердить',
  attachCopiesOfDocuments: 'Прикрепить копии документов',
  addOwnership: 'Добавить собственность'
};