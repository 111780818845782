"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DADATA_ADDRESS_SEARCH_BOUND_HOUSE = exports.DADATA_ADDRESS_SEARCH_BOUND_STREET = exports.DADATA_ADDRESS_SEARCH_BOUND_SETTLEMENT = exports.DADATA_ADDRESS_SEARCH_BOUND_CITY = exports.DADATA_ADDRESS_SEARCH_BOUND_AREA = exports.DADATA_ADDRESS_SEARCH_BOUND_REGION = exports.DADATA_ADDRESS_SEARCH_BOUND_COUNTRY = void 0;
exports.DADATA_ADDRESS_SEARCH_BOUND_COUNTRY = 'country';
exports.DADATA_ADDRESS_SEARCH_BOUND_REGION = 'region';
exports.DADATA_ADDRESS_SEARCH_BOUND_AREA = 'area';
exports.DADATA_ADDRESS_SEARCH_BOUND_CITY = 'city';
exports.DADATA_ADDRESS_SEARCH_BOUND_SETTLEMENT = 'settlement';
exports.DADATA_ADDRESS_SEARCH_BOUND_STREET = 'street';
exports.DADATA_ADDRESS_SEARCH_BOUND_HOUSE = 'house';