"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Политика и условия',
  invitationModalPolicyText: 'Принимая данное приглашение на присоединение, я подтверждаю, что мне известны, и я принимаю Условия использования и политику Конфиденциальности сервиса Сквер.',
  buttonReject: 'Отказаться от приглашения',
  buttonAccept: 'Принять приглашение',
  pdfURL: 'https://sqvr-mailresources.s3.eu-central-1.amazonaws.com/mailresources/production/policy_and_condition.pdf',
  googleDocsPdfURL: 'https://drive.google.com/file/d/1dlidJxsho1og61bMQaFoIAY0RjAMhFhu/preview',
  checkBoxText: 'Принимаю данное приглашение и подтверждаю что мной прочитаны и одобрены  Политика конфиденциальности и Условия использования сервиса Сквер. ',
  downloadPdfText: 'Просмотр PDF недоступен, скачайте ' + '<a href="https://sqvr.ru/mailresources/policy_and_condition.pdf" target="_blank" rel="noopener noreferrer">' + 'Условия использования и Политику конфиденциальности' + '</a>' + ' сервиса Сквер.'
};