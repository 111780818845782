"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  uploadError: 'Ошибка загрузки',
  maxFileSizeExceed: 'Загружаемый Вами файл слишком большой. Он больше установленного лимита в 15Mb',
  maxFileSizeExceedCustom: 'Среди загружаемых файлов есть те, размер которых превышает максимально допустимый размер в {{limit}}',
  maxFilesCountExceed: 'Вы не можете прикреплять более 10 файлов',
  maxFilesCountExceedCustom_0: 'Вы не можете загружать более {{count}} файла',
  maxFilesCountExceedCustom_1: 'Вы не можете загружать более {{count}} файлов',
  maxFilesCountExceedCustom_2: 'Вы не можете загружать более {{count}} файлов',
  preventSelectMoreFiles_0: 'Вы не можете выбрать более {{count}} файла',
  preventSelectMoreFiles_1: 'Вы не можете выбрать более {{count}} файлов',
  preventSelectMoreFiles_2: 'Вы не можете выбрать более {{count}} файлов',
  preventSelectMoreFilesText1_0: 'Количество загружаемых файлов {{count}} штука',
  preventSelectMoreFilesText1_1: 'Количество загружаемых файлов {{count}} штуки',
  preventSelectMoreFilesText1_2: 'Количество загружаемых файлов {{count}} штук',
  preventSelectMoreFilesText2_0: 'больше чем максимально допустимое {{count}} штука',
  preventSelectMoreFilesText2_1: 'больше чем максимально допустимое {{count}} штуки',
  preventSelectMoreFilesText2_2: 'больше чем максимально допустимое {{count}} штук',
  preventSelectNewFiles: 'Вы уже выбрали максимальное количество файлов',
  filesUpload: 'Загрузка файлов',
  filesUploadTitle_0: 'Загрузка {{count}} файла...',
  filesUploadTitle_1: 'Загрузка {{count}} файлов...',
  filesUploadTitle_2: 'Загрузка {{count}} файлов...',
  successUpload_0: 'Загрузка {{count}} файла завершена',
  successUpload_1: 'Загрузка {{count}} файлов завершена',
  successUpload_2: 'Загрузка {{count}} файлов завершена',
  cancelUpload: 'Остановить загрузку',
  filesUploadRestriction: 'Выберите не более 10 файлов. Размер файла - не больше 15Mb.',
  upload: 'Загрузить',
  availableFileFormats: 'Форматы файлов, доступные для загрузки',
  image: 'Изображение',
  audio: 'Аудио',
  video: 'Видео',
  archive: 'Архив',
  document: 'Документ',
  selectFiles_0: 'Выберите файл для загрузки.',
  selectFiles_1: 'Выберите не более {{count}} файлов.',
  selectFiles_2: 'Выберите не более {{count}} файлов.',
  fileSizeLimit: 'Размер файла - не больше {{maxSize}}Mb.',
  availableSpace: 'Доступное место',
  selectedFiles: 'Выбрано файлов',
  wrongFormat: 'Недопустимый формат файла',
  noSpaceOnTheDisk: 'На диске нет места:',
  messageOfAvailableDiskSpace: 'Вам доступно только {{availableSpace}}, а Вы пытаетесь загрузить {{filesTotalSize}} данных. Перейдите в тарифы и оплатите дополнительное дисковое пространство',
  successFilesUpload: 'Файл успешно загружен',
  errorFileUpload: 'Загрузка прервана. Повторие попытку',
  estimatedTimeForUpload_0: 'Осталось {{count}} минута',
  estimatedTimeForUpload_1: 'Осталось {{count}} минуты',
  estimatedTimeForUpload_2: 'Осталось {{count}} минут',
  switchToTariffs: 'Перейти в тарифы',
  reloadFileUpload: 'Загрузка прервана. Повторить попытку',
  reloadFileUploadS3: 'Загрузка файлов' + '<br>' + '<br>' + ' В течение 15 минут все загруженные бюллетени будут обработаны автоматически и добавлены к участникам голосования. Все нераспознанные файлы Вы сможете посмотреть во вкладке «Нераспознанные документы» на странице «Собрания»' + '<strong> Подождите 15 минут.</strong>',
  countFilesWithErrors_0: 'Загрузка {{count}} файла была отменена',
  countFilesWithErrors_1: 'Загрузка {{count}} файлов была отменена',
  countFilesWithErrors_2: 'Загрузка {{count}} файлов была отменена'
};