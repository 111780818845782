"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  whoAreYou: 'Who are you?',
  multipleHOAHint: 'There are several properties registered in this partnership with different addresses, please select the home you need',
  selectHouse: 'Select the address of the house',
  yourData: 'Your data',
  percentValidationMessage1: 'Value must not be greater than',
  fractionValidationMessage3: 'Format (number/number): 1/2 - numbers must be greater than',
  totalArea: 'Total area',
  fractionValidationMessage4: 'Value must not be greater than 1',
  fractionValidationMessage1: 'Format: 1/2. Not more than 1',
  fractionValidationMessage5: 'Format (number/number): 1/2 - numbers must be greater than 0',
  valueMustntBeNumber: 'Value must not be less than',
  valueMustBeNumber: 'Value must be a number',
  fractionValidationMessage2: 'Format (number/number): 1/2',
  areaValidationMessage1: 'Value must not exceed total area'
};