"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Add your e-mail address',
  titlePassword: 'Choose a Password',
  titleDone: 'You did the right thing',
  titlePasswordConfirmWarning: 'Passwords do not match',
  loginFormTitle: 'This e-mail has already been registered. Enter the password for this e-mail.',
  subTitleExist: 'But this account is registered in another facility, if you want to join {{hoaName}} as well, do the following:',
  titleExist: 'Turns out you already have an account',
  titleConflict: 'Use a different e-mail address',
  whatsappFormTitle: 'Invitation',
  whatsappFormReject: 'Interrupt. UNJOIN',
  whatsappFormContinue: 'Join ',
  tooltipPhone: 'You cannot change your phone number right now. If this is not your number, click on \\"PREVENT. DO NOT CONNECT\\" at the bottom of this window. You will be able to change your phone number after you complete your registration.',
  existPassword: 'Enter your password',
  existWait: 'Wait for the application to be accepted',
  existWaitLogin: 'Once your application is approved, you will be able to use the same login and password to log in to different Facilities.',
  existWarning: 'The Facility Administrator will receive your application to join and, once approved, you will receive messages confirming your phone number and email address.',
  phone: 'The phone number has already been saved. You cannot change it now, as this is the phone to which the invitation was sent. You will be able to change it after registration.',
  donePhone: "The phone number's been saved.",
  email: '<strong>Enter</strong> your <strong>e-mail address</strong>. Then you will be able to receive messages about all events in the house or township and reduce the cost of sending sms.',
  doneEmail: 'The e-mail address has been saved',
  password: '<strong>Make up a password.</strong>',
  passwordValidate: 'Make up a password.',
  donePassword: 'Password created.',
  passwordConfirm: '<strong>Repeat the password</strong> to avoid the error.',
  differentPasswords: 'Passwords do not match',
  donePasswordConfirm: 'The password has been re-entered.',
  warning: 'The Facility Administrator will receive your application to join and, once approved, you will receive a confirmation sms for your phone number.'
};