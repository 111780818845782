"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  contacts: 'Статус контакта с участником. Запланированный контакт',
  editCommentPlaceholder: 'Укажите комментарий к изменению, минимум 2 символа',
  commentPlaceholder: 'Прокомментируйте свой последний контакт, чтобы другие организаторы были в курсе',
  noContacts: 'Нет необходимых контактных данных',
  lastContact: 'Последний контакт',
  nextContact: 'Следующий контакт',
  noContactsOccurred: 'Контактов не было',
  noContactsPlanned: 'Контакт не запланирован',
  statusChangeSuccessfully: 'Статус успешно изменен!',
  nextContactHasPlanned: 'Запланирован следующий контакт!',
  closeContactModalAlertTitle: 'Закрытие',
  closeContactModalAlertText: 'При закрытии вы потеряете введенные данные. Хотите выйти?',
  closeContactModalAlertYesLabel: 'Выйти',
  closeContactModalAlertNoLabel: 'Отменить',
  chooseNextContact: 'Выберите дату и время\nследующего контакта',
  linkCopied: 'Ссылка скопирована в буфер обмена!'
};