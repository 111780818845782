"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  notFoundRowsToDelete: 'No rows to delete',
  unknownStatus: 'Row deletion status: {{status}}',
  noUsersForMerge: 'No user has been selected to join the property',
  availableOnlyJoinOwnership: 'Only shared ownership is available to add, you have no vacant property left to add',
  notAvailableOwnershipNotFoundFreeSpace: "You don't have any vacant property",
  editingAllowedOnlyForJurPersons: 'TIN editing is available for Legal entities and State only',
  impossibleAddingNotFoundOwner: 'Unable to add contact details to service line, add owner first',
  impossibleEditingCell: 'Cannot edit this cell in the service row, add the owner first',
  serviceRowNotEditing: 'This is a service line showing the proportion of unfilled space in the room. It cannot be edited or otherwise manipulated',
  canNotEditInRoomView: 'Not available for editing in room view mode',
  joiningRequestContactDataChangeWarning: 'Changing contact information is not available in the lines from the applications for joining!',
  registryWithJoiningRequestsDeleteWarning: 'In the register there are lines with applications for connection. If the register is deleted, these applications will be rejected!',
  joiningRequestAccepted_0: 'The application to join {{count}} pcs. has been successfully accepted. Now a notification will be automatically sent to the e-mail address or cell phone of the owner with a proposal to confirm his e-mail address or phone number. If some user did not receive or did not respond to the notification, you have the opportunity to send the same notification to this owner from his profile by clicking on the "Send invitation code" button.',
  joiningRequestAccepted_1: 'Connection requests {{count}} pcs. have been successfully accepted. Now notifications will be automatically sent to the e-mail addresses or cell phones of the owners with the offer to confirm their e-mail address or phone number. If some user did not receive or did not respond to the notification, you have the opportunity to send the same notification to this owner from his profile by clicking on the "Send invitation code" button.',
  joiningRequestRejected_0: 'The application for annexation has been rejected!',
  joiningRequestRejected_1: 'Applications for annexation rejected!',
  registryDeleteWarningTitle: 'Removing the registry!',
  registryDeleteWarningMessageFirst: '1. All room lines will be removed from the registry except those belonging to registered users.',
  registryDeleteWarningMessageSecond: '2. The current registry will be saved as an excel file. You will be able to find it in the registry versions.',
  registryDeleteWarningNotification: '3. We strongly advise against deleting the registry. If you need to make mass edits, it is easier to select and clear the information in the desired rows or columns. To do this, select the rows or columns and use the button',
  juristicPerson: 'Legal entities',
  rowsCleared: 'Rows cleared',
  cantEdit: 'Editing field {{name}} for UNKNOWN owner is not available, first you need to add a name',
  hasNoRows: 'No rows to clear',
  states: 'Countries',
  formRegisteredType: 'Forms/Names/OGRN of the registered {{typeString}}',
  statusRowsCleared: 'Status of row clearing: {{status}}',
  hideThisAlert: 'Hide this alert.',
  fio: 'Full name of the registered user'
};