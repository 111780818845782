"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  publishedDraftHeaderThereIsNew: "There's a new one",
  publishedDraft: 'Published draft',
  publishedDraftHeaderOfMeeting: 'meetings',
  publishedDraftBodyNewMeetingIsPlanned: 'A new meeting is being planned.',
  publishedDraftBodyMeetingTitleIs: 'Meeting Title:',
  publishedDraftBodyHelpText: 'Voice your opinion even before the vote. You can influence the formation of the draft decision. Click on any question. Read the draft decision and leave your comments. Perhaps you will propose a better solution.',
  publishedDraftOpenMeeting: 'Go to the meeting.',
  active: 'Expected',
  activeHeader: 'beginning of meeting',
  activeBodyLeftBeforeStart: 'We have until the start of the meeting',
  activeBodyLeftDays_0: 'day',
  activeBodyLeftDays_1: 'days',
  activeBodyLeftDays_2: 'days',
  activeBodyStartVoting: 'voting on agenda items will commence',
  activeBodyNormal: 'there will be an in-person portion of the meeting',
  activeBodyFinishVoting: 'voting is closing',
  activeBodyAgenda: 'Questions put to the vote',
  activeBodyHelpText: 'You can read all the issues of the meeting day and print your ballot by clicking here',
  activeBodyLink: 'at this link',
  meeting: 'Meeting',
  voting: 'Taking place at the moment',
  votingBodyMeetingInProcess: 'Voting on agenda items is currently in progress',
  votingBodyUsual: 'ordinary',
  votingBodySudden: 'extraordinary',
  votingBodySemiVirtual: 'semi-virtual meeting',
  votingBodyVirtual: 'visual meeting',
  votingBodyNormal: 'in-person meeting',
  votingBodyOwners: 'owners',
  votingBodyResidents: 'members of the partnership',
  votingBodyCommittee: 'committee members',
  votingBodyCanVoteUntil: 'You can vote or change your vote right up until the voting deadline date',
  votingBodyYear: 'years',
  votingResult: 'Preliminary voting results: already voted - ',
  votingBodyOwnersCount_0: 'proprietor',
  votingBodyOwnersCount_1: 'proprietor',
  votingBodyOwnersCount_2: 'owners',
  votingBodyResidentsCount_0: 'partnership member',
  votingBodyResidentsCount_1: 'partnership member',
  votingBodyResidentsCount_2: 'members of the partnership',
  votingBodyCommitteeCount_0: 'committee member',
  votingBodyCommitteeCount_1: 'committee member',
  votingBodyCommitteeCount_2: 'committee members',
  votingBodyHas_0: 'having',
  votingBodyHas_1: 'having',
  votingBodyHas_2: 'having',
  votingBodyOfVotes: 'votes',
  votingBodyNoQuorum: 'No quorum',
  votingBodyHasQuorum: 'We have a quorum',
  votingBodyNoResults: 'Not a single decision has been made',
  votingBodyHelpText: 'You can view the preliminary vote totals, print your ballot and vote by going to',
  votingBodyLinkText: 'at this link',
  calcResults: 'The votes are being counted',
  calcResultsBodyFinished: 'Voting is over',
  calcResultsBodyDays_0: '{{count}} day',
  calcResultsBodyDays_1: '{{count}} of the day',
  calcResultsBodyDays_2: '{{count}} days',
  calcResultsBodyCalculation: 'votes are being counted',
  calcResultsBodyHelpText: 'You can view preliminary voting results by going to',
  calcResultsLinkText: 'at this link',
  closed: 'Canceled',
  closedBodyHelpText: 'View details and write your opinion You can',
  closedBodyLinkText: 'can be found here',
  meetingWasClosedWithComment: 'The meeting was closed with the following comment:',
  meetingWasClosedByUserWithComment: 'closed the meeting with the following comment:',
  finishedAccepted: 'Held',
  finishedBodyFinished: 'The meeting has been finalized. The resulting protocol has been published.',
  finishedBodyHasQuorum: 'We have a quorum',
  finishedBodyNoQuorum: 'No quorum',
  finishedBodyHelpText: 'View the vote totals by going to',
  finishedBodyLinkText: 'at this link',
  finishedRejected: "Didn't happen"
};