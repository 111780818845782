"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  registryColumnSettings: 'Редактировать отображение столбцов',
  columnSettingsWithHiddenRows: 'Вы скрыли некоторые столбцы, зайдите в настройки, чтобы вернуть их отображение',
  discardChanges: 'Отменить правки и вернуться к исходному реестру',
  discardChangesDisabled: 'Нет правок для отмены',
  joinInvite: 'Пригласить собственников дома',
  joinInviteDisabled: 'Обратитесь к Администратору или Модератору, чтобы пригласить пользователя в Сквер',
  joinRequests: 'Заявки на присоединение к дому',
  joinRequestsVillage: 'Заявки на присоединение к поселку',
  joinRequestsDisabled: 'Нет заявок на присоединение',
  registryHistory: 'История версий',
  registryHistoryDisabled: 'Нет истории версий',
  registryFilters: 'Показать все фильтры',
  registrySelectColumn: 'Выделить столбец',
  registrySortColumn: 'Сортировать значения',
  changeShipTypeInColumn_town: 'Изменить отображение. Возможные значения: % , дробь, м.кв.',
  changeShipTypeInColumn_village: 'Изменить отображение. Возможные значения: % , дробь',
  registryBlocked: 'Реестр заблокирован',
  isNotCleanableColumn: 'Нельзя очистить данный столбец',
  filtersChip: 'Для просмотра и изменения фильтров кликните на иконку фильтра. Выбранные фильтры будут выделены цветом.',
  disableFilter_apartmentView: 'Нельзя использовать фильтр в режиме просмотра всех собственников одного помещения',
  disableFilter_emptyRegistry: 'Нечего фильтровать. Нет данных для фильтрации.',
  allowMeeting: 'Данный пользователь не может голосовать на собрании, причина:',
  unknownPersonNotice: 'Служебная строка показывает, сколько площадей пустует в данном помещении. Введите ФИО собственника для заполнения этой площади',
  tempRows: 'Пожалуйста, отключите временные (синие) строки',
  downloadVersion: 'Скачать версию реестра',
  noLink: 'Нет ссылки для скачивания версии',
  unknownDate: 'Дата неизвестна',
  nothingToClear: 'Нечего очищать. Сначала выделите строку или столбец'
};