"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  openedApartmentChipTitle: 'All owners of premises num{{apartmentNumber}}',
  showAllOwnersOfOneApartment: 'Show all owners of the same premises',
  youAlreadyInApartment: "You're already in apartment",
  notSelectedOrMultipleSelected: 'It is not possible to show all owners of one room. Now none or several premises are selected at once',
  isOneUserSelectedForConnect: 'Select at least 2 lines to join the rooms',
  isUnknownSelectedForConnect: 'Attachment is not available for users with type "UNKNOWN" - these are service strings',
  isSameApartmentSelectedForConnect: 'It is not possible to combine users within the same room',
  addOwner: 'Add owner',
  deleteRows: 'Delete rows',
  sendNotification: 'Notify users',
  connectApartmentToOwner: 'Attach the premises to the owner',
  search: 'Search'
};