"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  serverError: 'Server error',
  stepPart1: 'Step',
  stepPart2: 'does not exist in the list of onboarding steps',
  serverConnectionError: 'An error occurred while connecting to the server',
  serverRequestError: 'Sorry, an error occurred while requesting',
  serverRequestErrorDescription: 'Something went wrong. Our engineers have already received an error message and will address it shortly. We apologize for any inconvenience caused.',
  atTheMomentTheServerIsUnderMaintenance: 'At the moment, technical work is being performed on the server. Please try to perform the action later',
  successfullySaved: 'Successfully saved!',
  innerErrorApp: 'Internal application error. Please try again or contact support.',
  notPossibleToSwitch: 'Unable to switch between objects',
  emailOfCurrentUserIsNotSpecified: 'The email of the current user is not specified',
  selectedAddressWasNotFound: 'The selected address was not found',
  addressIdIsNotRecognized: 'The address ID is not recognized, please try to select the address again'
};