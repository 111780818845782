"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  ownershipsMerge: 'Joining of spaces',
  noNotRegisteredUsersForMerge: 'Premises with unregistered owners have not been selected for joining!',
  connectingRooms: 'Joining rooms',
  yesPass: 'Yes, transfer'
};