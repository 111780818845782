"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  placeholder: 'Прокомментируйте причину редактирования',
  placeholderWithMinSymbolsCount: 'Прокомментируйте причину редактирования',
  saveEnteredData: 'Сохранить внесенные данные?',
  placeholderDelete: 'Прокомментируйте причину удаления',
  title: 'Обязательный комментарий'
};