"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  facebook: 'Facebook',
  twitter: 'Twitter',
  vk: 'VK',
  google: 'Google'
};