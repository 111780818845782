"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Invitation',
  buttonReject: 'Decline',
  buttonAccept: 'Accept invitation',
  text: 'Invitation for <strong>{{ name }}</strong>. You have been invited as <strong>{{ role }}</strong> to join Object <strong>{{</strong> <strong> hoaName }}</strong> and register for the Square <strong>{{ subdomain }}</strong> service. You can accept the invitation and register with this Object, or decline.'
};