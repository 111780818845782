"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  loginToSendVoteResultTitle: 'Log in to submit responses',
  loginToSendVoteResultMessage: 'In order for your responses to be included in the survey results, you will need to log in to an existing account or join the site by submitting a request to the administrator',
  joinToObject: 'Join the facility',
  requiredAuthorizationMessage1: 'This functionality is for registered users only.',
  requiredAuthorizationMessage2: 'Authorize using the "Login" button if you are already registered with this Facility.',
  requiredAuthorizationMessage3: 'Submit an application to join this Facility if you would like to access this functionality.',
  voteError: 'An error has occurred. Please contact your administrator',
  downloadTitle: 'Voting roster successfully sent to the post office!'
};