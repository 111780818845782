"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Настройки',
  commonData: 'Общие данные',
  registry: 'Реестр',
  informationDisclosure: 'Раскрытие информации',
  userRights: 'Права пользователей'
};