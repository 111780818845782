"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  tooltipForWarningIcon: 'User has disabled receiving notifications and messages',
  menuItem1: 'Add owner',
  menuItem2ManagementStuff: 'Add a CC employee',
  menuItem2HOAEmployee: 'Add an employee of the Partnership',
  menuInvite1: 'Send invitations by email or text',
  menuInvite2: 'Print out the invitations and hand them out',
  successInviteMessage: 'You will receive invitations to your email ({{email}}) within 15 minutes',
  notifyNoOneSelected: 'No user is selected',
  addUserButtonTooltipText: 'Add user'
};