"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  close: 'Back',
  login: 'Log in',
  ok: 'OK',
  emailUsedForResidentInCurrentHoaContent: 'The e-mail address you entered is already registered in this object. You cannot create a connection request with it. Go back and use a different e-mail address. If you do not want to continue registration, you can log in to your Object.',
  phoneUsedForResidentInCurrentHoaContent: 'The phone number you entered is already registered with this Site. You cannot add it to your account. Use a different phone number or click on the "Login" button to log in to this Facility with your password.',
  phoneUsedForResidentInCurrentHoaTitle: 'The phone number is already registered',
  emailUsedForResidentInCurrentHoaTitle: 'E-mail already registered',
  differentUserTypesTitle: 'E-mail already registered',
  differentUserRolesTitle: 'E-mail already registered',
  differentUserTypesContent: 'The e-mail address you entered is already registered. You cannot merge a physical person and a legal entity. Use a different e-mail address. If you do not want to continue, you can log in to your Object.',
  differentUserRolesContent: 'The e-mail address you entered is already registered. You cannot combine users with different roles within the same Object. Use a different e-mail address. If you do not want to continue, you can log in to your Object.'
};