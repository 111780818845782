"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GraphQLTypesLimits = void 0;
var GraphQLTypesLimits;
(function (GraphQLTypesLimits) {
  GraphQLTypesLimits[GraphQLTypesLimits["AmountMax"] = 200000] = "AmountMax";
  GraphQLTypesLimits[GraphQLTypesLimits["AreaMax"] = 500000] = "AreaMax";
})(GraphQLTypesLimits || (exports.GraphQLTypesLimits = GraphQLTypesLimits = {}));