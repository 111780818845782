"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  meetings: 'Собрания',
  polls: 'Опросы',
  complaints: 'Жалобы',
  announcements: 'Объявления',
  documents: 'Документы',
  usefullAddresses: 'Полезные адреса',
  reports: 'Отчеты',
  users: 'Пользователи',
  noDigest: 'В вашем объекте еще нет ни одного дайджеста',
  emptyDigest: 'События в доме',
  emptyDigestVillage: 'События в поселке',
  emptyMeetings: 'За данный период не было никаких событий по собраниям.',
  emptyPolls: 'Эх. Никто не интересуется мнением жильцов. Ни одного нового опроса.',
  emptyAnnouncements: 'За данный период не было ни одного нового объявления.',
  emptyDocuments: 'За данный период не было загружено ни одного документа.',
  emptyUsefullAddresses: 'За данный период не было добавлено ни одного нового адреса',
  emptyReportsNotVillage: 'За прошлый месяц не было подано ни одного отчета, потому что в сервисе Сквер нет информации о бюджете дома.',
  emptyReportsVillage: 'За прошлый месяц не было подано ни одного отчета, потому что в сервисе Сквер нет информации о бюджете поселка.',
  emptyReportsHelp: 'Ведение отчета в Сквере позволяет собственникам ежемесячно получать информацию обо всех расходах и работах проводимых в доме. Если Вы хотите получать информацию о выполняемых работах и предоставляемых отчетах, проголосуйте на собрании или проведите опрос в своем доме',
  emptyReportsHelpVillage: 'Ведение отчета в Сквере позволяет собственникам ежемесячно получать информацию обо всех расходах и работах проводимых в поселке. Если Вы хотите получать информацию о выполняемых работах и предоставляемых отчетах, проголосуйте на собрании или проведите опрос в своем поселке',
  meetingsActionText: 'Просмотреть все собрания.',
  pollsActionText: 'Просмотреть все опросы.',
  complaintsActionText: 'Просмотреть все жалобы.',
  announcementsActionText: 'Просмотреть все объявления.',
  documentsActionText: 'Просмотреть все документы.',
  usefullAddressesActionText: 'Просмотреть все адреса.',
  reportsActionText: 'Просмотреть все отчеты.',
  usersActionText: 'Просмотреть всех пользователей.',
  documentsFilledMain: 'У вас есть новые документы:',
  usefulAddressesMain: 'Соседи щедро делятся полезными адресами. В Сквер добавлены новые места',
  usersMainPart1: 'Да в вашем полку прибыло!',
  usersMainPart2: 'Так держать.',
  usersMainNeutral: 'Новых пользователей в этом месяце -',
  usersItemTotal: 'Всего в нашем объекте:',
  usersItemOwners: 'собственники',
  usersItemContractors: 'подрядчики',
  usersItemEmployees: 'сотрудники товарищества',
  usersItemEmployeesStructureTypeOther: 'сотрудники УК',
  usersTurnOffNotifications: 'Отключили получение уведомлений - ',
  announcementMain: 'За прошедший месяц было опубликовано объявлений - {{all}}, объявлений со статусом SOS -',
  announcementItem: 'Объявление:'
};