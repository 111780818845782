"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  PollsCreated: 'A poll has been created',
  PollTitleChanged: 'Poll title changed',
  PollDescAdded: 'Added a description of the poll',
  PollDescChangedDetail: 'Poll description changed',
  PollDescDocAdded: 'A document has been added to the poll description',
  PollDescDocDeleted: 'The document has been removed from the survey description',
  PollQuestionAdded: 'Question added',
  PollQuestionTitleChanged: 'Changed question title',
  PollQuestionItemsAdded: 'An answer choice has been added to the question',
  PollQuestionItemsDeleted: 'The answer choice has been removed from the question',
  PollQuestionItemsChanged: 'The answer choice in the question has been changed',
  PollQuestionTypeChanged: 'Changed question type',
  PollQuestionDocAdded: 'A document has been added to the question',
  PollQuestionDocDeleted: 'The document has been removed from the question',
  PollQuestionDeleted: 'Question deleted',
  PollWasPublished: 'A survey has been published',
  PollWasDeleted: 'Poll deleted',
  PublishedPollWasCanceled: 'Published poll canceled',
  PollWasFinished: 'Survey completed',
  PollDateFinishedChanged: 'Survey end date has been changed',
  PollCreatedFromTemplate: 'Survey template used',
  PollCreatedFromCopy: 'A copy of the survey has been created',
  PollVoted: 'Voted in the poll',
  PollExportResult: 'A printed version of the survey has been downloaded',
  FiltersAndSearch: 'Filters and poll search'
};