"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Ordering the register of premises owners',
  yesLabel: 'Back',
  okLabel: 'Update the register',
  noLabel: 'order a register',
  tariffLabel: 'Go to plans',
  cadastralNumber: 'Cadastral number of your house - {{cadastralNumber}}',
  alreadyOrderRegistry: 'You have already ordered the register of owners of premises, so it is better not to order the register again, but to use the service "Updating of the register". ',
  reminder: 'As a reminder, you may update the ownership roster up to {{endDate}} one more {{restCount}} time.',
  buyActualization: 'You can choose and pay for the "Registry Update" service ',
  goToTariffs: 'by going to the "Rates" page.',
  attention: 'Important!!!',
  suggestion: ' We recommend that you activate the "Register Update" service only before the general meeting.',
  priceCalculating: 'The cost of re-ordering the register of owners for your building is - ',
  priceActualization: 'Cost of updating the register of owners for your building - ',
  price: '{{price}} Dollars',
  registryOrderPrice: 'Cost of {{price}} dollars. Calculated as the number of premises in the MFB multiplied by {{cost}} dollars.',
  calculatingSchemePart1: 'Exact costing formulas ',
  calculatingSchemeInfoModalTitle: 'The exact formula for calculating the cost ',
  calculatingSchemePart2: 'registry order',
  calculatingSchemePart3: ' By whom and when was issued ',
  calculatingSchemePart4: 'updating the registry.',
  supportPart1: 'If you believe that the wrong cadastral number has been used for your MCD, ',
  supportPart2: 'please contact tech support.',
  tooltipOrderTitle: 'Exact cost of ordering the register of owners',
  tooltipOrderTitleMKD: ' for the residential building located at the address: ',
  tooltipOrderTitleGroup: ' for a group of ICDs located at the following addresses: ',
  step: 'Step ',
  tooltipPart1: ' We have found out the total number of rooms in your MCD.',
  tooltipCode1: 'In the Capital Construction Object (CCO) with cadastral numbers ',
  tooltipApartments_0: 'space available {{count}}',
  tooltipApartments_1: 'spaces available {{count}}',
  tooltipApartments_2: 'spaces available {{count}}',
  tooltipCalcApartments_0: 'there is {{count}} room (estimated number of rooms = {{count}})',
  tooltipCalcApartments_1: 'there are {{count}} rooms (estimated number of rooms = {{count}})',
  tooltipCalcApartments_2: 'there are {{count}} rooms (estimated number of rooms = {{count}})',
  tooltipPart2: 'In the Capital Construction Object (CCO) under cadastral number {{cadastralCode}}',
  tooltipPart5: ' Calculation of the estimated number of rooms. Application of the reduction factor {{coefficient}}.',
  tooltipPart6: 'As a rule, there are not only premises that have specific owners, but also premises belonging to the common property. No one knows how many such premises there are. Therefore, we apply a reduction factor {{coefficient}} for calculation.',
  tooltipPart7: 'We multiply {{amount}} * {{coefficient}} = {{total}} We round down to a whole number and get the calculated number of ICD premises - {{rounding}}.',
  tooltipPart9: ' Cost of ordering the register of owners',
  tooltipPart10: 'Multiply the estimated number of premises by {{cost}} rubles per one premise and get the cost of the register = {{finalCost}} rubles.',
  tooltipPart11: 'Multiply the estimated number of premises by {{cost}} rubles per one premise and get the cost of Register Actualization = {{finalCost}} rubles.',
  tooltipActualizeTitle: 'Exact cost of Updating the register of owners ',
  tooltipActualizeCost: ' Cost of Updating the Register of Owners',
  example1: ' To find out the total number of premises in the MFB',
  example2: 'Obtaining a general extract for the house, containing cadastral numbers of all premises in the MFB (CCS)',
  example3: 'Example: The OCS statement shows 538 premises, which includes stairs, basements, risers, attics, etc. We multiply 538 * {{coefficient}} = {{total}} Round down to a whole number and get the calculated number of premises in the building - {{rounding}}.',
  example4: 'Multiply the estimated number of premises by {{cost}} rubles per one premise and get the cost of the register = {{finalCost}} rubles.',
  actualizationTitle: 'The registry is being updated'
};