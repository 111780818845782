"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  noContacts: 'Нет добавленных контактных лиц',
  contactInfo: 'Контактные данные:',
  documents: 'Документы:',
  documentIsValidPersonality: 'Документ, удост. личность:',
  ownershipDocument: 'Документ на право собственности:',
  ownership: 'Собственность:',
  ownershipDocuments: 'Документы, удостоверяющие личность собственника'
};