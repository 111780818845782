"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  address: 'Property address',
  share: 'Share',
  entrance: 'Entrence',
  floor: 'Floor',
  ownershipType: 'Type of ownership',
  documents: 'Documents confirming ownership:',
  documentsName: 'Type of document',
  documentsNumber: 'Document number',
  documentsDate: 'Date of issue',
  owners: 'Owners in the premises',
  deleteOwnership: 'Remove the property',
  living_town: 'Living quarters',
  living_village: 'Residential building',
  noLiving_town: 'Non-residential premises',
  noLiving_village: 'Non-residential building',
  house: 'House',
  land: 'Land plot',
  townhouse: 'Apartment in a townhouse',
  room: 'Room in apartment',
  unknown: 'Unknown',
  other: 'Other',
  owner: 'Owner'
};