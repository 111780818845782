"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  statusActive: "There's a vote going on",
  statusFinished: 'The survey is now closed',
  days_0: '{{count}} day',
  days_1: '{{count}} day',
  days_2: '{{count}} days',
  activeHeaderConnection: 'poll on subject:',
  activeWillLast: 'Voting on the poll will continue for another',
  activeWillFinish: 'and will conclude',
  activeFinishAt: 'at',
  activeRestMessage: 'If you have already voted, you can view the preliminary results of the poll. If you have not voted yet, be sure to vote. You can vote on the poll and change your mind right up until the poll ends. Thank you.',
  activeOpenPollBtnText: 'Go to Poll',
  finishedHeaderConnection: 'Survey topic:',
  finishedInQuestion: 'In the question',
  finishedMostPopularAnswer: 'the most popular answer was',
  finishedGainedPercent: 'He typed',
  finishedVotes: 'votes',
  finishedGainedFromParticipants: 'from the owners who participated in the voting, or',
  finishedOfVotes: 'from votes',
  finishedGainedFromAll: 'of all the owners.',
  finishedNobodyVoted: 'No one voted in the poll.'
};