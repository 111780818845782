"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Connect Telegram-Bot',
  exit: 'Cancel',
  connectTelegram: 'Connect Telegram bot',
  chooseUsersOne: 'You have selected {{users}} proprietor.',
  chooseUsersFew: 'You have selected {{users}} owners.',
  ofThem: ' Of which:',
  noRegistreredOne: '{{users}} is not yet registered with Sqvr. We will not be able to send him a notification.',
  noRegistreredFew: '{{users}} have not registered with Sqvr yet. We will not be able to send them notifications.',
  hasTelegramOne: '{{users}} has already connected the telegram bot. He will not receive the notification again.',
  hasTelegramFew: '{{users}} have already connected the telegram bot. They will not be notified again.',
  offNotificationsOne: '{{users}} has disabled the ability to receive private notifications from Sqvr, and will not receive a notification either.',
  offNotificationsFew: '{{users}} have disabled the ability to receive private notifications from Sqvr, and will not receive notifications either.',
  other: 'All other owners selected by you will be notified by e-mail or SMS with a unique link, clicking on which they will be able to connect Telegram-bot',
  all: 'All owners selected by you will receive e-mail or SMS notifications with a unique link, clicking on which they will be able to connect Telegram-bot',
  uniqueLinks: 'You have the ability to send specific owners unique links to connect a Telegram bot from their Profile',
  uploadQR: 'Download QR code',
  or: ' or ',
  copyLink: 'copy the link',
  copySuccess: 'The link has been successfully copied',
  forMessengerAndAd: ' telegram bot connections for placement in messengers and print ads',
  goToTariffs: 'Pay the tariff',
  back: 'No, go back',
  noLimits: 'At the moment you have no possibility to send notifications to the selected owners ({{users}}), because there are not enough limits for sending sms and e-mail.',
  mayGo: 'You can go to \"',
  tariffs: 'Tariffs',
  pay: '\\" and pay for the option you want.',
  wasSentOne: 'Notice sent {{user}} to the owner',
  wasSentFew: 'Notices sent to {{users}} owners',
  noLimitsSms: 'At the moment you are unable to send notifications to selected owners ({{users}}) as there are not enough sms limits.',
  noLimitsEmail: 'Currently you are unable to send notifications to the selected owners ({{users}}) because there are not enough e-mail limits.',
  nobodyChoose: 'All of your selected users will not receive a notification with a link to connect a Telegram bot because they have either already connected Telegram, are not yet registered with Sqvr, or have turned off receiving notifications.',
  chooseOther: 'Select other users.',
  tooltip: 'User disabled personal notifications',
  invitationSMSTemplate: 'Connecting telegram bot linklinklinklinklinklinklinklinklinklinklinklinklinklinklinklinklinklinklinklinkli'
};