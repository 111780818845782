"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HTTPRequestError = void 0;
const utils_1 = require("@sqvr/utils");
class HTTPRequestError extends Error {
  constructor(error, status, originalError, originalData) {
    super(error);
    this.error = error;
    this.status = status;
    this.originalError = originalError;
    this.originalData = originalData;
  }
}
exports.HTTPRequestError = HTTPRequestError;
class BaseRestService {
  constructor() {
    this.customApiPath = '';
    this.basePath = '';
    this.send = (method = utils_1.GET, params = '', subPath = '', options = {}, requestOptions = {}) => __awaiter(this, void 0, void 0, function* () {
      var _a;
      const optionsMiddlewares = BaseRestService.commonOptionsMiddlewares;
      const errorHandlers = BaseRestService.commonErrorHandlers;
      let computedOptions = {
        method
      };
      if (!requestOptions.withoutOptionsMiddlewares) {
        optionsMiddlewares.forEach(middleware => computedOptions = middleware(computedOptions));
      }
      computedOptions = Object.assign(Object.assign({}, computedOptions), options);
      const url = `${this.customApiPath || BaseRestService.apiPath}${this.basePath}${subPath}${params ? `?${params}` : ''}`;
      let response;
      try {
        response = yield fetch(url, computedOptions);
      } catch (error) {
        if ((_a = computedOptions.signal) === null || _a === void 0 ? void 0 : _a.aborted) {
          throw error;
        }
        if (!requestOptions.withoutErrorHandlers) {
          errorHandlers.forEach(handler => handler(error.message));
        }
        throw new HTTPRequestError(error.message, null, error);
      }
      if (response.ok) {
        const data = yield response.json();
        return data;
      } else {
        let message = '';
        let errorData = null;
        try {
          errorData = yield response.json();
          if (errorData.message) {
            message = errorData.message || errorData.buildings;
          } else if (errorData.buildings) {
            const fieldError = Object.values(errorData.buildings)[0];
            message = fieldError === null || fieldError === void 0 ? void 0 : fieldError.cadastral_code;
          } else if (errorData.error && typeof errorData.error === 'string') {
            message = errorData.error;
          }
        } catch (e) {}
        if (!requestOptions.withoutErrorHandlers) {
          errorHandlers.forEach(handler => handler(message, response.status));
        }
        throw new HTTPRequestError(message, response.status, null, errorData);
      }
    });
  }
}
BaseRestService.apiPath = '/';
BaseRestService.commonOptionsMiddlewares = [options => Object.assign(Object.assign({}, options), {
  credentials: 'include',
  mode: 'cors',
  headers: Object.assign(Object.assign({}, options.headers), {
    'X-Local-Time': (0, utils_1.formatDate)(new Date(), {
      customFormat: 'yyyy-MM-dd HH:mm:ss'
    }),
    'Content-Type': 'application/json',
    Accept: 'application/json'
  })
}), options => {
  const hoaCode = utils_1.appConfig.hoaCode;
  if (!hoaCode || !hoaCode.trim()) return options;
  return Object.assign(Object.assign({}, options), {
    headers: Object.assign(Object.assign({}, options.headers), {
      'X-HOA-Code': hoaCode
    })
  });
}];
BaseRestService.commonErrorHandlers = [];
exports.default = BaseRestService;