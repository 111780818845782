"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  selectedFiltersNum_0: 'Filter selected {{count}}',
  selectedFiltersNum_1: 'Selected {{count}} filter',
  selectedFiltersNum_2: 'Selected {{count}} filters',
  clearFilters: 'Remove filters',
  deleteFilter: 'Remove the filter',
  resetFiltersAlert: 'Resetting filters will clear the list of selected users. This action cannot be canceled!',
  clear: 'Clear',
  showMore: 'Show more',
  selectedFilters: 'Selected filters',
  show: 'Show',
  filtersInfoModalTitle: 'Description of filter operation',
  warning: 'Warning!',
  saveSelected: 'Save Selection',
  cleanSelected: 'Clear selection',
  onChangeSaveOrClean: 'There are already selected rows in the table. Making changes may result in not all previously selected rows being shown in the table.',
  saveOrClearSelect: 'You can clear a previously made selection or save the selected rows.',
  filtersInfoModalDescription: '<ol style="font-weight: 500; line-height: normal;">' + '<li>After you add or delete some filters, be sure to click the "APPLY" button. Otherwise, the changed filters will not be applied and the table will remain as it is.</li>' + '<li>All filters are grouped. If several filters from the same group are selected, the rows that correspond to at least one of the selected filters are shown.<br/><br/> Example: if the user selects the filters Show owners of rooms from the 1st, 3rd and 6th entrances, then all owners from all selected entrances will be shown.</li>' + '<li>But if the user selects filters from different groups, then the rows that simultaneously satisfy the requirements from these different groups will be shown. <br/><br/> Example: a user selects the same filters (show owners from the 1st, 3rd and 6th entrances) and adds the filter "show registered owners".  Then only registered owners of rooms from the 1st, 3rd and 6th entrances will be shown. If a user selects one filter, then the table will show only those rows that satisfy this filter.</li>' + '<li>If the user first selects some rows of the table and then applies filters, he will be offered either to save the previously made selection of rows or to reset it.</li>' + '</ol>',
  groupsInfoModalTitle: 'All possible filter states for the group "{{ nameOfGroup }}"',
  groupsInfoModalHelperText: ' Note that filters may not show all possible states described above. We do not show so-called "null filters", i.e. such filters, after application of which no rows will be shown in the table or list or, on the contrary, all rows will be shown, which makes application of such filters meaningless.'
};