"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  accept: 'I accept',
  rules: 'user conditions',
  and: 'and',
  policy: 'privacy policy'
};