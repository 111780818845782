"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  registryError: 'The data in the registry differs from the data in the settings',
  notFilled_0: 'Not filled in',
  notFilled_1: 'Not filled in',
  notFilled_2: 'Not filled in',
  apartments_0: 'apartment',
  apartments_1: 'appartments',
  apartments_2: 'spaces',
  buildings_0: 'auilding',
  buildings_1: 'structures',
  buildings_2: 'buildings',
  living_0: 'residential',
  living_1: 'dwellings',
  living_2: 'dwellings',
  nonLiving_0: 'non-residential',
  nonLiving_1: 'non-residential',
  nonLiving_2: 'non-residential',
  sortButtonWarningHint0: 'The registry does not show {{counters}} because no owner is listed there.',
  sortButtonWarningHint1: 'The registry does not show {{counters}} because no owner is listed there.',
  loseDataWarning: 'You have made changes to your registry settings. Do you want to save them?',
  notAddress: 'No data was found for this address',
  items: 'qty',
  inRegistry: 'In the registry',
  inSettings: 'You are trying to enter an invalid value in the Object Settings',
  inReforma: "I've got it",
  apart: 'Appartments',
  settingsModalTitle: 'Settings'
};