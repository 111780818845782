"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  greetings: 'Здравствуйте',
  owner: 'собственник',
  rooms_0: 'помещения',
  rooms_1: 'помещений',
  rooms_2: 'помещений',
  phone: 'Ваш телефон',
  getValidationCode: 'Получить код',
  getValidationCodeAgain: 'Запросить код повторно',
  resendingCodeTime: 'через {{time}} сек.',
  signContract: 'Вы подписали договор с УК!',
  registerText1: 'Зарегистрируйтесь в Сквере',
  registerText2: 'чтобы использовать все возможности системы для участия в жизни Вашего дома!',
  signInToSQVRText1: 'Вы можете',
  signInToSQVRText2: 'войти в Сквер',
  contractHasAlreadySigned: 'Договор по Вашей ссылке уже был подписан ранее!',
  viewContract: 'Смотреть договор',
  pageNotFound: 'Такой страницы не существует.',
  infoModalText1: 'В соответствии с частью 1 статьи 162 Жилищного Кодекса РФ договор управления может быть заключен в электронной форме с использованием системы путем составления одного документа, подписанного сторонами. Предлагаем Вам к подписанию следующий текст договора. Подписание происходит с помощью простой электронной подписи (ПЭП, ФЗ “Об электронной подписи” от 06.04,2011 № 63-ФЗ статья 9) посредством направления уникального идентификатора: SMS-сообщением на мобильный номер телефона собственника или его законного представителя. Подписывая договор, Вы подтверждаете, что обладаете необходимыми полномочиями в силу закона либо на основании оформленной надлежащим образом доверенности.',
  infoModalText2: 'Что вам нужно сделать?',
  infoModalText3: '1. Внимательно ознакомиться с договором',
  infoModalText4: '2. Проверить правильность введения Вашего номера телефона',
  infoModalText5: '3. Если Вы согласны заключить договор управления, кликнуть на кнопку “Получить код”',
  infoModalText6: '4. Вам будет отправлено SMS с уникальным идентификатором - шестизначным кодом',
  infoModalText7: '5. Внести полученный код 6. Кликнуть на кнопку “Подписать”',
  infoModalText8: 'Настоящий договор вступит в силу, как только он будет подписан собственниками, обладающими более 50% голосов от общего числа голосов собственников помещений в Вашем доме.',
  checkSms: 'Проверьте SMS, мы отправили вам код подтверждения.',
  wrongCode: 'Неверный код подтверждения.'
};