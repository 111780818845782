"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  downloadPdfText: 'View PDF is unavailable, download ',
  pdfURL: 'https://sqvr-mailresources.s3.eu-central-1.amazonaws.com/mailresources/production/policy_and_condition.pdf',
  googleDocsPdfURL: 'https://drive.google.com/file/d/1dlidJxsho1og61bMQaFoIAY0RjAMhFhu/preview',
  checkBoxText: 'I accept this invitation and confirm that I have read and approved the Privacy Policy and Terms of Use of the Sqvr service.',
  title: 'Policy and conditions',
  buttonReject: 'Refuse the invitation',
  invitationModalPolicyText: 'By accepting this invitation to join, I confirm that I am aware of, and I accept the Terms of Use and the Privacy Policy of the Sqvr service.',
  buttonAccept: 'Accept the invitation'
};