"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  delete: 'Delete the poll?',
  cancel: 'Cancel the poll?',
  votes: 'Your vote counts!',
  voteWarning: "You didn't choose the answers to all the questions!"
};