"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  poll: 'Poll',
  polls: 'Polls',
  cancelPoll: 'Cancel the poll',
  pollResults: 'Voting results',
  addCover: 'Add a cover for the poll',
  pollPreview: 'Poll preview',
  publishPoll: 'Publish a poll',
  pollIsPublished: 'The poll has been released!',
  pollPublishError: 'Poll publishing error!',
  deletePoll: 'delete the poll',
  deletePollQuestion: 'Delete the poll?',
  attachDocuments: 'Attach documents to the poll',
  filtersAndSearch: 'Filters and poll search',
  pollDescription: 'Description of the poll',
  pollPost: 'Publication of the poll',
  notEditPollAfterPublishing: 'Once the poll is published, you will not be able to make changes to it.',
  followToPoll: 'Go to poll',
  pollDraftWillBeDeleted: 'The draft poll will be deleted.',
  usersVoted_0: 'voted',
  usersVoted_1: 'voted',
  usersVoted_2: 'voted',
  votes_0: '{{count}} vote',
  votes_1: '{{count}} votes',
  votes_2: '{{count}} votes',
  votesPerscentsFromAll: 'of the votes of all owners',
  withMyParticipation: 'With my participation',
  archived: 'Archived',
  usersVotedCount: 'User votes counted:',
  pollFinished: 'Poll finished',
  active: 'Voting is in progress',
  createPoll: 'Create a poll',
  pollWillBeFinished: 'The poll will be finished',
  pollCancelled: 'Poll cancelled',
  finished: 'Finished',
  voteIsTaken: 'Vote taken',
  draft: 'Draft',
  withoutMyParticipation: 'Polls where I did not vote'
};