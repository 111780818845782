"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  activity: 'You can go to the desired section by clicking on the title',
  profileTabs: 'Here you can go to another user profile section',
  avatar: 'Upload your photo so that your neighbors can recognize you.',
  extraMenu: 'Click on the icon to see additional features',
  ownership: 'Click on the card to view details about the property',
  tabsInProfile: 'Switch between tabs to view all data',
  email: 'This address is used for authorization'
};