"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lodash_1 = require("lodash");
function omitBlankValues(source, deep = false, predicate = x => typeof x === 'boolean' ? false : !x) {
  const clonedObject = (0, lodash_1.omitBy)(source, predicate);
  if (deep) {
    Object.keys(clonedObject).forEach(key => {
      const value = clonedObject[key];
      if ((0, lodash_1.isObjectLike)(value) && Object.entries(value).length) {
        clonedObject[key] = omitBlankValues(value, true, predicate);
      }
    });
  }
  return clonedObject;
}
exports.default = omitBlankValues;