"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  moderatorTolltipTitle: 'Вы являетесь модератором и не можете отключить получение информации',
  adminTooltipTitle: 'Вы являетесь администратором и не можете отключить получение информации',
  tooltipTitle: 'Вы можете изменить эти настройки перейдя в режим редактирования',
  settingTitle: 'Настройки уведомлений',
  commonNotificaionLabel: 'Получать новости о доме и предстоящих в нем событиях на почту',
  privateNotificationLabel: 'Получать личные уведомления и сообщения на почту',
  telegramNotificationLabel: 'Получать уведомления в telegram',
  modalTextOff: 'Вы отключаете получение уведомлений от СКВЕР в Telegram - боте, вы уверены ?',
  noOff: 'Нет, не отключать',
  yesOff: 'Да, отключить',
  tgConnect: 'Подключить Telegram-бот',
  multiInfo: 'Обратите внимание, что при подключении telegram-бота Вы будете получать уведомления в telegram по всем своим объектам.',
  sendLink: 'Отправить ссылку подключения Telegram-бота',
  telegramConnected: 'Telegram-бот подключен',
  linkWasSent: 'Ссылка была отправлена ',
  success: 'Ссылка для подключения успешно отправлена {{user}}',
  email: 'Эл. почта',
  social: 'Социальные сети',
  socialText: 'Используйте социальные сети для входа в SQVR. Мы никогда не будем публиковать сообщения в социальные сети или отправлять сообщения вашим друзьям без вашего разрешения.'
};