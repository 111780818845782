"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const documentsArchiveText = 'Все выбранные Вами документы будут сформированы как архив.';
const deliveryTimeText = 'Вы получите ссылку для скачивания этого архива';
exports.default = {
  disableDownloadTooltipText: 'Загрузить документы. Сначала отметьте нужные документы.',
  disableDeleteTooltipText: 'Удалить документы. Сначала отметьте нужные документы.',
  modalAlertTitle: 'Получение архива с документами',
  gotItText: 'Понятно',
  successDeletedDocumentsText: 'Документы были удалены',
  inputSearchPlaceholder: 'Поиск по названию или тегам',
  selectAll: 'Выбрать все',
  successDownloadDocsText: 'Ссылка на архив сформирована',
  sentToSQVRAppText: `<ol style="list-style-type: decimal; padding-left: 24px;">
    <li>${documentsArchiveText}</li>
    <li>${deliveryTimeText} в приложении Сквер в течение 15 минут.</li>
    </ol>`,
  sentToTgBotText: `<ol style="list-style-type: decimal; padding-left: 24px;">
    <li>${documentsArchiveText}</li>
    <li>${deliveryTimeText} в Telegram-bot сервиса Сквер в течение 15 минут.</li>
    </ol>`,
  sentToEmailText: `<ol style="list-style-type: decimal; padding-left: 24px;">
    <li>${documentsArchiveText}</li>
    <li>${deliveryTimeText} на свой e-mail в течение 15 минут.</li>
    </ol>`,
  warningDeleteText: `<ol style="list-style-type: decimal; padding-left: 24px;">
    <li>После удаления, документы невозможно восстановить.</li>
    <li>Доступ к документам для пользователей без административных прав будет закрыт немедленно.</li>
    <li>Пользователи с административными правами смогут видеть удаленные документ еще 90 дней. После этого документы будут недоступны для всех.</li>
    </ol>`,
  urlCopy: 'Ссылка успешно скопирована',
  addFavorite: 'Документ добавлен в избранное',
  removedFavorite: 'Документ больше не в избранном',
  emptyText: 'Ничего не найдено'
};