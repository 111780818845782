"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  Promise: 'Promises to vote',
  NoActions: 'Not contacted',
  Inaccessible: "Can't get in touch",
  AbsentContacts: 'No contact',
  DenyParticipate: "Can't participate",
  Dismiss: 'Declined',
  NextDateContact: 'Date of next contact'
};