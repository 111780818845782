"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  popoverTitle: 'Memo',
  memoTitle1: 'Why do we use sending an invitation on WhatsApp?',
  memoTitle2: 'Therefore, in order not to violate the 152nd Federal Law \\"On Personal Data\\" it is better to use private messages in WhatsApp.',
  memoTitle3: 'How it works?',
  memoInfoText1: '1. Mass mailing of invitations to the Sqvr service can be used only and exclusively in the framework of preparation and holding of the general meeting of owners or the meeting of members of the Partnership. Because Article 45 of the Housing Code of the Russian Federation explicitly says so',
  memoInfoText2: '1. Very often users need to be reminded several times before they register. In order not to waste paid sms limits send invitations via WhatsApp first. After the user himself responds to such invitation, we will send him a registration code via sms.',
  memoInfoText3: "2. If you are sure that the user is ready to register immediately, you can send him/her the registration code by sms or e-mail. The previously paid limit will be used up. If you don't have paid limits, you need to pay the tariff in advance to send messages. Go to ",
  memoInfoText4: '3. We will not send an invitation via WhatsApp if the number on WhatsApp is already registered with Sqvr and if the phone number and WhatsApp number do not match.',
  memoInfoText5: "4. We do not send an invitation via WhatsApp if there is no Last Name and First Name in the user's profile.",
  memoInfoText6: '5. After clicking on the "Send Invitation" button on your phone or computer, the WhatsApp messenger interface will open with a generic invitation with a link for that user.',
  memoInfoText7: '6. Send this message. Once the recipient clicks on the link, they will be taken to the Sqvr interface and will be asked to enter their password and confirm that they agree to register in the Square.',
  memoInfoText8: "7. After that, an sms will be sent to the user's mobile number with a link completing the confirmation of the phone number.",
  memoInfoSubtext1: '\\"p. 3.1. ...Consent of owners of premises in an apartment building to transfer personal data contained in the register of owners of premises in an apartment building, when providing this register in the manner prescribed by this part, for the purpose of convening and organizing a general meeting of owners of premises in an apartment building is not required. (part 3.1 introduced by the Federal Law dated 31.12.2017 N 485-FZ)\\"',
  tariffs: 'Plans'
};