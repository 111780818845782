"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  delete: 'ДА. УДАЛИТЬ',
  dontDelete: 'НЕТ. НЕ УДАЛЯТЬ',
  noChanges: 'НЕТ. НЕ ЗАМЕНЯТЬ',
  changes: 'ДА. ЗАМЕНИТЬ',
  started: 'ДА. НАЧАТЬ',
  yesCancel: 'ДА. ОТМЕНИТЬ',
  noCancel: 'НЕТ. НЕ ОТМЕНЯТЬ',
  cancel: 'ОТМЕНИТЬ',
  whoSignedProtocol: 'Кто подписал протокол',
  cancelingPublicationProtocol: 'Отмена публикации протокола',
  signersSelectComment: 'Проверьте все ли подписали итоговый протокол и поставьте соответствущий чекбокс',
  minSignersRule: 'Должен быть минимум {{count}} подписавший',
  youCanDeleteAlreadyPublishedProtocol: 'Вы можете удалить уже опубликованный протокол, но это ',
  itIsNotAdvisable: 'КРАЙНЕ НЕ ЖЕЛАТЕЛЬНО. ',
  startDownload: 'Если протокол уже выверен и подписан Председателем и Секретарем собрания, то начните загрузку.',
  thisCanOnlyBeDoneIfThereAreGoodReasons: 'Это можно делать только при наличии веских оснований (серьезное отклонение фактических результатов голосования, наличие смысловых ошибок в текстах решений и т.д.). После нажатия на кнопку “Да. Заменить” собрание переходит в особый статус “Инициирована замена протокола”, в котором вы сможете внести изменение в итоги голосования, добавить новые бюллетени.',
  theChangeMessageWillBeAutomaticallySentOut: 'Сообщение об изменении итогового протокола будет автоматически разослано всем собственника/членам товарищества после публикации нового протокола.',
  doYouReallyWantToReplaceTheFinalProtocol: 'Вы действительно хотите заменить итоговый протокол собрания?',
  userUploadedFinalProtocol: '{{user}} {{date}} уже загрузил в систему итоговый протокол собрания {{file_name}}. Загруженный файл будет удалён из материалов собрания.',
  finalProtocolHasAlreadyBeenSubmittedForPublication: 'Кроме того, итоговый протокол уже вынесен на опубликование и ждет подтверждения публикации со стороны Председателя или Секретаря собрания.',
  afterDeletingYouWillNeedToGoAllStepsAgain: 'После удаления Вам будет необходимо пройти все шаги по подписанию и публикации протокола с самого начала.',
  deleteFinalProtocolOfMeeting: 'Вы действительно хотите продолжить и удалить итоговый протокол собрания?',
  changeProtocolFileModalText1: '{{user}} {{date}} уже загрузил в систему итоговый протокол собрания {{file_name}}. Если Вы продолжите загрузку, то ранее загруженный файл будет удалён из материалов собрания, и вместо него будет новый документ.',
  changeProtocolFileModalText2: 'Вы действительно хотите продолжить и заменить итоговый протокол собрания?',
  publishingProtocolStartedModalText1: 'После начала публикации итогового протокола собрания, будут заблокированы любые действия на собрании. У собрания поменяется статус с ',
  publishingProtocolStartedModalText2: '"Идет подсчет голосов"',
  publishingProtocolStartedModalText3: ' на ',
  publishingProtocolStartedModalText4: '"Публикация протокола" ',
  publishingProtocolStartedModalText5: 'Вы не сможете изменить результаты голосования, добавить недостающие бюллетени и вносить любые изменения.',
  publishingProtocolStartedModalText6: 'Вы действительно хотите начать публикацию итогового протокола собрания?',
  publishingProtocolStartedModalText7: 'Вы не сможете изменить результаты голосования и вносить любые изменения.',
  cancelingPublicationProtocolModalText1: 'Отмена публикации протокола приведёт к изменению статуса собрания  с ',
  cancelingPublicationProtocolModalText2: '“Публикация протокола” ',
  cancelingPublicationProtocolModalText3: 'на ',
  cancelingPublicationProtocolModalText4: '“Идёт подсчёт голосов” ',
  cancelingPublicationProtocolModalText5: 'Рекомендуем отменять публикацию протокола и начинать публикацию нового только в случае наличия веских оснований (серьёзное отклонение фактических результатов голосования, наличие смысловых ошибок в текстах решений и т.д.). После нажатия на кнопку “Да. Отменить” вы сможете внести изменения в итоги голосования, добавить новые бюллетени и тд.',
  cancelingPublicationProtocolModalText6: 'Вы действительно хотите отменить публикацию итогового протокола собрания?',
  informationAboutDownloadedFiles: 'Если Вы загружаете несколько файлов, они будут объединены в один файл PDF. Убедитесь, что порядок файлов правильный (пример:  01.jpg, 02.jpg, 03.jpg...015.jpg и тд) и они следуют один за другим.',
  attention: 'Обратите внимание',
  bulletinsInProcess: ', что сейчас в собрание загружены, но еще не обработаны бюллетени участников {{bulletins}} шт. Вы можете подождать до одного часа или все равно начать публикацию протокола. Тогда не обработанные бюллетени не повлияют на итоги голосования.'
};