"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  titleThreadFeed: 'Comment Feed',
  titleCommentFeed: 'Comments',
  placeholder: 'Your comments on the draft decision and comments from the author of the draft decision, the Chair and Secretary of the meeting and the moderators will be displayed here.',
  closeCommentFeed: 'Close the comment feed',
  buttonText: 'ADD YOUR FIRST COMMENT',
  showAnswers: 'Showing replies',
  addComment: 'Comment',
  copyComment: 'Copy text',
  copySuccess: 'Text copied',
  replyComment: 'Answer',
  deleteModalTitle: 'Delete comment',
  deleteModalContent: 'Do you really want to delete the comment?',
  infoModalTitle: 'How comments work',
  edited: '(redacted)',
  yesLabel: 'Yes, remove it',
  noLabel: "No, don't delete it",
  commentYesLabel: 'Save',
  commentNoLabel: 'undo Changes',
  infoModalContentLKP: '<ol style=\\"list-style-type: decimal; margin-left: 16px;\\"><li>This comment feed is very similar to the work of messengers WhatsApp and Telegram.<li>You can add your comments, reply to comments, delete your comments, edit your last comment if there are no more recent comments under it.<li>Hover your cursor over any comment and three dots will appear in the upper right corner &lt;svg xmlns=\\"http://www.w3.org/2000/svg\\" style=\\"vertical-align: bottom\\" width=\\"24\\" height=\\"24\\" viewBox=\\"0 0 24 24 24\\" fill=\\"none\\"',
  InfoModalContentOLK: '<ol style=\\"list-style-type: decimal; margin-left: 16px;\\"><li>We try to minimize the cases when users can enter into negotiations similar to a collective chat or forum in order to not create a platform for inevitable conflicts.<li>Therefore, this feed consists only of the first comments left by different users. These are so-called "root comments", under which there is a separate feed of comments exchange with this user.<li>You can click on any root comment and fall into a separate feed of comments of this user.<li> Only the Chair and Secretary of the meeting, the author of the draft decision (Rapporteur) and users with administrative rights: Administrator, moderators and experts have access to the root comments feed.</ol>'
};