"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  isVerifiedChangesModalText1: '1. Мы проверим используется ли он уже или нет в сервисе Сквер.',
  isVerifiedChangesModalText2Part1: '2. В случае, если этот новый',
  isVerifiedChangesModalText2Part2: 'еще не используется в сервисе Сквер, Вы получите',
  isVerifiedChangesModalText2Part3: 'письмо',
  isVerifiedChangesModalText2Part4: 'с уникальной ссылкой, после клика на которую Ваш',
  isVerifiedChangesModalText2Part5: 'будет изменен.',
  isVerifiedChangesModalText3Part1: '3. В случае, если введенный Вами',
  isVerifiedChangesModalText3Part2: 'уже используется, то в',
  isVerifiedChangesModalText3Part3: 'письме',
  isVerifiedChangesModalText3Part4: 'будет инструкция по дальнейшим действиям по его смене.',
  isVerifiedChangesModalText4Part1: '4. До тех пор, пока Вы не подтвердите новый',
  isVerifiedChangesModalText4Part2: 'Вы можете использовать предыдущий',
  importChangeTownSettingsModalText: 'В настройках дома указано',
  totalArea: 'общей площадью',
  inImportedRegistry: 'В импортируемом реестре',
  selectedSpaces: 'Выбраны помещения:',
  dataOfAllUnregisteredUsersWillBeDeleted: 'Данные всех незарегистрированных пользователей будут удалены.',
  changeSettingsOfHouseToRequiredValues: 'Поменять настройки дома на требуемые значения?',
  importChangeVillageSettingsModalText: 'а в настройках объекта указано всего',
  ownershipMergeModalText: 'Укажите собственника которому вы хотите передать все эти помещения.',
  ownershipMergeForManyRegisteredUsersModalTextPart1: 'Невозможно присоединить помещения сразу к нескольким зарегистрированным собственникам:',
  ownershipMergeForManyRegisteredUsersModalTextPart2: 'Для присоединения выбранных помещений укажите только одного зарегистрированного пользователя.',
  ownershipMergeForOneRegisteredUserModalText1: 'В том числе один зарегистрированный пользователь',
  ownershipMergeForOneRegisteredUserModalText2: 'Вы действительно хотите передать все эти помещения',
  registryBlockedModalText1: 'В данный момент реестр редактирует',
  registryBlockedModalText2: 'попробуйте вернуться к редактированию позже.',
  selectPeopleModalTitle: 'Выбор собственников',
  selectPeopleModalSubTitleV1: 'Выберите все собственников, которым хотите отправить уведомление',
  selectPeopleModalSubTitleV2: 'Пригласить некого. Все уже зарегистрированы',
  selectPeopleModalSubTitleV3: 'Выберите всех собственников, которых вы хотите пригласить в Cквер',
  selectPeopleModalTextPart1: 'Из',
  selectPeopleModalTextPart2: 'выбранных незарегистрированных собственников по',
  selectPeopleModalTextPart3: 'нет информации ни о телефонах, ни об e-mail. Поэтому Вы получите файл с печатными формами приглашений для этих собственников. Остальные получат приглашение по e-mail и СМС.'
};