"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  selectAddress: 'Вы можете переключить поиск дома или поселка на поиск по его названию. Это улучшит результаты поиска уже зарегистрированных в Сквере объектов',
  selectHoa: 'Вы можете переключить на поиск дома или поселка только по адресу',
  disabledForInvite: 'Данное поле нельзя редактировать при регистрации по коду приглашения',
  addressForJoin: 'Выбор конкретного дома в данном Объекте будет возможен на следующем шаге. Здесь показан только адрес регистрации всего Объекта'
};