"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Add your e-mail address',
  titlePassword: 'Choose a Password',
  titleDone: 'You did the right thing',
  titlePasswordConfirmWarning: 'Passwords do not match',
  loginFormTitle: 'This phone is already registered. Enter the password for this phone.',
  subTitleExist: 'But this account is registered in another facility, if you want to join {{hoaName}} as well, do the following:',
  titleExist: 'Turns out you already have an account',
  titleConflict: 'Use a different phone',
  emailFormTitle: 'Invitation',
  emailFormReject: 'Interrupt. UNJOIN',
  emailFormContinue: 'Join ',
  tooltipEmail: 'You cannot change your e-mail address at this time. If this is not your e-mail address, click on \\"PREVENT. DO NOT ENTER\\" at the bottom of this window. You will be able to change your e-mail after you have completed your registration.',
  existPassword: 'Enter your password',
  existWait: 'After entering your password, you will be logged in',
  existWarning: 'The Facility Administrator will receive your application to join and, once approved, you will receive messages confirming your phone number and email address.',
  phone: '<strong>Keep a phone number</strong>. You will be able to use two-factor authorization and confirm your vote with a simple electronic signature.',
  donePhone: "The phone number's been saved.",
  email: '<strong>E-mail is</strong> already saved. You will be able to receive messages about all events in the house or village and reduce the cost of sending sms.',
  doneEmail: 'The e-mail address has been saved',
  password: '<strong>Make up a password.</strong>',
  passwordValidate: 'Make up a password.',
  donePassword: 'Password created.',
  passwordConfirm: '<strong>Repeat the password</strong> to avoid the error.',
  differentPasswords: 'Passwords do not match',
  donePasswordConfirm: 'The password has been re-entered.',
  warning: 'The Facility Administrator will receive your application to join and, once approved, you will receive a confirmation sms for your phone number.'
};