"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  addDocumentTitle: 'Сохранение нового документа',
  editDocumentTitle: 'Редактирование документа',
  chipsInputBtmText: 'Введите ключевые теги. Максимум {{count}} тегов.',
  nameInputHelperText: 'Введите название. Максимум {{count}} символов.',
  nameInputBottomHelperText: 'Можете переименовать и дать более понятное для всех название',
  tagsError: 'Необходимо указать макс. {{count}} тегов',
  selectPlaceholder: 'Выберите категорию',
  createCategory: 'Создать новую категорию',
  createCategoryModalTitle: 'Создание новой категории',
  editCategoryModalTitle: 'Редактирование категории',
  inputLabelOptionCreate: 'Создать категорию и добавить в нее документ',
  inputLabelOptionEdit: 'Придумайте более понятное название',
  maxLength: 'Максимум {{count}} символов',
  documentSuccessfullyAddedText: 'Документ успешно добавлен'
};