"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  importTitle: 'Загрузить данные в реестр',
  importText: 'Для загрузки данных из excel-файла в реестр, воспользуйтесь',
  template: 'шаблоном',
  importButton: 'Загрузить файл',
  return: 'Вернуться',
  resetLoad: 'отменить<br />загрузку',
  notChange: 'нет,&nbsp;не&nbsp;изменять<br />и&nbsp;продолжить',
  change: 'да, изменить <br /> и&nbsp;продолжить',
  continue: 'Продолжить',
  success: 'Успех!',
  warning: 'Результаты загрузки файла',
  changeSettings: 'Изменить настройки Объекта для того, чтобы все данные из файла были загружены?',
  errorStrings: 'Требуется редактирование строк: <span style="font-weight: 500">{{count}}</span>. Возможно, проще отредактировать excel-файл и попробовать еще раз.',
  allUploadStrings_0: 'Всего в файле <span style="font-weight: 500">{{count}}</span> строка',
  allUploadStrings_1: 'Всего в файле <span style="font-weight: 500">{{count}}</span> строк',
  allUploadStrings_2: 'Всего в файле <span style="font-weight: 500">{{count}}</span> строк',
  successLoadString: 'Загружено новых строк: <span style="font-weight: 500">{{count}}</span>',
  updateOwners: 'Обновлено строк: <span style="font-weight: 500">{{count}}</span>',
  stringsWithErrors_0: '{{count}} строка требует обновления и ручной обработки',
  stringsWithErrors_1: '{{count}} строки требуют обновления и ручной обработки',
  stringsWithErrors_2: '{{count}} строк требуют обновления и ручной обработки',
  houseSettings: 'Для того чтобы внести все данные из файла, необходимо изменить настройки объекта:',
  accessDeniedTitle: 'Доступ запрещен!',
  accessDeniedMessage: 'Доступ на страницу реестра запрещен собственникам. При закрытии этого окна Вы будете перемещены на главную страницу Вашего объекта.',
  toMainPage: 'Вернуться на главную',
  inviteModalTitle: 'Предпросмотр приглашения',
  notificationModalTitle: 'Предпросмотр уведомления',
  successEmailSended_0: 'Письмо успешно отправлено',
  successEmailSended_1: 'Письма успешно отправлены',
  successEmailSended_2: 'Письма успешно отправлены',
  successEmailsSended_0: 'Доставка письма может занять некоторое время',
  successEmailsSended_1: 'Доставка писем ({{count}} шт.) может занять некоторое время',
  successEmailsSended_2: 'Доставка писем ({{count}} шт.) может занять некоторое время',
  inviteSentSuccessfully_0: 'Приглашение успешно отправлено',
  inviteSentSuccessfully_1: 'Приглашения успешно отправлены',
  inviteSentSuccessfully_2: 'Приглашения успешно отправлены',
  successInviteSent_0: 'Доставка приглашения может занять некоторое время',
  successInviteSent_1: 'Доставка приглашений ({{count}} шт.) может занять некоторое время',
  successInviteSent_2: 'Доставка приглашений ({{count}} шт.) может занять некоторое время',
  notifySentSuccessfully_0: 'Уведомление успешно отправлено',
  notifySentSuccessfully_1: 'Уведомления успешно отправлены',
  notifySentSuccessfully_2: 'Уведомления успешно отправлены',
  successNotifySent_0: 'Доставка уведомления может занять некоторое время',
  successNotifySent_1: 'Доставка уведомлений ({{count}} шт.) может занять некоторое время',
  successNotifySent_2: 'Доставка уведомлений ({{count}} шт.) может занять некоторое время',
  lostWarning: 'Закрытие приведет к потере введенных Вами данных',
  closeInvite: 'Закрыть приглашение?',
  closeNotify: 'Закрыть уведомление?',
  spaces: 'Помещения',
  inRegistryCell: 'Сейчас:',
  inSettingsCell: 'Изменить на:',
  rowsClean: 'Очистка строк',
  rowsCleanWarning_0: 'Выделенную Вами строку очистить невозможно, потому что в ней находится зарегистрированный пользователь.',
  rowsCleanWarning_1: 'Выделенные Вами строки очистить невозможно, потому что в них находится зарегистрированный пользователь.',
  rowsCleanWarning_2: 'Выделенные Вами строки очистить невозможно, потому что в них находится зарегистрированный пользователь.',
  attention: 'Обратите внимание, что указанное в настройках Объекта количество помещений в ',
  house: 'доме - ',
  houses: 'домах - ',
  village: 'поселке - ',
  resizeInfo: '{{settingsCount}}, а в загружаемом реестре - {{uploadCount}}. Количество помещений, которые Вы не сможете загрузить в реестр: {{uploadCount}} - {{settingsCount}} = {{resizeCount}}. Если Вам нужно изменить количество помещений в Настройках, ',
  goToSupport: 'обратитесь в техническую поддержку',
  items: 'шт'
};