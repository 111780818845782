"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Dual authentication',
  titleInfoModal: 'Double authentication of voting participants',
  votingWithPEPText1: 'A Simple Electronic Signature (SEP) is used to vote in this meeting.',
  textWithMobileNumber: 'An SMS with a code will be sent to your previously confirmed cell phone number <span style=\\"font-weight: bold\\">{{phone}}</span> an SMS with a code will be sent to your previously confirmed cell phone number.',
  enterCodeText: 'Enter the code you received below.',
  votingWithPEPHelperText1: 'You have the opportunity to vote for free twice, including this time. The third and each subsequent vote will cost you {{cost}} rubles.',
  votingWithPEPHelperText2: 'You have already voted once. You can vote one more time. Each subsequent vote is a paid feature. Its cost is {{cost}} rubles.',
  votingWithPEPHelperText3: 'If you want to use a different phone number, go to your profile, change and confirm the new phone number.',
  successToastTitle: 'Your vote counts',
  successToastText1: 'Congratulations! You have successfully voted and can re-vote for free one more time. If you want to re-vote after the second time, you will have to pay {{cost}} rubles for each new vote.',
  successToastText2: 'Congratulations! You have successfully voted. If you want to re-vote again, you will have to pay {{cost}} rubles for each new vote.',
  votingWithPEPInfoText1: 'In this assembly, all participants using electronic voting are required to double authenticate themselves. For this purpose, a PEP (simple electronic signature) is used.',
  votingWithPEPInfoText2: 'Select a voting option (FOR, AGAINST, ABSTAIN) for each draft decision. Once you have voted on all agenda items, click on the "VOTE" button at the bottom of the screen.',
  votingWithPEPInfoText3: 'An SMS with a verification code will be sent to your phone number, which must be entered in a special form. Only after such confirmation your vote will be saved in the results of the meeting.',
  votingWithPEPInfoText4: 'Each participant of the meeting has the opportunity to vote electronically and then change his/her vote once free of charge. The third and all subsequent votes are a paid option. We therefore recommend that you vote carefully and attentively at once.',
  votingWithPEPInfoText5: 'You can always vote with a paper ballot.'
};