"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'Приглашение',
  buttonReject: 'Отказаться от приглашения',
  buttonAccept: 'Принять приглашение',
  text: 'Приглашение для ' + '<strong>{{ name }}</strong>.' + ' Вас пригласили как ' + '<strong>{{ role }}</strong>' + ' присоединиться к Объекту ' + '<strong>{{ hoaName }}</strong>' + ' и зарегистрироваться в сервисе Сквер ' + '<strong>{{ subdomain }}</strong>' + '. Вы можете принять приглашение и зарегистрироваться в данном Объекте, либо отказаться.'
};