"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.errorLink = void 0;
const lib_1 = require("apollo-link-error/lib");
const uploadFetch_1 = require("./uploadFetch");
exports.errorLink = (0, lib_1.onError)(({
  graphQLErrors,
  networkError,
  operation,
  forward
}) => {
  if ((networkError === null || networkError === void 0 ? void 0 : networkError.message) === uploadFetch_1.UPLOAD_CANCELED) return;
  return forward(operation);
});
exports.default = exports.errorLink;