"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  houses_change: 'Изменения в структуре дома',
  structure_type_actions: 'Изменения в управлении дома',
  houses_change_amount: 'Изменения в количестве помещений',
  reason_for_editing: 'Причины изменений',
  registry_actions: 'Действия с реестром',
  owners_actions: 'Изменение профилей пользователей',
  commitee_actions: 'Изменения в комитетах',
  meetings_actions: 'Изменения в жизни дома',
  unit_actions: 'Изменения в квартирах',
  rights_actions: 'Изменения в правах доступа пользователей'
};