"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  lastNameOfOwner: 'Укажите фамилию собственника',
  nameOfOwner: 'Укажите имя собственника',
  middleNameOfOwner: 'Укажите отчество собственника',
  juristicName: 'Укажите название организации',
  juristicLegalForm: 'Укажите правовую форму организации',
  juristicOgrn: 'Укажите ОГРН организации',
  juristicInn: 'Укажите ИНН организации',
  phone: 'Укажите телефон собственника',
  email: 'Укажите email собственника',
  personType: 'Выберите тип собственника',
  contactPerson: 'Добавьте информацию о контактном лице собственника',
  dateOfBirth: 'Укажите дату рождения',
  documentType: 'Укажите название документа, подтверждающего личность',
  documentNumber: 'Укажите номер документа, подтверждающего личность',
  documentIssueDate: 'Укажите дату выдачи документа, подтверждающего личность',
  personalFiles: 'Прикрепите, если у Вас имеется, копию документа, подтверждающего личность собственника',
  addressSelect: 'Укажите адрес собственности',
  buildingStreet: 'Укажите улицу',
  apartmentNumber: 'Укажите номер помещения',
  buildingNumber: 'Укажите номер дома/строения',
  totalArea: 'Укажите площадь помещения',
  ownershipShare: 'Укажите долю владения',
  propertyUsageType: 'Выберите тип помещения',
  ownerType: 'Выберите тип владения собственностью',
  ownershipDocumentName: 'Укажите название документа, подтверждающего право собственности',
  ownershipDocumentNumber: 'Укажите номер документа, подтверждающего право собственности',
  ownershipDocumentIssueDate: 'Укажите дату выдачи документа, подтверждающего право собственности',
  ownershipFiles: 'Прикрепите, если у Вас имеется, копию документа, на право собственности на помещение'
};