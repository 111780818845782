"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  email: 'E-mail',
  emailOrPhone: 'E-mail or phone',
  password: 'Password',
  passwordConfirm: 'Confirm password',
  firstName: 'Name',
  lastName: 'Surname',
  middleName: 'Middle Name',
  juristicPersonName: 'Title',
  juristicPersonLegalForm: 'Legal form',
  juristicPersonOgrn: 'Origin',
  juristicPersonInn: 'Tax number',
  phone: 'Phone number',
  phoneShort: 'Phone',
  zip: 'Zip code',
  region: 'Subject of the Federation',
  city: 'City/settlement',
  settlement: 'Neighborhood',
  streetType: 'Street type',
  house: 'House number',
  block: 'Block',
  unitType: 'Type',
  info: 'Additional information',
  address: 'Address',
  form: 'Form',
  name: 'Name',
  propertyUsageType: 'Property type',
  street: 'Street',
  flat: 'Apartment number',
  land: 'Plot number',
  apartment: 'Room number',
  apartmentShort: 'Apt.',
  apartmentFull: 'Premises',
  verificationCode: 'Confirmation code',
  dateAndTime: 'Date and time',
  cardNumber: 'Card Number',
  month: 'Month',
  year: 'Year',
  cvv: 'CVV',
  expiryDate: 'Deadline',
  code: 'Code',
  cardHolder: 'Card Holder',
  autoRenewal: 'Automatic renewal',
  date: 'Date',
  fio: 'Full name',
  sum: 'Amount ($)',
  entrance: 'Entrance number',
  homeAddress: 'House address',
  building: 'Building',
  buildingShort: 'Build',
  startEnteringName: 'Start typing the name',
  passport: 'Passport series and number',
  passportIssued: 'By whom and when was issued',
  webSite: 'Site',
  tags: 'Tags',
  additionalInfo: 'Additional information',
  category: 'Category',
  role: 'Role'
};