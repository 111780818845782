"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  passwordMin: 'Пароль должен содержать минимум {{count}} символа',
  passwordConfirm: 'Пароли должны совпадать',
  additionalMax: 'Максимальное количество символов в поле дополнительной информации - {{count}}',
  exists_phone: 'Данный номер телефона уже используется',
  exists_email: 'Данный адрес электронной почты уже используется',
  alreadyHaveShareOfOwnershipInSelectedRoom: 'В выбранном помещении у Вас уже есть доля владения',
  noFreeAreaInSelectedRoom: 'В выбранном помещении нет свободной площади',
  notEnoughFreeAreaInSelectedRoom: 'В выбранном помещении недостаточно свободной площади',
  noShareOfOwnershipInSelectedRoom: 'В выбранном помещении нет совместной собственности',
  valueMustBeNumber: 'значение должно быть числом',
  valueMustBeLessThan100: 'значение не может быть больше 100',
  valueMustntBeLessThan1: 'значение не может быть меньше 1',
  valueMustntBeLessThan1Variant2: 'значение не должно быть меньше 1',
  valueMustBeLessThan1: 'значение не может быть больше 1',
  unknownTypeOfProperty: 'Неизвестный тип собственности',
  areaOfRoomMustntBeMoreThanAreaOfAllRooms: 'Площадь помещения не может быть больше, чем площадь всех помещений в здании'
};