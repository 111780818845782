"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  statusActive: 'Идёт голосование',
  statusFinished: 'Опрос завершён',
  days_0: '{{count}} день',
  days_1: '{{count}} дня',
  days_2: '{{count}} дней',
  activeHeaderConnection: 'по опросу на тему:',
  activeWillLast: 'Голосование по опросу продлится еще',
  activeWillFinish: 'и завершится',
  activeFinishAt: 'в',
  activeRestMessage: 'Если Вы уже проголосовали, то можете просмотреть предварительные итоги опроса. Если Вы еще не голосовали, то обязательно проголосуйте. Вы можете голосовать по опросу и менять свое решение вплоть до окончания опроса. Спасибо.',
  activeOpenPollBtnText: 'Перейти к Опросу',
  finishedHeaderConnection: 'Тема опроса:',
  finishedInQuestion: 'В вопросе',
  finishedMostPopularAnswer: 'самый популярный ответ был',
  finishedGainedPercent: 'Он набрал',
  finishedVotes: 'голосов',
  finishedGainedFromParticipants: 'от собственников, принявших участие в голосовании, или',
  finishedOfVotes: 'от голосов',
  finishedGainedFromAll: 'всех собственников.',
  finishedNobodyVoted: 'В опросе никто не проголосовал.'
};