"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const lodash_1 = require("lodash");
function formatNumber(amount, maximumFractionDigits = 2, thousandsSeparator = '') {
  if ((0, lodash_1.isNil)(amount)) amount = 0;
  const text = amount.toLocaleString('en', {
    maximumFractionDigits
  }).replace(/,/g, thousandsSeparator);
  return text;
}
exports.default = formatNumber;