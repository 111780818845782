"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  activity: 'Вы можете перейти в нужный раздел, кликнув по заголовку',
  profileTabs: 'Здесь можно перейти в другой раздел профиля пользователя',
  avatar: 'Загрузите вашу фотографию, чтобы соседи смогли вас узнать.',
  extraMenu: 'Нажмите на иконку, чтобы увидеть дополнительные возможности',
  ownership: 'Кликните на карточку, чтобы посмотреть подробности о помещении',
  tabsInProfile: 'Переключайтесь между вкладками, чтобы посмотреть все данные',
  email: 'Данный адрес используется для авторизации'
};