"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  newComplaintsLastMonth: 'Complaints have been filed in our house in the past month:',
  newComplaintsLastMonthVillage: 'There have been complaints filed in our township in the past month:',
  outOfThem: 'Of these public ones:',
  successfullySolvedComplaints: 'Complaints were safely resolved:',
  totalCountInProcess: 'The total number of complaints in the work is',
  complaint: 'Complaint',
  tableTitle: 'Overall status by complaint since the beginning of the year:',
  statTableHeaderAmount: 'qty',
  statTableHeaderAverageTime: 'average time',
  statTableTotal: 'Total since the beginning of the year',
  statTablePublic: 'Public',
  statTablePrivate: 'Private',
  statTableSolved: 'Resolved',
  statTableDeclined: 'Rejected',
  daysShorten: 'day',
  hoursShorten: 'h',
  minutesShorten: 'm',
  tableCaption1: 'Number of complaints. Monthly change in parentheses',
  tableCaption2: 'Average complaint processing time days/hours/minutes. Monthly change in parentheses',
  newComplaintZero: 'New complaints:'
};