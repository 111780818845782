"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  delete: 'YES. REMOVE',
  dontDelete: "NO. DON'T REMOVE",
  noChanges: 'NO. DO NOT REPLACE',
  changes: 'YES. SUBSTITUTE',
  started: 'YES. START',
  yesCancel: 'YES. CANCEL',
  noCancel: 'NO. NO CANCELLATION',
  cancel: 'Cancel',
  whoSignedProtocol: 'Who signed the protocol',
  cancelingPublicationProtocol: 'Cancellation of the protocol publication',
  signersSelectComment: 'Check if everyone has signed the final minutes and check the corresponding checkboxes',
  minSignersRule: 'Must be a minimum of {{count}} signatory',
  youCanDeleteAlreadyPublishedProtocol: 'You can delete a protocol that has already been published, but this is a ',
  itIsNotAdvisable: 'IS HIGHLY UNDESIRABLE. ',
  startDownload: 'If the protocol have already been verified and signed by the Chair and Secretary of the meeting, then start uploading.',
  thisCanOnlyBeDoneIfThereAreGoodReasons: 'This can be done only if there are good reasons (serious deviation of the actual voting results, presence of semantic errors in the texts of decisions, etc.). After clicking on the "Yes. Replace" button, the meeting enters a special status "Protocol replacement initiated", where you can make changes to the voting results, add new ballots.',
  theChangeMessageWillBeAutomaticallySentOut: 'Notification of changes to the final minutes will be automatically sent out to all owners/members of the partnership once the new minutes are published.',
  doYouReallyWantToReplaceTheFinalProtocol: 'Do you really want to replace the final meeting minutes?',
  userUploadedFinalProtocol: '{{user}} {{date}} has already uploaded the final protocol of the meeting {{file_name}} to the system. The uploaded file will be removed from the meeting materials.',
  finalProtocolHasAlreadyBeenSubmittedForPublication: 'In addition, the final protocol have already been submitted for publication and are awaiting confirmation of publication by the Chair or Secretary of the meeting.',
  afterDeletingYouWillNeedToGoAllStepsAgain: 'Once removed, you will need to go through the steps of signing and publishing the protocol from the beginning.',
  deleteFinalProtocolOfMeeting: 'Do you really want to go ahead and delete the final meeting minutes?',
  changeProtocolFileModalText1: '{{user}} {{date}} has already uploaded the final meeting minutes {{file_name}} to the system. If you continue uploading, the previously uploaded file will be removed from the meeting materials and a new document will replace it.',
  changeProtocolFileModalText2: 'Do you really want to go ahead and replace the final meeting minutes?',
  publishingProtocolStartedModalText1: 'After the start of publication of the final minutes of the meeting, any actions in the meeting will be blocked. The status of the meeting will change from ',
  publishingProtocolStartedModalText2: '"The votes are being counted."',
  publishingProtocolStartedModalText3: ' on ',
  publishingProtocolStartedModalText4: '"Publishing of the protocol." ',
  publishingProtocolStartedModalText5: 'You will not be able to change the results of the vote, add missing ballots, or make any changes.',
  publishingProtocolStartedModalText6: 'Do you really want to start publishing the final protocol of the meeting?',
  publishingProtocolStartedModalText7: 'You will not be able to change the results of the vote or make any changes.',
  cancelingPublicationProtocolModalText1: 'Canceling the publication of the protocol would result in a change in the status of the meeting from a ',
  cancelingPublicationProtocolModalText2: '"Protocol publishing."',
  cancelingPublicationProtocolModalText3: 'on ',
  cancelingPublicationProtocolModalText4: '"The votes are being counted." ',
  cancelingPublicationProtocolModalText5: 'We recommend to cancel the publication of a protocol and start publishing a new one only if there are good reasons (serious deviation of the actual voting results, semantic errors in the texts of decisions, etc.). After clicking on the "Yes. Cancel" button, you will be able to make changes to the voting results, add new ballots, etc.',
  cancelingPublicationProtocolModalText6: 'Do you really want to cancel the publication of the final protocol of the meeting?',
  informationAboutDownloadedFiles: 'If you upload multiple files, they will be merged into one PDF file. Make sure that the order of the files is correct (example: 01.jpg, 02.jpg, 03.jpg...015.jpg, etc.) and that they follow one after the other.',
  attention: 'Please note',
  bulletinsInProcess: "that there are {{bulletins}} participants' ballots uploaded to the meeting but not yet processed. You can wait up to one hour or start publishing the protocol anyway. Then the unprocessed ballots will not affect the voting results."
};