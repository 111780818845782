"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  sqvr: 'Сквер',
  street: 'Улица',
  house: 'Дом',
  address: 'Адрес',
  registry: 'Реестр',
  polls: 'Опросы',
  naturalPerson: 'Физическое лицо',
  legalPerson: 'Юридическое лицо',
  title: 'Сквер — сервис для управления домом и посёлком'
};