"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  order: 'Order',
  orderRegistry: 'Order the register',
  managementOrganizationStatement: 'Application to the Managing Organization',
  free: 'Free',
  dataValidity: 'Data reliability',
  noWarranties: 'No warranties',
  statementHint: 'Download and fill out the application for the register. Give the application to the management organization.',
  downloadStatement: 'Download the application form',
  propertyPassport: 'Property passport of the House',
  pricePerMeterFrom: 'from {{price}} per room',
  propertyPassportDescription: 'List of premises and ownership interests therein',
  inExcelFormat: 'In excel file format',
  orderTerm: 'The deadline is {{term}} business days.',
  goToPayment: 'Order Now',
  changeOrder: 'Change order',
  orderTotalCost: 'Full Value of the Order:',
  inputAddressHint: 'Enter the address of the house and we will calculate the cost',
  registryExtractDiscountHint: 'If you want to order an Extract from USRN in the future, the cost of the order will be reduced by the cost previously paid for the Property Passport',
  overallSquare: 'Total number of premises: {{square}}',
  pricePerMeter: 'Cost per premise: {{price}}',
  propertyPassportDescriptionFull: '"\n"The property passport includes a list of all premises in the apartment building with an indication of all their characteristics:\\n"\n"- square;\\n"\n"- entrance;\\n"\n"- floor;\\n"\n"- cadastral number;\\n"\n"- number of shares of ownership in each premises;\\n"\n"- current details of the ownership document: name, registration date, number.The property passport includes a list of all premises in the apartment building with an indication of all their characteristics:\\n- square;\\n- entrance;\\n- floor;\\n- cadastral number;\\n- number of shares of ownership in each premises;\\n- current details of the ownership document: name, registration date, number.',
  lastOrderDate: 'You ordered Property passport of the House {{date}}',
  rosRegistryExtract: 'Extracts from the USRN',
  rosRegistryExtractDescription: 'Extracts for each room of the house from the Unified State Register of Registrants',
  rosRegistryExtractDescriptionFull: '"\n"The register, compiled from extracts, includes a list of all premises in the apartment building with an indication of their characteristics:\\n"\n"- square;\\n"\n"- floor;\\n"\n"- cadastral number;\\n"\n"- share of ownership in each premises;\\n"\n"- current details of the ownership document: name, registration date, number.The register, compiled from extracts, includes a list of all premises in the apartment building with an indication of their characteristics:\\n- square;\\n- floor;\\n- cadastral number;\\n- share of ownership in each premises;\\n- current details of the ownership document: name, registration date, number.',
  registryFromExcelFormat: 'In excel file format and separate extracts from the USRN for each premise.',
  registryExtractOrderDiscountHint: 'The cost of producing an extract from the Unified State Register of Registers is reduced by the cost of the previously ordered Property Passport of the House.',
  orderRegistryDescription_0: 'Extract of the register of owners for the apartment building at:',
  orderRegistryDescription_1: 'Extract of the register of owners for the houses at the addresses:',
  addressNotFoundAlertTitle: 'Attention',
  addressNotFoundAlertDesriptionPart1: 'Unfortunately, in public sources of information (',
  addressNotFoundAlertDesriptionPart2: ') There is no data on the number of rooms in your building and/or the total area of these rooms. Without this information, we cannot calculate the cost of producing the register',
  orderContactsModalTitle: 'Attention',
  orderContactsModalDescriptionPart1: 'Unfortunately, in public sources of information (',
  orderContactsModalDescriptionPart2: ') There is no data on the number of rooms in your building and/or the total area of these rooms. Without this information, we cannot calculate the cost of producing the register',
  fullNameLabel: 'Full name',
  emailLabel: 'E-mail',
  phoneLabel: 'Phone',
  inputValueRequired: 'Please fill in this field',
  fullNameRequired: 'Provide at least a first and last name',
  inputEmailRequired: 'Your e-mail address is incorrect',
  inputPhoneRequired: 'Your phone number is incorrect',
  orderContactsModalSubmit: 'Send',
  customer: 'Customer',
  deliveryOnEmail: 'E-mail delivery',
  executionPeriod: 'Due date',
  proceed: 'Continue',
  cardData: 'Card data',
  cardDataDescription: 'To pay, enter your bank card details',
  cardNumber: 'Card Number',
  cardNumberRequired: 'Incorrect card number',
  cardMonth: 'Month',
  cardFieldError: 'Error',
  cardYear: 'Year',
  cardCVV: 'CVV',
  goPay: 'Pay',
  savedContactsAlertTitle: 'Complete',
  savedContactsAlertDescription: 'Thank you',
  policyDescription: 'By clicking on the pay button, you accept our',
  policyTermsAndConditions: 'Terms of use',
  policyPrivatePolicy: 'Privacy policy',
  policyRefund: 'Refund policy',
  goNext: 'Next',
  goBack: 'Back',
  addressSearch: 'Search by address',
  paymentAttemptFailed: 'Payment not completed. Error code: {{code}}, {{error}}',
  successPaymentTitle: 'You have successfully paid for your order!',
  successPaymentDescription: 'All details of this order have been sent to your e-mail {{email}}',
  failedPaymentTitle: "The payment didn't go through",
  failedPaymentDescription: "We're sorry, the payment was not made",
  yourOrder: 'Your order:',
  price: 'Cost',
  successActialization: 'You have successfully ordered an update of the registry'
};