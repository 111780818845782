"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  street: 'Улица',
  streetType: 'Тип улицы',
  houseNumber: 'Номер дома',
  unit: 'Корпус',
  info: 'Доп. информация',
  entrancesAmount: 'Подъезды',
  floorsAmount: 'Этажи',
  area: 'м2',
  amount: 'Единиц',
  fraction: 'Дробь',
  share: 'Доля',
  address: 'Адрес',
  type: 'Тип недвижимости',
  form: 'Форма управления',
  count: 'Кол-во домов и строений в посёлке',
  property: 'Помещения',
  identicalHouse: 'Указаны одинаковые дома',
  zipError: 'Не найден индекс дома',
  addHouseTooltip: 'Если в вашем товариществе есть несколько домов, то вы можете сразу их добавить',
  addHouseDisabledTooltip: 'Добавление нескольких домов возможно только для товарищества собственников (ТСН, ТСЖ, ГСК и др.)'
};