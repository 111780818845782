"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  representativeInfo: 'Информация о доверенном лице участника собрания',
  addRepresentative: 'Добавить представителя',
  addRepresentativeComment: 'Указывается обязательно, если участие в голосовании принимает представитель собственника',
  representativeLawDocument: 'Документ удостоверяющий полномочия',
  representativePassportDocument: 'Документ представителя',
  procuration: 'Доверенность',
  edit: 'редактировать',
  reverse: 'Отменить',
  addFIO: 'Укажите ФИО',
  addEmail: 'Укажите почту',
  addDescription: 'Например, номер паспорта, кем выдан; доп. телефон',
  noInfo: 'Информация отсутствует',
  addDocuments: 'Добавить документы'
};