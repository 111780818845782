"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  ownCard: 'Your profile',
  ownEditing: 'Editing your profile',
  delete: 'User deleted or not found',
  editing: 'Edit profile',
  editingSpecial: 'Editing an employee profile',
  creating: 'Adding the owner of the premises',
  creatingContractor: 'Adding a contractor employee',
  creatingManager: 'Adding a CC employee',
  creatingHoaEmployee: 'Adding an employee of the partnership',
  changeMembership2: 'in the user avatar.',
  changeMembership1: 'For changing the membership value in the partnership, click on the user avatar.',
  noMembership: 'Membership in the partnership is not specified'
};