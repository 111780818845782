"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  protocolPublished: 'Protocol published',
  protocolUploaded: 'Protocol downloaded',
  protocolWaitingForPublishing: 'Protocol awaiting confirmation of publication',
  rejectProtocolPublishing: 'Cancel publication of the protocol',
  removePublishedProtocol: 'Delete a published protocol',
  beginProtocolPublishing: 'Start publication of the protocol',
  disabledButton: 'The button will be available after the voting is complete',
  protocolCreatingSigningAndPublishing: 'Formation, signing and publication of the protocol.',
  haveNotProtocol: "There's no protocol",
  protocolNotPublishingWaitingForPublishing: 'The protocol has not been published. Awaiting confirmation of publication',
  meetingWasHeld: 'The meeting was held.',
  meetingNotSucceed: "The meeting didn't take place",
  initiatedTheReplacementOfProtocol: 'Attention! Protocol change initiated!',
  youCanMakeChangeToVotingResults: '{Пользователь} {Время}{Дата} initiated a replacement protocol. You can make changes to the voting results, add ballots. After that you need to go through all the steps of publishing the protocol again. The previous version of the protocol can be downloaded here.',
  finalProtocolHasBeenPublished: 'The final protocol has been published. The final minutes can be found on the Documents page or by downloading the meeting materials. All participants were notified accordingly.',
  completeAllFiveSteps: 'Complete all five steps and publish the meeting protocol. Once the protocol is published, they will be posted on the Documents page and uploaded to the meeting materials.  Notices will be sent to all participants.'
};