"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  registryColumnSettings: 'Edit column display',
  columnSettingsWithHiddenRows: 'You have hidden some columns, go into the settings to bring them back to display',
  discardChanges: 'Undo the edits and return to the original registry',
  discardChangesDisabled: 'No edits to undo',
  joinInvite: 'Invite homeowners',
  joinInviteDisabled: 'Contact an Administrator or Moderator to invite a user to sqvr',
  joinRequests: 'Applications for annexation to the house',
  joinRequestsVillage: 'Applications for annexation to the settlement',
  joinRequestsDisabled: 'No applications for connection',
  registryHistory: 'Version History',
  registryHistoryDisabled: 'No version history',
  registryFilters: 'Show all filters',
  registrySelectColumn: 'Highlight a column',
  registrySortColumn: 'Sort values',
  changeShipTypeInColumn_town: 'Change the display. Possible values: % , fraction, m²',
  changeShipTypeInColumn_village: 'Change the display. Possible values: % , fraction',
  registryBlocked: 'The registry is locked',
  isNotCleanableColumn: 'You cannot clear this column',
  filtersChip: 'To view and change filters, click on the filter icon. The selected filters will be highlighted in color.',
  disableFilter_apartmentView: 'It is not possible to use the filter in the mode of viewing all owners of one premise',
  disableFilter_emptyRegistry: 'There is nothing to filter. There is no data to filter.',
  allowMeeting: 'This user cannot vote at the meeting, reason:',
  unknownPersonNotice: 'The service line shows how much space is empty in this room. Enter the name of the owner to fill this space',
  tempRows: 'Please disable the temporary (blue) strings',
  downloadVersion: 'Download the registry version',
  noLink: 'There is no link to download the version',
  unknownDate: 'Date unknown',
  nothingToClear: 'Nothing to clear. First select a row or column'
};