"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  copyAddress: 'Copy link',
  editAddress: 'Edit',
  deleteAddress: 'Delete',
  deleteAddressModalTitle: 'Delete address',
  deleteAddressModalText: 'Do you really want to delete the address?',
  deleteAddressModalYes: 'Yes, Delete',
  deleteAddressModalNo: "No, don't delete it",
  addressLabel: 'Address',
  contactsLabel: 'Contacts',
  descriptionLabel: 'Additional information',
  reviewLabel: 'Reviews',
  deleteAddressSuccess: 'The address has been successfully deleted',
  deleteReviewSuccess: 'Review successfully deleted',
  addFavoriteToast: 'Address added to favorites',
  removeFavoriteToast: 'Address removed from favorites',
  sendReview: 'Add a review',
  more: 'more',
  noReview: 'No reviews yet.',
  beeFirst: 'Be the first!',
  clickToButton: 'Click the button to add the Your first review.',
  nothingFound: 'Nothing found',
  noAddresses: 'There are no useful addresses yet.',
  addFirstAddress: 'Click on the blue round button with + at the bottom right of the map to add your first useful address.',
  deleteAddressReview: 'Do you want to delete a review?',
  deleteAddressReviewText: 'Are you sure you want to delete the review? The data entered will be lost.',
  createReviewSuccess: 'Feedback has been successfully saved.',
  editReviewSuccess: 'The address has been successfully edited',
  createReviewTitle: 'My review',
  yourReviewTitle: 'Your feedback',
  ratingLabel: 'Evaluation*',
  commentLabel: 'Feedback',
  submitButton: 'Save',
  cancelButton: 'Cancel',
  tooltipTitle: 'Give a grade',
  copyUrl: 'A link to a useful address is copied',
  emptyReviews: 'No reviews',
  notificationModalTitle: 'Obligatory comment',
  notificationReviewModalDesc: 'You are deleting the review. Please comment on this decision. This comment is mandatory and will be visible to all users. You cannot delete a review without a comment.',
  notificationAddressModalDesc: 'You are deleting the address. Comment on this decision. This comment is mandatory and will be visible to all users. The address cannot be deleted without a comment.'
};