"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  createAccount: 'Create an account using your e-mail or phone',
  accountExists: 'You already have an account with Sqvr',
  accountExists2: 'Such an account already exists',
  accountExistsInHOA: 'You are already registered in this facility',
  canNotUseThisLogin: 'You cannot use this e-mail/phone number',
  accountsJoining: 'Enter your password and we will add this Item to your account.',
  alreadyRegistered_email: 'Your e-mail address is already registered in this object. Click',
  alreadyRegistered_phone: 'Your phone number is already registered to this Facility. Click',
  here: 'here',
  inputPassword: 'and enter the password to log in.',
  canNotUseExistAccount_email: 'You have already registered an account in the same Object and with this e-mail. You cannot use the same e-mail address for different roles in the same Object',
  canNotUseExistAccount_phone: 'You have already registered an account in the same Object and with this phone number. You cannot use the same phone number for different roles in the same Object',
  accountExistsInSocialAuth_email: 'You have previously logged in with this email through a social network. Log in using a social network.',
  accountExistsInSocialAuth_phone: 'You have previously logged in with this phone number through a social network. Log in via social networks.',
  inputAnotherLogin: 'If you wish to use a different account for each Facility, enter an e-mail/phone number that has not been used before.',
  youCanUseAnotherLogin: 'You may use a different e-mail/phone number if you wish to create a new account with the Facility',
  youCanUseAnotherLoginInThisHOA: 'Use a different e-mail/phone number to register an account with this Facility',
  youCanUseAnotherLoginOrUseSocialNetwork: 'You may enter a different e-mail/phone number to register for this Facility or use a social network to log in to all of your Facilities'
};