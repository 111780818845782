"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Техническая поддержка',
  email: 'Ваш e-mail',
  message: 'Если у Вас возникли сложности в работе с системой или Вы нашли какую-то проблему, пожалуйста, сообщите об этом нам',
  messageSentTitle: 'Сообщение отправлено',
  messageSentText: 'Благодарим за Ваше обращение. В ближайшее время сотрудники технической поддержки рассмотрят вашу заявку и свяжутся с вами.'
};