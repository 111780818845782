"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  showErrors: 'Показать ошибки',
  uploadData: 'Загрузить данные в реестр',
  exelExport: 'Экспортировать в Excel',
  downloadTemplate: 'Скачать шаблон',
  deleteAll: 'Удалить весь реестр',
  cantEdit: 'Вы не можете сейчас редактировать реестр в данный момент его редактирует другой пользователь (',
  orderRegistry: 'Заказать реестр',
  invite: 'Пригласить в сквер',
  connectTelegram: 'Подключить Telegram-бот',
  registryProblems: 'Проблемы реестра'
};