"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  downloadProtocol: 'Receive the protocol prepared by Sqvr',
  protocolDownloaded: 'Protocol downloaded',
  downloadProtocolTooltip: 'You will receive automatically generated meeting protocol with all the results.',
  markSigners: 'Mark who signed the protocol',
  protocolSigned: 'Protocol signed',
  markSignersTooltip: 'Read the received protocol carefully. If necessary, edit the protocol (if there are typos). Sign it. The signatures of the Chairman and the Secretary of the meeting are mandatory. The signatures of the members of the Counting Committee and the Initiators of the meeting are not obligatory, but we strongly recommend that you also put the signatures of the members of the Counting Committee and the Initiators of the meeting.',
  uploadSignedProtocol: 'Download the signed protocol of the meeting',
  protocolUploaded: 'The signed protocol of the meeting have been uploaded',
  uploadSignedProtocolTooltipFirstPart: 'In order for this button to become active, you must first',
  uploadSignedProtocolTooltipSecondPart: 'download the protocol that Sqvr prepared. Then',
  uploadSignedProtocolTooltipThirdPart: 'it will need to be signed by the Secretary and the Chair of the meeting.',
  uploadSignedProtocolActiveTooltip: 'If you already have a signed protocol, please scan it and upload it. The button will then become active',
  uploadSignedProtocolDoneTooltip: 'uploaded a file with a scanned copy of the final protocol of the meeting, but has not yet published them. Please read the uploaded protocol carefully. If you see errors and inaccuracies, you can delete the file and go through all the steps of publishing the protocol again. If you believe that the protocol is correct and it correctly reflects the voting results, you can initiate the publication of the protocol. To do this, click on the button -',
  initiateProtocolPublication: 'Initiate publication of the protocol',
  protocolPublicationInitiated: 'Publication of the protocol initiated',
  initiateProtocolPublicationTooltip: 'Once you have uploaded the file, with the signed protocol, you will be able to initiate its publication.',
  initiateProtocolPublicationDoneTooltip: 'initiated the publication of the protocol. You can download the protocol and familiarize yourself with their content. If you have any comments or see errors and inaccuracies in the downloaded file, you can delete it and go through all the steps of publishing the protocol again. If everything is in order and the protocol is ready for publication, click on the button',
  confirmPublication: 'Confirm publication',
  protocolPublishedStatus: 'Protocol published',
  confirmPublicationTooltipFirstPart: 'Once the publication of the protocol has been confirmed, a scan copy of the signed protocol will be available to all voting participants. Sqvr will automatically notify everyone when the meeting is over and the final protocol is published. If you see inaccuracies or the protocol needs to be corrected, you can delete the file with the uploaded protocol at step #3. After that, you need to go through all the steps of publishing the protocol again.',
  confirmPublicationTooltipSecondPart: 'Once the protocol has been published, two documents will be saved on the Documents page:',
  confirmPublicationTooltipListFirstItem: 'Automatically generated protocol;',
  confirmPublicationTooltipListSecondItem: 'A scanned copy of the signed final protocol.',
  afterConfirmPublicationTooltip: 'initiated the publication of the protocol. Once the publication is confirmed by the Chair or the Secretary of the meeting, it will be published.',
  at: 'at',
  uploadedFinishProtocol: 'uploaded a file with a scanned copy of the final protocol.',
  initiatedProtocolPublication: 'initiated the release of the protocol.',
  protocolPublished: 'The protocol of the meeting have already been published'
};