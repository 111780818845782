"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  changesHistory: 'Change history',
  hideFilters: 'Hide change history filters',
  showFilters: 'Show change history filters',
  closeHistory: 'Close change history',
  ownerRemovedAction: 'Owner removed',
  addressChangedAction: 'Address changed',
  changeContactInfoAction: 'Contact information changed',
  changeAccessLevelAction: 'User rights changed',
  thereWasImportOfRegistry: 'Registry import occured',
  houseWithApartmentWasDeletedAction: 'House with apartment deleted',
  ownerAddedAction: 'Owner added',
  houseWasDeletedAction: 'House deleted',
  ownerDataHasBeenCleanedAction: 'Owner data cleaned',
  areaOfApartmentChangedAction: 'Apartment area changed',
  profileChangedAction: 'Profile changed',
  apartmentWasDeletedAction: 'Apartment deleted'
};