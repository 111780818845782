"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  representativeInfo: 'Information on the trusted participant in the meeting',
  addRepresentative: 'Add a representative',
  addRepresentativeComment: "Must be specified if the owner's representative participates in voting",
  representativeLawDocument: 'Authorization document',
  representativePassportDocument: "Representative's document",
  procuration: 'Power of attorney',
  edit: 'edit',
  reverse: 'Cancel',
  addFIO: 'Enter your full name',
  addEmail: 'Specify mail',
  addDescription: 'For example, passport number, by whom issued; additional phone number',
  noInfo: 'No information available',
  addDocuments: 'Add documents'
};