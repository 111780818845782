"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  showErrors: 'Show errors',
  uploadData: 'Upload data to the registry',
  exelExport: 'Export to excel',
  downloadTemplate: 'Download the template',
  deleteAll: 'Delete the entire registry',
  cantEdit: "You can't edit the registry right now at the moment it is being edited by another user (",
  orderRegistry: 'Order the register',
  invite: 'Invite to the Sqvr',
  connectTelegram: 'Connect Telegram bot',
  registryProblems: 'Registry problems'
};