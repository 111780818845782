"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  error404Title: 'Похоже вы заблудились :(',
  error404Description: 'страница, которую вы ищете, недоступна!',
  goHome: 'На главную',
  mobileAppInfo1: 'Информация по данному разделу доступна только в мобильном приложении',
  mobileAppInfo2: 'Чтобы просмотреть информацию по разделу «{{pageName}}», пожалуйста, перейдите в мобильное приложение.',
  mobileAppInfo3: 'Если у Вас не установлено мобильное приложение, можете скачать его по одной из кнопок ниже.'
};