"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  search: 'Search Sqvr',
  contacts: 'Contacts',
  documents: 'Documents',
  usefulAddresses: 'Useful addresses',
  desires: 'Appeals',
  announcements: 'Announcements',
  digest: 'Digest',
  meetings: 'Meetings',
  polls: 'Polls',
  budget: 'Budget',
  financialReports: 'Financial reports',
  management: 'Control',
  howItWork: 'How SQVR works',
  howToBegin: 'Where to start',
  forOwners: 'For owners',
  bills: 'Bills',
  invite: 'Invite to SQVR',
  inviteTooltip: "Share the link to your SQVR home's profile using social media or messengers",
  changeObject: 'Change object',
  communication: 'Communication',
  messages: 'Messages',
  support: 'Support',
  tariffs: 'Plans',
  pretensions: 'Pretensions',
  pretension: 'Pretension',
  petitions: 'Petitions',
  myProfile: 'Profile',
  feedback: 'Tech support',
  exit: 'Log out',
  reservations: 'My reservations',
  instructions: 'Security instructions',
  forAdmins: 'For administrators',
  vote: "Let's vote",
  delivery: 'My deliveries',
  appeals: 'Appeals',
  financial: 'Finances',
  help: 'Help',
  info: 'Info',
  calendar: 'Calendar of Events'
};