"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  addressCreateTitle: 'Создание адреса',
  addressEditTitle: 'Редактирование адреса',
  cancelAddressCreateTitle: 'Отменить создание адреса',
  cancelAddressEditTitle: 'Отменить редактирование',
  cancelAddressCreateText: 'Вы уверены, что хотите отменить создание адреса? Внесенные данные будут потеряны.',
  cancelAddressEditText: 'Вы уверены, что хотите отменить внесенные правки? Измененные данные будут потеряны.',
  phoneTypeCreateTitle: 'Создать свой тип телефона',
  phoneTypeEditTitle: 'Редактировать свой тип телефона',
  bottomTextForChipsInput: 'Введите ключевые слова через запятую',
  disabledTooltipText: 'Заполните обязательные поля',
  createAddressSuccess: 'Адрес успешно сохранен. Если Вы хотите, можете добавить первый отзыв об этом адресе.',
  leaveReview: 'Добавить отзыв',
  editAddressSuccess: 'Адрес успешно сохранен',
  noOptions: 'Ничего не найдено. Попробуйте ввести другие слова для поиска',
  showMore: 'Показать еще поля',
  errorTitleText: 'Введите название',
  errorAddressText: 'Введите действительный адрес',
  typeMobilePhone: 'Мобильный',
  typeLandlinePhone: 'Городской',
  typeWorkPhone: 'Рабочий',
  typeHomePhone: 'Домашний'
};