"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  totalLikes: 'total "likes"',
  percentVotesOfMeeting: 'voting percent in the meeting',
  totalDislikes: 'total "dislikes"',
  noComment: 'No comments',
  cantVote: "You can't vote at the meeting"
};