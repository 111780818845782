"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  weSentCode_email: 'Мы отправили вам шестизначный код на адрес:',
  weSentCode_phone: 'Мы отправили вам шестизначный код на телефонный номер:',
  inputCodeFrom_email: 'Введите полученный код или пройдите по ссылке в сообщении.',
  inputCodeFrom_phone: 'Введите полученный код или пройдите по ссылке в sms.',
  afterCodeInput: 'После введения кода вы будете направлены на страницу регистрации.',
  askAdminForCode: 'Если у вас нет кода обратитесь к администратору.',
  checkContact_email: 'Проверьте email',
  checkContact_phone: 'Проверьте телефон',
  inputCode: 'Введите полученный код',
  inputGettingCode: 'Введите ранее полученный код:',
  noCode: 'Если у Вас нет кода, обратитесь к администратору или ',
  register: 'зарегистрируйтесь',
  haveAccount: ' самостоятельно. Если у Вас уже есть аккаунт, ',
  inputLoginAndPassword: 'введите логин и пароль.',
  continue: 'Продолжить',
  error: 'Неверный код. Попробуйте ввести еще раз.',
  emailConfirmationCodeTitle: 'Код подтверждения',
  emailConfirmationInputCode: 'Введите полученный код:',
  emailConfirmationEnsure: 'Дождитесь получения письма c кодом регистрации на email. Проверяйте спам. ',
  emailConfirmationAlreadyConfirmed: 'Если вы уже зарегистрированы, то '
};