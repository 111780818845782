"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  repeatSend: 'Resend',
  sendCode: 'Send registration code',
  sendInvite: 'Send invitation',
  sendVerificationEmail: 'Send a confirmation e-mail',
  sendVerificationSMS: 'Send sms to confirm your phone number',
  appeals: 'Enter data',
  passwordEditing: 'Password change',
  password: 'Change password',
  allowMeeting: 'Bring back the ability to vote',
  toggleMain: 'Confirm',
  addOwnership: 'Add ownership',
  attachCopiesOfDocuments: 'Attach copies of documents'
};