"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  hideHints: 'Скрыть подсказки',
  openAllHints: 'Открыть все советы',
  showHints: 'Показать подсказки',
  needToSelectPeople: 'Нужно кого-то выбрать'
};