"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pretensoinNotificationRequestsSelectedUsersIds = exports.pretensionNotificationFullStatistics = exports.pretensionNotificationShortStatistics = exports.pretensionNotificationFilters = exports.pretensionNotificationSelectedUsersIds = exports.pretensionNotificationSelectedUsers = exports.pretensionRequestExport = exports.pretensionRequestList = exports.pretensionRequestFilters = exports.pretensionHistoryList = exports.pretensionFiltersList = exports.pretensionHistory = exports.pretensionRSO = exports.pretensionOne = exports.pretensionList = exports.pretensionNotificationUsersList = void 0;
var pretensionNotificationUsersList_1 = require("./pretensionNotificationUsersList");
Object.defineProperty(exports, "pretensionNotificationUsersList", {
  enumerable: true,
  get: function () {
    return pretensionNotificationUsersList_1.pretensionNotificationUsersList;
  }
});
var pretensionList_1 = require("./pretensionList");
Object.defineProperty(exports, "pretensionList", {
  enumerable: true,
  get: function () {
    return pretensionList_1.pretensionList;
  }
});
var pretensionOne_1 = require("./pretensionOne");
Object.defineProperty(exports, "pretensionOne", {
  enumerable: true,
  get: function () {
    return pretensionOne_1.pretensionOne;
  }
});
var pretensionRSO_1 = require("./pretensionRSO");
Object.defineProperty(exports, "pretensionRSO", {
  enumerable: true,
  get: function () {
    return pretensionRSO_1.pretensionRSO;
  }
});
var pretensionHistory_1 = require("./pretensionHistory");
Object.defineProperty(exports, "pretensionHistory", {
  enumerable: true,
  get: function () {
    return pretensionHistory_1.pretensionHistory;
  }
});
var pretensionFiltersList_1 = require("./pretensionFiltersList");
Object.defineProperty(exports, "pretensionFiltersList", {
  enumerable: true,
  get: function () {
    return pretensionFiltersList_1.pretensionFiltersList;
  }
});
var pretensionHistoryList_1 = require("./pretensionHistoryList");
Object.defineProperty(exports, "pretensionHistoryList", {
  enumerable: true,
  get: function () {
    return pretensionHistoryList_1.pretensionHistoryList;
  }
});
var pretensionRequestFilters_1 = require("./pretensionRequestFilters");
Object.defineProperty(exports, "pretensionRequestFilters", {
  enumerable: true,
  get: function () {
    return pretensionRequestFilters_1.pretensionRequestFilters;
  }
});
var pretensionRequestList_1 = require("./pretensionRequestList");
Object.defineProperty(exports, "pretensionRequestList", {
  enumerable: true,
  get: function () {
    return pretensionRequestList_1.pretensionRequestList;
  }
});
var pretensionRequestExport_1 = require("./pretensionRequestExport");
Object.defineProperty(exports, "pretensionRequestExport", {
  enumerable: true,
  get: function () {
    return pretensionRequestExport_1.pretensionRequestExport;
  }
});
var pretensionNotificationSelectedUsers_1 = require("./pretensionNotificationSelectedUsers");
Object.defineProperty(exports, "pretensionNotificationSelectedUsers", {
  enumerable: true,
  get: function () {
    return pretensionNotificationSelectedUsers_1.pretensionNotificationSelectedUsers;
  }
});
var pretensionNotificationSelectedUsersIds_1 = require("./pretensionNotificationSelectedUsersIds");
Object.defineProperty(exports, "pretensionNotificationSelectedUsersIds", {
  enumerable: true,
  get: function () {
    return pretensionNotificationSelectedUsersIds_1.pretensionNotificationSelectedUsersIds;
  }
});
var pretensionNotificationFilters_1 = require("./pretensionNotificationFilters");
Object.defineProperty(exports, "pretensionNotificationFilters", {
  enumerable: true,
  get: function () {
    return pretensionNotificationFilters_1.pretensionNotificationFilters;
  }
});
var pretensionNotificationShortStatistics_1 = require("./pretensionNotificationShortStatistics");
Object.defineProperty(exports, "pretensionNotificationShortStatistics", {
  enumerable: true,
  get: function () {
    return pretensionNotificationShortStatistics_1.pretensionNotificationShortStatistics;
  }
});
var pretensionNotificationFullStatistics_1 = require("./pretensionNotificationFullStatistics");
Object.defineProperty(exports, "pretensionNotificationFullStatistics", {
  enumerable: true,
  get: function () {
    return pretensionNotificationFullStatistics_1.pretensionNotificationFullStatistics;
  }
});
var pretensionNotificationRequestsSelectedUsersIds_1 = require("./pretensionNotificationRequestsSelectedUsersIds");
Object.defineProperty(exports, "pretensoinNotificationRequestsSelectedUsersIds", {
  enumerable: true,
  get: function () {
    return pretensionNotificationRequestsSelectedUsersIds_1.pretensoinNotificationRequestsSelectedUsersIds;
  }
});