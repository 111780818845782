"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  order: 'Заказ',
  orderRegistry: 'Заказать реестр',
  orderRegistrySqvr_notVip: '$t(modules.app.payment.orderRegistry) в Сквере без исправлений',
  orderRegistrySqvr_vip: '$t(modules.app.payment.orderRegistry) в Сквере с проверкой на наличие задвоения и ошибок',
  orderRegistryDescription_vip: 'Выписка реестра собственников в Сквере с проверкой на наличие задвоений и ошибок для многоквартирного дома по адресу:',
  orderRegistryDescription_notVip: 'Выписка реестра собственников для многоквартирного дома в том виде, в каком он будет сформирован Росреестром по адресу:',
  orderRegistryDescription_emptyType: 'Выписка реестра собственников для многоквартирного дома по адресу:',
  orderRegistrySecondaryDescription: 'Скачайте и заполните заявление на получение реестра. Передайте заявление в управляющую организацию',
  download: 'Скачать',
  downloadStatement: '$t(modules.app.payment.download) заявление',
  apply: 'Подать',
  applyStatement: '$t(modules.app.payment.apply) заявление',
  free: 'Бесплатно',
  noWarranties: 'Без гарантий',
  submitAStatement: 'Предоставьте заявление в управляющую организацию',
  statementRosreestr: 'Выписка из Росреестра',
  statementRosreestrWithCheckData: '$t(modules.app.payment.statementRosreestr) с проверкой данных',
  orderTotalCost: 'Полная стоимость Заказа:',
  currencyValue_0: '{{count}} рубль',
  currencyValue_1: '{{count}} рубля',
  currencyValue_2: '{{count}} рублей',
  cost: 'Стоимость:',
  dataValidity: 'Достоверность данных:',
  days_0: '{{count}} день',
  days_1: '{{count}} дня',
  days_2: '{{count}} дней',
  unitCost_vip: '1 рубль/1 кв. метр.',
  unitCost_notVip: '20 копеек/1 кв. метр.',
  fullRosreestrOrderText_vip: "Мы закажем реестр в Росреестре, проведём проверку данных, устраним ошибки и задвоения. Срок — $t(modules.app.payment.days, {'count': {{days}} })",
  fullRosreestrOrderText_notVip: "Мы закажем и предоставим реестр в том виде, в каком он будет сформирован Росреестром. Срок — $t(modules.app.payment.days, {'count': {{days}} })",
  editOrder: 'Изменить заказ',
  proceedToCheckout: 'Перейти к оплате',
  toPay: 'оплатить',
  customer: 'Заказчик',
  delivery: 'Доставка',
  email: 'На эл. почту:',
  executionPeriod: 'Срок исполнения:',
  proceed: 'Продолжить',
  cardData: 'Данные карты',
  cardDataDescription: 'Для оплаты введите данные банковской карты:',
  policyDescription: 'Нажимая на кнопку оплатить, Вы принимаете наши',
  policy_termsAndConditions: 'Условия использования',
  policy_privatePolicy: 'Политику конфиденциальности',
  policy_refund: 'Политику возврата денежных средств'
};