"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  delete: 'Удалить опрос',
  cancel: 'Отменить опрос',
  allPolls: 'К списку опросов',
  results: 'Посмотреть результаты',
  backToVote: 'Вернуться к голосованию',
  downloadVotersRegistry: 'Скачать реестр голосующих в опросе'
};