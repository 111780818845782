"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  requestCode: 'Получить код',
  resendCode: 'Получить код повторно',
  requestCodeTimoutComment: 'Код выслан. Вы можете еще раз запросить код через ',
  inputReceivedCode: 'Код подтверждения',
  inputPhoneNumber: 'Введите номер телефона',
  savePhone: 'Сохранить номер телефона в профиле',
  requestCodeAgain: 'Если Вы не получили  СМС с кодом, можно еще раз запросить код.',
  disableTooltipTextShouldWait: 'Еще не истекло время для повторной отправки кода. Подождите.',
  disableTooltipTextNoPhone: 'Для отправки кода подтверждения укажите корректный номер телефона'
};