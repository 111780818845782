"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  passwordMin: 'The password must contain a minimum of {{count}} characters',
  passwordConfirm: 'The passwords must match',
  additionalMax: 'The maximum number of characters in the additional information field is {{count}}',
  exists_phone: 'This phone number is already in use',
  exists_email: 'This e-mail address is already in use',
  valueMustBeLessThan100: 'value must not be more than 100',
  noFreeAreaInSelectedRoom: 'There is no free area in the selected room',
  valueMustntBeLessThan1Variant2: 'value must not be less than 1',
  valueMustBeLessThan1: 'value must not be more than 1',
  notEnoughFreeAreaInSelectedRoom: 'There is not enough free area in the selected room',
  valueMustBeNumber: 'value must be a number',
  unknownTypeOfProperty: 'Unknown type of property',
  alreadyHaveShareOfOwnershipInSelectedRoom: 'You already have a share of ownership in the selected room',
  noShareOfOwnershipInSelectedRoom: 'There is no shared ownership in the selected room',
  valueMustntBeLessThan1: 'value must not be less than 1',
  areaOfRoomMustntBeMoreThanAreaOfAllRooms: 'The area of the room must not be more than the area of all rooms in the building'
};