"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  notFoundRowsToDelete: 'Нет строк для удаления',
  unknownStatus: 'Статус удаления строк: {{status}}',
  noUsersForMerge: 'Не выбран пользователь для присоединения собственности',
  availableOnlyJoinOwnership: 'Для добавления доступна только совместная собственность, у вас не осталось свободной собственности',
  notAvailableOwnershipNotFoundFreeSpace: 'У вас не осталось свободной собственности',
  editingAllowedOnlyForJurPersons: 'Редактирование ИНН доступно только для Юр. лиц и Государства',
  impossibleAddingNotFoundOwner: 'Невозможно добавлять контактные данные служебной строке, сначала добавьте собственника',
  impossibleEditingCell: 'Невозможно редактировать данную ячейку в служебной строке, сначала добавьте собственника',
  serviceRowNotEditing: 'Это служебная строка, показывающая долю незаполненных площадей в помещении. ' + 'Редактирование или иные действия с ней невозможны',
  canNotEditInRoomView: 'Недоступно для редактирования в режиме просмотра помещения',
  joiningRequestContactDataChangeWarning: 'Изменение контактных данных недоступно в строках из заявок на присоединение!',
  registryWithJoiningRequestsDeleteWarning: 'В реестре есть строки с заявками на присоединение. При удалении реестра эти заявки будут отклонены!',
  joiningRequestAccepted_0: 'Заявка на присоединение {{count}} шт. успешно принята. Сейчас на адрес электронной почты или на мобильный телефон собственника будет автоматически выслано уведомление с предложением подтвердить свой адрес электронной почты или свой номер телефона. Если какой-то пользователь не получил или не отреагировал на уведомление, у вас есть возможность отправить такое же уведомление этому собственнику из его профиля нажав на кнопку "Отправить код приглашения".',
  joiningRequestAccepted_1: 'Заявки на присоединение {{count}} шт. успешно приняты. Сейчас на адреса электронной почты или на мобильные телефоны собственников будут автоматически высланы уведомления с предложением подтвердить свой адрес электронной почты или свой номер телефона. Если какой-то пользователь не получил или не отреагировал на уведомление, у вас есть возможность отправить такое же уведомление этому собственнику из его профиля нажав на кнопку "Отправить код приглашения".',
  joiningRequestRejected_0: 'Заявка на присоединение отклонена!',
  joiningRequestRejected_1: 'Заявки на присоединение отклонены!',
  registryDeleteWarningTitle: 'Удаление реестра!',
  registryDeleteWarningMessageFirst: '1. Из реестра будут удалены все строки с помещениями, кроме тех, что принадлежат зарегистрированным пользователям.',
  registryDeleteWarningMessageSecond: '2. Текущий реестр будет сохранен в виде файла формата эксель. Вы сможете его найти в версиях реестра.',
  registryDeleteWarningNotification: '3. Мы настоятельно не рекомендуем удалять реестр. Если Вам нужно вносить массовые правки, проще выделить и очистить информацию в нужных строках или столбцах. Для этого выделите строки или столбцы и воспользуйтесь кнопкой',
  hideThisAlert: 'Скрыть это оповещение.',
  cantEdit: 'Редактирование поля {{name}} у НЕИЗВЕСТНОГО собственника недоступно, сначала нужно добавить ФИО',
  fio: 'ФИО зарегистрированного пользователя',
  juristicPerson: 'Юр. лица',
  states: 'Государства',
  formRegisteredType: 'Формы/Названия/ОГРН зарегистрированного {{typeString}}',
  rowsCleared: 'Строки очищены',
  statusRowsCleared: 'Статус очистки строк: {{status}}',
  hasNoRows: 'Нет строк для очистки'
};