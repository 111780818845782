"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  newComplaintsLastMonth: 'За прошлый месяц в нашем доме было подано жалоб:',
  newComplaintsLastMonthVillage: 'За прошлый месяц в нашем поселке было подано жалоб:',
  outOfThem: 'Из них публичных:',
  successfullySolvedComplaints: 'Было благополучно решено жалоб:',
  totalCountInProcess: 'Общее количество жалоб в работе -',
  complaint: 'Жалоба',
  tableTitle: 'Общее состояние по жалобам с начала года:',
  statTableHeaderAmount: 'шт',
  statTableHeaderAverageTime: 'среднее время',
  statTableTotal: 'Всего с начала года',
  statTablePublic: 'Публичные',
  statTablePrivate: 'Приватные',
  statTableSolved: 'Решенные',
  statTableDeclined: 'Отклоненные',
  daysShorten: 'дн',
  hoursShorten: 'ч',
  minutesShorten: 'м',
  tableCaption1: 'Количество жалоб. В скобках изменение за месяц',
  tableCaption2: 'Среднее время обработки жалоб дни/часы/минуты. В скобках изменение за месяц',
  newComplaintZero: 'Новых жалоб -'
};