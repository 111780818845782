"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'You have added the Sqvr to the list of spammers',
  modalTitle: 'Important!',
  modalOkButton: 'Connect mailings',
  confirme: 'OK',
  modalTelegramButton: 'connect Telegram-bot',
  modalNoButton: 'Cancel',
  button: 'receive messages',
  text: 'This means that you do not receive any messages from the Sqvr service, unless you have forgotten your password. If you want to receive messages from the Sqvr service again, click "Receive messages".',
  notification: '<span>You are not receiving e-mails because you have added Sqvr to the spammer list.</span><br/><br/><span>If you want to receive e-mail newsletters from our service again, click on the "Activate mailings" button.</span><br/><br/><span style=\\"font-weight: 600;\\">IMPORTANTLY!!!</span> <span>You must exclude the Sqvr service from the list of spammers in your email account, otherwise we will be forced to disable the e-mail mailing for you again.</span>',
  offPushOffTelegramNotification: '<span>To receive emails again, you first need to:</span><br/><br/><span> a) enable the Telergram-bot of our service<br/> or </span><br/><span>b) download and log in with your username and password in the Sqvr mobile application and check that push notifications are enabled in the account settings.<span><br/><br/><span>Click on the "Connect Telegram-bot" button or click on the button leading to your app store for downloading mobile applications.</span><br/><br/><span>As soon as we see that the Telegram bot is connected or that you have enabled push notifications in our application, the email newsletter will be enabled automatically.</span><br/><br/><span>If you do not want to activate the email newsletter, just close this window or click on the "Cancel" button.</span></span></span>',
  onPushOffTelegramNotification: '<span>To receive messages by e-mail again, you first need to click on the "Connect Telegram-bot" button</span><br/><br/><span>As soon as we see that the Telegram-bot is connected or that you are logged in to our application, the email newsletter will be enabled automatically.</span><br/><br/><span>If you do not want to activate the email newsletter, just close this window or click on the "Cancel" button.</span>',
  offPushOnTelegramNotification: '<span>To receive emails again, you first need to download and log in with your username and password in the Sqvr mobile application and check that push notifications are enabled in the account settings.</span><br/><br/><span>Click on the button leading to the App Store for downloading mobile applications.</span><br/><br/><span>As soon as we see that you have enabled push notifications in our app, the email newsletter will be enabled automatically.<br/><br/><span>If you do not want to activate the email newsletter, just close this window or click on the "Cancel" button. </span> </span> ',
  thinkWeSpammersNotification: "You've added our service to the list of spammers twice in the last 90 days. Therefore, email newsletters are temporarily disabled for you. You can either register a new e-mail address in the Sqvr or try to activate the mailing list no earlier than {{date}}.",
  cantExcludeSpam: "<span>You've added our service to the list of spammers twice in the last 90 days. Therefore, email newsletters are temporarily disabled for you.</span><br/><span>You can either register a new e-mail address in the Sqvr or try to activate the mailing list no earlier {{date}}.</span>",
  availableIn: 'Available in',
  downloadedIn: 'Downloaded in',
  thinkWeSpammers: 'Email newsletters are disabled',
  resumedSendingMessagesToYourEmail: 'You resumed sending messages to your email'
};