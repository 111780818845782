"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  fillProfileAndSaveTitle: 'Fill out your profile and save',
  fillProfileAndSaveMessage: 'If your profile is not completely filled out, you will have difficulty participating in meetings, taking surveys, posting complaints, announcements, etc.',
  confirmEmailTitle: 'Confirm e-mail',
  confirmEmailMessage: 'An email has been sent to {{email}}, please follow the link in the email to confirm your email address',
  notVerifiedEmail: 'Unconfirmed e-mail address',
  proceedToApplicationTitle: 'Successful registration!',
  proceedToApplicationMessage: 'You can go to the mobile app for a better user experience',
  proceedToApplicationOK: 'Go to the app',
  proceedToApplicationCancel: 'Stay in the browser'
};