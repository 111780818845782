"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  addPaymentMethod: 'Add payment method',
  paymentMethod: 'Payment Method',
  payment: 'Payment',
  changePaymentMethod: 'Change payment method',
  noCardsAdded: 'No cards have been added',
  payUp: 'Pay extra',
  price: 'Cost',
  cardAddError: 'Error when adding a card.',
  cardRemoveError: 'Error when deleting a card.',
  cardSelectError: 'Error when selecting a card.',
  cardDelete: 'Deleting a card',
  cardDeleteWarning: 'Do you really want to delete this card?',
  cardWithAutoRenewalDeleteWarning: 'Do you really want to delete this card? It is currently being used for auto-renewal of the tariff. After deletion, auto-renewal will be disabled.',
  cardAutorewireLabel: 'Save the card for future payments',
  cvvHintTitle: 'CVV number location',
  cvvHintMessage: 'On Visa and Mastercard cards',
  russianCard: 'Russian card',
  foreignCard: 'Foreign card',
  stripeTokenErrorText: 'Failed to receive the card token from the payment system. Payment has not been made. Please try again',
  emailLabel: 'Enter e-mail',
  emailHint: 'We will send a check for payment to this e-mail address',
  emailErrorRequired: 'E-mail is required to send a check',
  emailError: 'Incorrect e-mail'
};