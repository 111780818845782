"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVENTS = exports.WSEvents = exports.WSUser = exports.WSMessages = exports.WSNotification = void 0;
var WSNotification;
(function (WSNotification) {
  WSNotification["NewNotification"] = "new_notification";
  WSNotification["ReadNotification"] = "read_notification";
  WSNotification["ReadAllNotification"] = "read_all_notification";
  WSNotification["SocketsPong"] = "sockets_pong";
  WSNotification["AppealsDelta"] = "appeals_delta";
  WSNotification["BulletinsWasUpdated"] = "bulletins_was_updated";
  WSNotification["UserLogout"] = "user_logout";
  WSNotification["UserRelogin"] = "user_relogin";
  WSNotification["UserActivity"] = "track_user_activity";
  WSNotification["YellowSpotsCounters"] = "yellow_spots_counters";
})(WSNotification || (exports.WSNotification = WSNotification = {}));
var WSMessages;
(function (WSMessages) {
  WSMessages["GetMessage"] = "get_message";
  WSMessages["MessageHasBeenRead"] = "message_has_been_read";
  WSMessages["AllMessagesHasBeenRead"] = "all_messages_has_been_read";
  WSMessages["EditMessage"] = "edit_message";
  WSMessages["DeleteMessage"] = "delete_message";
  WSMessages["ReadMessage"] = "read_message";
  WSMessages["UserIsTyping"] = "user_is_typing";
  WSMessages["BlockedForCurrentUser"] = "is_blocked_for_current_user";
  WSMessages["BlockedFromCurrentUser"] = "is_blocked_from_current_user";
  WSMessages["PrivateNotificationStatus"] = "user_private_notification_status";
})(WSMessages || (exports.WSMessages = WSMessages = {}));
var WSUser;
(function (WSUser) {
  WSUser["UserContactVerified"] = "user_contact_verified";
})(WSUser || (exports.WSUser = WSUser = {}));
var WSEvents;
(function (WSEvents) {
  WSEvents["ConnectionError"] = "connectionError";
  WSEvents["ConnectionLost"] = "connectionLost";
})(WSEvents || (exports.WSEvents = WSEvents = {}));
exports.EVENTS = [WSNotification.NewNotification, WSNotification.ReadNotification, WSNotification.ReadAllNotification, WSNotification.SocketsPong, WSNotification.AppealsDelta, WSNotification.BulletinsWasUpdated, WSNotification.UserLogout, WSNotification.UserRelogin, WSNotification.YellowSpotsCounters, WSMessages.GetMessage, WSMessages.MessageHasBeenRead, WSMessages.AllMessagesHasBeenRead, WSMessages.DeleteMessage, WSMessages.EditMessage, WSMessages.UserIsTyping, WSMessages.BlockedForCurrentUser, WSMessages.BlockedFromCurrentUser, WSMessages.PrivateNotificationStatus, WSUser.UserContactVerified];