"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  fillProfileAndSaveTitle: 'Заполните свой профиль и сохраните',
  fillProfileAndSaveMessage: 'Если Ваш профиль не будет до конца заполнен, у Вас будут  трудности с участием в собраниях, в опросах, размещении жалоб, объявлений и др.',
  confirmEmailTitle: 'Подтвердите e-mail',
  confirmEmailMessage: 'На адрес {{email}} было отправлено письмо, перейдите по ссылке в нем для подтверждения вашего e-mail',
  notVerifiedEmail: 'Неподтвержденный адрес e-mail',
  proceedToApplicationTitle: 'Успешная регистрация!',
  proceedToApplicationMessage: 'Вы можете перейти в мобильное приложение для более удобного использования',
  proceedToApplicationOK: 'Перейти в приложение',
  proceedToApplicationCancel: 'Остаться в браузере'
};