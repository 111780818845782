"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  dialogsFeedDrawerTitle: 'Сообщения',
  noMessages: 'Здесь будут отображаться Ваши сообщения и сообщения пользователя.',
  noMessagesAmi: 'Здесь будут отображаться Ваши сообщения.',
  searchTextLimitError: 'Максимум {{limit}} символов. Вы ввели лишние {{overLimit}}.',
  noSearchResult: 'По данному запросу ничего не найдено',
  pleaseEnterAnotherQuery: 'Пожалуйста, введите другой запрос',
  userWasDeletedText: 'Вы не можете писать этому пользователю, так как он удалён из&nbsp;СКВЕРа.',
  youAreBlacklistedText: 'Пользователь отключил получение сообщений. Вы не можете \nему написать.',
  userWasBlacklistedTooltipText: 'Вы внесли этого пользователя в черный список. Для отправки ему сообщений исключите его из своего черного списка.',
  userWasBlacklistedFooterText: '<span>Вы внесли этого пользователя в чёрный список. Вы не получаете от него сообщений и не можете отправлять ему сообщения. </span><br>' + '<span style="display: inline-block;margin-top: 10px">Вы можете исключить его из своего чёрного списка.</span>',
  removeFromBlackListModalText: '<span>Пока пользователь находится в Вашем чёрном списке, Вы не можете писать ему и получать <br> от него сообщения, и видеть его объявления.</span><br>' + '<span style="display: inline-block;margin-top: 10px">Вы действительно хотите исключить <br> его из чёрного списка?</span>',
  noContacts: 'Если Вы не видите чата с нужным пользователем, используйте строку поиска, чтобы найти его по фамилии, имени, или по номеру помещения и начать с ним чат.',
  alertModalTitle: 'Удалить сообщение',
  removeFromBlackListTitle: 'Удалить из черного списка',
  alertModalText: 'Вы действительно хотите удалить сообщение?',
  messageWasDeleted: 'Сообщение было удалено…',
  typing: 'печатает',
  writeMessages: 'Напишите сообщение',
  inReplyTo: 'В ответ',
  removeFromBlackList: 'Удалить из черного списка',
  keepInBlackList: 'Оставить в черном списке',
  userDeletedTitle: 'Пользователь успешно удален из черного списка',
  userDeletedText: 'Теперь Вы можете писать ему и получать от него сообщения, и видеть его объявления.',
  youOffNotify: 'Вы отключили получение уведомлений. Собеседники не смогут Вам ответить.',
  amiChatText: 'Этот чат используется только для одновременной отправки Ваших сообщений всем модераторам и администратору. Они не будут отвечать Вам здесь же, но увидят сообщение и ответят в личном чате.',
  messageCopied: 'Текст сообщения успешно скопирован',
  adminModerators: 'Администратор, модераторы',
  messageWasNotEdited: 'Сообщение не отредактировано так как на него уже ответили'
};