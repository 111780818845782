"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  meetings: 'Meetings',
  polls: 'Polls',
  complaints: 'Complaints',
  announcements: 'Announcement',
  documents: 'Documents',
  usefullAddresses: 'Useful addresses',
  reports: 'Reports',
  users: 'Users',
  noDigest: 'Your facility does not have any digests yet',
  emptyDigest: 'Events in the house',
  emptyDigestVillage: 'Events in the village',
  emptyMeetings: 'There were no meeting events during this period.',
  emptyPolls: 'Eh. No one is interested in the opinions of the residents. Not a single new poll.',
  emptyAnnouncements: 'There were no new announcements during this period.',
  emptyDocuments: 'No documents were uploaded during this period.',
  emptyUsefullAddresses: 'No new addresses have been added during this period',
  emptyReportsNotVillage: 'No reports were filed for last month because there is no house budget information in the Sqvr service.',
  emptyReportsVillage: 'No reports were filed for last month because there is no township budget information in Sqvr\'s service.',
  emptyReportsHelp: 'Keeping a report in Sqvr allows owners to receive monthly information about all expenses and works performed in the house. If you would like to be informed about the work being done and reports being submitted, vote at a meeting or take a survey in your building',
  emptyReportsHelpVillage: 'Maintaining a report in Sqvr allows owners to receive monthly information about all expenses and works carried out in the village. If you would like to be informed about the work being done and reports being submitted, please vote at a meeting or conduct a survey in your community',
  meetingsActionText: 'View all meetings.',
  pollsActionText: 'View all surveys.',
  complaintsActionText: 'View all complaints.',
  announcementsActionText: 'View all announcements.',
  documentsActionText: 'View all documents.',
  usefullAddressesActionText: 'View all addresses.',
  reportsActionText: 'View all reports.',
  usersActionText: 'View all users.',
  documentsFilledMain: 'You have new documents:',
  usefulAddressesMain: 'Neighbors generously share helpful addresses. New places have been added to the Square',
  usersMainPart1: 'Yes, your addition has arrived!',
  usersMainPart2: 'Way to go.',
  usersMainNeutral: 'The new users this month-',
  usersItemTotal: 'Total in our facility:',
  usersItemOwners: 'owners',
  usersItemContractors: 'contractors',
  usersItemEmployees: 'partnership staff',
  usersItemEmployeesStructureTypeOther: 'mC staff',
  usersTurnOffNotifications: 'Disabled notifications - ',
  announcementMain: 'In the past month there were published announcements - {{all}}, announcements with SOS status - {{all}}',
  announcementItem: 'Announcement:'
};