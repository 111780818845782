"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  comments: 'Комментарии',
  show: 'Показать',
  showMoreComments: 'Показать еще комментарии',
  showPrevComments: 'Показать предыдущие комментарии',
  addComment: 'Добавить комментарий',
  answer_0: '{{count}} ответ',
  answer_1: '{{count}} ответа',
  answer_2: '{{count}} ответов',
  hide: 'Скрыть',
  notCommentedYet: 'Вы еще не оставляли свой комментарий. Скажите что Вы думаете о проекте решения?',
  commentPlaceholder: 'Если хотите, прокомментируйте',
  save: 'Сохранить',
  withoutComment: 'Без комментариев',
  cancel: 'Отменить',
  addNewCommentToThread: 'Добавьте новый комментарий в это обсуждение',
  changeReaction: 'Вы хотите изменить оценку проекта решения?',
  saveSuccess: 'Комментарий успешно сохранен.',
  from: ' из ',
  edit: 'Редактировать',
  change: 'Изменить',
  delete: 'Удалить',
  editComment: 'Редактирование комментария',
  deleteSuccess: 'Комментарий успешно удален.',
  commendedDecision: 'Прокомментировать проект решения'
};