"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Technical Support',
  email: 'Your e-mail',
  message: 'If you have any difficulties with the system or you have found any problem, please let us know',
  messageSentTitle: 'Message sent',
  messageSentText: 'Thank you for your request. In the near future, technical support staff will view your request and contact you.'
};