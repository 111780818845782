"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  createSuccess: 'Новая претензия успешно создана',
  editFieldTitle: 'Заголовок претензии',
  editFieldDate: 'Дата и время начала претензии',
  editFieldRSO: 'Выберите РСО/УК',
  editFieldDescriptionRSO: 'Описание претензии к РСО',
  daysLeftTooltip: 'По истечению этого срока подписание претензии станет невозможным',
  requestCountTooltip: 'Количество обращений',
  appeal: 'обращений',
  days: 'дней',
  filterInitiator: 'Инициатор',
  filterStatus: 'Статус',
  filterRSO: 'РСО',
  notSelectRowsToDelete: 'Нечего удалять. Сначала выделите строку',
  notSelectRowsToExport: 'Не помечены строки для скачивания',
  notSelectRowsToSend: 'Не помечены строки для отправки уведомлений',
  pretensionCreate: 'Создать новую претензию',
  pretensionCreature: 'Создание новой претензии',
  exportPretension: 'Экспорт претензии и выбранных обращений',
  sendingNotification: 'Уведомить пользователей',
  selectedDelete: 'Удалить выбранное',
  pretensionEmpty: 'Претензии не найдены',
  requestEdit: 'Редактирование обращения',
  newRequest: 'Новое обращение/заявка',
  whoAsked: 'Кто обращался',
  requestNumber: 'Номер обращения/заявки',
  disabledDateTooltip: 'Дата и время доступны для редактирования в претензии со статусом "В работе"',
  notificationHint: 'Опишите возникшую проблему. Дайте собственникам инструкцию как действовать в этой ситуации: А) куда звонить, Б) что говорить и В) что делать. Чем больше собственников позвонит и зафиксирует свое обращение в РСО/УК, тем быстрее будет устранена проблема и тем проще будет получить потом перерасчет.',
  freeTariff: 'Недоступно при бесплатном тарифе',
  requests: {
    title: 'Обращения собственников в РСО/УК',
    addRequest: 'Добавить обращение',
    createBeforeAdd: 'Добавить обращение можно только в созданной претензии',
    columns: {
      owner: 'ФИО',
      address: 'Собственность',
      id: '№',
      number: 'Номер'
    },
    filter: {
      street: 'Адрес дома',
      entrance: 'Номер подъезда',
      isRegistered: 'Регистрация собственников',
      contactInfo: 'Контактная информация'
    },
    modalView: {
      titleEdit: 'Редактирование обращения',
      titleNew: 'Новое обращение/заявка',
      fields: {
        whoApplied: 'Кто обращался',
        number: 'Номер обращения/заявки'
      }
    },
    registered: 'Зарегистрированные',
    unregistered: 'Незарегистрированные',
    notSpecified: 'Не указан',
    emailSpecified: 'Указана почта',
    phoneSpecified: 'Указан телефон',
    titleDelete: 'Вы хотите удалить претензию?'
  },
  inProgress: 'В работе',
  deadlineWasExceededWithMoney: 'Исправлено. Положен перерасчет',
  fixedInDueTimeWithoutMoney: 'Исправлено. Без перерасчетов',
  success: 'Получен перерасчет'
};