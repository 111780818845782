"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  joiningRequestsInSelection_0: 'The line with the application for accession is selected, this application will be rejected when cleared!',
  joiningRequestsInSelection_1: 'Rows with applications for accession are selected, if cleared, these applications will be rejected!',
  joiningRequestsInSelection_2: 'Rows with applications for accession are selected, if cleared, these applications will be rejected!',
  deleteJuristicPerson: 'Deleting a registered owner {{juristicPersonLegalForm}} {{juristicPersonName}} {{ownershipCountText}} is not possible, the user will not have any ownership in the object.',
  registeredUsers: 'to already registered users.',
  ofThese: 'of these',
  deletePerson: 'Deleting a registered owner {{firstName}} {{lastName}} {{ownershipCountText}} is not possible, the user will not have any ownership in the object.',
  fromThisRoom: 'from this room',
  sureWantDelete: 'Are you sure you want to delete the registered owner {{firstName}} {{lastName}} {{ownershipCountText}}?',
  fromTheseRooms: 'from {{ownershipsInSelectedRows}}-x rooms',
  rowsClearing: 'Rows clearing',
  acceptClearing: 'Confirm clearing',
  select: 'You selected'
};