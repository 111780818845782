"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  loginToSendVoteResultTitle: 'Войдите для отправки ответов',
  loginToSendVoteResultMessage: 'Чтобы ваши ответы были учтены в результатах опроса, вам нужно войти в существующий аккаунт или присоединиться к объекту отправив заявку администратору',
  joinToObject: 'Присоединиться к\xA0объекту',
  requiredAuthorizationMessage1: 'Данный функционал — только для зарегистрированных пользователей.',
  requiredAuthorizationMessage2: 'Авторизуйтесь, воспользовавшись кнопкой «ВХОД», если Вы уже зарегистрированы в этом Объекте.',
  requiredAuthorizationMessage3: 'Отправьте заявку на присоединение к данному Объекту, если хотите получить доступ к данному функционалу.',
  voteError: 'Произошла ошибка. Обратитесь к администратору',
  downloadTitle: 'Реестр голосующих успешно отправлен на почту!'
};