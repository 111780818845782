"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  unknown: 'Not specified',
  apartment_living: 'Residential',
  apartment_nonliving: 'Non-residential',
  apartment_room: 'Room in apartment',
  apartment_unknown: '$t(modules.app.types.unknown)',
  apartment_house: 'House',
  apartment_land: 'Land plot',
  apartment_townhouse: 'Apartment in a townhouse',
  membership_unknown: '$t(modules.app.types.unknown)',
  membership_member: 'Member of the Partnership',
  membership_notMember: 'Not a member of the Partnership',
  ownership_unknown: '$t(modules.app.types.unknown)',
  ownership_share: 'Equity',
  ownership_join: 'Joint',
  person_unknown: '$t(modules.app.types.unknown)',
  person_natural: 'Natural entity',
  person_legal: 'Legal entity',
  person_government: 'State'
};