"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  ownersSelect: 'Выбор собственников',
  ownersSearch: 'Поиск собственника',
  selectAll: 'Выбрать всех',
  needSelectSomeone: 'Нужно кого-то выбрать'
};