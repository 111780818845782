"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  ownersNotify: 'Notices to owners',
  canNotSendNotifyForNotRegisteredUsers: 'Cannot send notifications to selected users. They are not yet registered to Svqr.',
  rows_0: 'string',
  rows_1: 'lines',
  rows_2: 'lines',
  rowsSelect_0: '{{count}} line',
  rowsSelect_1: '{{count}} lines',
  rowsSelect_2: '{{count}} lines',
  rowsRelate_0: '{{count}} line refers to',
  rowsRelate_1: '{{count}} lines refer to',
  rowsRelate_2: '{{count}} lines refer to',
  selectedUser_0: '{{count}} of the selected user',
  selectedUser_1: '{{count}} selected users',
  selectedUser_2: '{{count}} selected users',
  notRegisteredUser_0: '{{count}} user not registered',
  notRegisteredUser_1: '{{count}} user not registered',
  notRegisteredUser_2: '{{count}} no users registered',
  userWithoutContactData: '{{selectedUsers}} of {{allUsersCount}} do not have an email or phone number to contact.',
  notRegisteredUserAndWithoutEmail: '{{selectedUsers}} users do not have an email or phone number to contact and {{notRegisteredUsers}} of {{allUsersCount}}.',
  selectionWithoutNotRegisteredUsers: 'You have selected {{selectedRows}}, of which {{relatedRows}} to users who are not yet registered with Sqvr and cannot be notified'
};