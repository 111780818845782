"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
function romanize(num_) {
  const lookup = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  let roman = '';
  let i;
  let num = num_;
  for (i in lookup) {
    if (lookup.hasOwnProperty(i)) {
      while (num >= lookup[i]) {
        roman += i;
        num -= lookup[i];
      }
    }
  }
  return roman;
}
exports.default = romanize;