"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  weSentCode_email: 'We sent you a six digit code to your address:',
  weSentCode_phone: 'We sent you a six-digit code to your phone number:',
  inputCodeFrom_email: 'Enter the code you received or follow the link in the message.',
  inputCodeFrom_phone: 'Enter the code you received or follow the link in the sms.',
  afterCodeInput: 'After entering the code, you will be directed to the registration page.',
  askAdminForCode: 'If you do not have the code, please contact the administrator.',
  checkContact_email: 'Check your email',
  checkContact_phone: 'Check your phone',
  inputCode: 'Enter the code you received',
  inputGettingCode: 'Enter the code you received earlier:',
  noCode: 'If you do not have a code, please contact your administrator or ',
  register: 'sign up',
  haveAccount: ' on your own. If you already have an account, ',
  inputLoginAndPassword: 'enter your username and password.',
  continue: 'Continue',
  error: 'Incorrect code. Try entering it again.',
  emailConfirmationCodeTitle: 'Confirmation code',
  emailConfirmationInputCode: 'Enter the code you received:',
  emailConfirmationEnsure: 'Wait until you receive an email with the registration code. Check for spam. ',
  emailConfirmationAlreadyConfirmed: 'If you are already registered, then '
};