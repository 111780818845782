"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  addressForJoin: 'The selection of a specific house in this Object will be possible on the next step. Here, only the registration address of the entire Object is shown',
  selectHoa: 'You can only search for a house or village by address',
  disabledForInvite: 'This field cannot be edited when registering by invitation code',
  selectAddress: 'You can switch the search for a house or village to a search by its name. This will improve the search results for objects already registered in SQVR'
};