"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Сохранение кадастрового номера МКД',
  modalTitleGroup: 'Сохранение кадастровых номеров группы МКД',
  modalYes: 'да, сохранить',
  modalNo: 'не сохранять',
  cadastralNumber: 'Кадастровый номер',
  cadastralChangeNotification0: 'Внимательно проверьте номер. Потом его можно будет изменить только после обращение в техподдержку.',
  cadastralChangeNotification1: 'Внимательно проверьте номера. Потом их можно будет изменить только после обращения в техподдержку.',
  tryingToCheck: 'мы пытаемся проверить ваш адрес, пожалуйста подождите',
  tooltip: 'Введен недействительный кадастровый номер. Введите правильный номер',
  addressNotFound: 'Кадастровый код не найден, возможно вы ввели кадастровый код не относящийся к МКД(ОКС)',
  wrongObjectType: 'Введенный кадастровый номер не принадлежит дому',
  badRequest: 'Не удалось выполнить запрос.',
  tryLater: 'Попробуйте позже',
  sameNumbers: 'Вы ввели одинаковые кадастровые номера для разных домов',
  notFound: 'Адрес не найден',
  noCadastralNumbersForHoa: 'Обратите внимание, что в Вашем Объекте есть сразу несколько МКД. Как только Вы введете правильные кадастровые номера для каждого МКД и мы сможем верифицировать их в ЕГРН, станут доступны платные услуги.  Расчет стоимости платных услуг зависит от количества помещений во всех МКД, входящих в данный Объект.',
  noCadastralNumbersForOther: 'Как только Вы введете правильный кадастровый номер и мы сможем верифицировать его в ЕГРН, станут доступны платные услуги, потому что расчет их стоимости зависит от количества помещений в вашем доме.'
};