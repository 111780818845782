"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  requestCode: 'Get Code',
  resendCode: 'Get the code again',
  requestCodeTimoutComment: 'The code has been sent. You can request the code again in ',
  inputReceivedCode: 'Confirmation code',
  inputPhoneNumber: 'Enter phone number',
  savePhone: 'Save your phone number in your profile',
  requestCodeAgain: 'If you do not receive a code SMS, you can request the code again.',
  disableTooltipTextShouldWait: 'Time has not yet expired to resubmit the code. Wait.',
  disableTooltipTextNoPhone: 'Please provide a correct phone number to send a confirmation code'
};