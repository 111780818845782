"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  addToNewAccount: 'Для добавления объекта в новый аккаунт, введите не зарегистрированный в СКВЕР e-mail',
  addToNewAccountWithPass: 'Вы можете добавить объект в новый аккаунт, введите и подтвердите пароль',
  addToExistAccount: `Чтобы добавить объект в уже существующий аккаунт, ведите e-mail и пароль от аккаунта`,
  addVillageToExistsAccount: `Вы можете добавить новый поселок в уже существующий аккаунт, для этого введите пароль`,
  addTownToExistsAccount: `Вы можете добавить новый дом в уже существующий аккаунт, для этого введите пароль`,
  accountAlreadyExists: 'Такой аккаунт уже существует',
  socialNetworkAccountAlreadyExists: 'Регистрация невозможна. Эта соцсеть уже зарегистрирована в данном Объекте.',
  logInObject: 'Войдите в объект',
  joiningRequestWasSent: 'Заявка на присоединение была отправлена Администратору объекта {{- hoaName}}',
  joiningRequestMessage: 'В случае ее одобрения, Вы получите сообщение с кодом регистрации на e-mail или по смс.\nСейчас вы будете перенаправлены на страницу объекта как гость.',
  joiningRequestDoneMessageOne: 'Вы получите sms со ссылкой, пройдя по которой, Вы подтвердите свой телефон и завершите регистрацию.',
  joiningRequestDoneMessageTwo: 'Сейчас вы будете направлены на страницу Объекта как гость.',
  joiningRequestDoneTitle: 'Регистрация почти закончена',
  joiningAnExistingObject: 'Вы решили присоединиться к уже существующему дому. Нужно ввести информацию о себе и создать аккаунт. Администратор дома получит Вашу заявку на присоединение и примет решение.'
};