"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  placeholder: 'Comment on the reason for the edit',
  placeholderWithMinSymbolsCount: 'Comment on the reason for the edit',
  saveEnteredData: 'Do you save the data you have entered?',
  placeholderDelete: 'Comment on the reason for the deletion',
  title: 'Obligatory comment'
};