"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  dialogsFeedDrawerTitle: 'Messages',
  noMessages: "This is where your posts and the user's posts will be displayed.",
  noMessagesAmi: 'This is where your messages will be displayed.',
  searchTextLimitError: 'Maximum {{limit}} characters. You have entered an extra {{overLimit}}.',
  noSearchResult: 'Nothing was found for this request',
  pleaseEnterAnotherQuery: 'Please enter a different query',
  userWasDeletedText: 'You cannot post to this user as they have been removed from SQVR.',
  youAreBlacklistedText: 'The user has turned off receiving messages. You cannot message them',
  userWasBlacklistedTooltipText: 'You have blacklisted this user. To send them messages, remove them from your blacklist.',
  userWasBlacklistedFooterText: '<span>You have blacklisted this user. You do not receive messages from this user and cannot send messages to this user. </span><br><span style=\\"display: inline-block;margin-top: 10px\\">You can remove them from your blacklist.</span>',
  removeFromBlackListModalText: '<span>As long as a user is on your blacklist, you cannot write to him or receive <br> messages from him or see his ads.</span><br><span style=\\"display: inline-block;margin-top: 10px\\">Do you really want to remove <br> them from your blacklist?</span>',
  noContacts: "If you don't see a chat with the desired user, use the search bar to find them by last name, first name, or room number and start a chat with them.",
  alertModalTitle: 'Delete message',
  removeFromBlackListTitle: 'Remove from blacklist',
  alertModalText: 'Do you really want to delete the message?',
  messageWasDeleted: 'Message has been deleted…',
  typing: 'typing',
  writeMessages: 'Write a message',
  inReplyTo: 'In reply to',
  removeFromBlackList: 'Remove from blacklist',
  keepInBlackList: 'Leave in blacklist',
  userDeletedTitle: 'User successfully removed from the blacklist',
  userDeletedText: 'You can now write to them and receive messages from him and see his announcements.',
  youOffNotify: 'You have disabled notifications. Your contacts will not be able to reply to you.',
  amiChatText: 'This chat is only used to send your messages to all moderators and admin at the same time. They will not reply to you here, but will see the message and reply in private chat.',
  adminModerators: 'Administrator, moderators',
  messageCopied: 'Message text successfully copied',
  messageWasNotEdited: 'Message not edited as it has already been answered'
};