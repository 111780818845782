"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  delete: 'Удалить опрос?',
  cancel: 'Отменить опрос?',
  votes: 'Ваш голос учтён!',
  voteWarning: 'Вы выбрали ответы не на все вопросы!'
};