"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  password: 'Password',
  oldPassword: 'Old password',
  newPassword: 'New password',
  confirmPassword: 'Repeat the new password',
  lastName: 'Surname',
  firstName: 'Name',
  middleName: 'Middle Name',
  nickname: 'Nickname',
  description: 'Position',
  additionalInfo: 'Additional information:',
  position: 'Responsibilities',
  juristicPersonName: 'Name',
  juristicPersonLegalForm: 'Form',
  juristicPersonLegalFormFull: 'Legal form',
  juristicPersonOgrn: 'Origin',
  juristicPersonInn: 'Tax number',
  managementCompanyInn: 'TIN of the management company',
  managementCompanyInnPlaceholder: 'Enter the TIN of the management company',
  managementCompanyLegalForm: 'Form',
  managementCompanyLegalFormFull: 'Legal form',
  managementCompanyLegalName: 'Management company name',
  snils: 'Individual insurance number',
  phone: 'Phone',
  phoneNumber: 'Phone number',
  email: 'E-mail',
  dobDate: 'Date of birth',
  fb: 'Facebook',
  twitter: 'Twitter',
  vk: 'VKontakte',
  site: 'Site address',
  telegram: 'Telegram',
  whatsapp: 'WhatsApp',
  documentType: 'Name',
  documentNumber: 'Number',
  documentIssueDate: 'Date of issue',
  personType: 'Type of owner',
  buildingStreet: 'Street',
  buildingNumber: 'no. of house / building',
  ownerType: 'Type of ownership',
  propertyUsageType: 'Room type',
  apartmentNumber: 'Number of Appartments',
  totalArea: 'Area',
  ownershipShare: 'Share',
  entrance: 'Entrence',
  floor: 'Floor',
  allowMeeting_own: "You're disqualified from voting",
  allowMeeting_all: 'The owner is prevented from voting',
  toAdmin: 'Contact the administrator to resolve the problem',
  reason: 'Reason:',
  additionalEmail: 'Additional E-mail',
  mainEmail: 'Primary E-mail',
  additionalPhone: 'Additional Phone',
  mainPhone: 'Basic Phone',
  organization: 'Company',
  ownerDocumentType: 'Document type',
  docNumber: 'Document number',
  whenDocumentWasIssued: 'When the document was issued',
  apartNumber: 'Room number',
  apartArea: 'Floor area',
  entranceNumber: 'Entrance number',
  ownershipDocumentName: 'Type of document',
  issueDate: 'Date of issue',
  phoneAlreadyUse: 'This phone number is already in use',
  emailAlreadyUse: 'This e-mail address is already in use',
  ru: 'RU',
  whoAreYou: 'Who are you?',
  resident: 'Owner',
  manager: 'Manager',
  expert: 'Expert',
  natural: 'Individual',
  legal: 'Legal entity',
  index: 'Zip code',
  town: 'City/settlement',
  area: 'Neighborhood',
  streetType: 'Street type',
  streetName: 'Street name',
  houseNumber: 'House number',
  unit: 'Block',
  federateEntity: 'Subject of the Federation',
  organizationName: 'Management company name',
  post: 'Position',
  director: 'Director',
  additionalInfoShort: 'Additional info',
  lastVisit: 'Last visit date',
  createdPolls: 'Created polls',
  rating: 'Rating',
  contactPeople: 'Contact people',
  contractorForComplaints: 'Contractor for complaints',
  accountant: 'Accountant',
  government: 'Government',
  commiteeMember: 'Committee member',
  submittedReports: 'Submitted reports',
  placedAds: 'Placed ads',
  complaintsHaveBeenSubmitted: 'Complaints have been submitted',
  complaintsHaveBeenConsidered: 'Complaints have been considered',
  vicePresident: 'Vice President',
  contactPerson: 'Contact person',
  president: 'President',
  secretary: 'Secretary',
  reviewedAndSubmittedReports: 'Reviewed/submitted reports',
  participatedInMeetings: 'Participated in meetings',
  conductedOnWebsite: 'Conducted on the website',
  changeRegistrySettings: 'Change registry settings',
  sharedOwnership: 'Shared ownership',
  jointOwnership: 'Joint ownership',
  unknownOwnership: 'Unknown ownership',
  changeSettings: 'Change settings',
  contactDetails: 'Contact details'
};