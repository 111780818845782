"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: 'What do I need to do?',
  goToPrevAppealButtonTooltip: 'Go to the previous hint',
  goToNextAppealButtonTooltip: 'Go to the next hint',
  collapseAppeals: 'Collapse hints',
  expandAppeals: 'Expand all hints'
};