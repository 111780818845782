"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  yes: 'Да',
  no: 'Нет',
  ok: 'Ок',
  save: 'Сохранить',
  notSave: 'Не сохранять',
  close: 'Закрыть',
  cancel: 'Отменить',
  retry: 'Повторить',
  cancelShort: 'Отмена',
  cancelChanges: 'Отменить изменения',
  exit: 'Выйти',
  select: 'Выбрать',
  search: 'Поиск',
  all: 'Все',
  settings: 'Настройки',
  home: 'Главная',
  registry: 'Реестр',
  userRights: 'Права пользователей',
  committees: 'Комитеты',
  contractors: 'Подрядчики',
  generalInfo: 'Раскрытие информации',
  enter: 'Войти',
  entered: 'Вход',
  reset: 'Сбросить',
  warning: 'Внимание',
  forgotPassword: 'Забыли пароль?',
  continue: 'Продолжить',
  add: 'Добавить',
  create: 'Создать',
  createNew: 'Создать новый',
  requestError: 'Произошла ошибка в запросе',
  required: 'Обязательное поле',
  return: 'Вернуться',
  share: 'Поделиться',
  m2: 'м²',
  and: 'и',
  inDevelopment: 'Раздел находится в разработке',
  authorizationRequired: 'Доступно только авторизированным пользователям',
  unhandledError: 'Что-то пошло не так. Наши инженеры уже получили сообщение об ошибке и в ближайшее время приступят к её устранению. Приносим извинения за доставленные неудобства',
  badGatewayError: 'В данный момент выполняются технические работы на сервере. Попробуйте выполнить действие позже',
  contacts: 'Контакты',
  messages: 'Сообщения',
  description: 'Описание',
  witnessInfo: 'Информация о свидетеле',
  selectedName: 'Имя',
  email: 'E-mail',
  useEmail: 'Использовать E-mail',
  phone: 'Телефон',
  sms: 'SMS',
  useSms: 'Использовать SMS',
  delete: 'Удалить',
  logoutFromAccount: 'Выйти из аккаунта',
  loginInObject: 'Войти в объект',
  changesHistory: 'История изменений',
  register: 'Зарегистрироваться',
  author: 'Автор',
  status: 'Статус',
  other: 'Другое',
  filters: 'Фильтры',
  noName: 'Без названия',
  generated: 'Создан',
  date: 'Дата',
  endDate: 'Дата окончания',
  copy: 'Скопировать',
  publish: 'Опубликовать',
  hide: 'Скрыть',
  detail: 'Подробнее',
  questionNumber: 'Вопрос № {{count}}',
  averageMark: 'Средняя оценка',
  ball: 'балла',
  fromTen: 'из 10',
  additionalMenu: 'Дополнительное меню',
  template: 'Шаблон',
  useTemplate: 'Использовать шаблон',
  applyTemplate: 'Применить шаблон',
  makeCopy: 'Сделать копию',
  goToEdit: 'Перейти к редактированию',
  noVote: 'Вы еще не проголосовали.',
  vote: 'Проголосовать',
  inputYourOption: 'Введите свой вариант',
  watchResults: 'Посмотреть результаты',
  reVote: 'Переголосовать',
  preview: 'Предпросмотр',
  questions: 'Вопросы',
  edit: 'Редактировать',
  backToEditing: 'Вернуться к редактированию',
  preliminaryResults: 'Предварительные результаты',
  templateChoosing: 'Выбор шаблона',
  noTemplates: 'Шаблоны опросов не найдены',
  coverChoosing: 'Выбор обложки',
  uploadImage: 'загрузить изображение',
  pollPreview: 'Предпросмотр опроса',
  pollPublish: 'Опубликовать опрос',
  pollDelete: 'Удалить опрос',
  name: 'Название',
  addQuestion: 'Добавить вопрос',
  choose: 'Выбрать и вставить в описание',
  chooseDate: 'Выбрать и вставить в описание дату',
  chooseUserName: 'Выбрать и вставить в описание Фамилию и Имя пользователя',
  addLink: 'Добавить в описание ссылку',
  uploadLimit: 'Вы достигли лимита в 10 файлов',
  maxFilesCountExceed: 'Вы не можете прикреплять более 10 файлов',
  questionName: 'Название вопроса',
  questionType: 'Тип вопроса',
  option: 'Вариант',
  deleteOption: 'Удалить вариант',
  addOption: 'Добавить вариант',
  userInputOption: 'Позволить пользователю вводить свой вариант',
  attachFile: 'Прикрепить файл',
  useFilters: 'Используйте фильтры и поиск, чтобы быстро найти нужное',
  showAllFilters: 'Показать все фильтры',
  error: 'Ошибка',
  send: 'Отправить',
  secondsShort: 'с.',
  uploadError: 'Ошибка загрузки',
  confirm: 'Подтвердить',
  inputMessageText: 'Напишите здесь Ваше сообщение',
  change: 'Изменить',
  theme: 'Тема',
  notificationTitle: 'Заголовок уведомления',
  notifyUsers: 'Уведомить участников',
  nothingFound: 'Ничего не найдено',
  noMatch: 'Нет совпадений',
  clearSelection: 'Очистить выбор',
  complete: 'Завершить',
  comment: 'Комментарий',
  usersSearch: 'Поиск пользователей',
  loadMore: 'Загрузить ещё',
  pay: 'Оплатить',
  active: 'Активен',
  notActive: 'Не активен',
  more: 'Подробнее',
  polls: 'Опросы',
  meetings: 'Собрания',
  loading: 'Загрузка',
  noData: 'Нет данных',
  noDataToFilter: 'Нечего фильтровать. Нет данных для фильтрации.',
  filtersDeleted: 'Уже нет данных подпадающих под фильтры {{filters}}. Данные отображаются без этих фильтров',
  filterDeleted: 'Уже нет данных подпадающих под фильтр {{filters}}. Данные отображаются без этого фильтра',
  filterDeletedAll: 'Уже нет данных подпадающих под фильтр {{filters}}. Данные отображаются без фильтрации.',
  sqvrSystemUser: 'Система СКВЕР',
  addCover: 'Добавить обложку',
  sign: 'Подписать',
  refuse: 'Отказаться',
  commentAndClose: 'Прокомментировать и закрыть',
  doComment: 'Прокомментировать',
  smsVerificationCodeSent: 'Проверьте SMS, мы отправили вам код подтверждения.',
  days_0: 'день',
  days_1: 'дня',
  days_2: 'дней',
  votes_0: 'голос',
  votes_1: 'голоса',
  votes_2: 'голосов',
  join: 'Присоединиться',
  joinToObject: 'Присоединиться к объекту',
  results: 'Результаты',
  countShortTitle: 'шт',
  squareTitle: 'Площадь, м2',
  votePercentsTitle: 'Голоса, %',
  total: 'Всего',
  notSpecified: 'Не указано',
  copyLink: 'Скопировать ссылку',
  apply: 'Применить',
  important: 'Важно!!!',
  confirmSending: 'Да, отправить',
  yesDelete: 'Да, удалить',
  rejectSending: 'Нет, не отправлять',
  noUser: 'Нет собственника',
  hiddenUser: 'Скрытый собственник',
  hiddenData: 'Скрытые данные',
  legalUser: 'Юр.лицо',
  individualUser: 'Физ.лицо',
  cancelChoise: 'Отменить выбор',
  whatsapp: 'WhatsApp',
  telegram: 'Telegram',
  telegramBot: 'Telegram-bot',
  byCall: 'Позвонить по телефону',
  byEmail: 'Написать email',
  byWhatsApp: 'Написать в WhatsApp',
  byTelegram: 'Написать в Telegram',
  bySMS: 'Написать SMS',
  rejectDeleting: 'Нет, не удалять',
  paymentType: 'Способ оплаты',
  done: 'Готово',
  inviteLink: 'Поделитесь ссылкой на профиль вашего дома в Сквере с помощью социальных сетей или мессенджеров',
  appealsTooltip: 'Совет-подсказка, что нужно сделать',
  addComment: 'Добавьте комментарий',
  view: 'Просмотреть',
  noMoreShow: 'Больше не показывать',
  rulesForSendingNotifications: 'Правила отправки уведомлений',
  rulesForSendingInvitations: 'Правила отправки приглашений',
  rulesForSendingInvitationsTelegram: 'Правила отправки приглашений в Telegram-bot',
  download: 'Скачать',
  bold: 'Выделить текст жирным',
  italic: 'Выделить текст курсивом',
  underline: 'Выделить текст подчеркиванием',
  viewDocument: 'Нажмите для просмотра документа',
  menu: 'Меню',
  meetingToastText: 'Быстрый переход в Админ панель. Вы можете ознакомиться с последними событиями в собрании.',
  meetingToastTitle: 'Cобрание!',
  meetingToastButton: 'Перейти в собрание',
  payTariff: 'Оплатить тариф',
  fillFields: 'Заполните поля, для возможности оплаты тарифа',
  gotItText: 'Понятно',
  clickToSort: 'Нажмите для сортировки',
  notingToSort: 'Нечего сортировать',
  selectItemForSort: 'Выделите строки для сортировки',
  or: 'или',
  your: 'Ваш',
  selectAll: 'Выделить все',
  success: 'Успех!',
  from: 'из',
  today: 'Сегодня',
  captchaError: 'Некорректная капча. Пожалуйста начните заново',
  buildingSuffix: 'к.',
  buildingSuffix2: 'д.',
  timeAt: 'в',
  profile: 'Профиль',
  union: 'Объединить',
  answer: 'Ответ:',
  reply: 'Ответить',
  comments: 'Комментарии',
  ruble: 'Руб.',
  piecesShort: 'шт.',
  availableIn: 'Доступно в',
  downloadedIn: 'Загрузите в',
  toAddOwnerFillFields: 'Для добавления собственника требуется заполнить следующие поля: ',
  onlyJoinOwnershipIsAvailableForAddition: 'Для добавления доступна лишь совместная собственность, у вас не осталось свободной собственности',
  dateCantBeMoreCurrentDate: 'Дата не должна быть больше чем текущая дата календаря',
  dateHasError: 'Введенная вами дата содержит ошибку',
  fieldCantBeEmpty: 'Поле не может быть пустым',
  addPerson: 'Добавить собственника',
  registered: 'Зарегистрирован',
  notRegistered: 'Незарегистрирован',
  row_0: 'строка',
  row_1: 'строки',
  row_2: 'строк',
  was_0: 'была',
  was_1: 'были',
  was_2: 'было',
  deleted_0: 'удалена',
  deleted_1: 'удалены',
  deleteed_2: 'удалено',
  rowsDeleted: 'строки удалены',
  successfully: 'успешно',
  she_0: 'она',
  she_1: 'они',
  she_2: 'они',
  concern_0: 'относится',
  concern_1: 'относятся',
  concern_2: 'относятся',
  not: 'не',
  alredyRegisteredUers: 'к уже зарегистрированным пользователям.',
  forDeleteRegisteredUsers: 'Для удаления зарегистрированных пользователей кликните на их аватарку и перейдите в профиль пользователя.',
  columnSettings: 'Настройка столбцов',
  defaultSettings: 'настройки по умолчанию',
  edited: 'Редактирование',
  possibleOnly: 'возможно только через',
  goTo: 'Перейти',
  copied: 'Скопировано!',
  noInfoForCopy: 'Нет информации для копирования!',
  highPriority: 'Высокий приоритет',
  rowHasErrors: 'Строка содержит ошибки, проверьте ячейки',
  registryUnlocked: 'Реестр разблокирован, можете приступать к редактированию!',
  acceptApplication: 'Принять заявку',
  fixedAllErrors: 'Вы исправили все ошибки!',
  acceptedApplication: 'Заявка успешно принята!',
  errorWithAcceptedApplication: 'Произошла ошибка при одобрении заявки',
  fileIsEmpty: 'Файл пустой',
  errorInFileFormation: 'Ошибка в формировании файла',
  clearColumns: 'Очистить столбец',
  clearRows: 'Очистить строки',
  attach: 'Присоединить',
  serverErrorWithUploadFile: 'Ошибка сервера при загрузке файла',
  admin: 'Администратор',
  notJoin: 'Hе хочу присоединяться',
  registration: 'Регистрация',
  complaints: 'Жалобы',
  house: 'Дом',
  property: 'Собственность',
  codeSent: 'Код подтверждения',
  unknown: 'Неизвестно',
  find: 'найти',
  housing: 'Корпус',
  russia: 'Россия',
  password: 'Пароль',
  confirmPassword: 'Подтвердите пароль',
  street: 'Улица',
  userAvatar: 'Аватар пользователя',
  organization: 'Организация',
  addHouse: 'Добавить объект',
  user: 'О себе',
  realty: 'Недвижимость',
  login: 'Продолжить с помощью',
  activate: 'Код регистрации'
};