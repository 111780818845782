"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Заказ реестра собственников помещений',
  yesLabel: 'Вернуться',
  okLabel: 'Актуализировать реестр',
  noLabel: 'заказать реестр',
  tariffLabel: 'Перейти в Тарифы',
  cadastralNumber: 'Кадастровый номер Вашего дома - {{cadastralNumber}}',
  alreadyOrderRegistry: 'Вы уже заказывали реестр собственников помещений, поэтому лучше не заказывать реестр снова, а воспользоваться услугой “Актуализации реестра”. ',
  reminder: 'Напоминаем, что до {{endDate}} Вы можете еще {{restCount}} раз актуализировать реестр собственников.',
  buyActualization: 'Вы можете выбрать и оплатить услугу “Актуализации реестра” ',
  goToTariffs: 'перейдя на страницу “Тарифы”.',
  attention: 'ВАЖНО!!!',
  suggestion: ' Рекомендуем Вам активировать услугу “Актуализация реестра” только перед проведением общего собрания.',
  priceCalculating: 'Стоимость повторного заказа реестра собственников для Вашего дома - ',
  priceActualization: 'Стоимость актуализации реестра собственников для Вашего дома - ',
  price: '{{price}} рублей',
  registryOrderPrice: 'Стоимость {{price}} рублей. Рассчитана как количество помещений в МКД умноженное на {{cost}} рублей.',
  calculatingSchemePart1: 'Точные формулы расчета стоимости ',
  calculatingSchemeInfoModalTitle: 'Точная формула расчета стоимости ',
  calculatingSchemePart2: 'заказа реестра',
  calculatingSchemePart3: ' и ',
  calculatingSchemePart4: 'актуализации реестра.',
  supportPart1: 'Если Вы считаете, что использован не верный кадастровый номер для вашего МКД, ',
  supportPart2: 'обратитесь, пожалуйста, в техподдержку.',
  tooltipOrderTitle: 'Точная стоимость заказа реестра собственников',
  tooltipOrderTitleMKD: ' для МКД, расположенного по адресу: ',
  tooltipOrderTitleGroup: ' для группы МКД, расположенных по следующим адресам: ',
  step: 'Шаг ',
  tooltipPart1: ' Мы узнали общее количество помещений в Вашем МКД.',
  tooltipCode1: 'В Объекте капитального строительства (ОКС) с кадастровыми номерами ',
  tooltipApartments_0: 'имеется {{count}} помещение',
  tooltipApartments_1: 'имеется {{count}} помещения',
  tooltipApartments_2: 'имеется {{count}} помещений',
  tooltipCalcApartments_0: 'имеется {{count}} помещение (расчетное количество помещений = {{count}})',
  tooltipCalcApartments_1: 'имеется {{count}} помещения (расчетное количество помещений = {{count}})',
  tooltipCalcApartments_2: 'имеется {{count}} помещений (расчетное количество помещений = {{count}})',
  tooltipPart2: 'В Объекте капитального строительства (ОКС) под кадастровым номером {{cadastralCode}}',
  tooltipPart5: ' Вычисление расчетного количества помещений. Применение понижающего коэффициента {{coefficient}}.',
  tooltipPart6: 'Как правило в МКД есть не только помещения, у которых есть конкретные собственники, но и помещения, относящиеся к общедомовому имуществу(ОДИ). Сколько таких помещений никто предварительно не знает. Поэтому для расчета мы применяем понижающий коэффициент {{coefficient}}.',
  tooltipPart7: 'Мы умножаем {{amount}} * {{coefficient}} = {{total}} Округляем в меньшую сторону до целого числа и получаем расчетное количество помещений МКД - {{rounding}}.',
  tooltipPart9: ' Стоимость заказа реестра собственников',
  tooltipPart10: 'Перемножаем расчетное количество помещений на {{cost}} рублей за одно помещение и получаем стоимость реестра = {{finalCost}} рублей.',
  tooltipPart11: 'Перемножаем расчетное количество помещений на {{cost}} рублей за одно помещение и получаем стоимость  Актуализации реестра = {{finalCost}} рублей.',
  tooltipActualizeTitle: 'Точная стоимость Актуализации реестра собственников ',
  tooltipActualizeCost: ' Стоимость Актуализации реестра собственников',
  example1: ' Узнать общее количество помещений в МКД',
  example2: 'Получение общей выписки по дому, содержащей кадастровые номера всех помещений в МКД(ОКС)',
  example3: 'Пример: В выписке по ОКС указаны 538 помещений, куда включены лестницы, подвалы, стояки, чердаки и т.д. Мы умножаем 538 * {{coefficient}} = {{total}} Округляем в меньшую сторону до целого числа и получаем расчетное количество помещений МКД - {{rounding}}.',
  example4: 'Перемножаем расчетное количество помещений на {{cost}} рублей за одно помещение и получаем стоимость реестра = {{finalCost}} рублей.',
  actualizationTitle: 'Идет актуализация реестра'
};