"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  title: '1. There is no cadastral number',
  noSettingsTitle: 'The number of rooms is not specified in the House Settings',
  noLabel: 'Back',
  yesLabel: 'Go to settings',
  text1: 'The service is not available to you. The registry is not sufficient for ordering:',
  text2: '1. There is no cadastral number for the ICD. ',
  text3: 'Go to Settings',
  text4: 'to enter the cadastral number.',
  text5: '2. We do not yet know the number of premises in MFBs. And it is on this basis that all paid tariffs and most individual limits are calculated.',
  text6: '3. The number of rooms has not been entered in the House Settings.',
  text7: '4. After completing steps. 1-3 it will be possible to pay for the registry order',
  tooltip1: 'In order to generate a reliable register of owners we need to know:',
  tooltip2: '1. Cadastral number of the house',
  tooltip3: '2. Cadastral numbers of premises in the house',
  tooltip4: '3. Total number of rooms in the house.',
  tooltip5: 'If some information is missing, we cannot calculate the cost of the service and proceed with the order.',
  condo1: '1. Not every ICD has a cadastral number. ',
  condo2: 'to enter cadastral numbers for each condo.',
  condo3: '2. We do not yet know the number of premises for each MFB. And it is on this basis that all paid tariffs and most individual limits are calculated. ',
  condo4: '3. The number of rooms is not entered for each MFB in the Object Settings.',
  condo5: ''
};