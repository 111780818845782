import { getApolloClient, meStateQuery } from '@sqvr/protocol';
import { prepareLocale } from '@sqvr/i18n';
import getErrorFromGql from './_app/factories/helpers/getErrorFromGql';

export async function fetchMeDataAndInitializeLocale() {
  let data = null;
  const client = getApolloClient();

  try {
    data = await client.query({
      query: meStateQuery,
    });
    // Загрузим текста локализации перед стартом
    await prepareLocale(data.data.me.state.language);
  } catch (error) {
    console.error('Failed to load locale', error);
    // Если произошла ошибка, загружаем локаль по умолчанию
    await prepareLocale();
    data = getErrorFromGql(error);
  }

  return data;
}
